/* ==================== */
/* ページネーション */
/* ==================== */
import React from 'react';
import PropTypes from 'prop-types';

import './Pagination.css';

const Pagination = ({ totalPages, currentPage, onPageChange, totalItems }) => {
  const isDisabled = totalItems === 0;
  return (
    <div className="pagination-container">
      <button className="page-button" id="first" key="first" disabled={currentPage === 1 || isDisabled} onClick={() => onPageChange(1)}>
        
        <svg
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          width="20px"
          height="20px"
          viewBox="0 0 24 24"
          aria-labelledby="chevronsLeftIconTitle"
          stroke={currentPage === 1 || isDisabled ? "var(--disable-font-color)" : "var(--blue-font-color)"}
          strokeWidth="2.0"
          strokeLinecap="square"
          strokeLinejoin="miter"
          fill="none"
          color={currentPage === 1 || isDisabled ? "var(--disable-font-color)" : "var(--blue-font-color)"}
        >
          <title id="chevronsLeftIconTitle">最初へ</title>
          <polyline points="11 17 6 12 11 7 11 7" />
          <polyline points="17 17 12 12 17 7 17 7" />
        </svg>
        
      </button>

      <button className="page-button" id="prev" key="prev" disabled={currentPage === 1 || isDisabled} onClick={() => onPageChange(currentPage - 1)}>
        <svg
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          width="20px"
          height="20px"
          viewBox="0 0 24 24"
          aria-labelledby="chevronLeftIconTitle"
          stroke={currentPage === 1 || isDisabled ? "var(--disable-font-color)" : "var(--blue-font-color)"}
          strokeWidth="1.9"
          strokeLinecap="square"
          strokeLinejoin="miter"
          fill="none"
          color={currentPage === 1 || isDisabled ? "var(--disable-font-color)" : "var(--blue-font-color)"}
        >
          <title id="chevronLeftIconTitle">前へ</title>
          <polyline points="14 18 8 12 14 6 14 6" />
        </svg>
      </button>

      <button className="page-button" id="current" key={currentPage} style={{ fontWeight: 'bold', cursor: 'auto' }}>
        {currentPage}
      </button>

      <button className="page-button" id="next" key="next" disabled={currentPage === totalPages || isDisabled} onClick={() => onPageChange(currentPage + 1)}>
        <svg
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          width="20px"
          height="20px"
          viewBox="0 0 24 24"
          aria-labelledby="chevronRightIconTitle"
          stroke={currentPage === totalPages || isDisabled ? "var(--disable-font-color)" : "var(--blue-font-color)"}
          strokeWidth="1.9"
          strokeLinecap="square"
          strokeLinejoin="miter"
          fill="none"
          color={currentPage === totalPages || isDisabled ? "var(--disable-font-color)" : "var(--blue-font-color)"}
        >
          <title id="chevronRightIconTitle">次へ</title>
          <polyline points="10 6 16 12 10 18 10 18" />
        </svg>
      </button>

      <button className="page-button" id="last" key="last" disabled={currentPage === totalPages || isDisabled} onClick={() => onPageChange(totalPages)}>
        <svg
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          width="20px"
          height="20px"
          viewBox="0 0 24 24"
          aria-labelledby="chevronsRightIconTitle"
          stroke={currentPage === totalPages || isDisabled ? "var(--disable-font-color)" : "var(--blue-font-color)"}
          strokeWidth="2.0"
          strokeLinecap="square"
          strokeLinejoin="miter"
          fill="none"
          color={currentPage === totalPages || isDisabled ? "var(--disable-font-color)" : "var(--blue-font-color)"}
        >
          <title id="chevronsRightIconTitle">最後へ</title>
          <polyline points="13 7 18 12 13 17 13 17" />
          <polyline points="7 7 12 12 7 17 7 17" />
        </svg>
      </button>

      <span key="totalItems" className="total-items">{totalItems} 件</span>
    </div>
  )
}

Pagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  totalItems: PropTypes.number.isRequired,
};

export default Pagination;