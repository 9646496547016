/* ==================== */
/* LCモーダル */
/* ==================== */
import React, { useEffect, useState, useRef } from 'react';
import BottomSheet from '../BottomSheet/BottomSheet';
import Loading from '../Loading/Loading';
import { ANSWER_TYPE, RESPONSE, TEST_MODE } from '../../const/Enum';
import { ErrorMessage, Message } from '../../const/Constant';
import { requestApiLoadAndBottom } from '../../utils/apiLoadUtil';
import './TestModal.css';

/**
 * @param {string} jobId 研修ID
 * @param {string} jobTitle 研修名
 * @param {array} testData 問題リスト
 * @param {boolean} isOpen モーダルを開くかどうか
 * @param {function} closeModal モーダルを閉じる
 * @param {int} mode {1:採点モード, 2:受講モード, 3:プレビューモード}
 * @param {function} setLCEnd 採点モードで終了ボタンを押したときに呼び出す関数
 */

const TestModal = ({ jobId, jobTitle, testData, isOpen, closeModal, mode = TEST_MODE.LEARNING, setLCEnd }) => {
  const [newTestData, setNewTestData] = useState([]);
  const [dispAnswer, setDispAnswer] = useState(false);
  const [isCheckAnswer, setIsCheckAnswer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const bottomSheetRef = useRef(null);
  const [isAllAnswered, setIsAllAnswered] = useState(false);

  // ------------------------------------------------------------------------------------
  // 初期処理
  // ------------------------------------------------------------------------------------
  useEffect(() => {
    if (isOpen) {
      const initTestData = testData.map(question => {
        return {
          ...question,
          answerList: question.answerList.map(answer => {
            return {
              ...answer,
              isSelected: mode === TEST_MODE.GRADING ? answer.isSelected : false,
            }
          })
        }
      })
      setNewTestData(initTestData);
    }
  }, [isOpen, testData, mode]);

  useEffect(() => {
    const allAnswered = newTestData.every(question => 
      question.answerList.some(answer => answer.isSelected)
    );
    setIsAllAnswered(allAnswered);
  }, [newTestData]);

  useEffect(() => {
    if (isOpen && mode === TEST_MODE.GRADING && newTestData.length > 0) {
      onClickSubmit();
    }
    if (isOpen && mode === TEST_MODE.PREVIEW) {
      setDispAnswer(true);
    }
    // eslint-disable-next-line
  }, [isOpen, mode, newTestData]);

  if (!isOpen) {
    return null;
  }

  // ------------------------------------------------------------------------------------
  // 採点
  // ------------------------------------------------------------------------------------
  const onClickSubmit = async () => {
    if (!jobId || !newTestData || newTestData.length === 0) {
      alert(ErrorMessage.NOT_FOUND);
      return;
    }
    if (validateAnswer(newTestData).error) {
      alert(validateAnswer(newTestData).message);
      return;
    }
    
    if (mode !== TEST_MODE.GRADING) {
      const params = newTestData.map(question => {
        return {
          questionId: question.questionId,
          answerIds: question.answerList.filter(answer => answer.isSelected).map(answer => answer.answerId),
        }
      })
    
      const res = await requestApiLoadAndBottom(`/test/complete/${jobId}`,
        { answerList: params }, setIsLoading, bottomSheetRef, Message.BS_LC_COMPLETE_SUCCESS);
      if (res.return !== RESPONSE.SUCCESS) {
        return;
      }
    }

    setDispAnswer(true);
    setIsCheckAnswer(true);
    if (setLCEnd) {
      setLCEnd(true);
    }
    document.querySelector('.lc-modal-center-contents').scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  // ------------------------------------------------------------------------------------
  // 選択肢選択
  // ------------------------------------------------------------------------------------
  const handleAnswerClick = (item) => {
    if (dispAnswer) return;
    setNewTestData(prevTestData => {
      return prevTestData.map(question => {
        if (question.questionId === item.questionId) {
          if (question.answerType === ANSWER_TYPE.SINGLE_CHOICE) {
            return {
              ...question,
              answerList: question.answerList.map(answer => ({
                ...answer,
                isSelected: answer.answerId === item.answerId ? !answer.isSelected : false
              }))
            };
          } else if (question.answerType === ANSWER_TYPE.MULTIPLE_CHOICE) {
            return {
              ...question,
              answerList: question.answerList.map(answer => ({
                ...answer,
                isSelected: answer.answerId === item.answerId ? !answer.isSelected : answer.isSelected
              }))
            };
          }
        }
        return question;
      })
    })
  }

  // ------------------------------------------------------------------------------------
  // ValidationCheck
  // ------------------------------------------------------------------------------------
  const validateAnswer = (newTestData) => {
    for (const question of newTestData) {
      if (question.answerList.every(answer => !answer.isSelected)) {
        return { error: true, message: `Q ${question.questionId} の選択肢が未選択です。` };
      }
      if (question.answerType === ANSWER_TYPE.SINGLE_CHOICE
        && question.answerList.filter(answer => answer.isSelected).length > 1) {
        return { error: true, message: `Q ${question.questionId} に複数選択ができません。` };
      }
      if (question.answerType === ANSWER_TYPE.MULTIPLE_CHOICE
        && question.answerList.filter(answer => answer.isSelected).length === 1) {
        return { error: true, message: `Q ${question.questionId} は選択肢を1つ以上選択してください。` };
      }
    }
    return { error: false, message: '' };
  };



  return (
    <div className="lc-modal-overlay">
      <Loading isLoading={isLoading} />
      <BottomSheet ref={bottomSheetRef} />
      <div className="lc-modal-container" onClick={(e) => e.stopPropagation()}>
  
        <div className="lc-modal-top-contents">
          <h2 className="lc-modal-title">{jobTitle}</h2>
        </div>
        <div className="lc-modal-center-contents">
          {newTestData.map((question) => (
            <div key={question.questionId}>
              <div
                className="lc-question-item"
                key={question.questionId}>
                
                <h3>Q{question.questionId}</h3>
                <p>{question.questionText}
                  {question.answerType === ANSWER_TYPE.MULTIPLE_CHOICE &&
                    <span>（複数選択）</span>
                  }
                </p>
                <div className="lc-answer-list">
                  {question.answerList.map((answer) => (
                    <div className="lc-answer-item-container" key={answer.answerId}>
                      <div
                        key={answer.answerId}
                        className={
                          `lc-answer-item
                      ${answer.isSelected ? 'selecting' :
                            dispAnswer && !answer.isSelected && answer.isCorrect ? 'answer' : ''
                          }`}
                        onClick={() =>
                          handleAnswerClick(
                            {
                              questionId: question.questionId,
                              answerId: answer.answerId,
                              answerType: question.answerType,
                            })}>
                        <label
                          htmlFor={`answer-${answer.answerId}`}
                          style={{ marginLeft: '8px' }}>
                          {answer.answerText}
                        </label>
                      </div>
                      <span className={`${!dispAnswer && answer.isSelected ? 'select-mark' :
                        dispAnswer && answer.isSelected && answer.isCorrect ? 'correct-mark' :
                          dispAnswer && answer.isSelected && !answer.isCorrect ? 'incorrect-mark' :
                            dispAnswer && !answer.isSelected && answer.isCorrect ? 'answer-mark' : 'spacer'
                        }`}>
                        {!dispAnswer && answer.isSelected ?
                          <svg
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20px"
                            height="20px"
                            viewBox="0 0 24 24"
                            aria-labelledby="okIconTitle"
                            stroke="#007bff"
                            strokeWidth="2.4"
                            strokeLinecap="square"
                            strokeLinejoin="miter"
                            fill="none"
                            color="#007bff"
                          >
                            <title id="okIconTitle">OK</title>
                            <polyline points="4 13 9 18 20 7" />
                          </svg> :
                          dispAnswer && answer.isSelected && answer.isCorrect ?
                            <svg
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              width="20px"
                              height="20px"
                              viewBox="0 0 24 24"
                              aria-labelledby="circleIconTitle"
                              stroke="#007bff"
                              strokeWidth="2.0"
                              strokeLinecap="square"
                              strokeLinejoin="miter"
                              fill="none"
                              color="#007bff"
                            >
                              <title id="circleIconTitle">Circle</title>
                              <circle cx="12" cy="12" r="8" />
                            </svg> :
                            dispAnswer && answer.isSelected && !answer.isCorrect ?
                              <svg
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                aria-labelledby="closeIconTitle"
                                stroke="#007bff"
                                strokeWidth="2.0"
                                strokeLinecap="square"
                                strokeLinejoin="miter"
                                fill="none"
                                color="#007bff"
                              >
                                <title id="closeIconTitle">Close</title>
                                <path d="M6.34314575 6.34314575L17.6568542 17.6568542M6.34314575 17.6568542L17.6568542 6.34314575"/>
                              </svg> :
                              dispAnswer && !answer.isSelected && answer.isCorrect ? 'A' : ''
                        }
                      </span>
                    </div>
                  ))}
                </div>
                <div className="separate-line"></div>
              </div>
            </div>
          ))}
        </div>
        <div className="lc-modal-bottom-contents">
          <div className="button-group">
            {!isCheckAnswer && mode === TEST_MODE.LEARNING &&
              <button
                className="blue"
                onClick={onClickSubmit}
                disabled={!isAllAnswered}
              >採点</button>
            }
            <button
              className="no-right-space"
              onClick={closeModal}
            >閉じる</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TestModal;
