/* ==================== */
/* 会社選択モーダル */
/* ==================== */
import React, { useEffect, useState } from 'react';
import Loading from '../Loading/Loading';
import Modal from '../Modal/Modal';
import { CONTRACT_STATUS, MODAL_TYPE, RESPONSE } from '../../const/Enum';
import { requestApiLoad } from '../../utils/apiLoadUtil';
import './SelectMultCompanyModal.css';

const SelectMultCompanyModal = ({ isOpen, setIsOpen, setSelectCompanys, selectCompanys }) => {
  const [companyList, setCompanyList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const fetchData = async () => {
        const res = await requestApiLoad('/company/get', {}, setIsLoading);
        if (res.return !== RESPONSE.SUCCESS) {
          return;
        }
        setCompanyList(res.companyList.filter(company => company.contractStatus !== CONTRACT_STATUS.INACTIVE));
      };
      fetchData();
    }
  }, [isOpen]);

  useEffect(() => {
    setCompanyList(prevList => 
      prevList.map(company => ({
        ...company,
        isSelect: selectCompanys.some(selectedCompany => selectedCompany.companyId === company.companyId)
      }))
    );
  }, [selectCompanys]);

  const onSelectCompany = (item) => {
    setCompanyList(prevList =>
      prevList.map(company => 
        company.companyId === item.companyId
          ? { ...company, isSelect: !company.isSelect }
          : company
      )
    );
  };

  const handleSubmit = () => {
    const selectedCompanies = companyList.filter(company => company.isSelect);
    setSelectCompanys(selectedCompanies);
    setIsOpen(false);
  }
  
  return (   
    <Modal
    title="会社選択"
      isOpen={isOpen}
      closeModal={() => setIsOpen(false)}
      actionButtonText="OK"
      onAction={handleSubmit}
      playerKey={MODAL_TYPE.SELECT}
      id="select-companys-modal"
    >
      <div className="select-companys-modal">
        <Loading isLoading={isLoading} />
      
        <div className="card-list" id="modal-card-list">
          {companyList.length === 0 ? (
            <div className="no-data">データがありません</div>
          ) : (
            companyList.map((item) => (
              <div
                className="card"
                key={item.companyId}
                onClick={() => onSelectCompany(item)}
              >
                <div className="card-contents">
                  <div style={{ width: '20px', height: '20px', marginRight: '10px' }}>
                    {item.isSelect && (
                      <svg
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20px"
                        height="20px"
                        viewBox="0 0 24 24"
                        aria-labelledby="okIconTitle"
                        stroke="#007bff"
                        strokeWidth="2.4"
                        strokeLinecap="square"
                        strokeLinejoin="miter"
                        fill="none"
                        color="#007bff"
                      >
                        <title id="okIconTitle">OK</title>
                        <polyline points="4 13 9 18 20 7" />
                      </svg>
                    )}
                  </div>

                  <div className="card-contents-left">
                    <h3>{item.companyName}</h3>
                    <span className="sub-text">{item.contractStartDate}~{item.contractEndDate}
                      ({item.contractStatus === CONTRACT_STATUS.ACTIVE ? '契約中' :
                        item.contractStatus === CONTRACT_STATUS.PREPARING ? '契約前' : '契約終了'})</span>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </Modal>
  )
}

export default SelectMultCompanyModal;