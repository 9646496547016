/* ==================== */
/* 研修選択モーダル */
/* ==================== */
import React, { useEffect, useState } from 'react';
import Loading from '../Loading/Loading';
import Modal from '../Modal/Modal';
import { FILE_TYPE, MODAL_TYPE, RESPONSE } from '../../const/Enum';
import { requestApiLoad } from '../../utils/apiLoadUtil';
import './SelectJobModal.css';

const SelectJobModal = ({ isOpen, setIsOpen, setSelectJob }) => {
  const [jobList, setJobList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const fetchData = async () => {
        const params = {
          isSelectable: true
        }

        const res = await requestApiLoad('/job/get', params, setIsLoading);
        if (res.return !== RESPONSE.SUCCESS) {
          return;
        }

        setJobList(res.jobList);
      }
      fetchData();
    }
  }, [isOpen]);

  const onSelectJob = (item) => {
    const selectedJob = jobList.find((job) => job.jobId === item.jobId);
    setSelectJob(selectedJob);
    setIsOpen(false);
  };
  
  return (
    <Modal
      title="研修選択"
      isOpen={isOpen}
      closeButtonText="キャンセル"
      closeModal={() => setIsOpen(false)}
      playerKey={MODAL_TYPE.SELECT}
      id="select-job-modal"
    >
      <div className="select-job-modal">
        <Loading isLoading={isLoading} />
      
        <div className="card-list" id="modal-card-list">
          {jobList.length === 0 ? (
            <div className="no-data">データがありません</div>
          ) : (
            jobList.map((item) => (
              <div className="card" key={item.jobId} onClick={() => onSelectJob(item)}>
                <div className="card-contents">
                  <div className="card-contents-left">
                    <h3 className="card-title">
                      <span>
                        {item.fileType === FILE_TYPE.PDF && (
                          <svg
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            width="19px"
                            height="20px"
                            viewBox="0 0 24 24"
                            aria-labelledby="stickerIconTitle"
                            stroke="var(--blue-font-color)"
                            strokeWidth="1.8"
                            strokeLinecap="square"
                            strokeLinejoin="miter"
                            fill="none"
                            color="var(--blue-font-color)">
                            <title id="stickerIconTitle">PDF</title>
                            <path d="M21,3 L21,11 C21,16.5228475 16.5228475,21 11,21 L3,21 L3,3 L21,3 Z" />
                            <path d="M9,21 C11.6666667,21 13,19.6666667 13,17 C13,17 13,15.6666667 13,13 L17,13 C19.6666667,13 21,11.6666667 21,9" />
                          </svg>
                        )}
                        {item.fileType === FILE_TYPE.VIDEO && (
                          <svg
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            width="19px"
                            height="20px"
                            viewBox="0 0 24 24"
                            aria-labelledby="videoIconTitle"
                            stroke="var(--blue-font-color)"
                            strokeWidth="1.8"
                            strokeLinecap="square"
                            strokeLinejoin="miter"
                            fill="none"
                            color="var(--blue-font-color)">
                            <title id="videoIconTitle">Video</title>
                            <polygon points="18 12 9 16.9 9 7" />
                            <circle cx="12" cy="12" r="10" />
                          </svg>
                        )}
                      </span>
                      {item.jobName}
                      {item.groupName && (
                        <span>
                          <svg
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            width="17px"
                            height="17px"
                            viewBox="0 0 24 24"
                            aria-labelledby="peopleIconTitle"
                            stroke="var(--blue-font-color)"
                            strokeWidth="1.7"
                            strokeLinecap="square"
                            strokeLinejoin="miter"
                            fill="none"
                            color="var(--blue-font-color)">
                            <title id="peopleIconTitle">グループ</title>
                            <path d="M1 18C1 15.75 4 15.75 5.5 14.25 6.25 13.5 4 13.5 4 9.75 4 7.25025 4.99975 6 7 6 9.00025 6 10 7.25025 10 9.75 10 13.5 7.75 13.5 8.5 14.25 10 15.75 13 15.75 13 18M12.7918114 15.7266684C13.2840551 15.548266 13.6874862 15.3832994 14.0021045 15.2317685 14.552776 14.9665463 15.0840574 14.6659426 15.5 14.25 16.25 13.5 14 13.5 14 9.75 14 7.25025 14.99975 6 17 6 19.00025 6 20 7.25025 20 9.75 20 13.5 17.75 13.5 18.5 14.25 20 15.75 23 15.75 23 18" />
                            <path strokeLinecap="round" d="M12,16 C12.3662741,15.8763472 12.6302112,15.7852366 12.7918114,15.7266684" />
                          </svg>
                        </span>
                      )}
                    </h3>
                    <span className="sub-text">{item.releaseStartDate} ~ {item.releaseEndDate}</span>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </Modal>
  )
}

export default SelectJobModal;