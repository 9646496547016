import { useEffect, useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import './PDFThumbnail.css';

// PDFワーカーの設定
const pdfjsWorkerSrc = require('pdfjs-dist/build/pdf.worker.entry');
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorkerSrc;

const PDFThumbnail = ({ pdfUrl, isConfirmed }) => {
  const [thumbnail, setThumbnail] = useState(null);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadPdfThumbnail = async () => {
      setIsLoading(true);
      try {
        const loadingTask = pdfjsLib.getDocument(pdfUrl);
        const pdf = await loadingTask.promise;
        const page = await pdf.getPage(1);
        const viewport = page.getViewport({ scale: 0.3 });
        
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        await page.render({
          canvasContext: context,
          viewport: viewport
        }).promise;

        setThumbnail(canvas.toDataURL());
        setError(false);
      } catch (error) {
        console.error('PDFサムネイルの生成に失敗しました:', error);
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (pdfUrl) {
      loadPdfThumbnail();
    }
  }, [pdfUrl]);

  return (
    <div className="pdf-thumbnail-container">
      {isLoading ? (
        <div className="pdf-thumbnail-loading">
          <div className="spinner-small"></div>
        </div>
      ) : error ? (
        <div className="pdf-thumbnail-error">
          <svg width="40px" height="40px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="#ff6b6b" strokeWidth="1.5" fill="none">
            <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" />
            <path d="M9 9L15 15" />
            <path d="M15 9L9 15" />
          </svg>
        </div>
      ) : (
        <>
          <img src={thumbnail} alt="PDF thumbnail" className="pdf-thumbnail" />
          {isConfirmed && (
            <div className="confirmed-overlay">
              <div className="confirmed-overlay-icon">
                <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 12L10 18L20 6" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <span className="confirmed-overlay-text">確認済み</span>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PDFThumbnail;