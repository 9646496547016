/* ==================== */
/* 共通 メンテナンス画面 */
/* ==================== */
import React, { useState, useEffect } from 'react';
import './Maintenance.css';

const Maintenance = () => {
  const [maintenanceInfo, setMaintenanceInfo] = useState({
    startDate: '',
    startTime: '',
    endDateTime: '',
    endTime: ''
  });

  useEffect(() => {
    fetch(`${process.env.REACT_APP_PUBLIC_URL}maintenance-config.json`)
      .then(response => response.json())
      .then(data => setMaintenanceInfo(data))
      .catch(error => console.error('メンテナンス設定の読み込みエラー:', error));
  }, []);

  const formatMaintenanceTime = () => {
    if (!maintenanceInfo.startDate || !maintenanceInfo.endDateTime) return '';
    
    const startDate = new Date(maintenanceInfo.startDate);
    const endDate = new Date(maintenanceInfo.endDateTime);
    
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return `${year}年${month}月${day}日`;
    };

    if (maintenanceInfo.startDate === maintenanceInfo.endDateTime) {
      return `${formatDate(startDate)} ${maintenanceInfo.startTime} ~ ${maintenanceInfo.endTime}`;
    } else {
      return `${formatDate(startDate)} ${maintenanceInfo.startTime} ~ ${formatDate(endDate)} ${maintenanceInfo.endTime}`;
    }
  };

  return (
    <div className="maintenance-mode">
      <div className="maintenance-image">
        <img src="/images/system-logo.png" alt="メンテナンス" />
      </div>
      <div className="maintenance-text">
        <h1>システムメンテナンスを行っています</h1>

        <div className="maintenance-sub-text">
          <p>現在システムメンテナンスを行っているため、Jobルール365をご利用いただくことができません。</p>
          <p>ご不便をお掛け致しますが、ご理解いただきますようお願い申し上げます。</p>
          <p className="maintenance-time">
            <span>メンテナンス時間</span>
            <span>{formatMaintenanceTime()}</span>
          </p>
          <p className="maintenance-small-sub-text">※メンテナンス終了後、画面閉じて再度開くと、通常画面が表示されます。</p>
        </div>
      </div>
    </div>
  );
}

export default Maintenance;
