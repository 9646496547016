import React, { useEffect, useState } from 'react';
import './PaperViewerModal.css';

const PDFViewerModal = ({
  isOpen,
  closeModal,
  title,
  pdfUrl,
  onConfirm,
  isAdmin = false
}) => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [loadError, setLoadError] = useState(false);

  // ------------------------------------------------------------------------------------
  // 初期処理
  // ------------------------------------------------------------------------------------
  useEffect(() => {
    if (isAdmin) { 
      return;
    }
    setIsConfirmed(false);
    setLoadError(false);
  }, [isOpen]);

  const handleOk = () => {
    if (isAdmin) { 
      return;
    }
    if (isConfirmed) {
      onConfirm(true);
    }
    closeModal();
  };

  const handleClose = () => {
    if (!isAdmin && isConfirmed) {
      onConfirm(true);
    }
    closeModal();
  };

  const handleIframeError = (error) => {
    console.error('PDF読み込みエラー:', error);
    setLoadError(true);
  };

  if (!isOpen) return null;

  return (
    <div className="pdf-viewer-modal-overlay" onClick={handleClose}>
      <div className="pdf-viewer-modal-container" onClick={e => e.stopPropagation()}>
        <div className="pdf-viewer-modal-header">
          <h2>{title}</h2>
        </div>
        
        <div className="pdf-viewer-modal-content">
          <iframe
            src={`${pdfUrl}#toolbar=0&view=FitH`}
            title={title}
            className="pdf-viewer-iframe"
            
            loading="lazy"
            onError={handleIframeError}
          />
          {loadError && (
            <div className="pdf-load-error">
              PDFの読み込みに失敗しました。
            </div>
          )}
        </div>

        <div className="pdf-viewer-modal-footer">
          <div></div>
          {!isAdmin && (
            <label className="confirm-checkbox">
              <input
                type="checkbox"
                checked={isConfirmed}
                onChange={(e) => setIsConfirmed(e.target.checked)}
              />
              <span>確認しました</span>
            </label>
          )}
          {(isAdmin || !isConfirmed) && (
            <button className="close-button" onClick={handleClose}>
              閉じる
            </button>
          )}
          {(!isAdmin && isConfirmed) && (
            <button className="confirm-button" onClick={handleOk}>
              確定
              </button>
            )}
        </div>
      </div>
    </div>
  );
};

export default PDFViewerModal;