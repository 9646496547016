/* ==================== */
/* 請求書PDFレイアウト */
/* ==================== */
import React from 'react';
import './Invoice.css';

const InvoicePreview = ({ data }) => {
  return (
    <div className="invoice-preview">
      
      <div className="invoice-super-header">
        <h1>請求書</h1>
        <div className="invoice-super-header-date">
          <p className="date">{data.date}</p>
          <p className="invoiceNumber">請求書番号：{data.invoiceNumber}</p>
        </div>
      </div>

      <div className="invoice-header">
        <div className="invoice-header-left">
          
          <div className="invoice-header-cutomerName-wrapper">
            {/* 会社名が15文字以上で改行される */}
            <p className="invoice-header-cutomerName">{data.customerName} 様</p>
          </div>
          
          <p className="invoice-header-message">下記の通り、ご請求申し上げます。</p>
          
          <div className="invoice-header-totalAmountWithTax-wrapper">
            <span>ご請求金額</span>
            <p className="invoice-header-totalAmountWithTax">
              ¥{data.totalAmountWithTax.toLocaleString()}
              <span>(税込)</span></p>
          </div>
        </div>

        <div className="invoice-header-right">
          <p className="invoice-header-companyName">{data.companyName}</p>
          <p className="invoice-header-companyAddress">{data.companyAddress}</p>
          <p className="invoice-header-businessNumber">登録番号:T-{data.businessNumber}</p>
        </div>
      </div>

      <div className="invoice-account-info">
        <p className="invoice-account-info-bankAccount">振込先: {data.bankAccount}</p>
        {data.paymentDue && <p className="invoice-account-info-paymentDue">支払期限: {data.paymentDue}</p>}
      </div>

      <table className="invoice-table">
        <thead>
          <tr>
            <th>品名</th>
            <th>数量</th>
            <th>単価</th>
            <th>金額</th>
          </tr>
        </thead>
        <tbody>
          {data.items.map((item, index) => (
            <tr key={index}>
              <td className="invoice-table-item-name">{item.name}</td>
              <td className="invoice-table-item-quantity">{item.quantity}</td>
              <td className="invoice-table-item-unitPrice">{item.unitPrice}</td>
              <td className="invoice-table-item-amount">{item.amount}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="invoice-footer">
        <div className="invoice-summary">
          <p className="invoice-summary-totalAmount"><span>合計:</span> ¥{data.totalAmount.toLocaleString()}</p>
          <p className="invoice-summary-taxAmount"><span>消費税 {data.tax}%:</span> ¥{data.taxAmount.toLocaleString()}</p>
        </div>

        <div className="invoice-total">
          <p className="invoice-total-totalAmountWithTax">
            <span>ご請求金額</span>
            ¥{data.totalAmountWithTax.toLocaleString()}
          </p>
        </div>
      </div>
      <div className="invoice-note">
        <p>{data.note}</p>
      </div>
    </div>
  );
};

export default InvoicePreview;
