/* ==================== */
/* ローディング */
/* ==================== */
import React from 'react';
import './Loading.css';

const Loading = ({ isLoading}) => {
  return isLoading ? <div className="spinner"></div> : null;
};

export default Loading;
