import React, { useState, useEffect } from 'react';
import DeleteButton from '../../DeleteButton/DeleteButton';
import { ANSWER_TYPE } from '../../../const/Enum';
import { MaxLength } from '../../../const/Constant';
import * as validate from '../../../utils/realtimeValidation';
import './TestSettingForm.css';

const TestSettingForm = ({ formData, setFormData, isPermitEdit }) => {
  const [jobId, setJobId] = useState('');
  const [questions, setQuestions] = useState(formData.questions || []);
  const [questionErrors, setQuestionErrors] = useState([]);
  const [choiceErrors, setChoiceErrors] = useState([]);

  // ------------------------------------------------------------------------------------
  // 初期処理
  // ------------------------------------------------------------------------------------
  useEffect(() => {
    if (formData.jobId !== jobId) {
      setJobId(formData.jobId);
      setQuestionErrors([]);
      setChoiceErrors([]);
    }
  }, [formData]);

  useEffect(() => {
    setQuestions(formData.questions || []);
  }, [formData]);
  
  // ------------------------------------------------------------------------------------
  // イベント
  // ------------------------------------------------------------------------------------
  const handleInputChange = () => {
    const newQuestionErrors = [];
    const newChoiceErrors = [];

    questions.forEach((question, qIndex) => {
      const questionError = {};
      const choiceErrorsForQuestion = [];

      questionError.text = validate.validateTestQuestion(question.questionText);
      

      let checkedChoicesCount = 0;
      question.choices.forEach((choice, cIndex) => {
        const choiceError = {};
        choiceError.text = validate.validateTestChoice(choice.text);

        if (choice.isCorrect) {
          checkedChoicesCount++;
        }
        if (Object.keys(choiceError).length > 0) {
          choiceErrorsForQuestion[cIndex] = choiceError;
        }
      });

      if (question.answerType === ANSWER_TYPE.SINGLE_CHOICE && checkedChoicesCount !== 1) {
        questionError.answerType = '単一選択式では、1つの正解を選択してください。';
      } else if (question.answerType === ANSWER_TYPE.MULTIPLE_CHOICE && checkedChoicesCount < 2) {
        questionError.answerType = '複数選択式では、2つ以上の正解を選択してください。';
      }

      if (Object.keys(questionError).length > 0) {
        newQuestionErrors[qIndex] = questionError;
      }
      if (choiceErrorsForQuestion.length > 0) {
        newChoiceErrors[qIndex] = choiceErrorsForQuestion;
      }
    });

    setQuestionErrors(newQuestionErrors);
    setChoiceErrors(newChoiceErrors);
  };

  const handleQuestionChange = (index, field, value) => {
    const newQuestions = [...questions];
    newQuestions[index][field] = value;

    if (field === 'answerType' && value === ANSWER_TYPE.SINGLE_CHOICE) {
      newQuestions[index].choices.forEach(choice => {
        choice.isCorrect = false;
      });
    }

    updateQuestions(newQuestions);
    handleInputChange();
  };

  const handleChoiceChange = (qIndex, cIndex, field, value) => {
    const newQuestions = [...questions];
    if (field === 'isCorrect' && questions[qIndex].answerType === ANSWER_TYPE.SINGLE_CHOICE) {
      newQuestions[qIndex].choices.forEach((choice, index) => {
        if (index !== cIndex) {
          choice.isCorrect = false;
        }
      });
    }
    newQuestions[qIndex].choices[cIndex][field] = value;
    updateQuestions(newQuestions);
    handleInputChange();
  };

  // ------------------------------------------------------------------------------------
  // 問題追加
  // ------------------------------------------------------------------------------------
  const addQuestion = () => {
    if (questions.length < MaxLength.QUESTION_MAX_COUNT) {
      const newQuestion = {
        questionText: '',
        answerType: ANSWER_TYPE.SINGLE_CHOICE,
        choices: Array(MaxLength.CHOICE_MAX_COUNT).fill().map((_, index) => ({ id: `choice-${Date.now()}-${index}`, text: '', isCorrect: false }))
      };
      updateQuestions([...questions, newQuestion]);
      handleInputChange();
    }
  };

  // ------------------------------------------------------------------------------------
  // 問題更新
  // ------------------------------------------------------------------------------------
  const updateQuestions = (newQuestions) => {
    setQuestions(newQuestions);
    setFormData({ ...formData, questions: newQuestions });
  };

  // ------------------------------------------------------------------------------------
  // 問題削除
  // ------------------------------------------------------------------------------------
  const removeQuestion = (index) => {
    if (questions.length > 1) {
      const newQuestions = questions.filter((_, qIndex) => qIndex !== index);
      updateQuestions(newQuestions);
      handleInputChange();
    }
  };

  // ------------------------------------------------------------------------------------
  // 選択肢追加
  // ------------------------------------------------------------------------------------
  const addChoice = (qIndex) => {
    const newQuestions = [...questions];
    if (newQuestions[qIndex].choices.length < MaxLength.CHOICE_MAX_COUNT) {
      newQuestions[qIndex].choices.push({ id: `choice-${Date.now()}-${newQuestions[qIndex].choices.length}`, text: '', isCorrect: false });
      updateQuestions(newQuestions);
      handleInputChange();
    }
  };

  // ------------------------------------------------------------------------------------
  // 選択肢削除
  // ------------------------------------------------------------------------------------
  const removeChoice = (qIndex, cIndex) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].choices = newQuestions[qIndex].choices.filter((_, index) => index !== cIndex);
    updateQuestions(newQuestions);
    handleInputChange();
  };

  // ------------------------------------------------------------------------------------
  // レンダリング
  // ------------------------------------------------------------------------------------
  return (
    <div className="modal-form" id="lc-setting-form">
      {questions.map((question, qIndex) => (
        <div key={qIndex} className="question-card-wrapper">
          <div className="question-card">
            <h4>{`Q${qIndex + 1}`}</h4>
            <div className="row-set">
              <div className="input-group">
                <div className={`error-message-container ${questionErrors[qIndex] && questionErrors[qIndex].text ? 'has-error' : ''}`}>
                  <textarea
                    value={question.questionText}
                    onChange={(e) => handleQuestionChange(qIndex, 'questionText', e.target.value)}
                    placeholder="問題文"
                    disabled={!isPermitEdit}
                    style={{
                      width: '100%',
                      borderColor: questionErrors[qIndex] && questionErrors[qIndex].text ? 'red' : '',
                    }}
                  />
                  {questionErrors[qIndex] && questionErrors[qIndex].text && (
                    <div className="error-message">
                      {questionErrors[qIndex].text}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row-set">
              <label className="row-label">
                <input
                  type="radio"
                  name={`answerType${qIndex}`}
                  value={ANSWER_TYPE.SINGLE_CHOICE}
                  checked={question.answerType === ANSWER_TYPE.SINGLE_CHOICE}
                  onChange={() => handleQuestionChange(qIndex, 'answerType', ANSWER_TYPE.SINGLE_CHOICE)}
                  disabled={!isPermitEdit}
                />
                単一選択式
              </label>
              <label className="row-label">
                <input
                  type="radio"
                  name={`answerType${qIndex}`}
                  value={ANSWER_TYPE.MULTIPLE_CHOICE}
                  checked={question.answerType === ANSWER_TYPE.MULTIPLE_CHOICE}
                  onChange={() => handleQuestionChange(qIndex, 'answerType', ANSWER_TYPE.MULTIPLE_CHOICE)}
                  disabled={!isPermitEdit}
                />
                複数選択式
              </label>
            </div>
            
            <div className='choice-list'>
              {question.choices.map((choice, index) => (
                <div key={choice.id} className="choice-row">
                  <input
                    type="checkbox"
                    checked={choice.isCorrect}
                    onChange={(e) => handleChoiceChange(qIndex, index, 'isCorrect', e.target.checked)}
                    disabled={!isPermitEdit}
                  />
                  <div className="input-group">
                    <div className={`error-message-container ${choiceErrors[qIndex] && choiceErrors[qIndex][index] && choiceErrors[qIndex][index].text ? 'has-error' : ''}`}>
                      <input
                        type="text"
                        value={choice.text}
                        placeholder={`選択肢${index + 1}`}
                        onChange={(e) => handleChoiceChange(qIndex, index, 'text', e.target.value)}
                        disabled={!isPermitEdit}
                        style={{
                          borderColor: choiceErrors[qIndex] && choiceErrors[qIndex][index] && choiceErrors[qIndex][index].text ? 'red' : '',
                        }}
                      />
                      {choiceErrors[qIndex] && choiceErrors[qIndex][index] && choiceErrors[qIndex][index].text && (
                        <div className="error-message">
                          {choiceErrors[qIndex][index].text}
                        </div>
                      )}
                    </div>
                  </div>
                    
                  {(question.choices.length > 1 && isPermitEdit) && (
                    <DeleteButton onClick={() => removeChoice(qIndex, index)} />
                  )}
                    
                </div>
              ))}
              {questionErrors[qIndex] && questionErrors[qIndex].answerType && (
                <div className="item-error-messages">
                  <div className="item-error-message">{questionErrors[qIndex].answerType}</div>
                </div>
              )}
              {(question.choices.length < MaxLength.CHOICE_MAX_COUNT && isPermitEdit) && (
                <div className="button-container">
                  <div className="add-new-item-button" onClick={() => addChoice(qIndex)}>
                    <svg
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18px"
                      height="18px"
                      viewBox="0 0 24 24"
                      aria-labelledby="plusIconTitle"
                      stroke="var(--blue-font-color)"
                      strokeWidth="1.8"
                      strokeLinecap="square"
                      strokeLinejoin="miter"
                      fill="none"
                      color="var(--blue-font-color)"
                    >
                      <title id="plusIconTitle">選択肢追加</title>
                      <path d="M20 12L4 12M12 4L12 20" />
                    </svg>
                    <p className="hide-option">選択肢追加</p>
                  </div>
                </div>
              )}
            </div>
          </div>
          {(questions.length > 1 && isPermitEdit) && (
            <DeleteButton onClick={() => removeQuestion(qIndex)} />
          )}
        </div>
      ))}

      {(questions.length < MaxLength.QUESTION_MAX_COUNT && isPermitEdit) && (
        <div className="add-button-wrapper">
          <div className="button-container">
            <div className="add-new-item-button" onClick={addQuestion}>
              <svg
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                width="18px"
                height="18px"
                viewBox="0 0 24 24"
                aria-labelledby="plusIconTitle"
                stroke="var(--blue-font-color)"
                strokeWidth="1.8"
                strokeLinecap="square"
                strokeLinejoin="miter"
                fill="none"
                color="var(--blue-font-color)"
              >
                <title id="plusIconTitle">問題追加</title>
                <path d="M20 12L4 12M12 4L12 20" />
              </svg>
              <p className="hide-option">問題追加</p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default TestSettingForm;