import React, { createContext, useState } from 'react';

export const UnreadCountContext = createContext();

export const UnreadCountProvider = ({ children }) => {
  const [unreadCount, setUnreadCount] = useState(0);

  const decrementUnreadCount = () => {
    setUnreadCount(prevCount => Math.max(prevCount - 1, 0));
  };

  return (
    <UnreadCountContext.Provider value={{ unreadCount, setUnreadCount, decrementUnreadCount }}>
      {children}
    </UnreadCountContext.Provider>
  );
};