/* ==================== */
/* 研修設定画面 */
/* ==================== */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import BottomSheet from '../../components/BottomSheet/BottomSheet';
import JobSettingForm from '../../components/Form/JobSettingForm/JobSettingForm';
import FilterButton from '../../components/FilterButton/FilterButton';
import Loading from '../../components/Loading/Loading';
import Modal from '../../components/Modal/Modal';
import Pagination from '../../components/Pagination/Pagination';
import SortingButton from '../../components/SortingButton/SortingButton';
import { FILE_TYPE, JOB_STATUS, PUBLISH_SCOPE, RESPONSE, STATUS_TYPE, TEST_STATUS } from '../../const/Enum';
import { Message } from '../../const/Constant';
import useWindowSize from '../../hook/useWindowSize';
import { requestApiLoad, requestApiLoadAndBottom } from '../../utils/apiLoadUtil';
import { getNextYearDateString, getCurrentDateString } from '../../utils/dateUtils';
import './JobSetting.css';

const JobSetting = () => {
  const [itemList, setItemList] = useState([]);
  const [addItem, setAddItem] = useState({});
  const [editItem, setEditItem] = useState({});
  const [reportItem, setReportItem] = useState({ staffList: [] });
  const [isInit, setIsInit] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const bottomSheetRef = useRef(null);
  const size = useWindowSize();
  const nextYearToday = getNextYearDateString();
  const today = getCurrentDateString();

  /* メイン一覧画面 */
  const [mainSearchTerm, setMainSearchTerm] = useState('');
  const [mainStatusFilter, setMainStatusFilter] = useState(0);
  const [mainSortConfig, setMainSortConfig] = useState({ key: 'createAt', direction: true });
  const [mainCurrentPage, setMainCurrentPage] = useState(1);
  const [mainLastPage, setMainLastPage] = useState(1);
  const [mainTotalCount, setMainTotalCount] = useState(0);
  const mainStatusOptions = [
    { value: STATUS_TYPE.ALL, label: 'すべて' },
    { value: STATUS_TYPE.ACTIVE, label: '公開中' },
    { value: STATUS_TYPE.INACTIVE, label: '公開終了' }
  ];
  const mainSortOptions = [
    { key: 'createAt', value: { key: 'createAt', direction: true }, label: '標準' },
    { key: 'releaseStartDate_asc', value: { key: 'releaseStartDate', direction: false }, label: '開始日（昇順）' },
    { key: 'releaseStartDate_desc', value: { key: 'releaseStartDate', direction: true }, label: '開始日（降順）' },
    { key: 'releaseEndDate_asc', value: { key: 'releaseEndDate', direction: false }, label: '終了日（昇順）' },
    { key: 'releaseEndDate_desc', value: { key: 'releaseEndDate', direction: true }, label: '終了日（降順）' }
  ];

  /* サブ一覧画面 */
  const [subSearchTerm, setSubSearchTerm] = useState('');
  const [subStatusFilter, setSubStatusFilter] = useState(0);
  const [subSortConfig, setSubSortConfig] = useState({ key: 'staffId', direction: true });
  const [subCurrentPage, setSubCurrentPage] = useState(1);
  const [subLastPage, setSubLastPage] = useState(1);
  const [subTotalCount, setSubTotalCount] = useState(0);
  const subStatusOptions = [
    { value: JOB_STATUS.ALL, label: 'すべて' },
    { value: JOB_STATUS.NOT_END, label: '未受講' },
    { value: JOB_STATUS.END, label: '受講済' }
  ];
  const subSortOptions = [
    { key: 'staffId', value: { key: 'staffId', direction: true }, label: '標準' },
    { key: 'correctCount_asc', value: { key: 'correctCount', direction: false }, label: '正解数（昇順）' },
    { key: 'correctCount_desc', value: { key: 'correctCount', direction: true }, label: '正解数（降順）' },
    { key: 'takeCount_asc', value: { key: 'takeCount', direction: false }, label: '受講回数（昇順）' },
    { key: 'takeCount_desc', value: { key: 'takeCount', direction: true }, label: '受講回数（降順）' }
  ];
  
  /* 画面状態 */
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showReport, setShowReport] = useState(false);

  // ------------------------------------------------------------------------------------
  // 初期処理
  // ------------------------------------------------------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        page: 1,
        isDesc: mainSortConfig.direction,
        orderKey: mainSortConfig.key,
        status: mainStatusFilter,
        name: mainSearchTerm,
      }
      const res = await requestApiLoad('/job/get', params, setIsLoading);
      if (res.return !== RESPONSE.SUCCESS) {
        return;
      }
      
      setSelectedItem((res.jobList && res.jobList.length > 0) ? res.jobList[0] : {});
      setItemList((res.jobList && res.jobList.length > 0) ? res.jobList : []);
      setMainCurrentPage(1);
      setMainLastPage(res.lastPage);
      setMainTotalCount(res.dataCount);
    };
    fetchData();
  }, [mainStatusFilter, mainSortConfig.direction, mainSortConfig.key, mainSearchTerm]);

  useEffect(() => {
    if (!selectedItem.jobId) return;
    const fetchData = async () => {
      const params = {
        page: 1,
        isDesc: subSortConfig.direction,
        orderKey: subSortConfig.key,
        status: subStatusFilter,
        name: subSearchTerm,
      }
      
      const res = await requestApiLoad(`/job/getReport/${selectedItem.jobId}`, params, setIsLoading);
      if (res.return !== RESPONSE.SUCCESS) {
        return;
      }
  
      setReportItem(res.report);
      ChangeScreenMode(MODE.REPORT);
      setSubCurrentPage(1);
      setSubLastPage(res.report.lastPage);
      setSubTotalCount(res.report.dataCount);
    };
    fetchData();
    // eslint-disable-next-line
  }, [subStatusFilter, subSortConfig.direction, subSortConfig.key, subSearchTerm]);

  useEffect(() => {
    if (!isInit) { return; }
    if (itemList.length > 0) {
      if (size.width > 1200) {
        handleEdit(null, itemList[0]);
        ChangeScreenMode(MODE.EDIT);
      } else {
        ChangeScreenMode(MODE.NONE);
      }
      setIsInit(false);
    }
    // eslint-disable-next-line
  }, [itemList]);

  useEffect(() => {
    if (!editItem) { return; }
    createEditTable();
    // eslint-disable-next-line
  }, [editItem]);

  // ------------------------------------------------------------------------------------
  // 画面モード
  // ------------------------------------------------------------------------------------
  const MODE = {
    NONE: 'NONE',
    ADD: 'ADD',
    EDIT: 'EDIT',
    REPORT: 'REPORT',
  }

  const ChangeScreenMode = (mode) => {
    switch (mode) {
      case MODE.ADD:
        setShowAdd(true);
        setShowEdit(false);
        setShowReport(false);
        break;
      
      case MODE.EDIT:
        setShowAdd(false);
        setShowEdit(true);
        setShowReport(false);
        break;
      
      case MODE.REPORT:
        setShowAdd(false);
        setShowEdit(false);
        setShowReport(true);
        break;
     
      case MODE.NONE:
      default:
        setShowAdd(false);
        setShowEdit(false);
        setShowReport(false);
        break;
    }
  }

  // ------------------------------------------------------------------------------------
  // ページネーション
  // ------------------------------------------------------------------------------------
  const handleMainPageChange = async (pageNumber) => {
    setMainCurrentPage(pageNumber);
    
    const params = {
      page: pageNumber,
      isDesc: mainSortConfig.direction,
      orderKey: mainSortConfig.key,
      status: mainStatusFilter,
      name: mainSearchTerm,
    }
    
    const res = await requestApiLoad('/job/get', params, setIsLoading);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }

    setSelectedItem(res.jobList[0]);
    setItemList(res.jobList);
    setMainLastPage(res.lastPage);
    setMainTotalCount(res.dataCount);
  };
  
  const handleSubPageChange = async (pageNumber) => {
    setSubCurrentPage(pageNumber);
    
    const params = {
      page: pageNumber,
      isDesc: subSortConfig.direction,
      orderKey: subSortConfig.key,
      status: subStatusFilter,
      name: subSearchTerm,
    }
    
    const res = await requestApiLoad(`/job/getReport/${selectedItem.jobId}`, params, setIsLoading);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }

    setReportItem(res.report);
    setSubLastPage(res.report.lastPage);
    setSubTotalCount(res.report.dataCount);
  };

  // ------------------------------------------------------------------------------------
  // フィルター
  // ------------------------------------------------------------------------------------
  const handleMainStatusChange = async (event) => {
    const newStatusFilter = Number(event.target.value);
    setMainCurrentPage(1);
    setMainStatusFilter(newStatusFilter);
  };

  const handleSubStatusChange = async (event) => {
    const newStatusFilter = Number(event.target.value);
    setSubCurrentPage(1);
    setSubStatusFilter(newStatusFilter);
  };

  const sortedAndFilteredItems = useMemo(() => {
    let filteredItems = itemList || [];
    return filteredItems;
  }, [itemList]);

  // ------------------------------------------------------------------------------------
  // ソート
  // ------------------------------------------------------------------------------------
  const handleMainSortChange = async (event) => {
    const newSortConfig = !event ? { key: 'createAt', direction: true } : JSON.parse(event);
    setMainSortConfig(newSortConfig);
  };
  
  const handleSubSortChange = async (event) => {
    const newSortConfig = !event ? { key: 'staffId', direction: true } : JSON.parse(event);
    setSubSortConfig(newSortConfig);
  };
  // ------------------------------------------------------------------------------------
  // 検索欄
  // ------------------------------------------------------------------------------------
  const onMainClear = () => {
    setMainSearchTerm('');
  };

  const onSubClear = () => {
    setSubSearchTerm('');
  };

  // ------------------------------------------------------------------------------------
  // 新規追加
  // ------------------------------------------------------------------------------------
  const handleAdd = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    setAddItem({
      jobId: '',
      jobName: '',
      status: Number(STATUS_TYPE.ACTIVE),
      releaseStartDate: today,
      releaseEndDate: nextYearToday,
      vimeoId: '',
      filePath: '',
      fileName: '',
      fileType: FILE_TYPE.VIDEO,
      publishScope: Number(PUBLISH_SCOPE.ALL), //必ず「全体公開」になる
      isAllowRetest: true,
      isAllowReview: true,
      isDownload: false,
      isTest: true,
      isSign: false,
      isAvailable: true,
      test: [],
      eSignature: null
    });

    ChangeScreenMode(MODE.ADD);
  };

  const handleAddCancel = async (event) => {
    event.preventDefault();
    
    setItemList(itemList);
    setMainLastPage(mainLastPage);
    setMainTotalCount(mainTotalCount);
    setEditItem(itemList[0]);

    if(!itemList || itemList?.length === 0) {
      ChangeScreenMode(MODE.NONE);
    }
    else if (size.width <= 1200) {
      ChangeScreenMode(MODE.NONE);
    } else {
      ChangeScreenMode(MODE.EDIT);
    }

    setIsSubmitting(false);
  }

  const handleAddSubmit = async (event) => {
    event.preventDefault();
    try {
      const params = {
        publishScope: addItem.publishScope,
        groupId: addItem.publishScope === PUBLISH_SCOPE.LIMITED ? addItem.groupId : null,
        isAllowReview: addItem.isAllowReview,
        isAllowRetest: addItem.isAllowRetest,
        isDownload: addItem.isDownload,
        isTest: addItem.isTest,
        isSign: addItem.isSign,
        eSignatureId: addItem.isSign ? addItem.eSignature.eSignatureId : null,
      }

      const validationResult = validateCheck(params);
      if (validationResult.error) {
        alert(validationResult.message);
        return;
      }
      
      let isContinue = true;
      if (!window.confirm('研修を公開します。\n公開後、研修の編集はできません。')) {
        isContinue = false;
      }
      
      if (isContinue) {
        const res = await requestApiLoadAndBottom(`/jobSetting/add/${addItem.jobId}`,
          params, setIsLoading, bottomSheetRef, Message.BS_ADD_JOB_SUCCESS);
        if (res.return !== RESPONSE.SUCCESS) {
          return;
        }

        setFirstItem(res);
        setSelectedItem({
          ...res.jobList[0],
          publishScope: res.jobList[0].groupId ? PUBLISH_SCOPE.LIMITED : PUBLISH_SCOPE.ALL,
        });

        if (size.width <= 1200) {
          ChangeScreenMode(MODE.NONE);
        } else {
          ChangeScreenMode(MODE.EDIT);
        }
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  // ------------------------------------------------------------------------------------
  // 編集
  // ------------------------------------------------------------------------------------
  const handleEdit = async (e, item) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    
    if (!item) { return; }
    if (isSubmitting) {
      setIsSubmitting(false);
    }
    
    let testData = [];
    if (item.testStatus === TEST_STATUS.SETTING) {
      const res = await requestApiLoad(`/test/get/${item.jobId}`, {}, setIsLoading);
      if (res.return !== RESPONSE.SUCCESS) {
        return;
      }
      testData = res.questionList;
    }

    const fileName = item.fileType === FILE_TYPE.PDF ? item.fileName : '';
    const publishScope = item.groupId ? PUBLISH_SCOPE.LIMITED : PUBLISH_SCOPE.ALL;

    setSelectedItem(
      {
        jobId: item.jobId,
        jobName: item.jobName,
        vimeoId: item.vimeoId,
        fileName: fileName,
        filePath: item.filePath,
        fileType: item.fileType,
        groupId: item.groupId,
        groupName: item.groupName,
        isAllowReview: item.isAllowReview,
        isAllowRetest: item.isAllowRetest,
        isAvailable: item.isAvailable,
        isDownload: item.isDownload,
        isTest: item.isTest,
        isSign: item.isSign,
        publishScope: publishScope,
        publishCompanyIds: item.publishCompanyIds,
        releaseStartDate: item.releaseStartDate,
        releaseEndDate: item.releaseEndDate,
        status: Number(item.status),
        test: testData,
        eSignature: item.eSignature,
      }
    )

    ChangeScreenMode(MODE.EDIT);
  }

  const handleStopSubmit = async (event) => {
    if (!window.confirm(Message.CONFIRM_STOP_JOB_ADMIN)) { return; }
    event.preventDefault();

    const res = await requestApiLoadAndBottom(`/jobSetting/stop/${editItem.jobId}`,
      {}, setIsLoading, bottomSheetRef, Message.BS_STOP_JOB_SUCCESS);
    
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }
    setFirstItem(res);
    setSelectedItem(res.jobList[0]);

    if (size.width <= 1200) {
      ChangeScreenMode(MODE.NONE);
    } else {
      ChangeScreenMode(MODE.EDIT);
    }
  }

  // ------------------------------------------------------------------------------------
  // 集計
  // ------------------------------------------------------------------------------------
  const handleReport = async (e, item) => {
    e.stopPropagation();
    if (!item) { return; }
    setSelectedItem(item);
    
    const params = {
      page: 1,
      isDesc: subSortConfig.direction,
      orderKey: subSortConfig.key,
      status: subStatusFilter,
      name: subSearchTerm,
    }
    
    const res = await requestApiLoad(`/job/getReport/${item.jobId}`, params, setIsLoading);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }

    setReportItem(res.report);
    ChangeScreenMode(MODE.REPORT);
    setSubCurrentPage(1);
    setSubLastPage(res.report.lastPage);
    setSubTotalCount(res.report.dataCount);
  }

  // ------------------------------------------------------------------------------------
  // その他
  // ------------------------------------------------------------------------------------ 
  const getStatusText = (item) => {
    if (!item.isAvailable) {
      return ' (公開終了)';
    }

    switch (item.status) {
      case STATUS_TYPE.ACTIVE:
        //JobSettingにtestStatusが{2:未設定}は存在しないため、条件分岐不要
        return ' (公開中)';
      
      case STATUS_TYPE.UNRELEASE:
        return ' (公開予定)';
      
      default:
        return ' (公開終了)';
    }
  }

  const setFirstItem = (res) => {
    setItemList(res.jobList);
    setMainLastPage(res.lastPage);
    setMainTotalCount(res.dataCount);
    // setSelectedItem(res.jobList[0]);
    setEditItem(res.jobList[0]);
    
    setMainCurrentPage(1);
    setMainStatusFilter(Number(STATUS_TYPE.ALL));
    setMainSearchTerm('');
    setMainSortConfig({ key: 'createAt', direction: true });
    resetScroll();
    resetScrollMain();
  }

  const resetScroll = () => {
    const scrollForm = document.querySelector('.scroll-form');
    if (scrollForm) {
      scrollForm.scrollTop = 0;
    }
  }

  const resetScrollMain = () => {
    const scrollForm = document.querySelector('.card-list');
    if (scrollForm) {
      scrollForm.scrollTop = 0;
    }
  }

  // ------------------------------------------------------------------------------------
  // ValidationCheck
  // ------------------------------------------------------------------------------------
  const validateCheck = (params) => {
    if (params.publishScope === PUBLISH_SCOPE.LIMITED) { 
      if (!params.groupId) {
        return { message: 'グループを選択してください', error: true };
      }
    }
    if(params.isSign) {
      if (!params.eSignatureId) {
        return { message: '電子サインを選択してください', error: true };
      }
    }
    
    return { message: '', error: false };
  }
  // ------------------------------------------------------------------------------------
  // 一覧作成
  // ------------------------------------------------------------------------------------ 
  const createTable = (itemList) => {
    return (
      <div className="card-list">
        {itemList.length === 0 ? (
          <div className="no-data">データがありません</div>
        ) : (
          itemList.map((item) => (
            <div className="card" key={item.jobId} onClick={(e) => handleEdit(e, item)}>
              <div className="card-contents" id="job-setting-card-contents">
                <div className="card-contents-left">
                  <h3 className="card-title">
                    <span>
                      {item.fileType === FILE_TYPE.PDF && (
                        <svg
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          width="19px"
                          height="20px"
                          viewBox="0 0 24 24"
                          aria-labelledby="stickerIconTitle"
                          stroke="var(--blue-font-color)"
                          strokeWidth="1.8"
                          strokeLinecap="square"
                          strokeLinejoin="miter"
                          fill="none"
                          color="var(--blue-font-color)">
                          <title id="stickerIconTitle">PDF</title>
                          <path d="M21,3 L21,11 C21,16.5228475 16.5228475,21 11,21 L3,21 L3,3 L21,3 Z" />
                          <path d="M9,21 C11.6666667,21 13,19.6666667 13,17 C13,17 13,15.6666667 13,13 L17,13 C19.6666667,13 21,11.6666667 21,9" />
                        </svg>
                      )}
                      {item.fileType === FILE_TYPE.VIDEO && (
                        <svg
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          width="19px"
                          height="20px"
                          viewBox="0 0 24 24"
                          aria-labelledby="videoIconTitle"
                          stroke="var(--blue-font-color)"
                          strokeWidth="1.8"
                          strokeLinecap="square"
                          strokeLinejoin="miter"
                          fill="none"
                          color="var(--blue-font-color)">
                          <title id="videoIconTitle">Video</title>
                          <polygon points="18 12 9 16.9 9 7" />
                          <circle cx="12" cy="12" r="10" />
                        </svg>
                      )}
                    </span>
                    {item.jobName}
                    {item.groupName && (
                      <span>
                        <svg
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          width="17px"
                          height="17px"
                          viewBox="0 0 24 24"
                          aria-labelledby="peopleIconTitle"
                          stroke="var(--blue-font-color)"
                          strokeWidth="1.7"
                          strokeLinecap="square"
                          strokeLinejoin="miter"
                          fill="none"
                          color="var(--blue-font-color)">
                          <title id="peopleIconTitle">グループ</title>
                          <path d="M1 18C1 15.75 4 15.75 5.5 14.25 6.25 13.5 4 13.5 4 9.75 4 7.25025 4.99975 6 7 6 9.00025 6 10 7.25025 10 9.75 10 13.5 7.75 13.5 8.5 14.25 10 15.75 13 15.75 13 18M12.7918114 15.7266684C13.2840551 15.548266 13.6874862 15.3832994 14.0021045 15.2317685 14.552776 14.9665463 15.0840574 14.6659426 15.5 14.25 16.25 13.5 14 13.5 14 9.75 14 7.25025 14.99975 6 17 6 19.00025 6 20 7.25025 20 9.75 20 13.5 17.75 13.5 18.5 14.25 20 15.75 23 15.75 23 18" />
                          <path strokeLinecap="round" d="M12,16 C12.3662741,15.8763472 12.6302112,15.7852366 12.7918114,15.7266684" />
                        </svg>
                      </span>
                    )}
                  </h3>
                  <span className="sub-text">{item.releaseStartDate} ~ {item.releaseEndDate}
                    {getStatusText(item)}
                  </span>
                </div>

                <div className="card-footer">
                  {/* 集計 */}
                  <button
                    className="blue"
                    disabled={item.testStatus === TEST_STATUS.NONE}
                    onClick={(e) => handleReport(e, item)}
                    title="集計">
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-labelledby="pollIconTitle"
                      stroke={item.testStatus === TEST_STATUS.NONE ? 'var(--disable-font-color)' : 'var(--blue-font-color)'}
                      strokeWidth="2.0"
                      strokeLinecap="square"
                      strokeLinejoin="miter"
                      color={item.testStatus === TEST_STATUS.NONE ? 'var(--disable-font-color)' : 'var(--blue-font-color)'}>
                      <title id="pollIconTitle">集計</title>
                      <path d="M4 4V20" />
                      <path d="M4 6H15V10H4" />
                      <path d="M4 10H19V14H4" />
                      <path d="M4 14H12V18H4" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    );
  }

  // ------------------------------------------------------------------------------------
  // AddTable
  // ------------------------------------------------------------------------------------
  const createAddTable = () => {
    return (
      <>
        <div className="scroll-form">
          <JobSettingForm
            formData={addItem}
            setFormData={setAddItem}
            isEdit={false}
          />
        </div>
        <div className="bottom-button">
          <button onClick={handleAddCancel}>
            キャンセル
          </button>
          <button className="blue" onClick={handleAddSubmit}>
            登録
          </button>
        </div>
      </>
    )
  }

  // ------------------------------------------------------------------------------------
  // EditTable
  // ------------------------------------------------------------------------------------
  const createEditTable = () => {
    return (
      <>
        <div className="scroll-form">
          <JobSettingForm
            formData={selectedItem}
            setFormData={setSelectedItem}
            isEdit={true}
          />
        </div>
        {selectedItem.isAvailable && (
          <div className="bottom-button">
            <button className="red" onClick={handleStopSubmit}>
              公開停止
            </button>
          </div>
        )}
      </>
    )
  }

  // ------------------------------------------------------------------------------------
  // ReportTable
  // ------------------------------------------------------------------------------------
  const createReportTable = () => {
    return (
      <div className="card-list">
        {reportItem.staffList.length === 0 ? (
          <div className="no-data">データがありません</div>
        ) : (
          reportItem.staffList.map((staff) => (
            <div className="card" key={staff.staffId}>
              <div className="card-contents">
                <div className="card-contents-left">
                  <h3>{staff.staffName}</h3>
                  <span className="sub-text">
                    正解数：{staff.correctCount !== null && staff.correctCount >= 0 ? `${staff.correctCount}/${reportItem.questionCount}` : '-'}
                  </span>
                  <span className="sub-text">
                    受講回数：{staff.takeCount !== null && staff.takeCount >= 0 ? `${staff.takeCount}` : '-'}
                  </span>
                </div>
                <div className="card-footer">
                 
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    )
  }

  // ------------------------------------------------------------------------------------
  // レンダリング
  // ------------------------------------------------------------------------------------
  return (
    <div className="view-contents" id="job-master">
      <Loading isLoading={isLoading} />
      <BottomSheet ref={bottomSheetRef} />

      <div className="main-contents">
        {size.width > 1200 && (<h2 className="page-title">研修設定</h2>)}
        <div className="header-contents">
          <div className="search-bar">
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              <input
                type="text"
                placeholder="研修名で検索"
                value={mainSearchTerm || ''}
                onChange={(e) => setMainSearchTerm(e.target.value)}
                maxLength={20}
                style={{
                  backgroundImage: 'url(/images/search.png)',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '10px center',
                  backgroundSize: '20px 20px',
                  paddingLeft: '40px',
                  paddingRight: mainSearchTerm ? '30px' : '10px'
                }}
              />
              {mainSearchTerm && (
                <button
                  onClick={onMainClear}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '16px',
                    color: '#878787'
                  }}>
                  ×
                </button>
              )}
            </div>
          </div>
          <div className="button-container">
            {!isSubmitting && (
              <div className="add-new-item-button" onClick={handleAdd}>
                <svg
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18px"
                  height="18px"
                  viewBox="0 0 24 24"
                  aria-labelledby="plusIconTitle"
                  stroke="var(--blue-font-color)"
                  strokeWidth="1.8"
                  strokeLinecap="square"
                  strokeLinejoin="miter"
                  fill="none"
                  color="var(--blue-font-color)"
                >
                  <title id="plusIconTitle">新規追加</title>
                  <path d="M20 12L4 12M12 4L12 20" />
                </svg>
                <p className="hide-option">新規追加</p>
              </div>
            )}
          </div>
        </div>

        <div className="middle-contents">
          <div className="left-contents">
            <FilterButton
              statusFilter={mainStatusFilter}
              handleStatusChange={handleMainStatusChange}
              statusOptions={mainStatusOptions}
            />
            <SortingButton
              sortConfig={mainSortConfig}
              handleSortChange={handleMainSortChange}
              sortOptions={mainSortOptions}
            />
          </div>
          <Pagination
            totalPages={mainLastPage}
            currentPage={mainCurrentPage}
            onPageChange={handleMainPageChange}
            totalItems={mainTotalCount}
          />
        </div>

        {createTable(sortedAndFilteredItems)}
      </div>

      {size.width > 1200 ? (
        <>
          {showAdd && (
            <div className="sub-contents">
              <h2 className="page-title">研修追加</h2>
              {createAddTable()}
            </div>
          )}

          {showEdit && (
            <div className="sub-contents">
              <h2 className="page-title">{selectedItem.jobName}</h2>
              {createEditTable()}
            </div>
          )}

          {showReport && (
            <div className="sub-contents">
              <h2 className="page-title">{selectedItem.jobName}</h2>
              <div className="header-contents">
                <div className="search-bar">
                  <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                    <input
                      type="text"
                      placeholder="スタッフ名で検索"
                      value={subSearchTerm}
                      onChange={(e) => setSubSearchTerm(e.target.value)}
                      maxLength={20}
                      style={{
                        backgroundImage: 'url(/images/search.png)',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '10px center',
                        backgroundSize: '20px 20px',
                        paddingLeft: '40px',
                        paddingRight: subSearchTerm ? '30px' : '10px'
                      }}
                    />
                    {subSearchTerm && (
                      <button
                        onClick={onSubClear}
                        style={{
                          position: 'absolute',
                          right: '10px',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                          fontSize: '16px',
                          color: '#878787'
                        }}>
                        ×
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="middle-contents">
                <div className="left-contents">
                  <FilterButton
                    statusFilter={subStatusFilter}
                    handleStatusChange={handleSubStatusChange}
                    statusOptions={subStatusOptions}
                  />
                  <SortingButton
                    sortConfig={subSortConfig}
                    handleSortChange={handleSubSortChange}
                    sortOptions={subSortOptions}
                  />
                </div>
                <Pagination
                  totalPages={subLastPage}
                  currentPage={subCurrentPage}
                  onPageChange={handleSubPageChange}
                  totalItems={subTotalCount}
                />
              </div>

              {createReportTable(reportItem)}
            </div>
          )}
        </>
      ) : (
        <>
          <Modal
            isOpen={showAdd}
            title="研修追加"
            closeModal={() => {
              setIsSubmitting(false);
              setShowAdd(false);
            }}
            closeButtonText="閉じる"
            actionButtonText="登録"
            onAction={handleAddSubmit}
          >
            <div className="sub-contents">
              {createAddTable()}
            </div>
          </Modal>
            
          <Modal
            isOpen={showEdit}
            title={selectedItem.jobName}
            closeModal={() => setShowEdit(false)}
            closeButtonText="閉じる"
            actionSubButtonText={selectedItem.isAvailable ? "公開停止" : ""}
            onSubAction={handleStopSubmit}
          >
            <div className="sub-contents">
              {createEditTable()}
            </div>
          </Modal>
            
          <Modal
            isOpen={showReport}
            title={selectedItem.jobName}
            closeModal={() => setShowReport(false)}
            closeButtonText="閉じる"
          >
            <div className="sub-contents">
              <div className="header-contents">
                <div className="search-bar">
                  <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                    <input
                      type="text"
                      placeholder="スタッフ名で検索"
                      value={subSearchTerm}
                      onChange={(e) => setSubSearchTerm(e.target.value)}
                      maxLength={20}
                      style={{
                        backgroundImage: 'url(/images/search.png)',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '10px center',
                        backgroundSize: '20px 20px',
                        paddingLeft: '40px',
                        paddingRight: subSearchTerm ? '30px' : '10px'
                      }}
                    />
                    {subSearchTerm && (
                      <button
                        onClick={onSubClear}
                        style={{
                          position: 'absolute',
                          right: '10px',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                          fontSize: '16px',
                          color: '#878787'
                        }}>
                        ×
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="middle-contents">
                <div className="left-contents">
                  <FilterButton
                    statusFilter={subStatusFilter}
                    handleStatusChange={handleSubStatusChange}
                    statusOptions={subStatusOptions}
                  />
                  <SortingButton
                    sortConfig={subSortConfig}
                    handleSortChange={handleSubSortChange}
                    sortOptions={subSortOptions}
                  />
                </div>
                <Pagination
                  totalPages={subLastPage}
                  currentPage={subCurrentPage}
                  onPageChange={handleSubPageChange}
                  totalItems={subTotalCount}
                />
              </div>
              {createReportTable(reportItem)}
            </div>
          </Modal>
        </>
      )}
    </div>
  )
}

export default JobSetting;