/* ==================== */
/* グループ選択モーダル */
/* ==================== */
import React, { useEffect, useState } from 'react';
import Loading from '../Loading/Loading';
import Modal from '../Modal/Modal';
import { MODAL_TYPE, RESPONSE } from '../../const/Enum';
import { requestApiLoad } from '../../utils/apiLoadUtil';
import './SelectGroupModal.css';

const SelectGroupModal = ({ isOpen, setIsOpen, setSelectGroup, useNoGroup = true }) => {
  const [groupList, setGroupList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const fetchData = async () => {
        const res = await requestApiLoad('/staffGroup/get', {}, setIsLoading);
        if (res.return !== RESPONSE.SUCCESS) {
          return;
        }

        const fetchedGroups = res.groupList.map((group) => ({
          groupId: group.groupId,
          groupName: group.groupName,
          groupNameKana: group.groupNameKana,
          parsonCount: group.parsonCount,
        }));

        if (useNoGroup) {
          const noGroupOption = {
            groupId: null,
            groupName: "未所属",
            groupNameKana: "",
            parsonCount: 0,
          };
          setGroupList([noGroupOption, ...fetchedGroups]);
        } else {
          setGroupList(fetchedGroups);
        }
      }
      fetchData();
    }
  }, [isOpen, useNoGroup]);

  const onSelectGroup = (item) => {
    let selectedGroup = { groupId: null, groupName: "" };
    if (item.groupId !== null) {
      const selected = groupList.find((group) => group.groupId === item.groupId);
      selectedGroup = { groupId: selected.groupId, groupName: selected.groupName };
    }
    setSelectGroup(selectedGroup);
    setIsOpen(false);
  };
  
  return (   
    <Modal
      title="グループ選択"
      isOpen={isOpen}
      closeModal={() => setIsOpen(false)}
      playerKey={MODAL_TYPE.SELECT}
      id="select-group-modal"
    >
      <div className="select-group-modal">
        <Loading isLoading={isLoading} />
      
        <div className="card-list" id="modal-card-list">
        {groupList.length === 0 ? (
          <div className="no-data">データがありません</div>
        ) : (
          groupList.map((item) => (
            <div
              className="card"
              key={item.groupId || 'no-group'}
              onClick={() => onSelectGroup(item)}
            >
              <div className="card-contents">
                <div className="card-contents-left">
                  <h3>{item.groupName}</h3>
                  {item.parsonCount > 0 && (
                    <p className="sub-text">現在の所属人数：{item.parsonCount} 人</p>
                  )}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      </div>
    </Modal>
  )
}

export default SelectGroupModal;