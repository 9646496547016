/* ============================ */
/* グループ追加/編集モーダル */
/* ============================ */
import React, { useEffect, useState } from 'react';
import * as validate from '../../../utils/realtimeValidation';
import './StaffGroupForm.css';

const StaffGroupForm = ({ formData, setFormData }) => {
  /* エラーメッセージ */
  const [groupId, setGroupId] = useState('');
  const [groupNameError, setGroupNameError] = useState('');
  const [groupNameKanaError, setGroupNameKanaError] = useState('');

  // ------------------------------------------------------------------------------------
  // 初期処理
  // ------------------------------------------------------------------------------------ 
  useEffect(() => {
    if (formData.groupId !== groupId) { 
      setGroupId(formData.groupId);
      setGroupNameError('');
      setGroupNameKanaError('');
    }
  }, [formData]);

  // ------------------------------------------------------------------------------------
  // イベント
  // ------------------------------------------------------------------------------------
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let newGroupNameError = '';
    let newGroupNameKanaError = '';

    if (name === 'groupName') {
      newGroupNameError = validate.validateGroupName(value);
    } else if (name === 'groupNameKana') {
      newGroupNameKanaError = validate.validateGroupNameKana(value);
    }

    // エラーステートを更新
    setGroupNameError(prevGroupNameError => name === 'groupName' ? newGroupNameError : prevGroupNameError);
    setGroupNameKanaError(prevGroupNameKanaError => name === 'groupNameKana' ? newGroupNameKanaError : prevGroupNameKanaError);

    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  }

  // ------------------------------------------------------------------------------------
  // レンダリング
  // ------------------------------------------------------------------------------------
  return (
    <div className="modal-form" id="staff-group-form">
      <div className="column-set" id="if-name">
        <div className="row-set">
          <div className="input-group">
            <label>グループ名</label>
            <div className={`error-message-container ${groupNameError ? 'has-error' : ''}`}>
              <input
                type="text"
                name="groupName"
                value={formData.groupName}
                onChange={handleInputChange}
                placeholder="グループ名"
                style={{
                  borderColor: groupNameError ? 'red' : '',
                }}
              />
              {groupNameError && (
                <div className="error-message">
                  {groupNameError}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row-set">
          <div className="input-group">
            <label>グループ名（かな）</label>
            <div className={`error-message-container ${groupNameKanaError ? 'has-error' : ''}`}>
              <input
                type="text"
                name="groupNameKana"
                value={formData.groupNameKana}
                onChange={handleInputChange}
                placeholder="グループ名（かな）"
                style={{
                  borderColor: groupNameKanaError ? 'red' : '',
                }}
              />
              {groupNameKanaError && (
                <div className="error-message">
                  {groupNameKanaError}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StaffGroupForm;