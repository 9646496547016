/* ==================== */
/* アクションモーダル */
/* ==================== */
import React, { useEffect, useState } from 'react';
import Loading from '../Loading/Loading';
import Modal from '../Modal/Modal';
import SelectAdminModal from '../SelectAdminModal/SelectAdminModal';
import { ESIGN_OPTION, MODAL_TYPE, RESPONSE } from '../../const/Enum';
import { requestApiLoad } from '../../utils/apiLoadUtil';
import './ActionModal.css';

const ActionModal = ({ isOpen, setIsOpen, item, onClickOption }) => {
  const OPTION = {
    JOB: 1,
    TEST: 2,
    SIGN: 3,
  }
  const SIGN_STATUS = {
    FIRST: 1,      // 未署名
    SECOND: 2,        // 署名リクエスト済み（管理者未署名　スタッフ未署名）
    THIRD: 3,  // 署名リクエスト済み（管理者署名済　スタッフ未署名）
    FORTH: 4,  // 署名リクエスト済み（管理者署名済　スタッフ署名済）
  }

  const [isLoading, setIsLoading] = useState(false);
  const [isSelectAdminModalOpen, setIsSelectAdminModalOpen] = useState(false);
  const [signStatus, setSignStatus] = useState(SIGN_STATUS.FIRST);
  
  useEffect(() => {
    if (isOpen) {
      const fetchData = async () => {
        const res = await requestApiLoad(`/eSignatureSetting/getStatus/${item.eSignatureId}`, {}, setIsLoading);
        if (res.return !== RESPONSE.SUCCESS) {
          return;
        }
        setSignStatus(res.signStatus);
      }
      fetchData();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  // ------------------------------------------------------------------------------------
  // 研修
  // ------------------------------------------------------------------------------------ 
  const onClickJob = (e) => {
    e.preventDefault();
    if (!item.isAllowReview && item.takeJobCount > 0) {
      alert('この研修は受講済みです。');
      return;
    }

    onClickOption(OPTION.JOB);
    setIsOpen(false);
  }

  // ------------------------------------------------------------------------------------
  // テスト
  // ------------------------------------------------------------------------------------ 
  const onClickTest = (e) => {
    e.preventDefault();
    if (!item.isJobEnd) {
      alert('研修を完了してから行ってください。');
      return;
    }

    if (!item.isAllowRetest && item.takeTestCount > 0) {
      alert('このテストは既に受講済みです。');
      return;
    }

    onClickOption(OPTION.TEST);
    setIsOpen(false);
  }

  // ------------------------------------------------------------------------------------
  // 電子サイン
  // ------------------------------------------------------------------------------------ 
  const onClickSign = (e) => {
    e.preventDefault();
    if (!item.isSign) {
      return;
    }

    if (!item.isJobEnd) {
      alert('研修とテストを完了してから行ってください。');
      return;
    }

    if (signStatus === SIGN_STATUS.FORTH) {
      alert('既に電子サインに署名されています。');
      return;
    }

    setIsSelectAdminModalOpen(true);
  }

  const handleAdminSelect = (selectedAdmin) => {
    setIsSelectAdminModalOpen(false);
  
    if (selectedAdmin) {
      if (signStatus === SIGN_STATUS.SECOND || signStatus === SIGN_STATUS.THIRD) {
        if (window.confirm('電子サイン用メールを再送信します。')) {
          onClickOption(OPTION.SIGN, true, selectedAdmin);
        }
      } else {
        onClickOption(OPTION.SIGN, false, selectedAdmin);
      }
      setIsOpen(false);
    }
  }

  // ------------------------------------------------------------------------------------
  // メッセージとSVG取得
  // ------------------------------------------------------------------------------------ 
  const getMessageAndSVG = (item) => {
    if (item.isSign) {
      switch (Number(signStatus)) {
        case SIGN_STATUS.SECOND:
        case SIGN_STATUS.THIRD:
          return {
            message: '電子サインの署名リクエストを再送信します。',
            svg:
              <svg
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                width="20px"
                height="20px"
                viewBox="0 0 24 24"
                aria-labelledby="redoIconTitle"
                stroke="#007bff"
                strokeWidth="2.4"
                strokeLinecap="square"
                strokeLinejoin="miter"
                fill="none"
                color="#007bff">
                <title id="redoIconTitle">サインステータス</title>
                <path d="M16 15h5v-5" />
                <path d="M19 13C14 8 6.425 8.725 3 14" />
                <path strokeLinecap="round" d="M21 15l-2-2" />
              </svg>
          };
        
        case SIGN_STATUS.FORTH:
          return {
            message: '電子サイン済みです。',
            svg:
              <svg
                width="20px"
                height="20px"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-labelledby="verifiedIconTitle"
                stroke="#007bff" strokeWidth="2.4"
                strokeLinecap="square"
                strokeLinejoin="miter"
                fill="none"
                color="#007bff">
                <title id="verifiedIconTitle">完了</title>
                <path d="M8 12.5L10.5 15L16 9.5" />
                <path d="M12 22C13.2363 22 14.2979 21.2522 14.7572 20.1843C14.9195 19.8068 15.4558 19.5847 15.8375 19.7368C16.9175 20.1672 18.1969 19.9453 19.0711 19.0711C19.9452 18.1969 20.1671 16.9175 19.7368 15.8376C19.5847 15.4558 19.8068 14.9195 20.1843 14.7572C21.2522 14.2979 22 13.2363 22 12C22 10.7637 21.2522 9.70214 20.1843 9.24282C19.8068 9.08046 19.5847 8.54419 19.7368 8.16246C20.1672 7.08254 19.9453 5.80311 19.0711 4.92894C18.1969 4.05477 16.9175 3.83286 15.8376 4.26321C15.4558 4.41534 14.9195 4.1932 14.7572 3.8157C14.2979 2.74778 13.2363 2 12 2C10.7637 2 9.70214 2.74777 9.24282 3.81569C9.08046 4.19318 8.54419 4.41531 8.16246 4.26319C7.08254 3.83284 5.80311 4.05474 4.92894 4.92891C4.05477 5.80308 3.83286 7.08251 4.26321 8.16243C4.41534 8.54417 4.1932 9.08046 3.8157 9.24282C2.74778 9.70213 2 10.7637 2 12C2 13.2363 2.74777 14.2979 3.81569 14.7572C4.19318 14.9195 4.41531 15.4558 4.26319 15.8375C3.83284 16.9175 4.05474 18.1969 4.92891 19.0711C5.80308 19.9452 7.08251 20.1671 8.16243 19.7368C8.54416 19.5847 9.08046 19.8068 9.24282 20.1843C9.70213 21.2522 10.7637 22 12 22Z" />
              </svg>
          };
        
        case SIGN_STATUS.FIRST:
        default:
          return {
            message: '電子サインの署名リクエストを送信します。',
            svg: null
          };
      }
    } else {
      return {
        message: 'この研修に電子サインはありません。',
        svg: null
      };
    }

    
  }
  // ------------------------------------------------------------------------------------
  // レンダリング
  // ------------------------------------------------------------------------------------ 
  return (
    <>
      <Loading isLoading={isLoading} />
      <Modal
        title={item.jobName}
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        playerKey={MODAL_TYPE.PASSWORD}
      >
        <div className="action-modal">
          <div className="card-list" id="modal-card-list">
          
            <div
              className="card"
              key={OPTION.JOB}
              onClick={(e) => onClickJob(e)}
            >
              <div className="card-contents">
                <div className="card-contents-left">
                  <h3 className="card-title">
                    <span>
                      <svg
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20px"
                        height="20px"
                        viewBox="0 0 24 24"
                        aria-labelledby="bookOpenedIconTitle"
                        stroke="#007bff"
                        strokeWidth="2.0"
                        strokeLinecap="square"
                        strokeLinejoin="miter"
                        fill="none" color="#007bff">
                        <title id="bookOpenedIconTitle">研修</title>
                        <path d="M12 6s-2-2-4-2-5 2-5 2v14s3-2 5-2 4 2 4 2c1.333-1.333 2.667-2 4-2 1.333 0 3 .667 5 2V6c-2-1.333-3.667-2-5-2-1.333 0-2.667.667-4 2z" />
                        <path strokeLinecap="round" d="M12 6v14" />
                      </svg>
                    </span>
                    研修
                  </h3>
                  <span className="sub-text">
                    受講回数制限：{item.isAllowReview ? '無制限' : '1回'}
                  </span>
                </div>

                {item.isJobEnd && (
                  <div className="card-footer">
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-labelledby="verifiedIconTitle"
                      stroke="#007bff"
                      strokeWidth="2.4"
                      strokeLinecap="square"
                      strokeLinejoin="miter"
                      fill="none"
                      color="#007bff">
                      <title id="verifiedIconTitle">完了</title>
                      <path d="M8 12.5L10.5 15L16 9.5" />
                      <path d="M12 22C13.2363 22 14.2979 21.2522 14.7572 20.1843C14.9195 19.8068 15.4558 19.5847 15.8375 19.7368C16.9175 20.1672 18.1969 19.9453 19.0711 19.0711C19.9452 18.1969 20.1671 16.9175 19.7368 15.8376C19.5847 15.4558 19.8068 14.9195 20.1843 14.7572C21.2522 14.2979 22 13.2363 22 12C22 10.7637 21.2522 9.70214 20.1843 9.24282C19.8068 9.08046 19.5847 8.54419 19.7368 8.16246C20.1672 7.08254 19.9453 5.80311 19.0711 4.92894C18.1969 4.05477 16.9175 3.83286 15.8376 4.26321C15.4558 4.41534 14.9195 4.1932 14.7572 3.8157C14.2979 2.74778 13.2363 2 12 2C10.7637 2 9.70214 2.74777 9.24282 3.81569C9.08046 4.19318 8.54419 4.41531 8.16246 4.26319C7.08254 3.83284 5.80311 4.05474 4.92894 4.92891C4.05477 5.80308 3.83286 7.08251 4.26321 8.16243C4.41534 8.54417 4.1932 9.08046 3.8157 9.24282C2.74778 9.70213 2 10.7637 2 12C2 13.2363 2.74777 14.2979 3.81569 14.7572C4.19318 14.9195 4.41531 15.4558 4.26319 15.8375C3.83284 16.9175 4.05474 18.1969 4.92891 19.0711C5.80308 19.9452 7.08251 20.1671 8.16243 19.7368C8.54416 19.5847 9.08046 19.8068 9.24282 20.1843C9.70213 21.2522 10.7637 22 12 22Z" />
                    </svg>
                  </div>
                )}
              </div>
            </div>

            <div
              className={`card ${!item.isTest ? 'disabled' : ''}`}
              key={OPTION.TEST}
              onClick={(e) => onClickTest(e)}
            >
              <div className="card-contents">
                <div className="card-contents-left">
                  <h3 className="card-title">
                    <span>
                      <svg
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20px"
                        height="20px"
                        viewBox="0 0 24 24"
                        aria-labelledby="editIconTitle"
                        stroke={item.isTest ? "#007bff" : "#bbb"}
                        strokeWidth="2.4"
                        strokeLinecap="square"
                        strokeLinejoin="miter"
                        fill="none"
                        color={item.isTest ? "#007bff" : "#bbb"}>
                        <title id="editIconTitle">テスト</title>
                        <path d="M18.4142136 4.41421356L19.5857864 5.58578644C20.366835 6.36683502 20.366835 7.63316498 19.5857864 8.41421356L8 20 4 20 4 16 15.5857864 4.41421356C16.366835 3.63316498 17.633165 3.63316498 18.4142136 4.41421356zM14 6L18 10" />
                      </svg>
                    </span>
                    テスト
                  </h3>
                  <span className="sub-text">
                    {item.isTest ? ('受講回数制限：' + (item.isAllowRetest ? '無制限' : '1回')) : 'この研修にテストはありません。'}
                  </span>
                </div>
                {(!item.isJobEnd && item.isTest) && (
                  <div className="card-footer">
                    {/* ロック */}
                    <svg
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      aria-labelledby="lockIconTitle"
                      stroke="#007bff"
                      strokeWidth="2.4"
                      strokeLinecap="square"
                      strokeLinejoin="miter"
                      fill="none"
                      color="#007bff">
                      <title id="lockIconTitle">ロック</title>
                      <rect width="14" height="10" x="5" y="11" />
                      <path d="M12,3 L12,3 C14.7614237,3 17,5.23857625 17,8 L17,11 L7,11 L7,8 C7,5.23857625 9.23857625,3 12,3 Z" />
                    </svg>
                  </div>
                )}
                {(item.isTest && item.isTestEnd) && (
                  <div className="card-footer">
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-labelledby="verifiedIconTitle"
                      stroke="#007bff" strokeWidth="2.4"
                      strokeLinecap="square"
                      strokeLinejoin="miter"
                      fill="none"
                      color="#007bff">
                      <title id="verifiedIconTitle">完了</title>
                      <path d="M8 12.5L10.5 15L16 9.5" />
                      <path d="M12 22C13.2363 22 14.2979 21.2522 14.7572 20.1843C14.9195 19.8068 15.4558 19.5847 15.8375 19.7368C16.9175 20.1672 18.1969 19.9453 19.0711 19.0711C19.9452 18.1969 20.1671 16.9175 19.7368 15.8376C19.5847 15.4558 19.8068 14.9195 20.1843 14.7572C21.2522 14.2979 22 13.2363 22 12C22 10.7637 21.2522 9.70214 20.1843 9.24282C19.8068 9.08046 19.5847 8.54419 19.7368 8.16246C20.1672 7.08254 19.9453 5.80311 19.0711 4.92894C18.1969 4.05477 16.9175 3.83286 15.8376 4.26321C15.4558 4.41534 14.9195 4.1932 14.7572 3.8157C14.2979 2.74778 13.2363 2 12 2C10.7637 2 9.70214 2.74777 9.24282 3.81569C9.08046 4.19318 8.54419 4.41531 8.16246 4.26319C7.08254 3.83284 5.80311 4.05474 4.92894 4.92891C4.05477 5.80308 3.83286 7.08251 4.26321 8.16243C4.41534 8.54417 4.1932 9.08046 3.8157 9.24282C2.74778 9.70213 2 10.7637 2 12C2 13.2363 2.74777 14.2979 3.81569 14.7572C4.19318 14.9195 4.41531 15.4558 4.26319 15.8375C3.83284 16.9175 4.05474 18.1969 4.92891 19.0711C5.80308 19.9452 7.08251 20.1671 8.16243 19.7368C8.54416 19.5847 9.08046 19.8068 9.24282 20.1843C9.70213 21.2522 10.7637 22 12 22Z" />
                    </svg>
                  </div>
                )}
              </div>
            </div>

            <div
              className={`card ${!item.isSign ? 'disabled' : ''}`}
              key={OPTION.SIGN}
              onClick={(e) => onClickSign(e)}
            >
              <div className="card-contents">
                <div className="card-contents-left">
                  <h3 className="card-title">
                    <span>
                      <svg
                        width="20px"
                        height="20px"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-labelledby="featherIconTitle"
                        stroke={item.isSign ? "#007bff" : "#bbb"}
                        strokeWidth="2.4"
                        strokeLinecap="square"
                        strokeLinejoin="miter"
                        color={item.isSign ? "#007bff" : "#bbb"}>
                        <title id="featherIconTitle">電子サイン</title>
                        <path d="M5.12634 17C5.04271 17.6571 5 18.325 5 19V21M5.12634 17C6.03384 9.86861 11.7594 4 20 4L19 8H16L17 10L15 12H11L13 14L12 16H8L5.12634 17Z" />
                      </svg>
                    </span>
                    電子サイン
                  </h3>
                  <span className="sub-text">
                    {getMessageAndSVG(item).message}
                  </span>
                </div>

                {((!item.isJobEnd || !item.isTestEnd) && item.isSign) && (
                  <div className="card-footer">
                    {/* ロック */}
                    <svg
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      aria-labelledby="lockIconTitle"
                      stroke="#007bff"
                      strokeWidth="2.4"
                      strokeLinecap="square"
                      strokeLinejoin="miter"
                      fill="none"
                      color="#007bff">
                      <title id="lockIconTitle">ロック</title>
                      <rect width="14" height="10" x="5" y="11" />
                      <path d="M12,3 L12,3 C14.7614237,3 17,5.23857625 17,8 L17,11 L7,11 L7,8 C7,5.23857625 9.23857625,3 12,3 Z" />
                    </svg>
                  </div>
                )}

                {(item.isJobEnd && item.isTestEnd && item.isSign) && (
                  <div className="card-footer">
                    {/* 署名ステータス */}
                    {item.signStatus === ESIGN_OPTION.UNSIGN && (
                      getMessageAndSVG(item).svg
                    )}
                    {item.signStatus === ESIGN_OPTION.SIGNED && (
                      getMessageAndSVG(item).svg
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    
      <SelectAdminModal
        isOpen={isSelectAdminModalOpen}
        setIsOpen={setIsSelectAdminModalOpen}
        onSelectAdmin={handleAdminSelect}
      />
    </>
  )
}

export default ActionModal;