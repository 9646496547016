/* ==================== */
/* スタッフ選択モーダル */
/* ==================== */
import React, { useEffect, useState } from 'react';
import Loading from '../Loading/Loading';
import Modal from '../Modal/Modal';
import { MODAL_TYPE, RESPONSE, STAFF_STATUS } from '../../const/Enum';
import { requestApiLoad } from '../../utils/apiLoadUtil';
import './SelectMultStaffModal.css';

const SelectMultStaffModal = ({ isOpen, setIsOpen, setSelectStaffs, selectStaffs }) => {
  const [staffList, setStaffList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const fetchData = async () => {
        const params = {
          status: STAFF_STATUS.ACTIVE
        }
        const res = await requestApiLoad('/staff/get', params, setIsLoading);
        if (res.return !== RESPONSE.SUCCESS) {
          return;
        }
        setStaffList(res.userList.map(staff => ({
          ...staff,
          isSelect: selectStaffs.some(selectedStaff => selectedStaff.staffId === staff.staffId)
        })));
      }
      fetchData();
    }
  }, [isOpen, selectStaffs]);

  useEffect(() => {
    if (staffList.length > 0) {
      setStaffList(prevList => 
        prevList.map(staff => ({
          ...staff,
          isSelect: selectStaffs.some(selectedStaff => selectedStaff.staffId === staff.staffId)
        }))
      );
    }
    // eslint-disable-next-line
  }, [selectStaffs]);

  const onSelectStaff = (item) => {
    setStaffList(prevList =>
      prevList.map(staff => 
        staff.staffId === item.staffId
          ? { ...staff, isSelect: !staff.isSelect }
          : staff
      )
    );
  };

  const handleSubmit = () => {
    const selectedStaffs = staffList.filter(staff => staff.isSelect);
    setSelectStaffs(selectedStaffs);
    setIsOpen(false);
  }
  
  return (   
    <Modal
      title="スタッフ選択"
      isOpen={isOpen}
      closeModal={() => setIsOpen(false)}
      actionButtonText="OK"
      onAction={handleSubmit}
      playerKey={MODAL_TYPE.SELECT}
      id="select-staffs-modal"
    >
      <div className="select-staffs-modal">
        <Loading isLoading={isLoading} />
      
        <div className="card-list" id="modal-card-list">
          {staffList.length === 0 ? (
            <div className="no-data">データがありません</div>
          ) : (
            staffList.map((item) => (
              <div
                className="card"
                key={item.staffId}
                onClick={() => onSelectStaff(item)}
              >
                <div className="card-contents">
                  <div style={{ width: '20px', height: '20px', marginRight: '10px' }}>
                    {item.isSelect && (
                      <svg
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20px"
                        height="20px"
                        viewBox="0 0 24 24"
                        aria-labelledby="okIconTitle"
                        stroke="#007bff"
                        strokeWidth="2.4"
                        strokeLinecap="square"
                        strokeLinejoin="miter"
                        fill="none"
                        color="#007bff"
                      >
                        <title id="okIconTitle">OK</title>
                        <polyline points="4 13 9 18 20 7" />
                      </svg>
                    )}
                  </div>

                  <div className="card-contents-left">
                    <h3>{item.staffName}</h3>
                    <span className="sub-text">{item.groupName||'未所属'}</span>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </Modal>
  )
}

export default SelectMultStaffModal;