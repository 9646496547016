/* ==================== */
/* 研修一覧画面 */
/* ==================== */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import BottomSheet from '../../components/BottomSheet/BottomSheet';
import TestModal from '../../components/TestModal/TestModal';
import Loading from '../../components/Loading/Loading';
import Pagination from '../../components/Pagination/Pagination';
import SortingButton from '../../components/SortingButton/SortingButton';
import useWindowSize from '../../hook/useWindowSize';
import { ErrorMessage } from '../../const/Constant';
import { FILE_TYPE, RESPONSE, TEST_MODE } from '../../const/Enum';
import { requestApiLoad } from '../../utils/apiLoadUtil';
import './MyPage.css';

const MyPage = () => {
  const [itemList, setItemList] = useState([]);
  const [detailItem, setDetailItem] = useState([]);
  const [selectedJob, setSelectedJob] = useState([]);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const bottomSheetRef = useRef(null);
  const size = useWindowSize();

  /* メイン一覧画面 */
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'jobId', direction: true });
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const sortOptions = [
    { key: 'jobId', value: { key: 'jobId', direction: true }, label: '標準' },
    { key: 'jobDate_asc', value: { key: 'jobDate', direction: false }, label: '研修受講日（昇順）' },
    { key: 'jobDate_desc', value: { key: 'jobDate', direction: true }, label: '研修受講日（降順）' },
    { key: 'testDate_asc', value: { key: 'testDate', direction: false }, label: 'テスト受講日（昇順）' },
    { key: 'testDate_desc', value: { key: 'testDate', direction: true }, label: 'テスト受講日（降順）' }
  ];
  
  // ------------------------------------------------------------------------------------
  // 初期処理
  // ------------------------------------------------------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        page: 1,
        isDesc: sortConfig.direction,
        orderKey: sortConfig.key,
        name: searchTerm,
      }
      const res = await requestApiLoad('/myPage/get', params, setIsLoading);
      if (res.return !== RESPONSE.SUCCESS) {
        return;
      }
      
      setItemList((res.jobList && res.jobList.length > 0) ? res.jobList : []);
      setCurrentPage(1);
      setLastPage(res.lastPage);
      setTotalCount(res.dataCount);
    };
    fetchData();
  }, [sortConfig.direction, sortConfig.key, searchTerm]);

  // ------------------------------------------------------------------------------------
  // ページネーション
  // ------------------------------------------------------------------------------------
  const handlePageChange = async (pageNumber) => {
    setCurrentPage(pageNumber);
    
    const params = {
      page: pageNumber,
      isDesc: sortConfig.direction,
      orderKey: sortConfig.key,
      name: searchTerm,
    }
    
    const res = await requestApiLoad('/myPage/get', params, setIsLoading);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }

    setItemList(res.jobList);
    setLastPage(res.lastPage);
    setTotalCount(res.dataCount);
  };

  // ------------------------------------------------------------------------------------
  // ソート
  // ------------------------------------------------------------------------------------
  const handleSortChange = async (event) => {
    const newSortConfig = !event ? { key: 'jobId', direction: true } : JSON.parse(event);
    setSortConfig(newSortConfig);
  };

  const sortedAndFilteredItems = useMemo(() => {
    let filteredItems = itemList || [];
    return filteredItems;
  }, [itemList]);

  // ------------------------------------------------------------------------------------
  // 検索
  // ------------------------------------------------------------------------------------
  const onClear = () => {
    setSearchTerm('');
  };

  // ------------------------------------------------------------------------------------
  // テスト
  // ------------------------------------------------------------------------------------
  const handleDetail = async (job) => {
    if (!job) {
      alert(ErrorMessage.JOB_NOT_FOUND);
      return;
    }
    
    const res = await requestApiLoad(`/myPage/getResult/${job.jobId}`, {}, setIsLoading);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }
    if(res.questionList && res.questionList.length === 0){
      alert(ErrorMessage.JOB_NOT_FOUND);
      return;
    }
   
    // すべての回答がfalseの質問があるかチェック
    const hasUnansweredQuestion = res.questionList.some(question =>
      question.answerList.every(answer => !answer.isSelect)
    );
    if (hasUnansweredQuestion) {
      alert('この研修は、テストを受講していません。');
      return;
    }

    setSelectedJob(job);
    const updatedJobDetail = res.questionList.map(question => {
      return {
        ...question,
        answerList: question.answerList.map(answer => {
          return {
            ...answer,
            isSelected: answer.isSelect,
          }
        })
      }
    });
    setDetailItem({ ...res, questionList: updatedJobDetail });
    setIsDetailOpen(true);
  };
  const closeDetail = () => {
    setIsDetailOpen(false);
  };

  // ------------------------------------------------------------------------------------
  // 一覧作成
  // ------------------------------------------------------------------------------------ 
  const createTable = (itemList) => {
    return (
      <div className="card-list">
        {itemList.length === 0 ? (
          <div className="no-data">データがありません</div>
        ) : (
          itemList.map((item) => (
            <div className="card" key={item.jobId} onClick={() => handleDetail(item)}>
              <div className="card-contents" id="my-page-card-contents">
                <div className="card-contents-left">
                  <h3 className="card-title">
                    <span>
                      {item.fileType === FILE_TYPE.PDF && (
                        <svg
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          width="19px"
                          height="20px"
                          viewBox="0 0 24 24"
                          aria-labelledby="stickerIconTitle"
                          stroke="var(--blue-font-color)"
                          strokeWidth="1.8"
                          strokeLinecap="square"
                          strokeLinejoin="miter"
                          fill="none"
                          color="var(--blue-font-color)">
                          <title id="stickerIconTitle">PDF</title>
                          <path d="M21,3 L21,11 C21,16.5228475 16.5228475,21 11,21 L3,21 L3,3 L21,3 Z" />
                          <path d="M9,21 C11.6666667,21 13,19.6666667 13,17 C13,17 13,15.6666667 13,13 L17,13 C19.6666667,13 21,11.6666667 21,9" />
                        </svg>
                      )}
                      {item.fileType === FILE_TYPE.VIDEO && (
                        <svg
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          width="19px"
                          height="20px"
                          viewBox="0 0 24 24"
                          aria-labelledby="videoIconTitle"
                          stroke="var(--blue-font-color)"
                          strokeWidth="1.8"
                          strokeLinecap="square"
                          strokeLinejoin="miter"
                          fill="none"
                          color="var(--blue-font-color)">
                          <title id="videoIconTitle">Video</title>
                          <polygon points="18 12 9 16.9 9 7" />
                          <circle cx="12" cy="12" r="10" />
                        </svg>
                      )}
                    </span>
                    {item.jobName}
                    {item.groupName && (
                      <span>
                        <svg
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          width="17px"
                          height="17px"
                          viewBox="0 0 24 24"
                          aria-labelledby="peopleIconTitle"
                          stroke="var(--blue-font-color)"
                          strokeWidth="1.7"
                          strokeLinecap="square"
                          strokeLinejoin="miter"
                          fill="none"
                          color="var(--blue-font-color)">
                          <title id="peopleIconTitle">グループ</title>
                          <path d="M1 18C1 15.75 4 15.75 5.5 14.25 6.25 13.5 4 13.5 4 9.75 4 7.25025 4.99975 6 7 6 9.00025 6 10 7.25025 10 9.75 10 13.5 7.75 13.5 8.5 14.25 10 15.75 13 15.75 13 18M12.7918114 15.7266684C13.2840551 15.548266 13.6874862 15.3832994 14.0021045 15.2317685 14.552776 14.9665463 15.0840574 14.6659426 15.5 14.25 16.25 13.5 14 13.5 14 9.75 14 7.25025 14.99975 6 17 6 19.00025 6 20 7.25025 20 9.75 20 13.5 17.75 13.5 18.5 14.25 20 15.75 23 15.75 23 18" />
                          <path strokeLinecap="round" d="M12,16 C12.3662741,15.8763472 12.6302112,15.7852366 12.7918114,15.7266684" />
                        </svg>
                      </span>
                    )}
                  </h3>
                  <div className="sub-text-container">
                    <span className="sub-text">研修受講日：{item.jobDate}</span>
                    <span className="sub-text">テスト受講日：{item.testDate || '-'}</span>
                  </div>
                  <span className="sub-text">
                    {(item.correctCount !== undefined && item.correctCount !== null &&
                      item.questionCount !== undefined && item.questionCount !== null &&
                      item.participants !== undefined && item.participants !== null &&
                      item.rank !== undefined && item.rank !== null) && (
                        <>
                          正解数：{item.correctCount} / {item.questionCount}（{item.participants}人中 {item.rank}位）
                        </>
                      )}
                  </span>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    )
  }

  // ------------------------------------------------------------------------------------
  // レンダリング
  // ------------------------------------------------------------------------------------
  return (
    <div className="view-contents" id="my-page">
      <Loading isLoading={isLoading} />
      <BottomSheet ref={bottomSheetRef} />

      <div className="main-contents">
        {size.width > 1200 && (<h2 className="page-title">マイページ</h2>)}
        <div className="header-contents">
          <div className="search-bar">
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              <input
                type="text"
                placeholder="研修名で検索"
                value={searchTerm || ''}
                onChange={(e) => setSearchTerm(e.target.value)}
                maxLength={20}
                style={{
                  backgroundImage: 'url(/images/search.png)',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '10px center',
                  backgroundSize: '20px 20px',
                  paddingLeft: '40px',
                  paddingRight: searchTerm ? '30px' : '10px'
                }}
              />
              {searchTerm && (
                <button
                  onClick={onClear}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '16px',
                    color: '#878787'
                  }}>
                  ×
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="middle-contents">
          <div className="left-contents">
            <SortingButton
              sortConfig={sortConfig}
              handleSortChange={handleSortChange}
              sortOptions={sortOptions}
            />
          </div>
          <Pagination
            totalPages={lastPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            totalItems={totalCount}
          />
        </div>

        {createTable(sortedAndFilteredItems)}
      </div>

      {isDetailOpen &&
        <TestModal
          jobId={selectedJob.jobId}
          jobTitle={selectedJob.jobName}
          testData={detailItem.questionList}
          isOpen={isDetailOpen}
          closeModal={closeDetail}
          mode={TEST_MODE.GRADING}
        />}
    </div>
  )
}

export default MyPage;