import React from 'react';
import './StatusFilters.css';

const StatusFilters = ({ statusFilter, handleStatusChange, statusOptions, disabled = false }) => (
  <div className="status-filters">
    {statusOptions.map(option => (
      <label
        key={option.value}
        className={`status-filter ${disabled ? 'disabled' : ''}`}
      >
        <input
          type="radio"
          name="status"
          value={option.value}
          checked={statusFilter === option.value}
          onChange={handleStatusChange}
          disabled={disabled}
        />
        <p>{option.label}</p>
      </label>
    ))}
  </div>  
);

export default StatusFilters;