/* ==================== */
/* 研修一覧画面 */
/* ==================== */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ActionModal from '../../components/ActionModal/ActionModal';
import BottomSheet from '../../components/BottomSheet/BottomSheet';
import DocumentModal from '../../components/DocumentModal/DocumentModal';
import FilterButton from '../../components/FilterButton/FilterButton';
import Loading from '../../components/Loading/Loading';
import Modal from '../../components/Modal/Modal';
import Pagination from '../../components/Pagination/Pagination';
import SortingButton from '../../components/SortingButton/SortingButton';
import TestModal from '../../components/TestModal/TestModal';
import VimeoPlayer from '../../components/VimeoPlayer/VimeoPlayer';
import useWindowSize from '../../hook/useWindowSize';
import { ErrorMessage, Message } from '../../const/Constant';
import { ESIGN_OPTION, FILE_TYPE, MODAL_SUBMIT_TEXT, MODAL_TYPE,RESPONSE, STAFF_JOB_STATUS, STATUS_TYPE, TEST_MODE } from '../../const/Enum';
import { requestApiLoad, requestApiLoadAndBottom } from '../../utils/apiLoadUtil';
import './JobList.css';

const JobList = () => {
  const [itemList, setItemList] = useState([]);
  const [jobItem, setJobItem] = useState({
    jobId: '',
    jobName: '',
    vimeoId: '',
    filePath: '',
    fileName: '',
    fileType: FILE_TYPE.VIDEO,
    isTest: false,
    isDownload: false,
  });
  const [testItem, setTestItem] = useState({
  });
  const [selectedItem, setSelectedItem] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [playerModalOpen, setPlayerModalOpen] = useState(false);
  const [testModalOpen, setTestModalOpen] = useState(false);
  const [isLCEnd, setIsLCEnd] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const bottomSheetRef = useRef(null);
  const size = useWindowSize();
  
  /* メイン一覧画面 */
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState(0);
  const [sortConfig, setSortConfig] = useState({ key: 'createAt', direction: true });
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [popupOpen, setPopupOpen] = useState(null);
  const statusOptions = [
    { value: STATUS_TYPE.ALL, label: 'すべて' },
    { value: STAFF_JOB_STATUS.JOB_NOT_END, label: '研修未受講' },
    { value: STAFF_JOB_STATUS.JOB_END, label: '研修受講済' },
    { value: STAFF_JOB_STATUS.TEST_NOT_END, label: 'テスト未受講' },
    { value: STAFF_JOB_STATUS.TEST_END, label: 'テスト受講済' },
    { value: STAFF_JOB_STATUS.SIGN_NOT_END, label: '電子サイン未署名' },
    { value: STAFF_JOB_STATUS.SIGN_END, label: '電子サイン署名済' },
  ];
  const sortOptions = [
    { key: 'createAt', value: { key: 'createAt', direction: true }, label: '標準' },
    { key: 'releaseStartDate_asc', value: { key: 'releaseStartDate', direction: false }, label: '開始日（昇順）' },
    { key: 'releaseStartDate_desc', value: { key: 'releaseStartDate', direction: true }, label: '開始日（降順）' },
    { key: 'releaseEndDate_asc', value: { key: 'releaseEndDate', direction: false }, label: '終了日（昇順）' },
    { key: 'releaseEndDate_desc', value: { key: 'releaseEndDate', direction: true }, label: '終了日（降順）' }
  ];
  
  // ------------------------------------------------------------------------------------
  // オプション
  // ------------------------------------------------------------------------------------
  const OPTION = {
    JOB: 1,
    TEST: 2,
    SIGN: 3,
  }

// ------------------------------------------------------------------------------------
// 初期処理
// ------------------------------------------------------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        page: 1,
        isDesc: sortConfig.direction,
        orderKey: sortConfig.key,
        takeStatus: statusFilter,
        name: searchTerm,
      }
      const res = await requestApiLoad('/job/get', params, setIsLoading);
      if (res.return !== RESPONSE.SUCCESS) {
        return;
      }
      
      setItemList((res.jobList && res.jobList.length > 0) ? res.jobList : []);
      setCurrentPage(1);
      setLastPage(res.lastPage);
      setTotalCount(res.dataCount);
    };
    fetchData();
  }, [statusFilter, sortConfig.direction, sortConfig.key, searchTerm]);

  // ------------------------------------------------------------------------------------
  // ページネーション
  // ------------------------------------------------------------------------------------
  const handlePageChange = async (pageNumber) => {
    setCurrentPage(pageNumber);
    
    const params = {
      page: pageNumber,
      isDesc: sortConfig.direction,
      orderKey: sortConfig.key,
      takeStatus: statusFilter,
      name: searchTerm,
    }
    
    const res = await requestApiLoad('/job/get', params, setIsLoading);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }

    setItemList(res.jobList);
    setLastPage(res.lastPage);
    setTotalCount(res.dataCount);
  };

  // ------------------------------------------------------------------------------------
  // フィルター
  // ------------------------------------------------------------------------------------
  const handleStatusChange = async (event) => {
    const newStatusFilter = Number(event.target.value);
    setCurrentPage(1);
    setStatusFilter(newStatusFilter);
  };

  const sortedAndFilteredItems = useMemo(() => {
    let filteredItems = itemList || [];
    return filteredItems;
  }, [itemList]);

  // ------------------------------------------------------------------------------------
  // ソート
  // ------------------------------------------------------------------------------------
  const handleSortChange = async (event) => {
    const newSortConfig = !event ? { key: 'createAt', direction: true } : JSON.parse(event);
    setSortConfig(newSortConfig);
  };

  // ------------------------------------------------------------------------------------
  // 検索
  // ------------------------------------------------------------------------------------
  const onClear = () => {
    setSearchTerm('');
  }

  // ------------------------------------------------------------------------------------
  // 研修モーダル
  // ------------------------------------------------------------------------------------
  const openPlayerModal = async (job) => {
    if (!job) {
      alert(ErrorMessage.JOB_NOT_FOUND);
      return;
    }

    //複数回受講可能かチェック
    const { message, error } = checkTakingJob(job);
    if (error) {
      alert(message);
      return;
    }

    //複数回受講不可の場合メッセージを表示
    if (!job.isAllowReview) {
      if (!window.confirm(`「${job.jobName}」は一度しか受講できません。続けますか？`)) {
        return;
      }
    }

    setJobItem({
      jobId: job.jobId,
      jobName: job.jobName,
      vimeoId: job.vimeoId,
      filePath: job.filePath,
      fileName: job.fileName,
      fileType: job.fileType,
      isTest: job.isTest,
      isDownload: job.isDownload,
    });

    setIsEnd(false);
    setPlayerModalOpen(true);
  };

  const closePlayerModal = () => {
    const job = itemList.find((item) => item.jobId === jobItem.jobId);
    
    if (isEnd && job.takeJobCount === 1) {
      bottomSheetRef.current.showMessage(Message.BS_JOB_COMPLETE_SUCCESS);
    }
    setPlayerModalOpen(false);
    
    if (job.isTest && job.takeJobCount === 1 && !job.isTestEnd) {
      if (window.confirm('続けてテストを受験しますか？')) {
        openTestModal(job);
      }
    }
  }

  // ------------------------------------------------------------------------------------
  // テストモーダル
  // ------------------------------------------------------------------------------------
  const openTestModal = async (job) => {
    if (!job) {
      alert(ErrorMessage.TEST_NOT_FOUND);
      return;
    }

    //複数回LC受験可能かチェック
    const { message, error } = checkTakingTest(job);
    if (error) {
      alert(message);
      return;
    }

    //複数回LC受験不可の場合メッセージを表示
    if (!job.isAllowRetest) {
      if (!window.confirm(`「${job.jobName}」のテストは一度しか受験できません。続けますか？`)) {
        return;
      }
    }

    const res = await requestApiLoad(`/test/get/${job.jobId}`, {}, setIsLoading);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }
    setTestItem({
      jobId: job.jobId,
      jobTitle: job.jobName,
      questionList: res.questionList,
    });
    setTestModalOpen(true);
  };

  const closeTestModal = () => {
    if (isLCEnd) {
      // フロント側のデータも更新
      const job = itemList.find((job) => job.jobId === testItem.jobId);
      if (job) {
        job.isTestEnd = true;
        job.takeTestCount += 1;
      }
    }
    setTestModalOpen(false);
  };

  // ------------------------------------------------------------------------------------
  // 受講完了
  // ------------------------------------------------------------------------------------
  const onEndJob = async () => {
    if (isEnd) {
      return;
    }
    const res = await requestApiLoad(`/job/complete/${jobItem.jobId}`, {}, setIsLoading);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }
    // フロント側のデータも更新
    const job = itemList.find((job) => job.jobId === jobItem.jobId);
    if (job) {
      job.isJobEnd = true;
      job.takeJobCount += 1;
    }
    setIsEnd(true);
  }

  // ------------------------------------------------------------------------------------
  // その他
  // ------------------------------------------------------------------------------------ 
  const checkTakingJob = (job) => {
    if (!job) {
      return { message: ErrorMessage.JOB_NOT_FOUND, error: true };
    }

    return { message: '', error: false };
  };

  const checkTakingTest = (job) => {
    if (!job || !job.jobId) {
      return { message: ErrorMessage.JOB_NOT_FOUND, error: true };
    }

    return { message: '', error: false };
  };

  // ------------------------------------------------------------------------------------
  // アクション選択
  // ------------------------------------------------------------------------------------ 
  const handleOptionClick = (event, item) => {
    event.stopPropagation();
    setSelectedItem(item);
    setPopupOpen(true);
  };

  const handlePopupOptionClick = async (option, isResign = false, selectAdmin = null) => {
    switch (option) {
      case OPTION.JOB:
        openPlayerModal(selectedItem);
        break;
      
      case OPTION.TEST:
        openTestModal(selectedItem);
        break;
      
      case OPTION.SIGN:
        if (!selectAdmin || !selectAdmin.adminId) {
          alert('管理者が選択されていません。');
          return;
        }
        const res = await requestApiLoadAndBottom(`/eSignatureSetting/${isResign ? 'reSign' : 'sign'}/${selectedItem.eSignatureId}`,
          { adminId: selectAdmin.adminId }, setIsLoading, bottomSheetRef, `電子サインの${isResign ? '再署名' : '署名'}リクエストが送信されました。`);
        if (res.return !== RESPONSE.SUCCESS) {
          alert(`電子サインの${isResign ? '再署名' : '署名'}リクエストの送信に失敗しました。`);
          return;
        }
        break;
   
      default:
        break;
    }
  }

  // ------------------------------------------------------------------------------------
  // 一覧作成
  // ------------------------------------------------------------------------------------ 
  const createTable = (itemList) => {
    return (
      <div className="card-list">
        {itemList.length === 0 ? (
          <div className="no-data">データがありません</div>
        ) : (
          itemList.map((item) => (
            <div className="card" key={item.jobId} onClick={(e) => handleOptionClick(e, item)}>
              <div className="card-contents" id="job-list-card-contents">
                <div className="card-contents-left">
                  <h3 className="card-title">
                    <span>
                      {item.fileType === FILE_TYPE.PDF && (
                        <svg
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          width="19px"
                          height="20px"
                          viewBox="0 0 24 24"
                          aria-labelledby="stickerIconTitle"
                          stroke="var(--blue-font-color)"
                          strokeWidth="2.0"
                          strokeLinecap="square"
                          strokeLinejoin="miter"
                          fill="none"
                          color="var(--blue-font-color)">
                          <title id="stickerIconTitle">PDF</title>
                          <path d="M21,3 L21,11 C21,16.5228475 16.5228475,21 11,21 L3,21 L3,3 L21,3 Z" />
                          <path d="M9,21 C11.6666667,21 13,19.6666667 13,17 C13,17 13,15.6666667 13,13 L17,13 C19.6666667,13 21,11.6666667 21,9" />
                        </svg>
                      )}
                      {item.fileType === FILE_TYPE.VIDEO && (
                        <svg
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          width="19px"
                          height="20px"
                          viewBox="0 0 24 24"
                          aria-labelledby="videoIconTitle"
                          stroke="var(--blue-font-color)"
                          strokeWidth="2.0"
                          strokeLinecap="square"
                          strokeLinejoin="miter"
                          fill="none"
                          color="var(--blue-font-color)">
                          <title id="videoIconTitle">Video</title>
                          <polygon points="18 12 9 16.9 9 7" />
                          <circle cx="12" cy="12" r="10" />
                        </svg>
                      )}
                    </span>
                    {item.jobName}
                    {item.groupName && (
                      <span>
                        <svg
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          width="17px"
                          height="17px"
                          viewBox="0 0 24 24"
                          aria-labelledby="peopleIconTitle"
                          stroke="var(--blue-font-color)"
                          strokeWidth="1.7"
                          strokeLinecap="square"
                          strokeLinejoin="miter"
                          fill="none"
                          color="var(--blue-font-color)">
                          <title id="peopleIconTitle">グループ</title>
                          <path d="M1 18C1 15.75 4 15.75 5.5 14.25 6.25 13.5 4 13.5 4 9.75 4 7.25025 4.99975 6 7 6 9.00025 6 10 7.25025 10 9.75 10 13.5 7.75 13.5 8.5 14.25 10 15.75 13 15.75 13 18M12.7918114 15.7266684C13.2840551 15.548266 13.6874862 15.3832994 14.0021045 15.2317685 14.552776 14.9665463 15.0840574 14.6659426 15.5 14.25 16.25 13.5 14 13.5 14 9.75 14 7.25025 14.99975 6 17 6 19.00025 6 20 7.25025 20 9.75 20 13.5 17.75 13.5 18.5 14.25 20 15.75 23 15.75 23 18" />
                          <path strokeLinecap="round" d="M12,16 C12.3662741,15.8763472 12.6302112,15.7852366 12.7918114,15.7266684" />
                        </svg>
                      </span>
                    )}
                  </h3>
                  <span className="sub-text" style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}>{item.releaseStartDate} ~ {item.releaseEndDate}
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      gap: size.width > 1200 ? '10px' : '3px',
                    }}>
                      <svg
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        width={size.width > 1200 ? "20px" : "18px"}
                        height={size.width > 1200 ? "20px" : "18px"}
                        viewBox="0 0 24 24"
                        aria-labelledby="bookOpenedIconTitle"
                        stroke={item.isJobEnd ? "#007bff" : "#bbb"}
                        strokeWidth={size.width > 1200 ? "2.3" : "1.9"}
                        strokeLinecap="square"
                        strokeLinejoin="miter"
                        fill="none"
                        color={item.isJobEnd ? "#007bff" : "#bbb"}>
                        <title id="bookOpenedIconTitle">研修</title>
                        <path d="M12 6s-2-2-4-2-5 2-5 2v14s3-2 5-2 4 2 4 2c1.333-1.333 2.667-2 4-2 1.333 0 3 .667 5 2V6c-2-1.333-3.667-2-5-2-1.333 0-2.667.667-4 2z" />
                        <path strokeLinecap="round" d="M12 6v14" />
                      </svg>

                      <svg
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        width={size.width > 1200 ? "20px" : "18px"}
                        height={size.width > 1200 ? "20px" : "18px"}
                        viewBox="0 0 24 24"
                        aria-labelledby="editIconTitle"
                        stroke={!item.isTest ? "transparent" : item.isTestEnd ? "#007bff" : "#bbb"}
                        strokeWidth={size.width > 1200 ? "2.4" : "2.0"}
                        strokeLinecap="square"
                        strokeLinejoin="miter"
                        fill="none"
                        color={item.isTestEnd ? "#007bff" : "#bbb"}>
                        <title id="editIconTitle">テスト</title>
                        <path d="M18.4142136 4.41421356L19.5857864 5.58578644C20.366835 6.36683502 20.366835 7.63316498 19.5857864 8.41421356L8 20 4 20 4 16 15.5857864 4.41421356C16.366835 3.63316498 17.633165 3.63316498 18.4142136 4.41421356zM14 6L18 10" />
                      </svg>

                      <svg
                        width={size.width > 1200 ? "20px" : "18px"}
                        height={size.width > 1200 ? "20px" : "18px"}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-labelledby="featherIconTitle"
                        stroke={!item.isSign ? "transparent" : item.signStatus === ESIGN_OPTION.SIGNED ? "#007bff" : "#bbb"}
                        strokeWidth={size.width > 1200 ? "2.4" : "2.0"}
                        strokeLinecap="square"
                        strokeLinejoin="miter"
                        color={item.isSign ? "transparent" : item.isJobEnd ? "#007bff" : "#bbb"}>
                        <title id="featherIconTitle">電子サイン</title>
                        <path d="M5.12634 17C5.04271 17.6571 5 18.325 5 19V21M5.12634 17C6.03384 9.86861 11.7594 4 20 4L19 8H16L17 10L15 12H11L13 14L12 16H8L5.12634 17Z" />
                      </svg>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    )
  }

  return (
    <div className="view-contents" id="job-list">
      <Loading isLoading={isLoading} />
      <BottomSheet ref={bottomSheetRef} />

      <div className="main-contents">
        {size.width > 1200 && (<h2 className="page-title">研修一覧</h2>)}
        <div className="header-contents">
          <div className="search-bar">
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              <input
                type="text"
                placeholder="研修名で検索"
                value={searchTerm || ''}
                onChange={(e) => setSearchTerm(e.target.value)}
                maxLength={20}
                style={{
                  backgroundImage: 'url(/images/search.png)',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '10px center',
                  backgroundSize: '20px 20px',
                  paddingLeft: '40px',
                  paddingRight: searchTerm ? '30px' : '10px'
                }}
              />
              {searchTerm && (
                <button
                  onClick={onClear}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '16px',
                    color: '#878787'
                  }}>
                  ×
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="middle-contents">
          <div className="left-contents">
            <FilterButton
              statusFilter={statusFilter}
              handleStatusChange={handleStatusChange}
              statusOptions={statusOptions}
            />
            <SortingButton
              sortConfig={sortConfig}
              handleSortChange={handleSortChange}
              sortOptions={sortOptions}
            />
          </div>
          <Pagination
            totalPages={lastPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            totalItems={totalCount}
          />
        </div>

        {createTable(sortedAndFilteredItems)}
      </div>

      {playerModalOpen && jobItem.fileType === FILE_TYPE.PDF &&
        <DocumentModal
          isOpen={playerModalOpen}
          closeModal={closePlayerModal}
          title={jobItem.jobName}
          filePath={jobItem.filePath}
          isDownload={jobItem.isDownload}
          isPreview={false}
          onEnd={onEndJob}
        />
      }
      {playerModalOpen && jobItem.fileType === FILE_TYPE.VIDEO &&
        <Modal
          isOpen={playerModalOpen}
          closeModal={closePlayerModal}
          title={jobItem.jobName}
          closeButtonText={isEnd ? MODAL_SUBMIT_TEXT.COMPLETE : MODAL_SUBMIT_TEXT.CANCEL}
          playerKey={MODAL_TYPE.VIMEO}
        >
          <VimeoPlayer
            videoId={jobItem.vimeoId}
            onEnd={onEndJob}
          />
        </Modal>
      }
      {testModalOpen &&
        <TestModal
          jobId={testItem.jobId}
          jobTitle={testItem.jobTitle}
          testData={testItem.questionList}
          isOpen={testModalOpen}
          closeModal={closeTestModal}
          setLCEnd={setIsLCEnd}
          mode={TEST_MODE.LEARNING}
        />}
      {popupOpen &&
        <ActionModal
          isOpen={popupOpen}
          setIsOpen={setPopupOpen}
          item={selectedItem}
          onClickOption={handlePopupOptionClick}
        />
      }
    </div>
  )
}

export default JobList;