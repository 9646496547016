/* ==================== */
/* 共通アカウント作成画面 */
/* ==================== */
import React, { useEffect, useState, useRef } from 'react';
import BottomSheet from '../../components/BottomSheet/BottomSheet';
import Cookies from 'js-cookie';
import Loading from '../../components/Loading/Loading';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorMessage, MaxLength, Message } from '../../const/Constant';
import { RESPONSE } from '../../const/Enum';
import { requestApiLoad, requestApiLoadAndBottom } from '../../utils/apiLoadUtil';
import * as Validation from '../../utils/validation';
import './CreateAccount.css';

const CreateAccount = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [mail, setMail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [token, setToken] = useState('');
  const bottomSheetRef = useRef(null);

  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
    match: false,
  });

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const areAllCriteriaMet = () => {
    return Object.values(passwordCriteria).every(criterion => criterion === true);
  };

  useEffect(() => {
    setIsButtonDisabled(!areAllCriteriaMet());
    // eslint-disable-next-line
  }, [passwordCriteria]);

  useEffect(() => {
    const init = async () => {
      // Cookiesがある場合
      //（レアケースだが、管理者とスタッフが同じPC同じブラウザでログインしている場合）はCookiesを削除
      if (Cookies.get('sessionId')) { Cookies.remove('sessionId'); }

      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get('token');

      // トークンがない場合（URL直打ちなど）はログインページにリダイレクト
      if (!token || token === '') {
        window.location.href = '/'; 
      }

      setToken(token);
    };
    init();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const checkToken = async () => {
      if (!token || token === '') {
        return;
      }
      const res = await requestApiLoad('/auth/checkToken', { token: token }, setIsLoading);
      if (res.return !== RESPONSE.SUCCESS) {
        alert(ErrorMessage.AUTH_CHECK_TOKEN_FAILED);
        return;
      }
      if (res.user.mail) {
        setMail(res.user.mail);
      }
    };
    checkToken();
    // eslint-disable-next-line
  }, [token]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'newPassword') {
      setNewPassword(value);
      setPasswordCriteria({
        length: value.length >= 8 && value.length <= 16,
        uppercase: /[A-Z]/.test(value),
        lowercase: /[a-z]/.test(value),
        number: /[0-9]/.test(value),
        specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
        match: value !== '' && value === confirmPassword,
      });
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value);
      setPasswordCriteria((prev) => ({
        ...prev,
        match: value !== '' && value === newPassword,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationResult = Validation.validateNewAndConfirmPassword(newPassword, confirmPassword);
    if (validationResult.error) {
      alert(validationResult.message);
      return;
    }
    
    const res = await requestApiLoadAndBottom('/auth/initPassword',
      { token: token, password: newPassword }, setIsLoading, bottomSheetRef, Message.BS_INIT_PASSWORD_SUCCESS);
    if (res.return !== RESPONSE.SUCCESS) {
      alert(ErrorMessage.AUTH_INIT_PASSWORD_FAILED);
      return;
    }

    const role = res.user.role;
    const userName = res.user.userName;
    const session = res.user.session;

    Cookies.set('sessionId', session);
      
    switch (role) {
      case 1:
        navigate('/customer-master', { state: { role: role } });
        break;
      case 2:
        navigate('/job-setting', { state: { role: role } });
        break;
      default:
        navigate('/job-list', { state: { role: role } });
        break;
    }
  };

  return (
    <div className="create-account-container">
      <Loading isLoading={isLoading} />
      <BottomSheet ref={bottomSheetRef} />
      <form onSubmit={handleSubmit} className="create-account-form">
        <div className="column-set">
          <div className="row-set">
            <div className="input-group">
              <label>メールアドレス</label>
              <input
                value={mail}
                disabled
                autoComplete="username"
                maxLength={MaxLength.MAIL_MAX_LENGTH}
              />
            </div>
          </div>

          <div className="row-set">
            <div className="input-group">
              <label>パスワード</label>
              <input
                type={showPassword ? "text" : "password"}
                name="newPassword"
                value={newPassword}
                onChange={handleInputChange}
                autoComplete="new-password"
                maxLength={MaxLength.PASSWORD_MAX_LENGTH}
                required
              />
            </div>
          </div>

          <div className="row-set">
            <div className="input-group">
              <label>パスワード（確認）</label>
              <input
                type={showPassword ? "text" : "password"}
                name="confirmPassword"
                value={confirmPassword}
                onChange={handleInputChange}
                onPaste={(e) => e.preventDefault()}
                autoComplete="new-password"
                maxLength={MaxLength.PASSWORD_MAX_LENGTH}
                required
              />
            </div>
          </div>

          <div className="password-criteria-container">
            <div className="visible-button-container">
              <input
                type="checkbox"
                className="visible-button"
                checked={showPassword}
                onChange={() => setShowPassword(!showPassword)}
              />
              <label
                htmlFor="visible-button"
                onClick={() => setShowPassword(!showPassword)}>パスワード表示</label>
            </div>

            <div className="password-criteria">
              <p style={{ color: passwordCriteria.length ? '#32b948' : 'red' }}>
                <span className="check-icon-container">
                  <svg className={`check-icon ${passwordCriteria.length ? 'visible' : ''}`} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-labelledby="circleOkIconTitle">
                    <title id="circleOkIconTitle">OK</title>
                    <polyline points="7 13 10 16 17 9" />
                    <circle cx="12" cy="12" r="10" />
                  </svg>
                </span>
                8文字以上16文字以下
              </p>

              <p style={{ color: passwordCriteria.uppercase && passwordCriteria.lowercase && passwordCriteria.number && passwordCriteria.specialChar ? '#32b948' : 'red' }}>
                <span className="check-icon-container">
                  <svg className={`check-icon ${passwordCriteria.uppercase && passwordCriteria.lowercase && passwordCriteria.number && passwordCriteria.specialChar ? 'visible' : ''}`} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-labelledby="circleOkIconTitle">
                    <title id="circleOkIconTitle">OK</title>
                    <polyline points="7 13 10 16 17 9" />
                    <circle cx="12" cy="12" r="10" />
                  </svg>
                </span>
                英語大文字、英語小文字、数字、記号を各1文字以上含む
              </p>

              <p style={{ color: passwordCriteria.match ? '#32b948' : 'red' }}>
                <span className="check-icon-container">
                  <svg className={`check-icon ${passwordCriteria.match ? 'visible' : ''}`} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-labelledby="circleOkIconTitle">
                    <title id="circleOkIconTitle">OK</title>
                    <polyline points="7 13 10 16 17 9" />
                    <circle cx="12" cy="12" r="10" />
                  </svg>
                </span>
                確認パスワードと一致
              </p>
            </div>
          </div>

        </div>
        <button type="submit" className="submit-button" disabled={isButtonDisabled}>
          アカウント作成
        </button>
      </form>
    </div>
  )
}

export default CreateAccount;