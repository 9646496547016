import React, { useEffect, useState } from 'react';
import { getCurrentDateString, getDaysDifference } from '../../utils/dateUtils';
import './StatusMessage.css';

const MessageType = {
  USING:1,
  PREPARING: 2,
  STOP: 3,
};

/**
 * 契約ステータスによってメッセージを表示する
 * @param {string} status - ステータス{1:準備中,2:利用中,3:停止中}
 * @param {string} startDate - 開始日 {1の時は必須}
 * @param {string} endDate - 終了日 {2,3の時は必須}
 * @param {boolean} isOwner - オーナーかどうか（研修設定/スタッフ設定用）
 * @param {boolean} isAvailable - 利用可能かどうか（研修設定/スタッフ設定用）
 * @param {string} screen - 画面名
 * @returns {JSX.Element} - メッセージコンポーネント
 */
export const StatusMessage = ({ status, startDate, endDate, isOwner = true, isAvailable = true, screen }) => {
  const [messageType, setMessageType] = useState({ type: 0, remainingDays: 0 });
  const [labelText, setLabelText] = useState('');

  useEffect(() => {
    const today = getCurrentDateString();
    if (!status) {
      setMessageType({ type: 0, remainingDays: 0 });
      return;
    }

    switch (screen) {
      // 顧客マスタ
      case 'customerMaster':
        setLabelText('契約');
        break;
      
      // 研修マスタ
      // 研修設定
      case 'jobMaster':
      case 'jobSetting':
        setLabelText('公開');
        break;

      // 管理者マスタ
      // スタッフ設定
      case 'adminMaster':
      case 'staffSetting':
        setLabelText('利用');
        break;
      
      default:
        break;
    }

    // 管理者かつ、利用不可（研修設定で停止したもの、スタッフ設定で停止したもの）
    if (!isOwner && !isAvailable) {
      setMessageType({ type: 5, remainingDays: 0 });
      return;
    }

    if (status === MessageType.PREPARING) {
      if (!startDate) {
        setMessageType({ type: 0, remainingDays: 0 });
        return;
      }
      setMessageType({ type: 1, remainingDays: getDaysDifference(today, startDate) });
    
    } else if (status === MessageType.USING || status === MessageType.STOP) {
      if (!endDate) {
        setMessageType({ type: 0, remainingDays: 0 });
        return;
      }
      if (endDate === today) {
        setMessageType({ type: 2, remainingDays: 0 });
      } else if (endDate > today && getDaysDifference(today, endDate) <= 7) {
        setMessageType({ type: 3, remainingDays: getDaysDifference(today, endDate) });
      } else if (status === MessageType.STOP) {
        setMessageType({ type: 4, remainingDays: 0 });
      } else {
        setMessageType({ type: 0, remainingDays: 0 });
      }
    }
    // eslint-disable-next-line
  }, [status, startDate, endDate]);

  const renderMessage = () => {
    switch (messageType.type) {
      case 1:
        return (
          <p className="contract-start-message">
            <svg
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
              aria-labelledby="dangerIconTitle"
              stroke=" var(--green-font-color)"
              strokeWidth="2.4"
              strokeLinecap="square"
              strokeLinejoin="miter"
              fill="none"
              color=" var(--green-font-color)">
              <title id="dangerIconTitle">開始予定</title>
              <path d="M12 10L12 13" />
              <line x1="12" y1="16" x2="12" y2="16" />
              <path d="M12.8619342,4.46528817 L21.1135176,18.4929799 C21.3935371,18.969013 21.2346366,19.5819147 20.7586035,19.8619342 C20.6049268,19.9523322 20.4298761,20 20.2515834,20 L3.74841664,20 C3.19613189,20 2.74841664,19.5522847 2.74841664,19 C2.74841664,18.8217072 2.7960844,18.6466565 2.88648243,18.4929799 L11.1380658,4.46528817 C11.4180853,3.98925504 12.030987,3.83035459 12.5070201,4.11037408 C12.6535738,4.19658212 12.7757262,4.3187345 12.8619342,4.46528817 Z" />
            </svg>
            {new Date(startDate).toLocaleDateString('ja-JP', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '年').replace(/(\d+)$/, '$1日')}
            に{ labelText }開始します。
          </p>
        );
      
      case 2:
        return (
          <p className="contract-end-message">
            <svg
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
              aria-labelledby="dangerIconTitle"
              stroke="var(--red-font-color)"
              strokeWidth="2.4"
              strokeLinecap="square"
              strokeLinejoin="miter"
              fill="none"
              color="var(--red-font-color)">
              <title id="dangerIconTitle">当日終了</title>
              <path d="M12 10L12 13" />
              <line x1="12" y1="16" x2="12" y2="16" />
              <path d="M12.8619342,4.46528817 L21.1135176,18.4929799 C21.3935371,18.969013 21.2346366,19.5819147 20.7586035,19.8619342 C20.6049268,19.9523322 20.4298761,20 20.2515834,20 L3.74841664,20 C3.19613189,20 2.74841664,19.5522847 2.74841664,19 C2.74841664,18.8217072 2.7960844,18.6466565 2.88648243,18.4929799 L11.1380658,4.46528817 C11.4180853,3.98925504 12.030987,3.83035459 12.5070201,4.11037408 C12.6535738,4.19658212 12.7757262,4.3187345 12.8619342,4.46528817 Z" />
            </svg>
            本日の24時に{ labelText }終了します。
          </p>
        );
      
      case 3:
        return (
          <p className="contract-end-message">
            <svg
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
              aria-labelledby="dangerIconTitle"
              stroke="var(--red-font-color)"
              strokeWidth="2.4"
              strokeLinecap="square"
              strokeLinejoin="miter"
              fill="none"
              color="var(--red-font-color)">
              <title id="dangerIconTitle">終了予定</title>
              <path d="M12 10L12 13" />
              <line x1="12" y1="16" x2="12" y2="16" />
              <path d="M12.8619342,4.46528817 L21.1135176,18.4929799 C21.3935371,18.969013 21.2346366,19.5819147 20.7586035,19.8619342 C20.6049268,19.9523322 20.4298761,20 20.2515834,20 L3.74841664,20 C3.19613189,20 2.74841664,19.5522847 2.74841664,19 C2.74841664,18.8217072 2.7960844,18.6466565 2.88648243,18.4929799 L11.1380658,4.46528817 C11.4180853,3.98925504 12.030987,3.83035459 12.5070201,4.11037408 C12.6535738,4.19658212 12.7757262,4.3187345 12.8619342,4.46528817 Z" />
            </svg>
            { labelText}終了まで残り{messageType.remainingDays}日です。
          </p>
        );
      
      case 4:
        return (
          <p className="contract-end-message">
            <svg
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
              aria-labelledby="dangerIconTitle"
              stroke="var(--red-font-color)"
              strokeWidth="2.4"
              strokeLinecap="square"
              strokeLinejoin="miter"
              fill="none"
              color="var(--red-font-color)">
              <title id="dangerIconTitle">終了済</title>
              <path d="M12 10L12 13" />
              <line x1="12" y1="16" x2="12" y2="16" />
              <path d="M12.8619342,4.46528817 L21.1135176,18.4929799 C21.3935371,18.969013 21.2346366,19.5819147 20.7586035,19.8619342 C20.6049268,19.9523322 20.4298761,20 20.2515834,20 L3.74841664,20 C3.19613189,20 2.74841664,19.5522847 2.74841664,19 C2.74841664,18.8217072 2.7960844,18.6466565 2.88648243,18.4929799 L11.1380658,4.46528817 C11.4180853,3.98925504 12.030987,3.83035459 12.5070201,4.11037408 C12.6535738,4.19658212 12.7757262,4.3187345 12.8619342,4.46528817 Z" />
            </svg>
            {new Date(endDate).toLocaleDateString('ja-JP', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '年').replace(/(\d+)$/, '$1日')}
            に{ labelText}終了しました。
          </p>
        );
      
      // 研修終了専用
      case 5:
        return (
          <p className="contract-end-message">
            <svg
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
              aria-labelledby="dangerIconTitle"
              stroke="var(--red-font-color)"
              strokeWidth="2.4"
              strokeLinecap="square"
              strokeLinejoin="miter"
              fill="none"
              color="var(--red-font-color)">
              <title id="dangerIconTitle">終了済</title>
              <path d="M12 10L12 13" />
              <line x1="12" y1="16" x2="12" y2="16" />
              <path d="M12.8619342,4.46528817 L21.1135176,18.4929799 C21.3935371,18.969013 21.2346366,19.5819147 20.7586035,19.8619342 C20.6049268,19.9523322 20.4298761,20 20.2515834,20 L3.74841664,20 C3.19613189,20 2.74841664,19.5522847 2.74841664,19 C2.74841664,18.8217072 2.7960844,18.6466565 2.88648243,18.4929799 L11.1380658,4.46528817 C11.4180853,3.98925504 12.030987,3.83035459 12.5070201,4.11037408 C12.6535738,4.19658212 12.7757262,4.3187345 12.8619342,4.46528817 Z" />
            </svg>
            {labelText}終了しました。
          </p>
        );
      default:
        return null;
    }
  };

  return renderMessage();
};

export default StatusMessage;