import React, { createContext, useContext, useState, useEffect } from 'react';
import { requestApiLoad } from '../utils/apiLoadUtil';
import { RESPONSE } from '../const/Enum';
import Cookies from 'js-cookie';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      const sessionId = Cookies.get('sessionId');
      if (!sessionId) {
        setIsLoading(false);
        return;
      }

      if (!userData) {
        const res = await requestApiLoad('/auth/getUser', {}, () => {});
        if (res.return === RESPONSE.SUCCESS) {
          setUserData(res.user);
        }
      }
      setIsLoading(false);
    };

    fetchUserData();
  }, [userData]);

  if (isLoading) {
    return null; // またはローディングコンポーネント
  }

  return (
    <UserContext.Provider value={{ userData, setUserData }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};