/* ==================== */
/* 管理者マスタ画面 */
/* ==================== */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import AdminMasterForm from '../../components/Form/AdminMasterForm/AdminMasterForm';
import BottomSheet from '../../components/BottomSheet/BottomSheet';
import FilterButton from '../../components/FilterButton/FilterButton';
import Loading from '../../components/Loading/Loading';
import Modal from '../../components/Modal/Modal';
import Pagination from '../../components/Pagination/Pagination';
import SortingButton from '../../components/SortingButton/SortingButton';
import useWindowSize from '../../hook/useWindowSize';
import { RESPONSE, SEX_TYPE, STAFF_STATUS, USER_TYPE } from '../../const/Enum';
import { Message } from '../../const/Constant';
import { requestApiLoad, requestApiLoadAndBottom } from '../../utils/apiLoadUtil';
import { getNextYearDateString, getCurrentDateString } from '../../utils/dateUtils';
import { formatPostalCode } from '../../utils/formatUtil';
import * as Validators from '../../utils/validation';
import './AdminMaster.css';

const AdminMaster = () => {
  const [itemList, setItemList] = useState([]);
  const [addItem, setAddItem] = useState([]);
  const [editItem, setEditItem] = useState([]);
  const [isInit, setIsInit] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const bottomSheetRef = useRef(null);
  const size = useWindowSize();
  const nextYearToday = getNextYearDateString();
  const today = getCurrentDateString();

  /* メイン一覧画面 */
  const [mainSearchTerm, setMainSearchTerm] = useState('');
  const [mainStatusFilter, setMainStatusFilter] = useState(0);
  const [mainSortConfig, setMainSortConfig] = useState({ key: 'adminId', direction: true });
  const [mainCurrentPage, setMainCurrentPage] = useState(1);
  const [mainLastPage, setMainLastPage] = useState(1);
  const [mainTotalCount, setMainTotalCount] = useState(0);
  const sortOptions = [
    { key: 'adminId', value: { key: 'adminId', direction: true }, label: '標準' },
    { key: 'adminName_asc', value: { key: 'adminName', direction: false }, label: '管理者名（昇順）' },
    { key: 'adminName_desc', value: { key: 'adminName', direction: true }, label: '管理者名（降順）' },
    { key: 'startDate_asc', value: { key: 'startDate', direction: false }, label: '開始日（昇順）' },
    { key: 'startDate_desc', value: { key: 'startDate', direction: true }, label: '開始日（降順）' },
    { key: 'endDate_asc', value: { key: 'endDate', direction: false }, label: '終了日（昇順）' },
    { key: 'endDate_desc', value: { key: 'endDate', direction: true }, label: '終了日（降順）' }
  ];
  const statusOptions = [
    { value: STAFF_STATUS.ALL, label: 'すべて' },
    { value: STAFF_STATUS.ACTIVE, label: '利用中' },
    { value: STAFF_STATUS.SCHEDULED, label: '準備中' },
    { value: STAFF_STATUS.INACTIVE, label: '停止中' }
  ];

  /* 画面状態 */
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  
  // ------------------------------------------------------------------------------------
  // 初期処理
  // ------------------------------------------------------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        page: 1,
        isDesc: mainSortConfig.direction,
        orderKey: mainSortConfig.key,
        status: mainStatusFilter,
        name: mainSearchTerm,
      }
      const res = await requestApiLoad('/admin/get', params, setIsLoading);
      if (res.return !== RESPONSE.SUCCESS) {
        return;
      }
      
      setItemList(res.adminList);
      setMainCurrentPage(1);
      setMainLastPage(res.lastPage);
      setMainTotalCount(res.dataCount);
    };
    fetchData();
  }, [mainStatusFilter, mainSortConfig.direction, mainSortConfig.key, mainSearchTerm]);

  useEffect(() => {
    if(!isInit) {
      return;
    }
    if (itemList.length > 0) {
      if (size.width > 1200) {
        handleEdit(null, itemList[0]);
      }
      setIsInit(false);
    }
    // eslint-disable-next-line
  }, [itemList]);

  useEffect(() => {
    createEditTable();
    // eslint-disable-next-line
  }, [editItem]);

  // ------------------------------------------------------------------------------------
  // 画面モード
  // ------------------------------------------------------------------------------------
  const MODE = {
    NONE: 'NONE',
    ADD: 'ADD',
    EDIT: 'EDIT',
  }

  const ChangeScreenMode = (mode) => {
    switch (mode) {
      case MODE.ADD:
        setShowAdd(true);
        setShowEdit(false);
        break;
      
      case MODE.EDIT:
        setShowAdd(false);
        setShowEdit(true);
        break;
        
      case MODE.NONE:
      default:
        setShowAdd(false);
        setShowEdit(false);
        break;
    }
  }

  // ------------------------------------------------------------------------------------
  // ページネーション
  // ------------------------------------------------------------------------------------
  const handleMainPageChange = async (pageNumber) => {
    setMainCurrentPage(pageNumber);
    
    const params = {
      page: pageNumber,
      isDesc: mainSortConfig.direction,
      orderKey: mainSortConfig.key,
      status: mainStatusFilter,
      name: mainSearchTerm,
    }
    
    const res = await requestApiLoad('/admin/get', params, setIsLoading);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }

    setItemList(res.adminList);
    setMainLastPage(res.lastPage);
    setMainTotalCount(res.dataCount);
  };

  // ------------------------------------------------------------------------------------
  // フィルター
  // ------------------------------------------------------------------------------------
  const handleMainStatusChange = async (event) => {
    const newStatusFilter = Number(event.target.value);
    setMainCurrentPage(1);
    setMainStatusFilter(newStatusFilter);
  };

  const sortedAndFilteredItems = useMemo(() => {
    let filteredItems = itemList || [];
    return filteredItems;
  }, [itemList]);

  // ------------------------------------------------------------------------------------
  // ソート
  // ------------------------------------------------------------------------------------
  const handleMainSortChange = async (event) => {
    const newSortConfig = !event  ? { key: 'adminId', direction: true } : JSON.parse(event);
    setMainSortConfig(newSortConfig);
  };
  
  // ------------------------------------------------------------------------------------
  // 検索
  // ------------------------------------------------------------------------------------
  const onMainClear = () => {
    setMainSearchTerm('');
  };

  // ------------------------------------------------------------------------------------
  // 新規追加
  // ------------------------------------------------------------------------------------
  const handleAdd = async () => {
    setAddItem({
      adminId: '',
      adminName: '',
      adminNameKana: '',
      mail: '',
      companyId: '',
      startDate: today,
      endDate: nextYearToday,
      sex: SEX_TYPE.NONE,
      birthday: '',
      workName: '',
      telephone: '',
      postalCode: '',
      address1: '',
      address2: '',
      note: '',
    });
    ChangeScreenMode(MODE.ADD);
  };

  const handleAddSubmit = async (event) => {
    event.preventDefault();
    const params = {
      ...addItem,
      sex: Number(addItem.sex),
      postalCode: addItem.postalCode ? addItem.postalCode.replace(/-/g, '') : '',
    }

    const validationResult = validateCheck(params);
    if (validationResult.error) {
      alert(validationResult.message);
      return;
    }

    let isContinue = true;
    if (addItem.startDate === today) {
      if (!window.confirm(Message.CONFIRM_NOW_ADD_ADMIN)) {
        isContinue = false;
      }
    } else {
      if (!window.confirm(Message.CONFIRM_SCHEDULE_ADD_CUSTOMER)) {
        isContinue = false;
      }
    }

    if (isContinue) {
      const res = await requestApiLoadAndBottom('/admin/add',
        params, setIsLoading, bottomSheetRef, Message.BS_ADMIN_ADD_SUCCESS);
      if (res.return !== RESPONSE.SUCCESS) {
        if (res.errorCode === 'W-AD1-001') {
          alert('このメールアドレスは使用できません。');
        }
        return;
      }
      setFirstItem(res);

      if (size.width <= 1200) {
        ChangeScreenMode(MODE.NONE);
      } else {
        ChangeScreenMode(MODE.EDIT);
      }
    }
  }

  // ------------------------------------------------------------------------------------
  // 編集
  // ------------------------------------------------------------------------------------
  const handleEdit = async (e, item) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }

    if (!item) { return; }
    setEditItem({
      ...item,
      sex: Number(item.sex),
      status: Number(item.status),
      postalCode: item.postalCode ? formatPostalCode(item.postalCode) : '',
    });
    ChangeScreenMode(MODE.EDIT);
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    const params = {
      ...editItem,
      sex: Number(editItem.sex),
      postalCode: editItem.postalCode ? editItem.postalCode.replace(/-/g, '') : '',
    }
    
    const validationResult = validateCheck(params, true);
    if (validationResult.error) {
      alert(validationResult.message);
      return;
    }
    
    let isContinue = true;
    if (editItem.startDate === today) {
      if (!window.confirm(Message.CONFIRM_NOW_ADD_ADMIN)) {
        isContinue = false;
      }
    } else {
      if (!window.confirm(Message.CONFIRM_SCHEDULE_ADD_STAFF)) {
        isContinue = false;
      }
    }
    
    if (isContinue) {
      const res = await requestApiLoadAndBottom(`/admin/update/${editItem.adminId}`,
        params, setIsLoading, bottomSheetRef, Message.BS_ADMIN_UPDATE_SUCCESS);
      if (res.return !== RESPONSE.SUCCESS) {
        return;
      }
      setFirstItem(res);
      
      if (size.width <= 1200) {
        ChangeScreenMode(MODE.NONE);
      } else {
        ChangeScreenMode(MODE.EDIT);  
      }
    }
  }

  const handleStopSubmit = async (event) => {
    event.preventDefault();
    
    let confirmMessage = '';
    let bsMessage = '';
    if (editItem.status === STAFF_STATUS.ACTIVE) { 
      confirmMessage = Message.CONFIRM_NOW_STOP_ACCOUNT;
      bsMessage = Message.BS_ADMIN_STOP_SUCCESS;
    } else if(editItem.status === STAFF_STATUS.SCHEDULED) {
      confirmMessage = Message.CONFIRM_NOW_DELETE_ACCOUNT;
      bsMessage = Message.BS_ADMIN_DELETE_SUCCESS;
    }

    if (!window.confirm(confirmMessage)) { return; }
    const res = await requestApiLoadAndBottom(`/admin/stop/${editItem.adminId}`,
      {}, setIsLoading, bottomSheetRef, bsMessage);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }
    setFirstItem(res);

    if (size.width <= 1200) {
      ChangeScreenMode(MODE.NONE);
    } else {
      ChangeScreenMode(MODE.EDIT);
    }
  }

  // ------------------------------------------------------------------------------------
  // その他
  // ------------------------------------------------------------------------------------ 
  const getStatusText = (item) => {
    switch (item.status) {
      case STAFF_STATUS.ACTIVE:
        return ' (利用中)';
      
      case STAFF_STATUS.SCHEDULED:
        return ' (準備中)';
      
      default:
        return ' (停止中)';
    }
  }

  const setFirstItem = (res) => {
    setItemList(res.adminList);
    setMainLastPage(res.lastPage);
    setMainTotalCount(res.dataCount);
      
    setEditItem(res.adminList[0]);
    setMainCurrentPage(1);
    // setSelectedItem(res.adminList[0]);
    setMainCurrentPage(1);
    setMainStatusFilter(Number(STAFF_STATUS.ALL));
    setMainSearchTerm('');
    setMainSortConfig({ key: 'adminId', direction: true });
    resetScroll();
    resetScrollMain();
  }

  const resetScroll = () => {
    const scrollForm = document.querySelector('.scroll-form');
    if (scrollForm) {
      scrollForm.scrollTop = 0;
    }
  }

  const resetScrollMain = () => {
    const scrollForm = document.querySelector('.card-list');
    if (scrollForm) {
      scrollForm.scrollTop = 0;
    }
  }

  // ------------------------------------------------------------------------------------
  // ValidationCheck
  // ------------------------------------------------------------------------------------
  const validateCheck = (params, isEdit = false) => {
    if (!isEdit) {
      // 管理者名
      const adminNameResult = Validators.validateUserName(params.adminName, USER_TYPE.ADMIN);
      if (adminNameResult.error) {
        return { message: adminNameResult.message, error: true };
      }
      // 管理者名（カナ）
      const adminNameKanaResult = Validators.validateUserNameKana(params.adminNameKana, USER_TYPE.ADMIN);
      if (adminNameKanaResult.error) {
        return { message: adminNameKanaResult.message, error: true };
      }
      // メールアドレス
      const mailResult = Validators.validateMail(params.mail);
      if (mailResult.error) {
        return { message: mailResult.message, error: true };
      }
      // 所属会社
      const companyId = params.companyId;
      if (!companyId) {
        return { message: '所属会社を選択してください。', error: true };
      }
      // 利用開始日
      const useStartDateResult = Validators.validateStartDate(params.startDate);
      if (useStartDateResult.error) {
        return { message: useStartDateResult.message, error: true };
      }
    }

    if (params.endDate) {
      // 利用終了日
      const useEndDateResult = Validators.validateEndDate(params.endDate);
      if (useEndDateResult.error) {
        return { message: useEndDateResult.message, error: true };
      }
      
      if (params.startDate) {
        // 利用開始日 & 利用終了日
        const useStartDateAndEndDateResult = Validators.validateUseDate(params.startDate, params.endDate);
        if (useStartDateAndEndDateResult.error) {
          return { message: useStartDateAndEndDateResult.message, error: true };
        }
      }
    }
    
    if (params.workName) {
      // 業務内容
      const workNameResult = Validators.validateWorkName(params.workName);
      if (workNameResult.error) {
        return { message: workNameResult.message, error: true };
      }
    }

    if (params.birthday) {
      // 誕生日
      const birthdayResult = Validators.validateBirthday(params.birthday);
      if (birthdayResult.error) {
        return { message: birthdayResult.message, error: true };
      }
    }

    // 性別
    const sexResult = Validators.validateSex(params.sex);
    if (sexResult.error) {
      return { message: sexResult.message, error: true };
    }

    if (params.telephone) {
      // 電話番号
      const telephoneResult = Validators.validatePhoneNumber(params.telephone);
      if (telephoneResult.error) {
        return { message: telephoneResult.message, error: true };
      }
    }

    if (params.postalCode) {
      // 郵便番号
      const postalCodeResult = Validators.validatePostalCode(params.postalCode);
      if (postalCodeResult.error) {
        return { message: postalCodeResult.message, error: true };
      }
    }

    if (params.address1) {
      // 住所1
      const address1Result = Validators.validateAddress1(params.address1);
      if (address1Result.error) {
        return { message: address1Result.message, error: true };
      }
    }

    if (params.address2) {
      // 住所2
      const address2Result = Validators.validateAddress2(params.address2);
      if (address2Result.error) {
        return { message: address2Result.message, error: true };
      }
    }
    
    if (params.note) {
      // 備考
      const noteResult = Validators.validateNote(params.note);
      if (noteResult.error) {
        return { message: noteResult.message, error: true };
      }
    }
    
    return { message: '', error: false };
  }

  // ------------------------------------------------------------------------------------
  // 一覧作成
  // ------------------------------------------------------------------------------------ 
  const createTable = (itemList) => {
    return (
      <div className="card-list">
        {itemList.length === 0 ? (
          <div className="no-data">データがありません</div>
        ) : (
          itemList.map((item) => (
            <div className="card" key={item.adminId} onClick={(e) => handleEdit(e, item)}>
              <div className="card-contents" id="admin-master-card-contents">
                <div className="card-contents-left">
                  <h3>{item.adminName}</h3>
                  <span className="sub-text">{item.startDate}~{item.endDate}
                     {getStatusText(item)}
                  </span>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    );
  }

  // ------------------------------------------------------------------------------------
  // AddTable
  // ------------------------------------------------------------------------------------ 
  const createAddTable = () => {
    return (
      <>
        <div className="scroll-form">
          <AdminMasterForm
            formData={addItem}
            setFormData={setAddItem}
            isEdit={false}
          />
        </div>
        <div className="bottom-button">
          <button className="blue" onClick={handleAddSubmit}>
            登録
          </button>
        </div>
      </>
    )
  }

  // ------------------------------------------------------------------------------------
  // EditTable
  // ------------------------------------------------------------------------------------ 
  const createEditTable = () => {
    return (
      <>
        <div className="scroll-form">
          <AdminMasterForm
            formData={editItem}
            setFormData={setEditItem}
            isEdit={true}
          />
        </div>
        <div className="bottom-button">
          {(editItem.isAvailable && editItem.status !== STAFF_STATUS.INACTIVE) && (
            <button className="red" onClick={handleStopSubmit}>
              {editItem.status === STAFF_STATUS.SCHEDULED ? "削除" : "利用停止"}
            </button>
          )}
          {(editItem.isAvailable && editItem.status !== STAFF_STATUS.INACTIVE) && (
            <button className="blue" onClick={handleEditSubmit}>
              更新
            </button>
          )}
        </div>
      </>
    )
  }

  // ------------------------------------------------------------------------------------
  // レンダリング
  // ------------------------------------------------------------------------------------ 
  return (
    <div className="view-contents" id="admin-master">
      <Loading isLoading={isLoading} />
      <BottomSheet ref={bottomSheetRef} />

      <div className="main-contents">
        {size.width > 1200 && (<h2 className="page-title">管理者マスタ</h2>)}
        <div className="header-contents">
          <div className="search-bar">
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              <input
                type="text"
                placeholder="管理者名で検索"
                value={mainSearchTerm}
                onChange={(e) => setMainSearchTerm(e.target.value)}
                maxLength={20}
                style={{
                  backgroundImage: 'url(/images/search.png)',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '10px center',
                  backgroundSize: '20px 20px',
                  paddingLeft: '40px',
                  paddingRight: mainSearchTerm ? '30px' : '10px'
                }}
              />
              {mainSearchTerm && (
                <button
                  onClick={onMainClear}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '16px',
                    color: '#878787'
                  }}>
                  ×
                </button>
              )}
            </div>
          </div>
          <div className="button-container">
            <div className="add-new-item-button" onClick={handleAdd}>
              <svg
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                width="18px"
                height="18px"
                viewBox="0 0 24 24"
                aria-labelledby="plusIconTitle"
                stroke="var(--blue-font-color)"
                strokeWidth="1.8"
                strokeLinecap="square"
                strokeLinejoin="miter"
                fill="none"
                color="var(--blue-font-color)"
              >
                <title id="plusIconTitle">新規追加</title>
                <path d="M20 12L4 12M12 4L12 20" />
              </svg>
              <p className="hide-option">新規追加</p>
            </div>
          </div>
        </div>

        <div className="middle-contents">
          <div className="left-contents">
            <FilterButton
              statusFilter={mainStatusFilter}
              handleStatusChange={handleMainStatusChange}
              statusOptions={statusOptions}
            />
            <SortingButton
              sortConfig={mainSortConfig}
              handleSortChange={handleMainSortChange}
              sortOptions={sortOptions}
            />
          </div>
          
          <Pagination
            totalPages={mainLastPage}
            currentPage={mainCurrentPage}
            onPageChange={handleMainPageChange}
            totalItems={mainTotalCount}
          />
        </div>

        {createTable(sortedAndFilteredItems)}
      </div>

      {size.width > 1200 ? (
        <>
          {showAdd && (
            <div className="sub-contents">
              <h2 className="page-title">管理者追加</h2>
              {createAddTable()}
            </div>
          )}

          {showEdit && (
            <div className="sub-contents">
              <h2 className="page-title">{editItem.adminName}</h2>
              {createEditTable()}
            </div>
          )}
        </>
      ) : (
        <>
          <Modal
            isOpen={showAdd}
            title="管理者追加"
            onAction={handleAddSubmit}
            actionButtonText="登録"
            closeButtonText="閉じる"
            closeModal={() => setShowAdd(false)}
          >
            <div className="sub-contents">
              {createAddTable()}
            </div>
          </Modal>
            
          <Modal
            isOpen={showEdit}
            title={editItem.adminName}
            actionButtonText={editItem.isAvailable && editItem.status !== STAFF_STATUS.INACTIVE ? "更新" : ""}
            onAction={handleEditSubmit}
              actionSubButtonText={editItem.isAvailable && editItem.status !== STAFF_STATUS.INACTIVE ? editItem.status === STAFF_STATUS.SCHEDULED ? "削除" : "利用停止" : ""}
            onSubAction={handleStopSubmit}
            closeButtonText="閉じる"
            closeModal={() => setShowEdit(false)}
          >
            <div className="sub-contents">
              {createEditTable()}
            </div>
          </Modal>
        </>
      )}
    </div>
  )
}

export default AdminMaster;