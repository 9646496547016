// /* ==================== */
// /* ドキュメントビューアー */
// /* ==================== */
import React, { useState, useEffect, useRef } from 'react';
import { GlobalWorkerOptions, getDocument } from 'pdfjs-dist';
import Loading from '../Loading/Loading';
import { ENVIRONMENT } from '../../const/Enum';
import './DocumentViewer.css';

if (process.env.REACT_APP_ENVIRONMENT === ENVIRONMENT.LOCAL) {
  GlobalWorkerOptions.workerSrc = `${process.env.REACT_APP_PUBLIC_URL}pdf.worker.mjs`;
} else {
  GlobalWorkerOptions.workerSrc = `${process.env.REACT_APP_S3_URL}/modules/pdf.worker.mjs`;
}

const DocumentViewer = ({
  filePath,
  onEnd = () => { },
  pageNumber,
  setPageNumber,
  scale,
  setScale,
  setInitialScale,
  setModalNumPages,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [numPages, setNumPages] = useState(null);
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const renderTaskRef = useRef(null);
  const isMountedRef = useRef(true);
  const endTimeoutRef = useRef(null);
  const pdfDocRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (pdfDocRef.current) {
        pdfDocRef.current.getPage(pageNumber).then(page => {
          const viewport = page.getViewport({ scale: 1 });
          const containerWidth = containerRef.current ? containerRef.current.clientWidth : window.innerWidth;
          const newScale = containerWidth / viewport.width;
          setScale(newScale);
          renderPage(pdfDocRef.current, pageNumber, newScale);
        });
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, [pageNumber, setScale]);

  useEffect(() => {
    setPageNumber(1);
    // eslint-disable-next-line
  }, [filePath]);

  useEffect(() => {
    setModalNumPages(numPages);
    // eslint-disable-next-line
  }, [numPages]);

  useEffect(() => {
    isMountedRef.current = true;
    const url = `${process.env.REACT_APP_S3_URL}/${filePath}`;
    const loadingTask = getDocument(url);

    loadingTask.promise.then(pdf => {
      if (isMountedRef.current) {
        pdfDocRef.current = pdf;
        setNumPages(pdf.numPages);
        if (pageNumber < 1 || pageNumber > pdf.numPages) {
          console.error('Invalid page request.');
          setIsLoading(false);
          return;
        }
        pdf.getPage(pageNumber).then(page => {
          const viewport = page.getViewport({ scale: 1 });
          const containerWidth = containerRef.current ? containerRef.current.clientWidth : window.innerWidth;
          const newScale = containerWidth / viewport.width;
          setScale(newScale);
          setInitialScale(newScale);
          renderPage(pdf, pageNumber, newScale);
        });
      }
    }).catch(error => {
      console.error('Loading error:', error);
      console.error('Failed URL:', url);
      setIsLoading(false);
      alert('PDFファイルを読み込めませんでした。');
    });

    return () => {
      isMountedRef.current = false;
      if (renderTaskRef.current) {
        renderTaskRef.current.cancel();
      }
      if (endTimeoutRef.current) {
        clearTimeout(endTimeoutRef.current);
      }
    };
    // eslint-disable-next-line
  }, [filePath, pageNumber]);

  useEffect(() => {
    if (numPages) {
      const url = `${process.env.REACT_APP_S3_URL}/${filePath}`;
      const loadingTask = getDocument(url);

      loadingTask.promise.then(pdf => {
        if (isMountedRef.current) {
          renderPage(pdf, pageNumber, scale);
        }
      }).catch(error => {
        console.error('Loading error:', error);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line
  }, [scale]);

  const renderPage = (pdf, pageNumber, scale) => {
    if (pageNumber < 1 || pageNumber > pdf.numPages) {
      return;
    }

    pdf.getPage(pageNumber).then(page => {
      if (!isMountedRef.current) return;
      const canvas = canvasRef.current;
      if (!canvas) return;
      const context = canvas.getContext('2d');

      const scaledViewport = page.getViewport({ scale });
      canvas.height = scaledViewport.height;
      canvas.width = scaledViewport.width;
      canvas.style.height = `${scaledViewport.height}px`;
      canvas.style.width = `${scaledViewport.width}px`;

      if (renderTaskRef.current) {
        renderTaskRef.current.cancel();
      }

      const renderContext = {
        canvasContext: context,
        viewport: scaledViewport
      };
      const renderTask = page.render(renderContext);
      renderTaskRef.current = renderTask;
      renderTask.promise.then(() => {
        if (isMountedRef.current) {
          setIsLoading(false);
          renderTaskRef.current = null;
          if (pdf.numPages === 1) {
            endTimeoutRef.current = setTimeout(onEnd, 5000);
          }
        }
      }).catch(error => {
        if (error.name !== 'RenderingCancelledException') {
          console.error('Render error:', error);
        }
        setIsLoading(false);
      });
    });
  };

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%' }}>
      <Loading isLoading={isLoading} />
      <canvas ref={canvasRef} className="document-viewer"></canvas>
    </div>
  )
}

export default DocumentViewer;