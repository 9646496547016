import { requestApi } from './apiUtil';
import * as ExceptionUtils from './exceptionUtil';
import { RESPONSE } from '../const/Enum';
import { ErrorMessage } from '../const/Constant';
import axios from 'axios';

const requestStatus = {};

/**
 * メンテナンスモードのチェック
 */
const checkMaintenanceMode = async () => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/checkMaintenance`;
    const response = await axios.post(url);
    if (response.data.isMaintenance) {
      window.location.href = '/maintenance';
      return true;
    }
  } catch (error) {
    alert(ErrorMessage.OUT_OF_SERVICE);
  }
  return false;
};

const checkOffline = () => {
  if (!navigator.onLine) {
    alert(ErrorMessage.OFFLINE);
    window.location.href = '/';
    return true;
  }
  return false;
}

/**
 * APIのリクエストを行う
 * @param {string} url  APIパス
 * @param {object} params リクエストデータ
 * @param {function} setIsLoading ローディング状態を更新する関数
 * @param {boolean} needHeader ヘッダ
 * @returns レスポンスデータ
 */
export const requestApiLoad = async (
  url, 
  params = {}, 
  setIsLoading = () => { },
  needHeader = false
) => {
  // オフラインチェック
  const isOffline = checkOffline();
  if (isOffline) return;
  // メンテナンスチェック
  const isMaintenance = await checkMaintenanceMode();
  if (isMaintenance) return;
  
  if (requestStatus[url]) {
    return { return: RESPONSE.PROGRESS };
  }
  requestStatus[url] = true;

  const source = axios.CancelToken.source();
  const timeout = setTimeout(() => {
    source.cancel();
    setIsLoading(false);
    alert(ErrorMessage.API_TIMEOUT);
    requestStatus[url] = false;
  }, 10000);

  setIsLoading(true);
  try {
    const response = await requestApi(url, params, needHeader, { cancelToken: source.token });
    clearTimeout(timeout);
    return response.data;

  } catch (error) {
    ExceptionUtils.handleError(error);
    return { return: RESPONSE.SESSION_EXPIRED };

  } finally {
    setIsLoading(false);
    requestStatus[url] = false;
  }
};

/**
 * APIのリクエストを行う
 * @param {string} url  APIパス
 * @param {object} params リクエストデータ
 * @param {function} setIsLoading ローディング状態を更新する関数
 * @param {object} bottomSheetRef ボトムシートの参照
 * @param {string} successMessage ボトムシートに表示するメッセージ
 * @param {boolean} needHeader ヘッダ
 * @returns レスポンスデータ
 */
export const requestApiLoadAndBottom = async (
  url, 
  params = {}, 
  setIsLoading = () => { },  
  bottomSheetRef = { current: null },
  successMessage = '',
  needHeader = false,
) => {
  // オフラインチェック
  const isOffline = checkOffline();
  if (isOffline) return;
  // メンテナンスチェック
  const isMaintenance = await checkMaintenanceMode();
  if (isMaintenance) return;

  if (requestStatus[url]) {
    return { return: RESPONSE.PROGRESS };
  }
  requestStatus[url] = true;

  const source = axios.CancelToken.source();
  const timeout = setTimeout(() => {
    source.cancel();
    setIsLoading(false);
    alert(ErrorMessage.API_TIMEOUT);
    requestStatus[url] = false;
  }, 10000);

  setIsLoading(true);
  try {
    const response = await requestApi(url, params, needHeader, { cancelToken: source.token });
    clearTimeout(timeout);
    if (response.data.return !== RESPONSE.SUCCESS) {
      return response.data;
    }

    if (bottomSheetRef.current) {
      bottomSheetRef.current.showMessage(successMessage);
    } else {
      console.warn("bottomSheetRef.current is null");
    }

    return response.data;
  } catch (error) {
    ExceptionUtils.handleError(error);
    return { return: RESPONSE.SESSION_EXPIRED };

  } finally {
    setIsLoading(false);
    requestStatus[url] = false;
  }
};