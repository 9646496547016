/* ============================ */
/* 通知設定追加/編集モーダル */
/* ============================ */
import React, { useEffect, useState } from 'react';
import MiniSelectedView from '../../MiniSelectedView/MiniSelectedView';
import SelectMultGroupModal from '../../../components/SelectMultGroupModal/SelectMultGroupModal';
import SelectMultStaffModal from '../../../components/SelectMultStaffModal/SelectMultStaffModal';
import { USER_TYPE } from '../../../const/Enum';
import * as validate from '../../../utils/realtimeValidation';
import './NotificationSettingForm.css';

const NotificationSettingForm = ({ formData, setFormData, isEdit, role }) => {
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
  const [isStaffModalOpen, setIsStaffModalOpen] = useState(false);
  const [selectGroups, setSelectGroups] = useState([]);
  const [selectStaffs, setSelectStaffs] = useState([]);
  
  /* エラーメッセージ */
  const [titleError, setTitleError] = useState('');
  const [contentsError, setContentsError] = useState('');

  const SEND_TO_TYPE = {
    ALL: 1,
    ADMIN: 2,
    STAFF: 3,
    GROUP: 4,
    PERSONAL: 5,
  };
  
  // ------------------------------------------------------------------------------------
  // 初期処理
  // ------------------------------------------------------------------------------------
  useEffect(() => {
    if (Number(formData.sendType) === SEND_TO_TYPE.GROUP) {
      setFormData(prevFormData => ({
        ...prevFormData,
        sendToIds: [...selectGroups.map(group => group.groupId)]
      }));
      handleInputChange({ target: { name: 'sendToIds', value: selectGroups } });

    } else if (Number(formData.sendType) === SEND_TO_TYPE.PERSONAL) {
      setFormData(prevFormData => ({
        ...prevFormData,
        sendToIds: [...selectStaffs.map(staff => staff.staffId)]
      }));
      handleInputChange({ target: { name: 'sendToIds', value: selectStaffs } });
    }
    // eslint-disable-next-line
  }, [formData.sendType, selectGroups, selectStaffs]);

  // ------------------------------------------------------------------------------------
  // イベント
  // ------------------------------------------------------------------------------------
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let newTitleError = '';
    let newContentsError = '';
  
    if (name === 'title') {
      newTitleError = validate.validateNotificationTitle(value);
    } else if (name === 'contents') {
      newContentsError = validate.validateNotificationContents(value);
    } 
    
    // エラーステートを更新
    setTitleError(prevTitleError => name === 'title' ? newTitleError : prevTitleError);
    setContentsError(prevContentsError => name === 'contents' ? newContentsError : prevContentsError);
  
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };

  // ------------------------------------------------------------------------------------
  // レンダリング
  // ------------------------------------------------------------------------------------
  return (
    <div className="modal-form" id="notification-setting-form">
      {!isEdit && (
        <div className='column-set'>
          <div className="row-set" id="if-name">
            <div className="input-group">
              <label>タイトル</label>
              <div className={`error-message-container ${titleError ? 'has-error' : ''}`}>
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  placeholder="タイトル"
                  disabled={isEdit}
                  style={{
                    borderColor: titleError ? 'red' : '',
                  }}
                />
                {titleError && (
                  <div className="error-message">
                    {titleError}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {isEdit && (
        <div className='column-set'>
          <div className="row-set" id="if-name">
            <div className="input-group">
              <label>日付</label>
              <input
                type="text"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
                disabled
              />
            </div>
          </div>
        </div>
      )}

      <div className="column-set">
        <div className="row-set">
          <div className="input-group">
            <label>内容</label>
            <div className={`error-message-container ${contentsError ? 'has-error' : ''}`}>
              <textarea
                name="contents"
                value={formData.contents}
                onChange={handleInputChange}
                placeholder="通知内容"
                disabled={isEdit}
                style={{ 
                  height: '300px',
                  width: '100%',
                  borderColor: contentsError ? 'red' : '',
                }}
              />
              {contentsError && (
                <div className="error-message">
                  {contentsError}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {role === USER_TYPE.OWNER ? (
        <div className="row-set" id="if-name">
          <div className="input-group">
            <label>通知先</label>
            <select
              name="sendType"
              value={formData.sendType}
              onChange={handleInputChange}
              disabled={isEdit}
            >
              <option value={SEND_TO_TYPE.ALL}>全員</option>
              <option value={SEND_TO_TYPE.ADMIN}>管理者のみ</option>
            </select>
          </div>
        </div>
      ) : (
        <div className="row-set" id="if-name">
          <div className="input-group">
            <label>通知先</label>
            <select
              name="sendType"
              value={formData.sendType}
              onChange={handleInputChange}
              disabled={isEdit}
            >
              <option value={SEND_TO_TYPE.STAFF}>全員</option>
              <option value={SEND_TO_TYPE.GROUP}>指定グループ</option>
              <option value={SEND_TO_TYPE.PERSONAL}>指定スタッフ</option>
            </select>
          </div>
        </div>
      )}

      {Number(formData.sendType) === SEND_TO_TYPE.GROUP && (
        <>
          {!isEdit ? (
            <div>
              <button
                className='blue group-select'
                type="button"
                onClick={() => setIsGroupModalOpen(true)}
                disabled={isEdit}
              >
                {selectGroups === null || selectGroups.length === 0 ? 'グループ選択' : 'グループ追加'}
              </button>
              {selectGroups && selectGroups.length > 0 && (
                <MiniSelectedView items={selectGroups} keyProp="groupId" displayProp="groupName" />
              )}
            </div>
          ) : (
            <div className="row-set" id="if-name">
              <MiniSelectedView items={formData.sendToList} keyProp="id" displayProp="name" />
            </div>
          )}
        </>
      )}
      
      {Number(formData.sendType) === SEND_TO_TYPE.PERSONAL && (
        <>
          {!isEdit ? (
            <div>
              <button
                className='blue staff-select'
                type="button"
                onClick={() => setIsStaffModalOpen(true)}
                disabled={isEdit}
              >
                {selectStaffs === null || selectStaffs.length === 0 ? 'スタッフ選択' : 'スタッフ追加'}
              </button>
              {selectStaffs && selectStaffs.length > 0 && (
                <MiniSelectedView items={selectStaffs} keyProp="staffId" displayProp="staffName" />
              )}
            </div>
          ) : (
            <div className="row-set" id="if-name">
              <MiniSelectedView items={formData.sendToList} keyProp="id" displayProp="name" />
            </div>
          )}
        </>
      )}

      <SelectMultGroupModal
        isOpen={isGroupModalOpen}
        setIsOpen={setIsGroupModalOpen}
        setSelectGroups={setSelectGroups}
        selectGroups={selectGroups}
      />
      
      <SelectMultStaffModal
        isOpen={isStaffModalOpen}
        setIsOpen={setIsStaffModalOpen}
        setSelectStaffs={setSelectStaffs}
        selectStaffs={selectStaffs}
      />
    </div>
  )
}

export default NotificationSettingForm;