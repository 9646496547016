/* ==================== */
/* スタッフ名簿 */
/* ==================== */
import React from 'react';
import './StaffList.css';

const StaffList = ({ data, showHeader = true, currentPage, totalPages }) => {
  return (
    <div className="staff-list-preview">
      {showHeader && (
        <div className="staff-list-header">
          <div className="title"><h1>スタッフ名簿</h1></div>
          <div className="date"><p>{new Date().toLocaleDateString('ja-JP')}</p><p>（{currentPage}/{totalPages}）</p></div>
        </div>
      )}

      <div className="staff-list-table">
        {showHeader && (
          <div className="staff-list-table-header">
            <div className="first-row">
              <div className="staff-name"><p>スタッフ名</p></div>
              <div className="staff-name-kana"><p>スタッフ名（かな）</p></div>
              <div className="sex"><p>性別</p></div>
              <div className="birthday"><p>生年月日</p></div>
              <div className="mail"><p>メールアドレス</p></div>
            </div>
            
            <div className="second-row">
              <div className="employment-date"><p>雇用年月日</p></div>
              <div className="telphone"><p>連絡先</p></div>
              <div className="address"><p>住所</p></div>
            </div>

            <div className="third-row">
              <div className="group-name"><p>所属グループ</p></div>
              <div className="remarks"><p>備考</p></div>
            </div>
          </div>
        )}
        
        <div className="staff-list-table-body">
          {data.map((staff, index) => (
            <div key={index} className="staff-list-table-row">
              <div className="first-row">
                <div className="staff-name"><p>{staff.staffName}</p></div>
                <div className="staff-name-kana"><p>{staff.staffNameKana}</p></div>
                <div className="sex"><p>{staff.sex === 1 ? '男性' : staff.sex === 2 ? '女性' : '無回答'}</p></div>
                <div className="birthday"><p>{staff.birthday ? new Date(staff.birthday).toLocaleDateString('ja-JP', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '年').replace(/(\d+)$/, '$1日') : ''}</p></div>
                <div className="mail"><p>{staff.mail || ''}</p></div>
              </div>
              
              <div className="second-row">
                <div className="employment-date"><p>{staff.employmentStartDate && staff.employmentEndDate ? `${new Date(staff.employmentStartDate).toLocaleDateString('ja-JP', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '年').replace(/(\d+)$/, '$1日')} ~ ${new Date(staff.employmentEndDate).toLocaleDateString('ja-JP', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '年').replace(/(\d+)$/, '$1日')}` : staff.employmentStartDate ? `${new Date(staff.employmentStartDate).toLocaleDateString('ja-JP', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '年').replace(/(\d+)$/, '$1日')} ~`: ''}</p></div>
                <div className="telphone"><p>{staff.telphone||''}</p></div>
                <div className="address"><p>{staff.postalCode && staff.address1 && staff.address2 ? `〒${staff.postalCode.slice(0, 3)}-${staff.postalCode.slice(3)} ${staff.address1}${staff.address2}` : ''}</p></div>
              </div>

              <div className="third-row">
              <div className="group-name"><p>{staff.groupName || '未所属'}</p></div>
                <div className="remarks"><p>備考</p></div>
              </div>

            </div>
          ))}
        </div>
      </div>
      
    </div>
  )
}

export default StaffList;