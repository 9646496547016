/* ==================== */
/* ボトムシート */
/* ==================== */
import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import './BottomSheet.css';

const BottomSheet = forwardRef(({ duration = 2000, onClose }, ref) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    let timer;
    if (isVisible) {
      timer = setTimeout(() => {
        handleClose();
      }, duration);
    }
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [isVisible, duration]);

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isVisible]);

  useImperativeHandle(ref, () => ({
    showMessage: (msg) => {
      setMessage(msg);
      setIsVisible(true);
    },
    showUpdateMessage: () => {
      setMessage('更新しました');
      setIsVisible(true);
    },
    showAddMessage: () => {
      setMessage('追加しました');
      setIsVisible(true);
    },
    showDeleteMessage: () => {
      setMessage('削除しました');
      setIsVisible(true);
    },
    showAccountStopMessage: () => {
      setMessage('アカウントを停止しました');
      setIsVisible(true);
    },
    showPasswordUpdateMessage: () => {
      setMessage('パスワードの更新が完了しました');
      setIsVisible(true);
    },
    showResendEmailMessage: () => {
      setMessage('初期設定用メールを再送信しました');
      setIsVisible(true);
    },
  }));

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsVisible(false);
      setIsClosing(false);
      if (onClose) onClose();
    }, 300);
  };

  return (
    <div className={`bottom-sheet ${isVisible && !isClosing ? 'visible' : ''}`}>
      <div className="bottom-sheet-content">
        <span>{message}</span>
      </div>
    </div>
  );
});

export default BottomSheet;
