/* ==================== */
/* （研修マスタ）研修追加/編集フォーム */
/* ==================== */
import React, { useEffect, useState } from 'react';
import DeleteButton from '../../DeleteButton/DeleteButton';
import DocumentModal from '../../DocumentModal/DocumentModal';
import MiniSelectedView from '../../MiniSelectedView/MiniSelectedView';
import SelectMultCompanyModal from '../../../components/SelectMultCompanyModal/SelectMultCompanyModal';
import { StatusMessage } from '../../../components/StatusMessage/StatusMessage';
import ToggleSwitch from '../../../components/ToggleSwitch/ToggleSwitch';
import VimeoPlayer from '../../../components/VimeoPlayer/VimeoPlayer';
import { PUBLISH_SCOPE, STATUS_TYPE } from '../../../const/Enum';
import { handleDateBlur as handleDateBlurUtil } from '../../../utils/dateInputUtil';
import * as validate from '../../../utils/realtimeValidation';
import './JobForm.css';

const JobForm = ({ formData, setFormData, isEdit }) => {
  const [isSelectCompanyModalOpen, setIsSelectCompanyModalOpen] = useState(false);
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [selectDocument, setSelectDocument] = useState({});
  const [currentVimeoId, setCurrentVimeoId] = useState('');

  /* エラーメッセージ */
  const [jobId, setJobId] = useState('');
  const [jobNameError, setJobNameError] = useState('');
  const [vimeoIdError, setVimeoIdError] = useState('');
  const [releaseStartDateError, setReleaseStartDateError] = useState('');
  const [releaseEndDateError, setReleaseEndDateError] = useState('');
  
  // ------------------------------------------------------------------------------------
  // 初期処理
  // ------------------------------------------------------------------------------------ 
  useEffect(() => {
    if (formData.jobId !== jobId) { 
      setJobId(formData.jobId);
      setJobNameError('');
      setVimeoIdError('');
      setReleaseStartDateError('');
      setReleaseEndDateError('');
    }
  }, [formData]);

  // ------------------------------------------------------------------------------------
  // イベント
  // ------------------------------------------------------------------------------------
  const handleInputChange = (event) => {
    const { name, value, type, files } = event.target;
    let newJobNameError = '';
    let newVimeoIdError = '';
    let newReleaseStartDateError = '';
    let newReleaseEndDateError = '';
    
    if (name === 'jobName') {
      newJobNameError = validate.validateJobName(value);
    } else if (name === 'isPDF') {
      setFormData(prevFormData => ({
        ...prevFormData,
        vimeoId: '',
        pdfFile: '',
        fileName: ''
      }));
    } else if (name === 'vimeoId') {
      newVimeoIdError = validate.validateVimeoId(value);
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value
      }));
    } else if (name === 'pdfFile') {
      const file = files[0];
      if (file) {
        const pdfError = validate.validatePDFFile(file);
        if (pdfError) {
          alert(pdfError);
          event.target.value = '';
        } else {
          setFormData(prevFormData => ({
            ...prevFormData,
            pdfFile: file,
            fileName: file.name
          }));
        }
      }
    } else if (name === 'releaseStartDate') {
      newReleaseStartDateError = validate.validateDateCheck(value);
    } else if (name === 'releaseEndDate') {
      newReleaseEndDateError = validate.validateDateRangeCheck(value);
    }

    // エラーステートを更新
    setJobNameError(prevJobNameError => name === 'jobName' ? newJobNameError : prevJobNameError);
    setVimeoIdError(prevVimeoIdError => name === 'vimeoId' ? newVimeoIdError : prevVimeoIdError);
    setReleaseStartDateError(prevReleaseStartDateError => name === 'releaseStartDate' ? newReleaseStartDateError : prevReleaseStartDateError);
    setReleaseEndDateError(prevReleaseEndDateError => name === 'releaseEndDate' ? newReleaseEndDateError : prevReleaseEndDateError);

    // fileは上でsetFormDataしているので、ここではsetFormDataしない
    if (type !== "file") {
      if (type === "checkbox") {
        setFormData(prevFormData => ({
          ...prevFormData,
          [name]: event.target.checked
        }));
      } else if (type === "radio") {
        setFormData(prevFormData => ({
          ...prevFormData,
          [name]: Number(value)
        }));
      } else {
        setFormData(prevFormData => ({
          ...prevFormData,
          [name]: value
        }));
      }
    }
  }

  const handleVimeoIdBlur = () => {
    if (formData.vimeoId !== currentVimeoId && vimeoIdError ==='') {
      setCurrentVimeoId(formData.vimeoId);
    }
  }

  const handleDateBlur = (event) => {
    handleDateBlurUtil(event, setFormData);
  };

  // ------------------------------------------------------------------------------------
  // ドキュメントモーダル
  // ------------------------------------------------------------------------------------
  const handleDocumentPreview = (e, doc) => {
    e.stopPropagation();
    setSelectDocument(doc);
    setIsDocumentModalOpen(true);
  };

  // ------------------------------------------------------------------------------------
  // レンダリング
  // ------------------------------------------------------------------------------------
  return (
    <div className="modal-form" id="job-form">
      <StatusMessage
        status={formData.status}
        startDate={formData.releaseStartDate}
        endDate={formData.releaseEndDate}
        screen='jobMaster'
      />
      <p className="separate-title">基本設定</p>
      <div className="row-set" id="if-name">
        <div className="input-group">
          <label>研修名</label>
          <div className={`error-message-container ${jobNameError ? 'has-error' : ''}`}>
            <input
              type="text"
              name="jobName"
              value={formData.jobName}
              onChange={handleInputChange}
              placeholder="研修名"
              disabled={isEdit}
              style={{
                borderColor: jobNameError ? 'red' : '',
              }}
            />
            {jobNameError && (
              <div className="error-message">
                {jobNameError}
              </div>
            )}
          </div>
        </div>
      </div>

      <ToggleSwitch
        name="isPDF"
        checked={formData.isPDF ?? false}
        onChange={handleInputChange}
        disabled={isEdit}
        label="PDF"
      />

      {!formData.isPDF && (
        <div className="row-set" id="if-postalcode">
          <div className="input-group">
            <label>VimeoID</label>
            <div className={`error-message-container ${vimeoIdError ? 'has-error' : ''}`}>
              <input
                type="number"
                name="vimeoId"
                value={formData.vimeoId}
                onChange={handleInputChange}
                onBlur={handleVimeoIdBlur}
                placeholder="948539351"
                disabled={isEdit}
                style={{
                  borderColor: vimeoIdError ? 'red' : '',
                }}
              />
              {vimeoIdError && (
                <div className="error-message">
                  {vimeoIdError}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {formData.isPDF && (
        <div className="row-set" id="if-name">
          {!formData.fileName && (
            <div className="input-group">
              <label>PDFファイル</label>
              <input
                type="file"
                name="pdfFile"
                accept="application/pdf"
                onChange={handleInputChange}
              />
            </div>
          )}
          {formData.fileName && (
            <div className="pdf-file-name">
              <label>{`${formData.fileName}`}</label>
              {(!isEdit) && (
                <DeleteButton onClick={() => setFormData(prevFormData => ({
                  ...prevFormData,
                  pdfFile: '',
                  fileName: ''
                }))} />
              )}
            </div>
          )}
        </div>
      )}
      {((!formData.isPDF && currentVimeoId) || (isEdit && !formData.isPDF))&& (
        <div className='vimeo-player-wrapper'>
          <VimeoPlayer
            videoId={isEdit ? formData.vimeoId : currentVimeoId}
          />
        </div>
      )}
      <div className='button-wrapper-group'>
        {formData.isPDF && isEdit && (
          <div className="pdf-view-button-wrapper">
            <button
              className='border-blue'
              onClick={(e) => handleDocumentPreview(e, {
                documentName: formData.jobName,
                documentFilePath: formData.filePath,
              })}
            >
              研修PDFを確認
            </button>
          </div>
        )}
      </div>
    
      <div className="separate-line"></div>
      <p className="separate-title">公開設定</p>

      {!isEdit && (
        <div className="status-filters row-set" id="if-status">
          <label className="row-label">
            <input
              type="radio"
              name="status"
              value={STATUS_TYPE.ACTIVE}
              checked={formData.status === STATUS_TYPE.ACTIVE}
              onChange={handleInputChange}
            />
            本日公開
          </label>
          <label className="row-label">
            <input
              type="radio"
              name="status"
              value={STATUS_TYPE.UNRELEASE}
              checked={formData.status === STATUS_TYPE.UNRELEASE}
              onChange={handleInputChange}
            />
            スケジュール公開
          </label>
        </div>
      )}

      {/* 公開予定時のみ表示 */}
      {((!isEdit && formData.status === STATUS_TYPE.UNRELEASE) || isEdit) && (
        <div className="row-set" id="if-dates">
          <div className="input-group">
            <label>公開開始日</label>
            <div className={`error-message-container ${releaseStartDateError ? 'has-error' : ''}`}>
              <input
                type="date"
                name="releaseStartDate"
                value={formData.releaseStartDate || ''}
                onChange={handleInputChange}
                onBlur={handleDateBlur}
                pattern="\d{4}-\d{2}-\d{2}"
                min="1900-01-01"
                max="2999-12-31"
                disabled={isEdit}
                style={{
                  borderColor: releaseStartDateError ? 'red' : '',
                }}
              />
              {releaseStartDateError && (
                <div className="error-message">
                  {releaseStartDateError}
                </div>
              )}
            </div>
          </div>
          <div className="input-group">
            <label>公開終了日</label>
            <div className={`error-message-container ${releaseEndDateError ? 'has-error' : ''}`}>
              <input
                type="date"
                name="releaseEndDate"
                value={formData.releaseEndDate || ''}
                onChange={handleInputChange}
                onBlur={handleDateBlur}
                pattern="\d{4}-\d{2}-\d{2}"
                min="1900-01-01"
                max="2999-12-31"
                disabled={formData.status === STATUS_TYPE.INACTIVE}
                style={{
                  borderColor: releaseEndDateError ? 'red' : '',
                }}
              />
              {releaseEndDateError && (
                <div className="error-message">
                  {releaseEndDateError}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div>
        <div className="status-filters row-group width100">
          <label className="row-label">
            <input
              type="radio"
              name="publishScope"
              value={PUBLISH_SCOPE.ALL}
              checked={formData.publishScope === PUBLISH_SCOPE.ALL}
              onChange={handleInputChange}
              disabled={isEdit && formData.status !== STATUS_TYPE.UNRELEASE}
            />
            全員
          </label>
          <label className="row-label">
            <input
              type="radio"
              name="publishScope"
              value={PUBLISH_SCOPE.LIMITED}
              checked={formData.publishScope === PUBLISH_SCOPE.LIMITED}
              onChange={handleInputChange}
              disabled={isEdit && formData.status !== STATUS_TYPE.UNRELEASE}
            />
            限定公開
          </label>
        </div>

        {formData.publishScope === PUBLISH_SCOPE.LIMITED && formData.publishCompanyList && (
          <MiniSelectedView
            items={formData.publishCompanyList}
            keyProp="companyId"
            displayProp="companyName"
          />
        )}

        {/* 新規追加時 & 限定公開時に公開顧客選択ボタンを表示 */}
        {/* 編集時 & 公開予定時 & 限定公開時に公開顧客選択ボタンを表示 */}
        {((!isEdit && formData.publishScope === PUBLISH_SCOPE.LIMITED) ||
          (isEdit && formData.status === STATUS_TYPE.UNRELEASE && formData.publishScope === PUBLISH_SCOPE.LIMITED)) && (
          <div className="select-company-button">
            <button
              className='blue company-select'
              type="button"
              disabled={isEdit && formData.status === STATUS_TYPE.ACTIVE}
              onClick={() => setIsSelectCompanyModalOpen(true)}
            >
              {formData.publishCompanyList && formData.publishCompanyList.length > 0 ? '公開顧客追加' : '公開顧客選択'}
            </button>
          </div>
        )}
      </div>

      <div className="separate-line"></div>
      <p className="separate-title">詳細設定</p>
      <div className="toggle-group">
        <ToggleSwitch
          name="isDownload"
          checked={!formData.isPDF ? false : formData.isDownload}
          onChange={handleInputChange}
          disabled={(isEdit && formData.status !== STATUS_TYPE.UNRELEASE) || !formData.isPDF}
          label="PDFのダウンロードを許可"
        />
        <ToggleSwitch
          name="isAllowReview"
          checked={formData.isAllowReview}
          onChange={handleInputChange}
          disabled={isEdit && formData.status !== STATUS_TYPE.UNRELEASE}
          label="研修の複数回受講を許可"
        />
        <ToggleSwitch
          name="isTest"
          checked={formData.isTest}
          onChange={handleInputChange}
          disabled={isEdit && formData.status !== STATUS_TYPE.UNRELEASE}
          label="テストを実施する"
        />
        {formData.isTest && (
          <ToggleSwitch
            name="isAllowRetest"
            checked={formData.isAllowRetest}
            onChange={handleInputChange}
            disabled={(isEdit && formData.status !== STATUS_TYPE.UNRELEASE) || !formData.isTest}
            label="テストの複数回受講を許可"
          />
        )}
        <ToggleSwitch
          name="isSign"
          checked={formData.isSign}
          onChange={handleInputChange}
          disabled={isEdit && formData.status !== STATUS_TYPE.UNRELEASE}
          label="電子サインの利用を許可"
        />
      </div>
      
      <SelectMultCompanyModal
        isOpen={isSelectCompanyModalOpen}
        setIsOpen={setIsSelectCompanyModalOpen}
        setSelectCompanys={(companys) => {
          setFormData(prevFormData => ({
            ...prevFormData,
            publishCompanyList: companys
          }));
        }}
        selectCompanys={formData.publishCompanyList || []}
      />
 
      <DocumentModal
        isOpen={isDocumentModalOpen}
        closeModal={() => setIsDocumentModalOpen(false)}
        title={selectDocument.documentName}
        filePath={selectDocument.documentFilePath}
        isDownload={false}
        isDownloadDisp={false}
        isPreview={true}
      />
    </div>
  )
}

export default JobForm;