import React, { useState, useRef, useEffect } from 'react';
import './OutputButton.css';

const OutputButton = ({ handlePDFOutput, handleCSVOutput }) => {
  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="output-button">
      <div className="output-select-button" onClick={() => setIsOpen(!isOpen)}>
        <svg
          width="20px"
          height="20px"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-labelledby="shareIconTitle"
          stroke="var(--blue-font-color)"
          strokeWidth="1.9"
          strokeLinecap="square"
          strokeLinejoin="miter"
          fill="none"
          color="var(--blue-font-color)"
        >
          <title id="shareIconTitle">PDF出力</title>
          <path d="M12 14V6" />
          <path d="M9 8L12 5L15 8" />
          <path d="M5 13V18H19V13" />
        </svg>
        <p className="hide-option">出力</p>
      </div>

      {isOpen && (
        <div className="output-button-popup" ref={popupRef}>
          <button className="blue" onClick={() => { handlePDFOutput(); setIsOpen(false); }}>PDF出力
          </button>
          <button className="blue" onClick={() => { handleCSVOutput(); setIsOpen(false); }}>
            CSV出力
          </button>
        </div>
      )}
    </div>
  )
}

export default OutputButton;