/**
 * コンソール出力
 * デバッグ時にしか出力しないようにしているため、ログ出力はこちらを使ってください
 * @param {object} object ログ出力オブジェクト
 */
export function consoleLog(object) {
  if (process.env.REACT_APP_ENVIRONMENT === 'product')
    return;

  console.log(object);
}

/**
 * コンソール出力（エラー）
 * デバッグ時にしか出力しないようにしているため、ログ出力はこちらを使ってください
 * @param {object} object ログ出力オブジェクト
 */
export function consoleError(object) {
  if (process.env.REACT_APP_ENVIRONMENT === 'product')
    return;
  
  console.error(object);
}