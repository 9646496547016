import React from 'react';
import './MiniSelectedView.css';

const MiniSelectedView = ({ items, keyProp, displayProp }) => {
  return (
    <>
      {items.length > 0 && (
        <div className="mini-selected-view">
          {items.map((item, index) => (
            <p key={`${item[keyProp]}-${index}`}>{item[displayProp]}</p>
          ))}
        </div>
      )}
    </>
  )
}

export default MiniSelectedView;