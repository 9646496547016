/**
 * PDFファイルをBase64形式に変換する
 * @param {File} pdfFile - 変換するPDFファイル
 * @returns {Promise<string>} Base64形式に変換されたPDFファイルの内容
 */
export const convertPDFToBase64 = (pdfFile) => {
  return new Promise((resolve, reject) => {
    if (!pdfFile) {
      reject(new Error('PDFファイルが指定されていません。'));
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(pdfFile);
  });
};