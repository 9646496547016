import axios from 'axios';
import Cookies from 'js-cookie';
import * as logUtil from './logUtil';

/**
 * APIのリクエストを行う
 * @param {string} path  APIパス
 * @param {object} requestData リクエストデータ
 * @param {boolean} needHeader ヘッダ
 * @returns レスポンスデータ
 */
export async function requestApi(path, requestData = {}, needHeader = false) {
  const url = `${process.env.REACT_APP_BACKEND_URL}/api${path}`;
  
  logUtil.consoleLog("request api---------------------------");
  logUtil.consoleLog("- url : " + url);
  logUtil.consoleLog("- request : ");
  logUtil.consoleLog(requestData);

  requestData.session = Cookies.get('sessionId');
  const header = needHeader ? { headers: { 'Content-Type': 'multipart/form-data' } } : {};

  const response = await axios.post(url, requestData, header);

  logUtil.consoleLog("- responseData :");
  logUtil.consoleLog(response);

  return response;
}