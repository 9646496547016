export const getAddress = async (postalCode) => {
  try {
    const response = await fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postalCode.replace('-', '')}`);
    const data = await response.json();
    if (data.results) {
      const address = data.results[0];
      return {
        address1: `${address.address1}${address.address2}`,
        address2: `${address.address3}`
      };
    }
  } catch (error) {
    alert('住所の取得に失敗しました。');
    return null;
  }
};