/* =================================================================================== */
/* 共通モーダル */
/* =================================================================================== */
import React, { useState, useEffect } from 'react';
import { MODAL_SUBMIT_TEXT, MODAL_TYPE } from '../../const/Enum';
import useWindowSize from '../../hook/useWindowSize';
import './Modal.css';

const Modal = ({
  isOpen,               // モーダルが開いているかどうか
  closeModal,           // モーダルを閉じる関数
  onAction,             // アクションボタンがクリックされたときに呼ばれる関数
  onSubAction,          // サブアクションボタンがクリックされたときに呼ばれる関数
  title = "",             // モーダルのタイトル
  children,             // モーダルのコンテンツ
  actionButtonText,     // アクションボタンのテキスト
  actionSubButtonText,  // サブアクションボタンのテキスト
  closeButtonText = "キャンセル",  // キャンセルボタンのテキスト
  playerKey = 0,
  id = ""               // モーダルのoverlayに割り振るid
}) => {

  const size = useWindowSize();
  const [isVisible, setIsVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  
  // ------------------------------------------------------------------------------------
  // 初期処理
  // ------------------------------------------------------------------------------------
  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      setIsClosing(false);
    } else {
      setIsClosing(true);
      const timer = setTimeout(() => {
        setIsVisible(false);
        setIsClosing(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  if (!isOpen && !isVisible) {
    return null;
  }

  // ------------------------------------------------------------------------------------
  // モーダルクローズ
  // ------------------------------------------------------------------------------------
  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      closeModal();
    }, 300);
  };

  // ------------------------------------------------------------------------------------
  // スタイル
  // ------------------------------------------------------------------------------------
  const modalStyle = () => {
    switch (playerKey) {
      case MODAL_TYPE.BASE:
        if (size.width > 768) {
          return { height: '85vh', width: '80vw', boxSizing: 'border-box' };
        } else {
          return { height: '95%', width: '90vw', boxSizing: 'border-box' };
        }

      /* PDFモーダル */
      case MODAL_TYPE.PDF:
        if (size.width > 768) {
          return { height: 'auto', width: '70vw' };
        } else {
          return { height: 'auto', width: '90vw' }
        }

      /* Vimeoプレーヤーモーダル */
      case MODAL_TYPE.VIMEO:
        if(size.width > 768) {
          return {
            height: '85vh',
            width: '70vw',
            maxHeight: '90%',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            padding: '10px'
          }
        } else {
          return {
            height: '95%',
            width: '90vw',
            maxHeight: '90%',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            padding: '10px'
          }
        }
        
      /* パスワード変更モーダル */
      case MODAL_TYPE.PASSWORD:
        if (size.width > 1200) {
          return { height: 'auto', width: '30vw' };
        } else if (size.width > 1000) {
          return { height: 'auto', width: '40vw' };
        } else if (size.width > 768) {
          return { height: 'auto', width: '50vw' };
        } else {
          return { height: 'auto', width: '90vw', maxHeight:'95%', boxSizing:'border-box' };
        }

      /* 選択モーダル */
      case MODAL_TYPE.SELECT:
        if (size.width > 768) {
          return { height: '75vh', width: '70vw' };
        } else {
          return {
            height: 'auto',
            minHeight: '30vh',
            maxHeight: '70vh',
            width: '90vw'
          };
        }

      default:
        if (size.width > 768) {
          return { height: '75vh', width: '70vw' };
        } else {
          return { height: '90vh', width: '90vw' };
        }
    }
  }

  // ------------------------------------------------------------------------------------
  // レンダリング
  // ------------------------------------------------------------------------------------
  return (
    <div
      className={`modal-overlay ${isVisible ? 'visible' : ''} ${isClosing ? 'closing' : ''}`}
      id={id}
      onClick={(e) => e.stopPropagation()}>
      <div
        className={`modal-container ${isVisible ? 'visible' : ''} ${isClosing ? 'closing' : ''}`}
        style={modalStyle()}
        onClick={(e) => e.stopPropagation()}
      >
    
        <div className="modal-top-contents">
          <h2 className="modal-title">{title}</h2>
        </div>
  
        <div 
          className={`modal-center-contents ${playerKey === MODAL_TYPE.VIMEO ? 'vimeo-player-wrapper' : ''}`}
          style={playerKey === MODAL_TYPE.PDF ? { maxHeight: '70vh' } :
            playerKey === MODAL_TYPE.VIMEO ? { flex: 1, minHeight: '0', overflow: 'hidden' } : {}}
        >
          <div style={{ height: '100%', width: '100%', boxSizing: 'border-box' }}>{children}</div>
        </div>
  
        <div className="modal-bottom-contents">
          {playerKey === MODAL_TYPE.VIMEO && (
            <div className="button-group">
              <button
                className={`no-right-space ${closeButtonText === MODAL_SUBMIT_TEXT.COMPLETE ? 'blue' : ''}`}
                onClick={handleClose}>
                {closeButtonText}
              </button>
            </div>
          )}
          {playerKey !== MODAL_TYPE.VIMEO && (
            <div className="button-group">
              {actionSubButtonText && (
                <button
                  className='red'
                  onClick={onSubAction}>
                  {actionSubButtonText}
                </button>
              )}
              {actionButtonText && (
                <button
                  className='blue'
                  onClick={onAction}>
                  {actionButtonText}
                </button>
              )}
              <button
                className="no-right-space"
                onClick={handleClose}>
                {closeButtonText}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Modal;
