import React, { useState, useRef, useEffect } from 'react';
import './SortingButton.css';

const SortingButton = ({ sortConfig, handleSortChange, sortOptions, disabled = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState('');
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const selectedOption = sortOptions.find(option => 
      option.value.key === sortConfig.key && option.value.direction === sortConfig.direction
    );
    setSelectedLabel(selectedOption ? selectedOption.label : '');
  }, [sortConfig, sortOptions]);

  return (
    <div className="sorting-button">
      <div className="button-container">
        <svg
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          width="20px"
          height="20px"
          viewBox="0 0 24 24"
          aria-labelledby="listIconTitle"
          stroke="var(--blue-font-color)"
          strokeWidth="1"
          strokeLinecap="square"
          strokeLinejoin="miter"
          fill="none"
          color="var(--blue-font-color)"
        >
          <title id="listIconTitle" />
          <path d="M10 7L18 7M10 12L18 12M10 17L18 17" />
          <line x1="7" y1="7" x2="7" y2="7" />
          <line x1="7" y1="12" x2="7" y2="12" />
          <line x1="7" y1="17" x2="7" y2="17" />
        </svg>
        <button className="blue sorting-button-label-wrapper" onClick={() => setIsOpen(!isOpen)}>
          <span className="sorting-button-label">並び替え：</span>{selectedLabel && `${selectedLabel}`}
        </button>
      </div>

      {isOpen && (
        <div className="sorting-button-popup" ref={popupRef}>
          {sortOptions.map(option => (
            <label
              key={option.key}
              className={`sorting-button ${disabled ? 'disabled' : ''}`}
            >
              <input
                type="radio"
                name="sort"
                value={JSON.stringify(option.value)}
                checked={sortConfig.key === option.value.key && sortConfig.direction === option.value.direction}
                onChange={(e) => {
                  handleSortChange(e.target.value);
                  setSelectedLabel(option.label);
                  setIsOpen(false);
                }}
                disabled={disabled}
              />
              <p>{option.label}</p>
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default SortingButton;