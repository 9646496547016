/* ============================ */
/* 電子サイン追加/編集フォーム */
/* ============================ */
import React, { useState, useEffect } from 'react';
import DeleteButton from '../../DeleteButton/DeleteButton';
import MiniSelectedView from '../../MiniSelectedView/MiniSelectedView';
import SelectMultCompanyModal from '../../SelectMultCompanyModal/SelectMultCompanyModal';
import DocumentModal from '../../DocumentModal/DocumentModal';
import { MaxLength } from '../../../const/Constant';
import { PUBLISH_SCOPE } from '../../../const/Enum';
import * as validate from '../../../utils/realtimeValidation';
import './ESignatureForm.css';

const ESignatureForm = ({ formData, setFormData, isEdit }) => {
  const [isSelectCompanyModalOpen, setIsSelectCompanyModalOpen] = useState(false);
  const [selectDocument, setSelectDocument] = useState({});
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  /* エラーメッセージ */
  const [templateIdError, setTemplateIdError] = useState('');
  const [templateNameError, setTemplateNameError] = useState('');
  const [templateNameKanaError, setTemplateNameKanaError] = useState('');

  // ------------------------------------------------------------------------------------
  // 初期処理
  // ------------------------------------------------------------------------------------
  useEffect(() => {
    if (!formData.documentList) {
      setFormData(prevData => ({
        ...prevData,
        documentList: [{ file: null, documentName: '' }]
      }));
    }
    validateForm();
    // eslint-disable-next-line
  }, [formData]);

  // ------------------------------------------------------------------------------------
  // イベント
  // ------------------------------------------------------------------------------------
  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    let newTemplateIdError = '';
    let newTemplateNameError = '';
    let newTemplateNameKanaError = '';

    if (name === 'templateId') {
      newTemplateIdError = validate.validateTemplateId(value);
    } else if (name === 'templateName') {
      newTemplateNameError = validate.validateTemplateName(value);
    } else if (name === 'templateNameKana') {
      newTemplateNameKanaError = validate.validateTemplateNameKana(value); 
    }
    
    // エラーステートを更新
    setTemplateIdError(prevTemplateIdError => name === 'templateId' ? newTemplateIdError : prevTemplateIdError);
    setTemplateNameError(prevTemplateNameError => name === 'templateName' ? newTemplateNameError : prevTemplateNameError);
    setTemplateNameKanaError(prevTemplateNameKanaError => name === 'templateNameKana' ? newTemplateNameKanaError : prevTemplateNameKanaError);

    const newValue = type === 'radio' ? Number(value) : value;
    setFormData(prevData => ({
      ...prevData,
      [name]: newValue,
      ...(name === 'publishScope' && newValue === PUBLISH_SCOPE.ALL ? { publishCompanyList: [] } : {})
    }));

    validateForm();
  }

  // ------------------------------------------------------------------------------------
  // 文書変更
  // ------------------------------------------------------------------------------------
  const handleDocumentChange = (index, file) => {
    if (file && file.type === 'application/pdf') {
      const fileNameWithoutExtension = file.name.slice(0, file.name.lastIndexOf('.'));
      if (fileNameWithoutExtension.length > MaxLength.FILE_NAME_MAX_LENGTH) {
        alert(`PDFファイル名は${MaxLength.FILE_NAME_MAX_LENGTH}文字以内にしてください。（拡張子を除く）`);
        return;
      }
      if (file.size > MaxLength.FILE_NAME_MAX_SIZE_MB * 1024 * 1024) {
        alert(`ファイルサイズは${MaxLength.FILE_NAME_MAX_SIZE_MB}MB以下にしてください。`);
        return;
      }
      const newDocumentList = [...formData.documentList];
      newDocumentList[index] = { 
        pdfFile: file,
        documentName: file.name
      };
      setFormData(prevFormData => ({
        ...prevFormData,
        documentList: newDocumentList
      }));
    } else {
      alert('PDFファイルを選択してください。');
    }
  };

  // ------------------------------------------------------------------------------------
  // 文書追加
  // ------------------------------------------------------------------------------------
  const addDocument = () => {
    if (formData.documentList && formData.documentList.length < MaxLength.ESIGN_DOCUMENT_MAX_COUNT) {
      setFormData(prevFormData => ({
        ...prevFormData,
        documentList: [...prevFormData.documentList, { file: null, documentName: '' }]
      }));
    }
  };

  // ------------------------------------------------------------------------------------
  // 文書削除
  // ------------------------------------------------------------------------------------
  const removeDocument = (index) => {
    if (formData.documentList.length > 1) {
      const newDocuments = formData.documentList.filter((_, i) => i !== index);
      setFormData(prevData => ({
        ...prevData,
        documentList: newDocuments
      }));
    }
  };

  // ------------------------------------------------------------------------------------
  // フォームバリデーション
  // ------------------------------------------------------------------------------------
  const validateForm = () => {
    const isBasicInfoValid = !templateIdError && !templateNameError && !templateNameKanaError &&
      formData.templateId?.trim() !== '' && formData.templateName?.trim() !== '' && formData.templateNameKana?.trim() !== '';

    const isDocumentListValid = formData.documentList && formData.documentList.length > 0 &&
      formData.documentList.every(doc => doc.pdfFile);

    const isPublishScopeValid = formData.publishScope === PUBLISH_SCOPE.ALL ||
      (formData.publishScope === PUBLISH_SCOPE.LIMITED && formData.publishCompanyList && formData.publishCompanyList.length > 0);

    return isBasicInfoValid && isDocumentListValid && isPublishScopeValid;
  }

  // ------------------------------------------------------------------------------------
  // 文書プレビュー
  // ------------------------------------------------------------------------------------
  const handleDocumentPreview = (e, doc) => {
    e.stopPropagation();
    setSelectDocument(doc);
    setIsDocumentModalOpen(true);
  }

  // ------------------------------------------------------------------------------------
  // レンダリング
  // ------------------------------------------------------------------------------------
  return (
    <div className="modal-form" id="e-signature-form">
      <p className="separate-title">基本設定</p>
      
      <div className="column-set" id="if-name">
        <div className="row-set">
          <div className="input-group">
            <label>テンプレートID</label>
            <div className={`error-message-container ${templateIdError ? 'has-error' : ''}`}>
              <input
                type="text"
                name="templateId"
                value={formData.templateId}
                onChange={handleInputChange}
                placeholder="テンプレートID"
                disabled={isEdit}
                style={{
                  borderColor: templateIdError ? 'red' : '',
                }}
              />
              {templateIdError && (
                <div className="error-message">
                  {templateIdError}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row-set">
          <div className="input-group">
            <label>テンプレート名</label>
            <div className={`error-message-container ${templateNameError ? 'has-error' : ''}`}>
              <input
                type="text"
                name="templateName"
                value={formData.templateName}
                onChange={handleInputChange}
                placeholder="テンプレート名"
                disabled={isEdit}
                style={{
                  borderColor: templateNameError ? 'red' : '',
                }}
              />
              {templateNameError && (
                <div className="error-message">
                  {templateNameError}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row-set">
          <div className="input-group">
            <label>テンプレート名（かな）</label>
            <div className={`error-message-container ${templateNameKanaError ? 'has-error' : ''}`}>
              <input
                type="text"
                name="templateNameKana"
                value={formData.templateNameKana}
                onChange={handleInputChange}
                placeholder="テンプレート名（かな）"
                disabled={isEdit}
                style={{
                  borderColor: templateNameKanaError ? 'red' : '',
                }}
              />
              {templateNameKanaError && (
                <div className="error-message">
                  {templateNameKanaError}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="separate-line"></div>
      <div className="column-set">
        <p className="separate-title">文書設定</p>
        <div className="document-list">
          {formData.documentList && formData.documentList.map((doc, index) => (
            <div key={index} className="document-list-card" style={!isEdit ? {padding: '20px'} : {padding: '10px'}}>
              {!doc.documentName ? (
                <div className="input-group">
                  <label>PDFファイル</label>
                  <input
                    type="file"
                    accept="application/pdf"
                    onChange={(e) => handleDocumentChange(index, e.target.files[0])}
                  />
                </div>
              ) : (
                <div className="pdf-file-name">
                  <label>{doc.documentName}</label>
                </div>
              )}
              {!isEdit && formData.documentList.length > 1 && (
                <DeleteButton onClick={() => removeDocument(index)} />
              )}
              {isEdit && (
                <button className="blue" title="プレビュー" onClick={(e) => handleDocumentPreview(e, doc)}>
                  <svg
                    width="20px"
                    height="20px"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-labelledby="eyeIconTitle"
                    stroke="var(--blue-font-color)"
                    strokeWidth="2.0"
                    strokeLinecap="square"
                    strokeLinejoin="miter"
                    fill="none"
                    color="var(--blue-font-color)">
                    <title id="eyeIconTitle">プレビュー</title>
                    <path d="M22 12C22 12 19 18 12 18C5 18 2 12 2 12C2 12 5 6 12 6C19 6 22 12 22 12Z" />
                    <circle cx="12" cy="12" r="3" />
                  </svg>
                </button>
              )}
            </div>
          ))}
          {!isEdit && formData.documentList && (formData.documentList.length < MaxLength.ESIGN_DOCUMENT_MAX_COUNT) && (
            <div className="button-container">
              <div className="add-new-item-button" onClick={addDocument}>
                <svg
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18px"
                  height="18px"
                  viewBox="0 0 24 24"
                  aria-labelledby="plusIconTitle"
                  stroke="var(--blue-font-color)"
                  strokeWidth="1.8"
                  strokeLinecap="square"
                  strokeLinejoin="miter"
                  fill="none"
                  color="var(--blue-font-color)"
                >
                  <title id="plusIconTitle">文書を追加</title>
                  <path d="M20 12L4 12M12 4L12 20" />
                </svg>
                <p className="hide-option">文書を追加</p>
              </div>
            </div>
          )}
        </div>
      </div>
      
      <div className="separate-line"></div>
      <div className="column-set">
        <p className="separate-title">公開設定</p>
        <div>
          <div className="status-filters row-group width100">
            <label className="row-label">
              <input
                type="radio"
                name="publishScope"
                value={PUBLISH_SCOPE.ALL}
                checked={Number(formData.publishScope) === PUBLISH_SCOPE.ALL}
                onChange={handleInputChange}
                disabled={isEdit}
              />
              全員
            </label>
            <label className="row-label">
              <input
                type="radio"
                name="publishScope"
                value={PUBLISH_SCOPE.LIMITED}
                checked={Number(formData.publishScope) === PUBLISH_SCOPE.LIMITED}
                onChange={handleInputChange}
                disabled={isEdit}
              />
              限定公開
            </label>
          </div>
          {Number(formData.publishScope) === PUBLISH_SCOPE.LIMITED && formData.publishCompanyList && (
            <MiniSelectedView
              items={formData.publishCompanyList}
              keyProp="companyId"
              displayProp="companyName"
            />
          )}

          {!isEdit && Number(formData.publishScope) === PUBLISH_SCOPE.LIMITED && (
            <div className="select-company-button">
              <button
                className='blue company-select'
                type="button"
                onClick={() => setIsSelectCompanyModalOpen(true)}
              >
                {formData.publishCompanyList && formData.publishCompanyList.length > 0 ? '公開顧客追加' : '公開顧客選択'}
              </button>
            </div>
          )}
        </div>
      </div>

      <SelectMultCompanyModal
        isOpen={isSelectCompanyModalOpen}
        setIsOpen={setIsSelectCompanyModalOpen}
        setSelectCompanys={(companys) => {
          setFormData(prevFormData => ({
            ...prevFormData,
            publishCompanyList: companys
          }));
        }}
        selectCompanys={formData.publishCompanyList || []}
      />

      <DocumentModal
        isOpen={isDocumentModalOpen}
        closeModal={() => setIsDocumentModalOpen(false)}
        title={selectDocument.documentName}
        filePath={selectDocument.filePath}
        isDownload={true}
        isPreview={true}
      />
    </div>
  )
}

export default ESignatureForm;