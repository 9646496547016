/* ==================== */
/* 研修マスタ画面 */
/* ==================== */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import BottomSheet from '../../components/BottomSheet/BottomSheet';
import JobForm from '../../components/Form/JobForm/JobForm';
import TestSettingForm from '../../components/Form/TestSettingForm/TestSettingForm';
import FilterButton from '../../components/FilterButton/FilterButton';
import Loading from '../../components/Loading/Loading';
import Modal from '../../components/Modal/Modal';
import Pagination from '../../components/Pagination/Pagination';
import SortingButton from '../../components/SortingButton/SortingButton';
import useWindowSize from '../../hook/useWindowSize';
import { ANSWER_TYPE, FILE_TYPE, PUBLISH_SCOPE, RESPONSE, STATUS_TYPE, STATUS_TYPE_CLASSIX, TEST_STATUS } from '../../const/Enum';
import { MaxLength, Message } from '../../const/Constant';
import { requestApiLoad, requestApiLoadAndBottom } from '../../utils/apiLoadUtil';
import { convertPDFToBase64 } from '../../utils/fileUtil';
import { getNextYearDateString, getCurrentDateString } from '../../utils/dateUtils';
import * as Validators from '../../utils/validation';
import './JobMaster.css';

const JobMaster = () => {
  const [itemList, setItemList] = useState([]);
  const [addItem, setAddItem] = useState([]);
  const [editItem, setEditItem] = useState([]);
  const [settingItem, setSettingItem] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [isInit, setIsInit] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const bottomSheetRef = useRef(null);
  const size = useWindowSize();
  const nextYearToday = getNextYearDateString();
  const today = getCurrentDateString();

  /* メイン一覧画面 */
  const [mainSearchTerm, setMainSearchTerm] = useState('');
  const [mainStatusFilter, setMainStatusFilter] = useState(0);
  const [mainSortConfig, setMainSortConfig] = useState({ key: 'jobId', direction: true });
  const [mainCurrentPage, setMainCurrentPage] = useState(1);
  const [mainLastPage, setMainLastPage] = useState(1);
  const [mainTotalCount, setMainTotalCount] = useState(0);
  const sortOptions = [
    { key: 'jobId', value: { key: 'jobId', direction: true }, label: '標準' },
    { key: 'releaseStartDate_asc', value: { key: 'releaseStartDate', direction: false }, label: '開始日（昇順）' },
    { key: 'releaseStartDate_desc', value: { key: 'releaseStartDate', direction: true }, label: '開始日（降順）' },
    { key: 'releaseEndDate_asc', value: { key: 'releaseEndDate', direction: false }, label: '終了日（昇順）' },
    { key: 'releaseEndDate_desc', value: { key: 'releaseEndDate', direction: true }, label: '終了日（降順）' }
  ];
  const statusOptions = [
    { value: STATUS_TYPE_CLASSIX.ALL, label: 'すべて' },
    { value: STATUS_TYPE_CLASSIX.ACTIVE, label: '公開中' },
    { value: STATUS_TYPE_CLASSIX.NOTSETTING, label: '公開待機中' },
    { value: STATUS_TYPE_CLASSIX.SCHEDULE, label: '公開予定' },
    { value: STATUS_TYPE_CLASSIX.INACTIVE, label: '公開終了' }
  ];

  /* 画面状態 */
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showSetting, setShowSetting] = useState(false);

  // ------------------------------------------------------------------------------------
  // 初期処理
  // ------------------------------------------------------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        page: 1,
        isDesc: mainSortConfig.direction,
        orderKey: mainSortConfig.key,
        status: mainStatusFilter,
        name: mainSearchTerm,
      }
      const res = await requestApiLoad('/job/get', params, setIsLoading);
      if (res.return !== RESPONSE.SUCCESS) {
        return;
      }
      
      setItemList(res.jobList);
      setMainCurrentPage(1);
      setMainLastPage(res.lastPage);
      setMainTotalCount(res.dataCount);
    };
    fetchData();
  }, [mainStatusFilter, mainSortConfig.direction, mainSortConfig.key, mainSearchTerm]);

  useEffect(() => {
    if (!isInit) {
      return;
    }

    if (itemList.length > 0) {
      if (size.width > 1200) {
        handleEdit(null, itemList[0]);
        changeScreenMode(MODE.EDIT);
      } else {
        changeScreenMode(MODE.NONE);
      }
      setIsInit(false);
    }
    // eslint-disable-next-line
  }, [itemList]);

  useEffect(() => {
    createEditTable();
    // eslint-disable-next-line
  }, [editItem]);

  // ------------------------------------------------------------------------------------
  // 画面モード
  // ------------------------------------------------------------------------------------
  const MODE = {
    NONE: 'NONE',
    ADD: 'ADD',
    EDIT: 'EDIT',
    SETTING: 'SETTING',
  }

  const changeScreenMode = (mode) => {
    switch (mode) {
      case MODE.ADD:
        setShowAdd(true);
        setShowEdit(false);
        setShowSetting(false);
        break;
      
      case MODE.EDIT:
        setShowAdd(false);
        setShowEdit(true);
        setShowSetting(false);
        break;
      
      case MODE.SETTING:
        setShowAdd(false);
        setShowEdit(false);
        setShowSetting(true);
        break;
     
      case MODE.NONE:
      default:
        setShowAdd(false);
        setShowEdit(false);
        setShowSetting(false);
        break;
    }
  }

  // ------------------------------------------------------------------------------------
  // ページネーション
  // ------------------------------------------------------------------------------------
  const handleMainPageChange = async (pageNumber) => {
    setMainCurrentPage(pageNumber);
    
    const params = {
      page: pageNumber,
      isDesc: mainSortConfig.direction,
      orderKey: mainSortConfig.key,
      status: mainStatusFilter,
      name: mainSearchTerm,
    }
    
    const res = await requestApiLoad('/job/get', params, setIsLoading);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }

    setItemList(res.jobList);
    setMainLastPage(res.lastPage);
    setMainTotalCount(res.dataCount);
  };

  // ------------------------------------------------------------------------------------
  // フィルター
  // ------------------------------------------------------------------------------------
  const handleMainStatusChange = async (event) => {
    const newStatusFilter = Number(event.target.value);
    setMainCurrentPage(1);
    setMainStatusFilter(newStatusFilter);
  };

  const sortedAndFilteredItems = useMemo(() => {
    let filteredItems = itemList || [];
    return filteredItems;
  }, [itemList]);

  // ------------------------------------------------------------------------------------
  // ソート
  // ------------------------------------------------------------------------------------
  const handleMainSortChange = async (event) => {
    const newSortConfig = !event ? { key: 'jobId', direction: true } : JSON.parse(event);
    setMainSortConfig(newSortConfig);
  };
  
  // ------------------------------------------------------------------------------------
  // 検索
  // ------------------------------------------------------------------------------------
  const onMainClear = () => {
    setMainSearchTerm('');
  };

  // ------------------------------------------------------------------------------------
  // 新規追加
  // ------------------------------------------------------------------------------------
  const handleAdd = async () => {
    setAddItem({
      jobId: '',
      jobName: '',
      vimeoId: '',
      pdfFile: '',
      isPDF: false,
      status: Number(STATUS_TYPE.ACTIVE),
      releaseStartDate: today,
      releaseEndDate: nextYearToday,
      isTest: true,
      isDownload: false,
      isAllowReview: true,
      isAllowRetest: true,
      isSign: false,
      publishScope: PUBLISH_SCOPE.ALL,
      publishCompanyList: null,
    });
    changeScreenMode(MODE.ADD);
  };
  
  const handleAddSubmit = async (event) => {
    event.preventDefault();
    // 先にPDFファイルのバリデーションチェック
    if (addItem.isPDF) {
      const pdfFile = addItem.pdfFile;
      const validationResult = Validators.validatePDFFile(pdfFile);
      if (validationResult.error) {
        alert(validationResult.message);
        return;
      }
      
      try {
        const base64Data = await convertPDFToBase64(pdfFile);
        addItem.pdfFile = base64Data;

      } catch (error) {
        alert('PDFファイルの変換に失敗しました:', error);
        return;
      }
    }

    const status = addItem.status === STATUS_TYPE.ACTIVE ? Number(STATUS_TYPE.ACTIVE) : Number(STATUS_TYPE.UNRELEASE);
    const releaseStartDate = addItem.status !== STATUS_TYPE.INACTIVE ? addItem.releaseStartDate : null;
    const releaseEndDate = addItem.status !== STATUS_TYPE.INACTIVE ? addItem.releaseEndDate : null;
    const publishCompanyIds =
      addItem.publishScope === PUBLISH_SCOPE.LIMITED ? addItem.publishCompanyList?.map(company => company.companyId) || null : null;

    const params = {
      jobName: addItem.jobName,
      vimeoId: addItem.isPDF ? null : addItem.vimeoId,
      pdfFile: addItem.isPDF ? addItem.pdfFile : null,
      fileName: addItem.isPDF ? addItem.fileName : '',
      fileType: addItem.isPDF ? Number(FILE_TYPE.PDF) : Number(FILE_TYPE.VIDEO),
      status: status,
      releaseStartDate: releaseStartDate,
      releaseEndDate: releaseEndDate,
      isTest: addItem.isTest,
      isDownload: addItem.isDownload,
      isAllowReview: addItem.isAllowReview,
      isAllowRetest: addItem.isAllowRetest,
      publishScope: addItem.publishScope,
      publishCompanyIds: publishCompanyIds,
      isSign: addItem.isSign,
    }

    /* バリデーションチェック */
    const validationResult = validateJobData(params, false);
    if (validationResult.error) {
      alert(validationResult.message);
      return;
    }
   
    /* 確認メッセージ表示 */
    let isContinue = true;
    if (addItem.releaseStartDate === today) {
      if (!window.confirm(Message.CONFIRM_NOW_START_JOB)) {
        isContinue = false;
      }
    } else {
      if (!window.confirm(Message.CONFIRM_SCHEDULE_START_JOB)) {
        isContinue = false;
      }
    }
    
    if (isContinue) {
      const res = await requestApiLoadAndBottom('/job/add',
        params, setIsLoading, bottomSheetRef, Message.BS_ADD_JOB_SUCCESS);
      if (res.return !== RESPONSE.SUCCESS) {
        if (res.errorCode === 'W-JO5-V404') {
          alert('Vimeoの動画が見つかりません。Viemo動画IDを確認してください。');
        }
        return;
      }
      setFirstItem(res);

      if (size.width <= 1200) {
        changeScreenMode(MODE.NONE);
      } else {
        changeScreenMode(MODE.EDIT);
      }
    }
  }

  // ------------------------------------------------------------------------------------
  // 編集
  // ------------------------------------------------------------------------------------
  const handleEdit = async (e, item) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    if (!item) { return; }

    setSelectedItem(item);
    const isPDF = item.fileType === FILE_TYPE.PDF;
    const fileName = isPDF ? item.fileName : '';
    const publishScope = item.publishCompanyList?.length > 0 ? PUBLISH_SCOPE.LIMITED : PUBLISH_SCOPE.ALL;
    
    setEditItem(
      {
        jobId: item.jobId,
        jobName: item.jobName,
        vimeoId: isPDF ? '' : item.vimeoId,   // PDFなら空文字、VIDEOならVimeoID
        filePath: isPDF ? item.filePath : '', // PDFならファイルパス、VIDEOなら空文字
        fileName: fileName,                   // PDFならファイル名、VIDEOなら動画タイトル名
        isPDF: isPDF,
        status: Number(item.status),
        releaseStartDate: item.releaseStartDate,
        releaseEndDate: item.releaseEndDate,
        isTest: item.isTest,
        isDownload: item.isDownload,
        isAllowReview: item.isAllowReview,
        isAllowRetest: item.isAllowRetest,
        publishScope: publishScope,
        publishCompanyList: item.publishCompanyList,
        isSign: item.isSign,
        testStatus: item.testStatus,
      }
    )
    changeScreenMode(MODE.EDIT);
  }

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const status = editItem.status === STATUS_TYPE.ACTIVE ? Number(STATUS_TYPE.ACTIVE) : Number(STATUS_TYPE.UNRELEASE);
    const publishCompanyIds =
      editItem.publishScope === PUBLISH_SCOPE.LIMITED ? editItem.publishCompanyList?.map(company => company.companyId) || null : null;

    const params = {
      status: status,
      releaseStartDate: editItem.releaseStartDate,
      releaseEndDate: editItem.releaseEndDate,
      isTest: editItem.isTest,
      isDownload: editItem.isDownload,
      isAllowReview: editItem.isAllowReview,
      isAllowRetest: editItem.isAllowRetest,
      publishCompanyIds: publishCompanyIds,
      isSign: editItem.isSign,
    }

    /* バリデーションチェック */
    const validationResult = validateJobData(params, true);
    if (validationResult.error) {
      alert(validationResult.message);
      return;
    }
  
    /* 確認メッセージ表示 */
    let isContinue = true;
    if (editItem.releaseStartDate === today) {
      if (!window.confirm(Message.CONFIRM_NOW_START_JOB)) {
        isContinue = false;
      }
    } else {
      if (!window.confirm(Message.CONFIRM_SCHEDULE_START_JOB)) {
        isContinue = false;
      }
    }
        
    if (isContinue) {
      const res = await requestApiLoadAndBottom(`/job/update/${editItem.jobId}`,
        params, setIsLoading, bottomSheetRef, Message.BS_UPDATE_JOB_SUCCESS);
      if (res.return !== RESPONSE.SUCCESS) {
        if (res.errorCode === 'W-JO5-V404') {
          alert('Vimeoの動画が見つかりません。Viemo動画IDを確認してください。');
        }
        return;
      }
      setFirstItem(res);

      if (size.width <= 1200) {
        changeScreenMode(MODE.NONE);
      } else {
        changeScreenMode(MODE.EDIT);
      }
    }
  }

  const handleStopSubmit = async (e) => {
    e.preventDefault();
    if (!editItem.jobId) { return; }
    if (!window.confirm(Message.CONFIRM_STOP_JOB)) { return; }

    const res = await requestApiLoadAndBottom(`/job/stop/${editItem.jobId}`,
      {}, setIsLoading, bottomSheetRef, Message.BS_STOP_JOB_SUCCESS);
    
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }
    setFirstItem(res);
     
    if (size.width <= 1200) {
      changeScreenMode(MODE.NONE);
    } else {
      changeScreenMode(MODE.EDIT);
    }
  }

  // ------------------------------------------------------------------------------------
  // テスト設定
  // ------------------------------------------------------------------------------------
  const handleSetting = async (e, item) => {
    e.stopPropagation();
    if (!item) { return; }
    setSelectedItem(item);

    if (item.testStatus === TEST_STATUS.SETTING) {
      
      // LC設定済
      const res = await requestApiLoad(`/test/get/${item.jobId}`,
        {}, setIsLoading);
      if (res.return !== RESPONSE.SUCCESS) {
        return;
      }
      
      const questions = res.questionList;
      if (!questions || questions.length === 0) {
        // LC設定済み（問題数が0）
        setSettingItem({
          jobId: item.jobId,
          jobName: item.jobName,
          questions: [{
            questionText: '',
            answerType: ANSWER_TYPE.SINGLE_CHOICE,
            choices: Array.from(
              { length: MaxLength.CHOICE_MAX_LENGTH },
              (_, index) => ({
                id: `choice-${Date.now()}-${index}`,
                text: '', isCorrect: false
              }))
          }]
        });
      } else {
        // LC設定済み（問題数が1以上）
        setSettingItem({
          jobId: item.jobId,
          jobName: item.jobName,
          questions: questions.map(question => ({
            questionText: question.questionText,
            answerType: question.answerType,
            choices: question.answerList.map((choice, index) => ({
              id: `choice-${Date.now()}-${index}`,
              text: choice.answerText,
              isCorrect: choice.isCorrect
            }))
          }))
        });
      }
    } else if (item.testStatus === TEST_STATUS.UNSETTING) {
      // LC未設定
      setSettingItem({
        jobId: item.jobId,
        jobName: item.jobName,
        questions: [{
          questionText: '',
          answerType: ANSWER_TYPE.SINGLE_CHOICE,
          choices: Array.from(
            { length: MaxLength.CHOICE_MAX_COUNT },
            (_, index) => ({
              id: `choice-${Date.now()}-${index}`,
              text: '', isCorrect: false
            }))
        }]
      });
    } else {
      // テスト実施なし
    }

    changeScreenMode(MODE.SETTING);
  }

  const handleSettingSubmit = async (event) => {
    event.preventDefault();
    const params = {
      questionList: settingItem.questions.map(question => ({
        questionText: question.questionText,
        answerType: question.answerType,
        answerList: question.choices.map(choice => ({
          answerText: choice.text,
          isCorrect: choice.isCorrect
        }))
      }))
    };

    const validateResult = validateLCSettingData(params);
    if (validateResult.error) {
      alert(validateResult.message);
      return;
    }

    const res = await requestApiLoadAndBottom(`/test/update/${settingItem.jobId}`,
      params, setIsLoading, bottomSheetRef, Message.BS_LC_SETTING_SUCCESS);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }
    
    // テストステータスの更新
    setItemList(prevJobs => prevJobs.map(
      job => job.jobId === settingItem.jobId ? { ...job, testStatus: TEST_STATUS.SETTING } : job));
    
    // メイン一覧更新
    const jobParams = {
      page: 1,
      isDesc: mainSortConfig.direction,
      orderKey: mainSortConfig.key,
      status: mainStatusFilter,
      name: mainSearchTerm,
    }
    const resJob = await requestApiLoad(`/job/get`, jobParams, setIsLoading);
    if (resJob.return !== RESPONSE.SUCCESS) {
      return;
    }
    setFirstItem(resJob);

    if (size.width <= 1200) {
      changeScreenMode(MODE.NONE);
    } else {
      changeScreenMode(MODE.EDIT);
    }
  }

  // ------------------------------------------------------------------------------------
  // その他
  // ------------------------------------------------------------------------------------ 
  const getStatusText = (item) => {
    if (!item.isAvailable) {
      return ' (公開終了)';
    }

    switch (item.status) {
      case STATUS_TYPE.ACTIVE:
        return item.testStatus === TEST_STATUS.UNSETTING ? ' (公開待機中)' : ' (公開中)';
      
      case STATUS_TYPE.UNRELEASE:
        return ' (公開予定)';
      
      default:
        return ' (公開終了)';
    }
  }

  const setFirstItem = (res) => {
    setItemList(res.jobList);
    setMainLastPage(res.lastPage);
    setMainTotalCount(res.dataCount);
      
    const item = res.jobList[0];
    const isPDF = item.fileType === FILE_TYPE.PDF;
    const fileName = isPDF ? item.fileName : '';
    const publishScope = item.publishCompanyList?.length > 0 ? PUBLISH_SCOPE.LIMITED : PUBLISH_SCOPE.ALL;
      
    // 更新後、1ページ目の1件目のデータを選択
    setEditItem(
      {
        jobId: item.jobId,
        jobName: item.jobName,
        vimeoId: isPDF ? '' : item.vimeoId,   // PDFなら空文字、VIDEOならVimeoID
        fileName: fileName,                   // PDFならファイル名、VIDEOなら動画タイトル
        filePath: isPDF ? item.filePath : '', // PDFならファイルパス、VIDEOなら空文字 
        isPDF: isPDF,
        status: Number(item.status),
        releaseStartDate: item.releaseStartDate,
        releaseEndDate: item.releaseEndDate,
        isTest: item.isTest,
        isDownload: item.isDownload,
        isAllowReview: item.isAllowReview,
        isAllowRetest: item.isAllowRetest,
        publishScope: publishScope,
        publishCompanyList: item.publishCompanyList,
        isSign: item.isSign,
        testStatus: item.testStatus,
      }
    );
    setSelectedItem(res.jobList[0]);
    setMainCurrentPage(1);
    setMainStatusFilter(Number(STATUS_TYPE.ALL));
    setMainSearchTerm('');
    setMainSortConfig({ key: 'jobId', direction: true });
    resetScroll();
    resetScrollMain();
  }

  const resetScroll = () => {
    const scrollForm = document.querySelector('.scroll-form');
    if (scrollForm) {
      scrollForm.scrollTop = 0;
    }
  }

  const resetScrollMain = () => {
    const scrollForm = document.querySelector('.card-list');
    if (scrollForm) {
      scrollForm.scrollTop = 0;
    }
  }

  // ------------------------------------------------------------------------------------
  // 一覧作成
  // ------------------------------------------------------------------------------------ 
  const createTable = (itemList) => {
    return (
      <div className="card-list">
        {itemList.length === 0 ? (
          <div className="no-data">データがありません</div>
        ) : (
          itemList.map((item) => (
            <div className="card" key={item.jobId} onClick={(e) => handleEdit(e, item)} >
              <div className="card-contents" id="job-master-card-contents">
                <div className="card-contents-left">
                  <h3 className="card-title">
                    <span>
                      {item.fileType === FILE_TYPE.PDF && (
                        <svg
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          width="19px"
                          height="20px"
                          viewBox="0 0 24 24"
                          aria-labelledby="stickerIconTitle"
                          stroke="var(--blue-font-color)"
                          strokeWidth="1.8"
                          strokeLinecap="square"
                          strokeLinejoin="miter"
                          fill="none"
                          color="var(--blue-font-color)">
                          <title id="stickerIconTitle">PDF</title>
                          <path d="M21,3 L21,11 C21,16.5228475 16.5228475,21 11,21 L3,21 L3,3 L21,3 Z" />
                          <path d="M9,21 C11.6666667,21 13,19.6666667 13,17 C13,17 13,15.6666667 13,13 L17,13 C19.6666667,13 21,11.6666667 21,9" />
                        </svg>
                      )}
                      {item.fileType === FILE_TYPE.VIDEO && (
                        <svg
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          width="19px"
                          height="20px"
                          viewBox="0 0 24 24"
                          aria-labelledby="videoIconTitle"
                          stroke="var(--blue-font-color)"
                          strokeWidth="1.8"
                          strokeLinecap="square"
                          strokeLinejoin="miter"
                          fill="none"
                          color="var(--blue-font-color)">
                          <title id="videoIconTitle">Video</title>
                          <polygon points="18 12 9 16.9 9 7" />
                          <circle cx="12" cy="12" r="10" />
                        </svg>
                      )}
                    </span>
                    {item.jobName}
                    {item.publishCompanyList && item.publishCompanyList.length > 0 && (
                      <span>
                        <svg
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          width="17px"
                          height="17px"
                          viewBox="0 0 24 24"
                          aria-labelledby="peopleIconTitle"
                          stroke="var(--blue-font-color)"
                          strokeWidth="1.7"
                          strokeLinecap="square"
                          strokeLinejoin="miter"
                          fill="none"
                          color="var(--blue-font-color)">
                          <title id="peopleIconTitle">グループ</title>
                          <path d="M1 18C1 15.75 4 15.75 5.5 14.25 6.25 13.5 4 13.5 4 9.75 4 7.25025 4.99975 6 7 6 9.00025 6 10 7.25025 10 9.75 10 13.5 7.75 13.5 8.5 14.25 10 15.75 13 15.75 13 18M12.7918114 15.7266684C13.2840551 15.548266 13.6874862 15.3832994 14.0021045 15.2317685 14.552776 14.9665463 15.0840574 14.6659426 15.5 14.25 16.25 13.5 14 13.5 14 9.75 14 7.25025 14.99975 6 17 6 19.00025 6 20 7.25025 20 9.75 20 13.5 17.75 13.5 18.5 14.25 20 15.75 23 15.75 23 18" />
                          <path strokeLinecap="round" d="M12,16 C12.3662741,15.8763472 12.6302112,15.7852366 12.7918114,15.7266684" />
                        </svg>
                      </span>
                    )}
                  </h3>
                  <span className="sub-text">{item.releaseStartDate} ~ {item.releaseEndDate}
                    {getStatusText(item)}</span>
                </div>
                <div className="card-footer">
                  {/* 詳細 */}
                  <button className="blue" disabled={item.testStatus === TEST_STATUS.NONE} onClick={(e) => handleSetting(e, item)} title="テスト設定">
                    <svg
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      aria-labelledby="settingsIconTitle"
                      stroke={!item.isTest || item.testStatus === TEST_STATUS.NONE ? 'var(--disable-font-color)' :
                        item.testStatus === TEST_STATUS.UNSETTING ? 'var(--red-font-color)' :
                          'var(--blue-font-color)'}
                      strokeWidth="1.9"
                      strokeLinecap="square"
                      strokeLinejoin="miter"
                      fill="none"
                      color="var(--blue-font-color)">
                      <title id="settingsIconTitle">テスト設定</title>
                      <path d="M5.03506429,12.7050339 C5.01187484,12.4731696 5,12.2379716 5,12 C5,11.7620284 5.01187484,11.5268304 5.03506429,11.2949661 L3.20577137,9.23205081 L5.20577137,5.76794919 L7.9069713,6.32070904 C8.28729123,6.0461342 8.69629298,5.80882212 9.12862533,5.61412402 L10,3 L14,3 L14.8713747,5.61412402 C15.303707,5.80882212 15.7127088,6.0461342 16.0930287,6.32070904 L18.7942286,5.76794919 L20.7942286,9.23205081 L18.9649357,11.2949661 C18.9881252,11.5268304 19,11.7620284 18.9649357,12.7050339 L20.7942286,14.7679492 L18.7942286,18.2320508 L16.0930287,17.679291 C15.7127088,17.9538658 15.303707,18.1911779 14.8713747,18.385876 L14,21 L10,21 L9.12862533,18.385876 C8.69629298,18.1911779 8.28729123,17.9538658 7.9069713,17.679291 L5.20577137,18.2320508 L3.20577137,14.7679492 L5.03506429,12.7050339 Z" />
                      <circle cx="12" cy="12" r="1" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    );
  }

  // ------------------------------------------------------------------------------------
  // AddTable
  // ------------------------------------------------------------------------------------
  const createAddTable = () => {
    return (
      <>
        <div className="scroll-form">
          <JobForm
            formData={addItem}
            setFormData={setAddItem}
            isEdit={false}
          />
        </div>
        <div className="bottom-button">
          <button className="blue" onClick={handleAddSubmit}>
            登録
          </button>
        </div>
      </>
    )
  }

  // ------------------------------------------------------------------------------------
  // EditTable
  // ------------------------------------------------------------------------------------
  const createEditTable = () => {      
    return (
      <>
        <div className="scroll-form">
          <JobForm
            formData={editItem}
            setFormData={setEditItem}
            isEdit={true}
          />
        </div>
        <div className="bottom-button">
          {(Number(editItem.status) === STATUS_TYPE.ACTIVE) &&
            (new Date(editItem.releaseEndDate).getTime() !== new Date(today).getTime()) && (
              <button className="red" onClick={handleStopSubmit}>
                公開停止
              </button>
            )}
          {Number(editItem.status) !== STATUS_TYPE.INACTIVE && (
            <button className="blue" onClick={handleEditSubmit}>
              更新
            </button>
          )}
        </div>
      </>
    )
  }

  // ------------------------------------------------------------------------------------
  // SettingTable
  // ------------------------------------------------------------------------------------
  const createSettingTable = () => {
    if (selectedItem.testStatus === TEST_STATUS.NONE) { return; }
    const isPermitEdit = selectedItem.testStatus === TEST_STATUS.UNSETTING || selectedItem.status === STATUS_TYPE.UNRELEASE;

    return (
      <>
        <div className="scroll-form">
          <TestSettingForm
            formData={settingItem}
            setFormData={setSettingItem}
            isPermitEdit={isPermitEdit}
          />
        </div>
        <div className="bottom-button">
          <button className="blue" onClick={handleSettingSubmit}>
            OK
          </button>
        </div>
      </>
    )
  }

  // ------------------------------------------------------------------------------------
  // ValidationCheck
  // ------------------------------------------------------------------------------------
  const validateJobData = (params, isEdit) => {
    if (!isEdit) {
      // --新規追加の場合--
      const jobNameResult = Validators.validateJobName(params.jobName);
      if (jobNameResult.error) {
        return jobNameResult;
      }
      // 動画研修の場合
      if (params.fileType === FILE_TYPE.VIDEO) {
        const vimeoIdResult = Validators.validateVimeoId(params.vimeoId);
        if (vimeoIdResult.error) {
          return vimeoIdResult;
        }
      }
      // PDFのチェックは事前に行なっているため、なし
      
      // スケジュール公開の場合
      if (params.status === STATUS_TYPE.UNRELEASE) {
        const releaseStartDateResult = Validators.validateReleaseStartDate(params.releaseStartDate);
        if (releaseStartDateResult.error) {
          return releaseStartDateResult;
        }
        // 終了日が入力されていればチェック
        if (params.releaseEndDate) {
          const releaseEndDateResult = Validators.validateReleaseEndDate(params.releaseEndDate);
          if (releaseEndDateResult.error) {
            return releaseEndDateResult;
          }
          // 開始日と終了日のチェック
          const releaseDateResult = Validators.validateReleaseDate(params.releaseStartDate, params.releaseEndDate);
          if (releaseDateResult.error) {
            return releaseDateResult;
          }
        }
      }
      // 限定公開の場合は公開先をチェック
      if (!isEdit && params.publishScope === PUBLISH_SCOPE.LIMITED) {
        const publishCompanyListResult = Validators.validatePublishCompanyIds(params.publishCompanyIds);
        if (publishCompanyListResult.error) {
          return publishCompanyListResult;
        }
      }

    } else {
      // --更新の場合--
      // 終了日が入力されていればチェック
      if (params.releaseEndDate) {
        const releaseEndDateResult = Validators.validateReleaseEndDate(params.releaseEndDate);
        if (releaseEndDateResult.error) {
          return releaseEndDateResult;
        }
        // 開始日と終了日のチェック
        const releaseDateResult = Validators.validateReleaseDate(params.releaseStartDate, params.releaseEndDate);
        if (releaseDateResult.error) {
          return releaseDateResult;
        }
      }
    } 
    return { error: false, message: '' };
  }

  const validateLCSettingData = (params) => {
    const questionListResult = Validators.validateQuestion(params.questionList);
    if (questionListResult.error) {
      return questionListResult;
    }
    return { message: '', error: false };
  };

  // ------------------------------------------------------------------------------------
  // レンダリング
  // ------------------------------------------------------------------------------------
  return (
    <div className="view-contents" id="job-master">
      <Loading isLoading={isLoading} />
      <BottomSheet ref={bottomSheetRef} />

      <div className="main-contents">
        {size.width > 1200 && (<h2 className="page-title">研修マスタ</h2>)}
        <div className="header-contents">
          <div className="search-bar">
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              <input
                type="text"
                placeholder="研修名で検索"
                value={mainSearchTerm}
                onChange={(e) => setMainSearchTerm(e.target.value)}
                maxLength={20}
                style={{
                  backgroundImage: 'url(/images/search.png)',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '10px center',
                  backgroundSize: '20px 20px',
                  paddingLeft: '40px',
                  paddingRight: mainSearchTerm ? '30px' : '10px'
                }}
              />
              {mainSearchTerm && (
                <button
                  onClick={onMainClear}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '16px',
                    color: '#878787'
                  }}>
                  ×
                </button>
              )}
            </div>
          </div>
          <div className="button-container">
            <div className="add-new-item-button" onClick={handleAdd}>
              <svg
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                width="18px"
                height="18px"
                viewBox="0 0 24 24"
                aria-labelledby="plusIconTitle"
                stroke="var(--blue-font-color)"
                strokeWidth="1.8"
                strokeLinecap="square"
                strokeLinejoin="miter"
                fill="none"
                color="var(--blue-font-color)"
              >
                <title id="plusIconTitle">新規追加</title>
                <path d="M20 12L4 12M12 4L12 20" />
              </svg>
              <p className="hide-option">新規追加</p>
            </div>
          </div>
        </div>

        <div className="middle-contents">
          <div className="left-contents">
            <FilterButton
              statusFilter={mainStatusFilter}
              handleStatusChange={handleMainStatusChange}
              statusOptions={statusOptions}
            />
            <SortingButton
              sortConfig={mainSortConfig}
              handleSortChange={handleMainSortChange}
              sortOptions={sortOptions}
            />
          </div>
          
          <Pagination
            totalPages={mainLastPage}
            currentPage={mainCurrentPage}
            onPageChange={handleMainPageChange}
            totalItems={mainTotalCount}
          />
        </div>

        {createTable(sortedAndFilteredItems)}
      </div>

      {size.width > 1200 ? (
        <>
          {showAdd && (
            <div className="sub-contents">
              <h2 className="page-title">研修追加</h2>
              {createAddTable()}
            </div>
          )}

          {showEdit && (
            <div className="sub-contents">
              <h2 className="page-title">{selectedItem.jobName}</h2>
              {createEditTable()}
            </div>
          )}

          {showSetting && (
            <div className="sub-contents">
              <h2 className="page-title">{selectedItem.jobName}</h2>
              {createSettingTable()}
            </div>
          )}
        </>
      ) : (
        <>
          <Modal
            isOpen={showAdd}
            title="研修追加"
            actionButtonText="登録"
            onAction={handleAddSubmit}
            closeButtonText="閉じる"
            closeModal={() => setShowAdd(false)}
          >
            <div className="sub-contents">
              {createAddTable()}
            </div>
          </Modal>
          <Modal
            isOpen={showEdit}
            title={selectedItem.jobName}
              actionButtonText={(Number(editItem.status) !== STATUS_TYPE.INACTIVE) &&
                (new Date(editItem.releaseEndDate).getTime() !== new Date(today).getTime()) ? "更新" : ""}
            onAction={handleEditSubmit}
            actionSubButtonText={(Number(editItem.status) === STATUS_TYPE.ACTIVE) &&
              (new Date(editItem.releaseEndDate).getTime() !== new Date(today).getTime()) ? "公開停止" : ""}
            onSubAction={handleStopSubmit}
            closeModal={() => setShowEdit(false)}
            closeButtonText="閉じる"
          >
            <div className="sub-contents">
              {createEditTable()}
            </div>
          </Modal>
            
          <Modal
            isOpen={showSetting}
            title={selectedItem.jobName}
            actionButtonText="OK"
            onAction={handleSettingSubmit}
            closeButtonText="閉じる"
            closeModal={() => setShowSetting(false)}
          >
            <div className="sub-contents">
              {createSettingTable()}
            </div>
          </Modal>
        </>
      )}
    </div>
  )
}

export default JobMaster;