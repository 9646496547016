import React, { useEffect, useState, useRef } from 'react';
import Loading from '../Loading/Loading';
import axios from 'axios';
import * as ExceptionUtils from '../../utils/exceptionUtil';
import Player from '@vimeo/player';
import './VimeoPlayer.css';

const VimeoPlayer = ({ videoId, onEnd = () => {} }) => {
  const [loading, setLoading] = useState(true);
  const [videoData, setVideoData] = useState(null);
  const playerRef = useRef(null);
  const accessToken = process.env.REACT_APP_VIMEO_ACCESS_TOKEN;

  useEffect(() => {
    const fetchVimeoVideoData = async () => {
      try {
        const response = await axios.get(`https://api.vimeo.com/videos/${videoId}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
        setVideoData(response.data);
      } catch (error) {
        if(error.response.status === 404 && error.response.statusText === 'Not Found') {
          alert('Vimeoの動画が見つかりません。');
          return;
        } else {
          ExceptionUtils.handleError(error);
          return;
        }
      } finally {
        setLoading(false);
      }
    };
    setLoading(true);
    fetchVimeoVideoData();
  }, [videoId, accessToken]);

  useEffect(() => {
    const handleVideoEnd = (event) => {
      if (event.origin === "https://player.vimeo.com") {
        const data = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
        if (data.event === "ended") {
          onEnd();
        }
      }
    };

    window.addEventListener("message", handleVideoEnd);

    return () => {
      window.removeEventListener("message", handleVideoEnd);
    };
  }, [onEnd]);

  const handleIframeLoad = () => {
    const iframe = playerRef.current;
    if (iframe) {
      const player = new Player(iframe);
      player.on('ended', onEnd);

      return () => {
        player.off('ended');
      };
    }
  };

  return (
    <div className="vimeo-player-container">
      {loading && <Loading isLoading={loading}/>}
      {videoData && videoData.embed && videoData.embed.html && (
        <iframe
          ref={playerRef}
          src={`${videoData.embed.html.match(/src="([^"]+)"/)[1]}?api=1&player_id=player1`}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
          title={videoData.name}
          onLoad={handleIframeLoad}
          id="player1"
          className="vimeo-player-iframe"
        ></iframe>
      )}
    </div>
  );
};

export default VimeoPlayer;