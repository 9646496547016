/* ==================== */
/* 顧客請求書フォーム */
/* ==================== */
import React, { useState } from 'react';
import DeleteButton from '../../DeleteButton/DeleteButton';
import { MaxLength } from '../../../const/Constant';
import useWindowSize from '../../../hook/useWindowSize';
import { handleDateBlur as handleDateBlurUtil } from '../../../utils/dateInputUtil';
import { validateInput, validateDate, validateDateRange } from '../../../utils/validation';
import './CustomerPaymentForm.css';

const CustomerPaymentForm = ({ formData, setFormData }) => {
  const size = useWindowSize();
  const items = formData.items;
  
  /* エラーメッセージ */
  const [invoiceNumberError, setInvoiceNumberError] = useState('');
  const [dateError, setDateError] = useState('');
  const [paymentDueError, setPaymentDueError] = useState('');
  const [taxError, setTaxError] = useState('');
  const [noteError, setNoteError] = useState('');
  const [itemErrors, setItemErrors] = useState(items.map(() => ({ name: '', quantity: '', unitPrice: '' })));
  const [itemErrorMessages, setItemErrorMessages] = useState([]);

  // ------------------------------------------------------------------------------------
  // イベント
  // ------------------------------------------------------------------------------------
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let newInvoiceNumberError = '';
    let newDateError = '';
    let newPaymentDueError = '';
    let newTaxError = '';
    let newNoteError = '';

    if (name === 'invoiceNumber') {
      if (!value.trim()) {
        newInvoiceNumberError = '必須項目です。';
      } else if (!/^[A-Za-z0-9]+$/.test(value)) {
        newInvoiceNumberError = '英数字のみ入力可能です。';
      } else if (value.length > MaxLength.INVOICE_NUMBER_MAX_LENGTH) {
        newInvoiceNumberError = `最大文字数を超えています。(${value.length}/${MaxLength.INVOICE_NUMBER_MAX_LENGTH})`;
      } else {
        newInvoiceNumberError = validateInput(value);
      }
      setInvoiceNumberError(newInvoiceNumberError);
    
    } else if (name === 'date') {
      const dateRangeResult = validateDate(value);
      if (dateRangeResult.error) {
        newDateError = dateRangeResult.message;
      }
      setDateError(newDateError);

    } else if (name === 'paymentDue') {
      const dateRangeResult = validateDateRange(value);
      if (dateRangeResult.error) {
        newPaymentDueError = dateRangeResult.message;
      }
      setPaymentDueError(newPaymentDueError);

    } else if (name === 'tax') {
      if (!value.trim() ) {
        newTaxError = `必須項目です。`;
      }
      if ((value < MaxLength.MIN_TAX_RATE || value > MaxLength.MAX_TAX_RATE)) {
        newTaxError = `消費税は${MaxLength.MIN_TAX_RATE}%から${MaxLength.MAX_TAX_RATE}%の間で設定してください。`;
      }
      setTaxError(newTaxError);

    } else if (name === 'note') {
      if (value.length > MaxLength.NOTE_MAX_LENGTH) {
        newNoteError = `最大文字数を超えています。(${value.length}/${MaxLength.NOTE_MAX_LENGTH})`;
      } else {
        newNoteError = validateInput(value);
      }
      setNoteError(newNoteError);
    }

    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  }

  const handleDateBlur = (event) => {
    handleDateBlurUtil(event, setFormData);
  };

  // ------------------------------------------------------------------------------------
  // 明細変更
  // ------------------------------------------------------------------------------------
  const handleItemChange = (index, field, value) => {
    const newItems = [...items];
    newItems[index][field] = value;
    if (field === 'quantity' || field === 'unitPrice') {
      newItems[index].amount = newItems[index].quantity * newItems[index].unitPrice;
    }

    const newItemErrors = [...itemErrors];
    let error = '';

    if (field === 'name') {
      if (!value.trim()) {
        error = '品名は必須項目です。';
      } else if (value.length > MaxLength.ITEM_NAME_MAX_LENGTH) {
        error = `最大文字数を超えています。(${value.length}/${MaxLength.ITEM_NAME_MAX_LENGTH})`;
      }
    } else if (field === 'quantity') {
      if (!value) {
        error = '数量は必須項目です。';
      } else if (value < MaxLength.ITEM_QUANTITY_MIN || value > MaxLength.ITEM_QUANTITY_MAX) {
        error = `数量は${MaxLength.ITEM_QUANTITY_MIN}から${MaxLength.ITEM_QUANTITY_MAX}の範囲で入力してください。`;
      }
    } else if (field === 'unitPrice') {
      if (!value) {
        error = '単価は必須項目です。';
      } else if (value < MaxLength.ITEM_UNIT_PRICE_MIN || value > MaxLength.ITEM_UNIT_PRICE_MAX) {
        error = `単価は${MaxLength.ITEM_UNIT_PRICE_MIN}から${MaxLength.ITEM_UNIT_PRICE_MAX}の範囲で入力してください。`;
      }
    }

    newItemErrors[index][field] = error;

    setFormData({ ...formData, items: newItems });
    setItemErrors(newItemErrors);

    const newItemErrorMessages = newItemErrors.flatMap((errors, idx) =>
      Object.entries(errors).map(([field, errorMsg]) =>
        errorMsg ? `（明細${idx + 1}）${errorMsg}` : null
      ).filter(Boolean)
    );
    setItemErrorMessages(newItemErrorMessages);
  };

  // ------------------------------------------------------------------------------------
  // 明細追加
  // ------------------------------------------------------------------------------------
  const addItem = () => {
    if (items.length < MaxLength.MAX_PAYMENT_ITEM_COUNT) {
      setFormData({ ...formData, items: [...items, { name: '', quantity: '', unitPrice: '', amount: '' }] });
      setItemErrors([...itemErrors, { name: '', quantity: '', unitPrice: '' }]);
    }
  };

  // ------------------------------------------------------------------------------------
  // 明細削除
  // ------------------------------------------------------------------------------------
  const removeItem = (index) => {
    const newItems = items.filter((_, i) => i !== index);
    const newItemErrors = itemErrors.filter((_, i) => i !== index);
    setFormData({ ...formData, items: newItems });
    setItemErrors(newItemErrors);
  
    // 明細エラーメッセージを更新
    const newItemErrorMessages = newItemErrors.flatMap((errors, idx) =>
      Object.entries(errors).map(([field, errorMsg]) =>
        errorMsg ? `（明細${idx + 1}）${errorMsg}` : null
      ).filter(Boolean)
    );
    setItemErrorMessages(newItemErrorMessages);
  };
  
  // ------------------------------------------------------------------------------------
  // レンダリング
  // ------------------------------------------------------------------------------------
  return (
    <div className="modal-form" id="customer-payment-form">
      <div className="column-set">

        <div className="row-set" id="if-name">
          <div className="input-group">
            <label>請求先会社名</label>
            <input
              type="text"
              name="customerName"
              value={formData.customerName}
              onChange={handleInputChange}
              readOnly
              disabled
            />
          </div>
        </div>
        
        <div className='separate-line'></div>
        <div className="row-set" id="if-name">
          <div className="input-group">
            <label>請求書番号</label>
            <div className={`error-message-container ${invoiceNumberError ? 'has-error' : ''}`}>
              <input
                type="text"
                name="invoiceNumber"
                value={formData.invoiceNumber}
                onChange={handleInputChange}
                style={{
                  borderColor: invoiceNumberError ? 'red' : '',
                }}
              />
              {invoiceNumberError && (
                <div className="error-message">
                  {invoiceNumberError}
                </div>
              )}
            </div>
          </div>
        </div>
      
        <div className="row-set" id="if-dates">
          <div className="input-group">
            <label>請求書発行日</label>
            <div className={`error-message-container ${dateError ? 'has-error' : ''}`}>
              <input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
                onBlur={handleDateBlur}
                pattern="\d{4}-\d{2}-\d{2}"
                min="1900-01-01"
                max="2999-12-31"
                style={{
                  borderColor: dateError ? 'red' : '',
                }}
              />
              {dateError && (
                <div className="error-message">
                  {dateError}
                </div>
              )}
            </div>
          </div>
          <div className="input-group">
            <label>支払期限日</label>
            <div className={`error-message-container ${paymentDueError ? 'has-error' : ''}`}>
              <input
                type="date"
                name="paymentDue"
                value={formData.paymentDue}
                onChange={handleInputChange}
                onBlur={handleDateBlur}
                pattern="\d{4}-\d{2}-\d{2}"
                min="1900-01-01"
                max="2999-12-31"
                style={{
                  borderColor: paymentDueError ? 'red' : '',
                }}
              />
              {paymentDueError && (
                <div className="error-message">
                  {paymentDueError}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='spacer30'></div>
      
        {size.width > 1024 && (
          <div className='item-group'>
            <div className="row-set-group">
              <div className='row-set' id='item-name-header'>品名</div>
              <div className='row-set' id='item-quantity-header'>数量</div>
              <div className='row-set' id='item-price-header'>単価</div>
              <div className='row-set' id='item-amount-header'>金額</div>
              <div className='row-set' id='item-delete-button-header'></div>
            </div>

            {items.map((item, index) => (
              <div key={index} className="row-set-group">
                <div className='column-set'>

                  <div className="row-set">
                    <div className="input-group" id='item-name'>
                      <input
                        name={`item.name`}
                        value={item.name}
                        onChange={(e) => handleItemChange(index, 'name', e.target.value)}
                        style={{
                          borderColor: itemErrors[index].name ? 'red' : '',
                        }}
                      />
                    </div>

                    <div className="input-group" id='item-quantity'>
                      <input
                        type="number"
                        name={`item.quantity`}
                        value={item.quantity}
                        onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                        min={MaxLength.ITEM_QUANTITY_MIN}
                        max={MaxLength.ITEM_QUANTITY_MAX}
                        maxLength={MaxLength.ITEM_QUANTITY_MAX_LENGTH}
                        style={{
                          borderColor: itemErrors[index].quantity ? 'red' : '',
                        }}
                      />
                    </div>

                    <div className="input-group" id='item-price'>
                      <input
                        type="number"
                        name={`item.unitPrice`}
                        value={item.unitPrice}
                        onChange={(e) => handleItemChange(index, 'unitPrice', e.target.value)}
                        min={MaxLength.ITEM_UNIT_PRICE_MIN}
                        max={MaxLength.ITEM_UNIT_PRICE_MAX}
                        maxLength={MaxLength.ITEM_UNIT_PRICE_MAX_LENGTH}
                        style={{
                          borderColor: itemErrors[index].unitPrice ? 'red' : '',
                        }}
                      />
                    </div>

                    <div className="input-group" id='item-amount'>
                      <input
                        type="number"
                        name={`item.amount`}
                        value={item.amount}
                        onChange={(e) => handleItemChange(index, 'amount', e.target.value)}
                        disabled
                      />
                    </div>

                    <div className='row-set' id='item-delete-button'>
                      {index > 0 && (
                        <DeleteButton onClick={() => removeItem(index)} />
                      )}
                    </div>
                  </div>

                </div>
              </div>
            ))}
            {itemErrorMessages.length > 0 && (
              <div className="item-error-messages">
                {itemErrorMessages.map((errorMsg, index) => (
                  <div key={index} className="item-error-message">{errorMsg}</div>
                ))}
              </div>
            )}
            {items.length < MaxLength.MAX_PAYMENT_ITEM_COUNT && (
              <div className="button-container">
                <div className="add-new-item-button" onClick={addItem}>
                  <svg
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18px"
                    height="18px"
                    viewBox="0 0 24 24"
                    aria-labelledby="plusIconTitle"
                    stroke="var(--blue-font-color)"
                    strokeWidth="1.8"
                    strokeLinecap="square"
                    strokeLinejoin="miter"
                    fill="none"
                    color="var(--blue-font-color)"
                  >
                    <title id="plusIconTitle">明細追加</title>
                    <path d="M20 12L4 12M12 4L12 20" />
                  </svg>
                  <p className="hide-option">明細追加</p>
                </div>
              </div>
            )}
          </div>
        )}

        {size.width <= 1024 && (
          <div className='sp-item-group'>

            {items.map((item, index) => (
              <div key={index} className="sp-row-set-group">
                <div className="column-set">
                  <div className='row-set'>
                    <div className="input-group" id='item-name'>
                      <label>品名</label>
                      <input
                        type="text"
                        name={`item.name`}
                        value={item.name}
                        onChange={(e) => handleItemChange(index, 'name', e.target.value)}
                        maxLength={MaxLength.ITEM_NAME_MAX_LENGTH}
                        style={{
                          borderColor: itemErrors[index].name ? 'red' : '',
                        }}
                      />
                    </div>
                  </div>

                  <div className="row-set">
                    <div className="input-group" id='item-quantity'>
                      <label>数量</label>
                      <input
                        type="number"
                        name={`item.quantity`}
                        value={item.quantity}
                        onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                        min={MaxLength.ITEM_QUANTITY_MIN}
                        max={MaxLength.ITEM_QUANTITY_MAX}
                        maxLength={MaxLength.ITEM_QUANTITY_MAX_LENGTH}
                        style={{
                          borderColor: itemErrors[index].quantity ? 'red' : '',
                        }}
                      />
                    </div>
      
                    <div className="input-group" id='item-price'>
                      <label>単価</label>
                      <input
                        type="number"
                        name={`item.unitPrice`}
                        value={item.unitPrice}
                        onChange={(e) => handleItemChange(index, 'unitPrice', e.target.value)}
                        min={MaxLength.ITEM_UNIT_PRICE_MIN}
                        max={MaxLength.ITEM_UNIT_PRICE_MAX}
                        maxLength={MaxLength.ITEM_UNIT_PRICE_MAX_LENGTH}
                        style={{
                          borderColor: itemErrors[index].unitPrice ? 'red' : '',
                        }}
                      />
                    </div>
                    <div className="input-group" id='item-amount'>
                      <label>金額</label>
                      <input
                        type="number"
                        name={`item.amount`}
                        value={item.amount}
                        onChange={(e) => handleItemChange(index, 'amount', e.target.value)}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              
                <div className='row-set' id='item-delete-button'>
                  {index > 0 && (
                    <DeleteButton onClick={() => removeItem(index)} />
                  )}
                </div>
              </div>
            ))}
            {itemErrorMessages.length > 0 && (
              <div className="item-error-messages">
                {itemErrorMessages.map((errorMsg, index) => (
                  <div key={index} className="error-message">{errorMsg}</div>
                ))}
              </div>
            )}
            {items.length < MaxLength.MAX_PAYMENT_ITEM_COUNT && (
              <div className="add-button-wrapper">
                <div className="button-container">
                  <div className="add-new-item-button" onClick={addItem}>
                    <svg
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18px"
                      height="18px"
                      viewBox="0 0 24 24"
                      aria-labelledby="plusIconTitle"
                      stroke="var(--blue-font-color)"
                      strokeWidth="1.8"
                      strokeLinecap="square"
                      strokeLinejoin="miter"
                      fill="none"
                      color="var(--blue-font-color)"
                    >
                      <title id="plusIconTitle">明細追加</title>
                      <path d="M20 12L4 12M12 4L12 20" />
                    </svg>
                    <p className="hide-option">明細追加</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        <div className='spacer30'></div>

        <div className="row-set" id="if-tax">
          <div className="input-group">
            <label>消費税（%）</label>
            <div className={`error-message-container ${taxError ? 'has-error' : ''}`}>
              <input
                type="number"
                name="tax"
                value={formData.tax}
                onChange={handleInputChange}
                min={MaxLength.MIN_TAX_RATE}
                max={MaxLength.MAX_TAX_RATE}
                style={{
                  borderColor: taxError ? 'red' : '',
                }}
              />
              {taxError && (
                <div className="error-message">
                  {taxError}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row-set" id="if-price">
          <div className="input-group">
            <label>請求金額（税抜）</label>
            <input
              type="number"
              name="subtotal"
              onChange={handleInputChange}
              value={items.reduce((acc, item) =>
                acc + (item.unitPrice * item.quantity), 0)}
              readOnly
              disabled
            />
          </div>
          <div className="input-group">
            <label>請求金額（税込）</label>
            <input
              type="number"
              name="totalAmountWithTax"
              onChange={handleInputChange}
              value={Math.floor(items.reduce((acc, item) =>
                acc + (item.unitPrice * item.quantity), 0) * (1 + formData.tax / 100))}
              readOnly
              disabled
            />
          </div>
        </div>

        <div className="row-set" id="if-note">
          <div className="input-group">
            <label>備考</label>
            <div className={`error-message-container ${noteError ? 'has-error' : ''}`}>
              <textarea
                name="note"
                value={formData.note}
                onChange={handleInputChange}
                style={{
                  height: '100px',
                  width: '100%',
                  borderColor: noteError ? 'red' : '',
                }}
              />
              {noteError && (
                <div className="error-message">
                  {noteError}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomerPaymentForm;