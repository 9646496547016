/* ==================== */
/* パスワード変更モーダル */
/* ==================== */
import React, { useState, useEffect } from 'react';
import Modal from '../Modal/Modal';
import { ErrorMessage, MaxLength, Message } from '../../const/Constant';
import { MODAL_TYPE, RESPONSE } from '../../const/Enum';
import { requestApiLoadAndBottom } from '../../utils/apiLoadUtil';
import * as Validators from '../../utils/validation';
import * as validation from '../../utils/realtimeValidation';
import './PasswordChangeModal.css';

const PasswordChangeModal = ({ isOpen, closeModal, bottomSheetRef, setIsLoading }) => {
  const [passwordFormData, setPasswordFormData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [isInitialRender, setIsInitialRender] = useState(true);

  // ------------------------------------------------------------------------------------
  // 初期処理
  // ------------------------------------------------------------------------------------
  useEffect(() => {
    setPasswordFormData({
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
    setCurrentPasswordError('');
    setNewPasswordError('');
    setConfirmPasswordError('');
    setIsInitialRender(true);
  }, [isOpen]);

  // ------------------------------------------------------------------------------------
  // イベント
  // ------------------------------------------------------------------------------------
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let newCurrentPasswordError = '';
    let newNewPasswordError = '';
    let newConfirmPasswordError = '';

    if (name === 'currentPassword') {
      if (!value.trim()) {
        newCurrentPasswordError = '現在のパスワードは必須項目です。';
      } else if (value.length > MaxLength.PASSWORD_MAX_LENGTH) {
        newCurrentPasswordError = `最大文字数を超えています。(${value.length}/${MaxLength.PASSWORD_MAX_LENGTH})`;
      }
      setCurrentPasswordError(newCurrentPasswordError);

    } else if (name === 'newPassword') {
      const validateResult = validation.validateNewPassword(value);
      if (validateResult.error) {
        newNewPasswordError = validateResult.message;
      }
      if (!isInitialRender && value !== passwordFormData.confirmPassword) {
        newNewPasswordError = 'パスワードが一致しません。';
        newConfirmPasswordError = 'パスワードが一致しません。';
      } else {
        newConfirmPasswordError = '';
      }
      setNewPasswordError(newNewPasswordError);
      setConfirmPasswordError(newConfirmPasswordError);

    } else if (name === 'confirmPassword') {
      const validateResult = validation.validateNewPassword(value);
      if (validateResult.error) {
        newConfirmPasswordError = validateResult.message;
      }
      if (!isInitialRender && value !== passwordFormData.newPassword) {
        newConfirmPasswordError = 'パスワードが一致しません。';
        newNewPasswordError = 'パスワードが一致しません。';
      } else {
        newNewPasswordError = '';
      }

      // エラーステートを更新
      setConfirmPasswordError(newConfirmPasswordError);
      setNewPasswordError(newNewPasswordError);
    }

    setPasswordFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
    setIsInitialRender(false);
  }

  // ------------------------------------------------------------------------------------
  // パスワード変更
  // ------------------------------------------------------------------------------------
  const handlePasswordChange = async (event) => {
    event.preventDefault();
    const params = {
      oldPassword: passwordFormData.currentPassword,
      newPassword: passwordFormData.newPassword,
      confirmPassword: passwordFormData.confirmPassword,
    };

    const validationResult = validateCheck(params);
    if (validationResult.error) {
      alert(validationResult.message);
      return;
    }

    const res = await requestApiLoadAndBottom('/auth/changePassword', {
      oldPassword: params.oldPassword,
      newPassword: params.newPassword,
    }, setIsLoading, bottomSheetRef, Message.BS_UPDATE_PASSWORD_SUCCESS);
    if (res.return !== RESPONSE.SUCCESS) {
      if (res.errorCode === 'W-AU4-001') {
        alert(ErrorMessage.NOW_PASSWORD_FAILED);
      } else {
        alert(ErrorMessage.PASSWORD_UPDATE_FAILED);
      }
      return;
    }

    closeModal();
  };

  // ------------------------------------------------------------------------------------
  // ValidationCheck
  // ------------------------------------------------------------------------------------
  const validateCheck = (params) => {
    // 現在のパスワード
    const currentPasswordResult = Validators.validateCurrentPassword(params.oldPassword);
    if (currentPasswordResult.error) {
      return { message: currentPasswordResult.message, error: true };
    }
    // 新しいパスワード & 新しいパスワード（確認）
    const newPasswordResult = Validators.validateNewAndConfirmPassword(params.newPassword, params.confirmPassword);
    if (newPasswordResult.error) {
      return { message: newPasswordResult.message, error: true };
    }
    
    return { message: '', error: false };
  }

  // ------------------------------------------------------------------------------------
  // レンダリング
  // ------------------------------------------------------------------------------------
  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      title="パスワード変更"
      onAction={handlePasswordChange}
      actionButtonText="変更"
      playerKey={MODAL_TYPE.PASSWORD}
    >
      <div className="password-form">
        <div className="column-set">
          <div className="row-set" id="password">
            <div className="input-group">
              <label>現在のパスワード</label>
              <div className={`error-message-container ${currentPasswordError ? 'has-error' : ''}`}>
                <input
                  type={showPassword ? "text" : "password"}
                  name="currentPassword"
                  value={passwordFormData.currentPassword}
                  onChange={handleInputChange}
                  autoComplete="current-password"
                  style={{
                    borderColor: currentPasswordError ? 'red' : '',
                  }}
                />
                {currentPasswordError && (
                  <div className="error-message">
                    {currentPasswordError}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row-group" id="password">
            <div className="input-group">
              <label>新しいパスワード</label>
              <div className={`error-message-container ${newPasswordError ? 'has-error' : ''}`}>
                <input
                  type={showPassword ? "text" : "password"}
                  name="newPassword"
                  value={passwordFormData.newPassword}
                  onChange={handleInputChange}
                  autoComplete="new-password"
                  style={{
                    borderColor: newPasswordError ? 'red' : '',
                  }}
                />
                {newPasswordError && (
                  <div className="error-message">
                    {newPasswordError}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row-group" id="password">
            <div className="input-group">
              <label>新しいパスワード（確認）</label>
              <div className={`error-message-container ${confirmPasswordError ? 'has-error' : ''}`}>
                <input
                  type={showPassword ? "text" : "password"}
                  name="confirmPassword"
                  value={passwordFormData.confirmPassword}
                  onChange={handleInputChange}
                  onPaste={(e) => e.preventDefault()}
                  autoComplete="new-password"
                  style={{
                    borderColor: confirmPasswordError ? 'red' : '',
                  }}
                />
                {confirmPasswordError && (
                  <div className="error-message">
                    {confirmPasswordError}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="visible-button-container">
          <input
            type="checkbox"
            className="visible-button"
            checked={showPassword}
            onChange={() => setShowPassword(!showPassword)}
          />
          <label
            htmlFor="visible-button"
            onClick={() => setShowPassword(!showPassword)}>パスワード表示</label>
        </div>
      </div>
    </Modal>
  );
};

export default PasswordChangeModal;