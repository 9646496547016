import { PLAN_TYPE } from '../const/Enum';

/**
 * プランがjobサイン365を含むプランかどうかを判定する
 * @param {number} allPlanType プランタイプ
 * @returns {boolean} jobサイン365を含むプランの場合true
 */
export const isJsPlan = (allPlanType) => {
  const jsPlan = [
    PLAN_TYPE.LITE_AND_JS,
    PLAN_TYPE.STANDARD_AND_JS, 
    PLAN_TYPE.PREMIUM_AND_JS,
    PLAN_TYPE.ENTERPRISE_AND_JS,
    PLAN_TYPE.JS
  ];
  return jsPlan.includes(allPlanType);
};