/**
 * 郵便番号をフォーマットする
 * @param {string} value 
 * @returns 
 */
export const formatPostalCode = (value) => {
  if (!value) { return ''; }
  const numericValue = value.replace(/[^0-9]/g, '');
  if (numericValue.length <= 3) {
    return numericValue;
  } else {
    return `${numericValue.slice(0, 3)}-${numericValue.slice(3, 7)}`;
  }
};

/**
 * 適格請求書発行事業者登録番号をフォーマットする
 * @param {string} value 
 * @returns 
 */
export const formatBusinessNumber = (value) => {
  return `T${value}`;
};