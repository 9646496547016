/* ==================== */
/* パスワード変更モーダル */
/* ==================== */
import React, { useState, useEffect } from 'react';
import Modal from '../Modal/Modal';
import { ErrorMessage, Message } from '../../const/Constant';
import { MODAL_TYPE, RESPONSE } from '../../const/Enum';
import { requestApiLoadAndBottom } from '../../utils/apiLoadUtil';
import * as Validators from '../../utils/validation';
import * as validation from '../../utils/realtimeValidation';
import './MailChangeModal.css';

const MailChangeModal = ({ isOpen, closeModal, bottomSheetRef, setIsLoading }) => {
  const [mailFormData, setMailFormData] = useState({
    currentMail: '',
    newMail: ''
  });
  
  const [currentMailError, setCurrentMailError] = useState('');
  const [newMailError, setNewMailError] = useState('');

  // ------------------------------------------------------------------------------------
  // 初期処理
  // ------------------------------------------------------------------------------------
  useEffect(() => {
    setMailFormData({
      currentMail: '',
      newMail: '',
    });
  }, [isOpen]);

  // ------------------------------------------------------------------------------------
  // イベント
  // ------------------------------------------------------------------------------------
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let newCurrentMailError = '';
    let newNewMailError = '';

    if (name === 'currentMail') {
      newCurrentMailError = validation.validateMail(value);
    }
    if (name === 'newMail') {
      newNewMailError = validation.validateMail(value);
    }

    // エラーステートを更新
    setCurrentMailError(prevCurrentMailError => name === 'currentMail' ? newCurrentMailError : prevCurrentMailError);
    setNewMailError(prevNewMailError => name === 'newMail' ? newNewMailError : prevNewMailError);

    setMailFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  }

  // ------------------------------------------------------------------------------------
  // メールアドレス変更
  // ------------------------------------------------------------------------------------
  const handleMailChange = async (event) => {
    event.preventDefault();
    const params = {
      oldMail: mailFormData.currentMail,
      newMail: mailFormData.newMail,
    };

    const validationResult = validateCheck(params);
    if (validationResult.error) {
      alert(validationResult.message);
      return;
    }

    const res = await requestApiLoadAndBottom('/auth/changeMail',
      params, setIsLoading, bottomSheetRef, Message.BS_UPDATE_MAIL_SUCCESS);
    if (res.return !== RESPONSE.SUCCESS) {
      if (res.errorCode === 'W-AU8-001') {
        alert(ErrorMessage.NOW_MAIL_FAILED);
      } else if (res.errorCode === 'W-AU8-002') {
        alert('このメールアドレスは使用できません。');
      } else {
        alert(ErrorMessage.MAIL_CHANGE_FAILED);
      }
      return;
    }

    closeModal();
  };

   // ------------------------------------------------------------------------------------
  // ValidationCheck
  // ------------------------------------------------------------------------------------
  const validateCheck = (params) => {
    // 現在のメールアドレス
    const currentMailResult = Validators.validateCurrentMail(params.oldMail);
    if (currentMailResult.error) {
      return { message: currentMailResult.message, error: true };
    }
    // 新しいメールアドレス
    const newMailResult = Validators.validateNewMail(params.newMail);
    if (newMailResult.error) {
      return { message: newMailResult.message, error: true };
    }
    
    return { message: '', error: false };
  }

  // ------------------------------------------------------------------------------------
  // レンダリング
  // ------------------------------------------------------------------------------------
  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      title="メールアドレス変更"
      onAction={handleMailChange}
      actionButtonText="変更"
      playerKey={MODAL_TYPE.PASSWORD}
    >
      <div className="mail-change-form">
        <div className="column-set">
          <div className="row-set" id="mail">
            <div className="input-group">
              <label>現在のメールアドレス</label>
              <div className={`error-message-container ${currentMailError ? 'has-error' : ''}`}>
                <input
                  type="text"
                  name="currentMail"
                  value={mailFormData.currentMail}
                  onChange={handleInputChange}
                  style={{
                    borderColor: currentMailError ? 'red' : '',
                  }}
                />
                {currentMailError && (
                  <div className="error-message">
                    {currentMailError}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row-set" id="mail">
            <div className="input-group">
              <label>新しいメールアドレス</label>
              <div className={`error-message-container ${newMailError ? 'has-error' : ''}`}>
                <input
                  type="text"
                  name="newMail"
                  value={mailFormData.newMail}
                  onChange={handleInputChange}
                  style={{
                    borderColor: newMailError ? 'red' : '',
                  }}
                />
                {newMailError && (
                  <div className="error-message">
                    {newMailError}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default MailChangeModal;