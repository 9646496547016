/* ==================== */
/* 電子サイン一覧画面 */
/* ==================== */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import BottomSheet from '../../components/BottomSheet/BottomSheet';
import DocumentModal from '../../components/DocumentModal/DocumentModal';
import FilterButton from '../../components/FilterButton/FilterButton';
import Loading from '../../components/Loading/Loading';
import Modal from '../../components/Modal/Modal';
import Pagination from '../../components/Pagination/Pagination';
import SortingButton from '../../components/SortingButton/SortingButton';
import useWindowSize from '../../hook/useWindowSize';
import { E_SIGNATURE_DOWNLOAD_TYPE, ESIGN_OPTION, RESPONSE } from '../../const/Enum';
import { requestApiLoad } from '../../utils/apiLoadUtil';
import './ESignatureList.css';

const ESignatureList = () => {
  const [itemList, setItemList] = useState([]);
  const [detailItem, setDetailItem] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [selectDocument, setSelectDocument] = useState({});
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [isInit, setIsInit] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const bottomSheetRef = useRef(null);
  const size = useWindowSize();

  /* メイン一覧画面 */
  const [mainSearchTerm, setMainSearchTerm] = useState('');
  const [mainStatusFilter, setMainStatusFilter] = useState(0);
  const [mainSortConfig, setMainSortConfig] = useState({ key: 'eSignatureId', direction: true });
  const [mainCurrentPage, setMainCurrentPage] = useState(1);
  const [mainLastPage, setMainLastPage] = useState(1);
  const [mainTotalCount, setMainTotalCount] = useState(0);
  const mainStatusOptions = [
    { value: 0, label: 'すべて' },
    { value: 1, label: '使用中' },
    { value: 2, label: '未使用' }
  ];
  const mainSortOptions = [
    { key: 'eSignatureId', value: { key: 'eSignatureId', direction: true }, label: '標準' },
    { key: 'templateName_asc', value: { key: 'templateName', direction: false }, label: 'テンプレート名（昇順）' },
    { key: 'templateName_desc', value: { key: 'templateName', direction: true }, label: 'テンプレート名（降順）' },
  ];
  
  /* サブ一覧画面 */
  const [subSearchTerm, setSubSearchTerm] = useState('');
  const [subStatusFilter, setSubStatusFilter] = useState(0);
  const [subSortConfig, setSubSortConfig] = useState({ key: 'staffId', direction: true });
  const [subCurrentPage, setSubCurrentPage] = useState(1);
  const [subLastPage, setSubLastPage] = useState(1);
  const [subTotalCount, setSubTotalCount] = useState(0);
  const subSortOptions = [
    { key: 'staffId', value: { key: 'staffId', direction: true }, label: '標準' },
    { key: 'staffName_asc', value: { key: 'staffName', direction: false }, label: 'スタッフ名（昇順）' },
    { key: 'staffName_desc', value: { key: 'staffName', direction: true }, label: 'スタッフ名（降順）' },
    { key: 'signDate_asc', value: { key: 'signDate', direction: false }, label: '署名日（昇順）' },
    { key: 'signDate_desc', value: { key: 'signDate', direction: true }, label: '署名日（降順）' }
  ];
  const subStatusOptions = [
    { value: 0, label: 'すべて' },
    { value: 1, label: '署名済み' },
    { value: 2, label: '未署名' },
  ];
  
  /* 画面状態 */
  const [showStaff, setShowStaff] = useState(false);

  // ------------------------------------------------------------------------------------
  // 初期処理
  // ------------------------------------------------------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        page: 1,
        isDesc: mainSortConfig.direction,
        orderKey: mainSortConfig.key,
        status: mainStatusFilter,
        name: mainSearchTerm,
      }

      const res = await requestApiLoad('/eSignatureSetting/get', params, setIsLoading);
      if (res.return !== RESPONSE.SUCCESS) {
        return;
      }

      setSelectedItem((res.eSignatureList && res.eSignatureList.length > 0) ? res.eSignatureList[0] : {});
      setItemList((res.eSignatureList && res.eSignatureList.length > 0) ? res.eSignatureList : []);
      setMainCurrentPage(1);
      setMainLastPage(res.lastPage);
      setMainTotalCount(res.dataCount);
    };
    fetchData();
  }, [mainStatusFilter, mainSortConfig.direction, mainSortConfig.key, mainSearchTerm]);

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedItem || Object.keys(selectedItem).length === 0 ||
        (selectedItem.eSignatureId === null || selectedItem.eSignatureId === undefined)) {
        return;
      }

      const params = {
        page: 1,
        isDesc: subSortConfig.direction,
        orderKey: subSortConfig.key,
        status: subStatusFilter,
        name: subSearchTerm,
      }
    
      const res = await requestApiLoad(`/eSignatureSetting/getStaff/${selectedItem.eSignatureId}`, params, setIsLoading);
      if (res.return !== RESPONSE.SUCCESS) {
        return;
      }

      const staffList = res.staffList.map(staff => ({
        id: staff.staffId,
        name: staff.staffName,
        signDate: staff.signDate,
        signStatus: staff.signStatus,
        eSignatureList: staff.eSignatureList,
      }));
    
      setDetailItem(staffList);
      setSubCurrentPage(1);
      setSubLastPage(res.lastPage);
      setSubTotalCount(res.dataCount);
    }
    
    fetchData();
  }, [selectedItem, subStatusFilter, subSortConfig.direction, subSortConfig.key, subSearchTerm]);

  useEffect(() => {
    const subInit = () => {
      if (!isInit) { return; }

      if (itemList.length > 0) {
        if (size.width > 1200) {
          handleStaffView(itemList[0]);
          ChangeScreenMode(MODE.STAFF);
        } else {
          ChangeScreenMode(MODE.NONE);
        }
        setIsInit(false);
      }
    }
    subInit();
    // eslint-disable-next-line
  }, [itemList]);

  useEffect(() => {
    createDetailTable();
    // eslint-disable-next-line
  }, [detailItem]);

  // ------------------------------------------------------------------------------------
  // 画面モード
  // ------------------------------------------------------------------------------------
  const MODE = {
    NONE: 'NONE',
    STAFF: 'STAFF',
  }

  const ChangeScreenMode = (mode) => {
    switch (mode) {
      case MODE.STAFF:
        setShowStaff(true);
        break;
      
      case MODE.NONE:
      default:
        setShowStaff(false);
        break;
    }
  }

  // ------------------------------------------------------------------------------------
  // ページネーション
  // ------------------------------------------------------------------------------------
  const handleMainPageChange = async (pageNumber) => {
    setMainCurrentPage(pageNumber);
    
    const params = {
      page: pageNumber,
      isDesc: mainSortConfig.direction,
      orderKey: mainSortConfig.key,
      status: mainStatusFilter,
      name: mainSearchTerm,
    }
    
    const res = await requestApiLoad(`/eSignatureSetting/get`, params, setIsLoading);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }

    setSelectedItem(res.eSignatureList[0]);
    setItemList(res.eSignatureList);
    setMainLastPage(res.lastPage);
    setMainTotalCount(res.dataCount);
  }
  
  const handleSubPageChange = async (pageNumber) => {
    setSubCurrentPage(pageNumber);
    
    const params = {
      page: pageNumber,
      isDesc: subSortConfig.direction,
      orderKey: subSortConfig.key,
      status: subStatusFilter,
      name: subSearchTerm,
    }
    
    const res = await requestApiLoad(`/eSignatureSetting/getStaff/${selectedItem.eSignatureId}`, params, setIsLoading);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }

    setDetailItem(res.report);
    setSubLastPage(res.report.lastPage);
    setSubTotalCount(res.report.dataCount);
  }

  // ------------------------------------------------------------------------------------
  // フィルター
  // ------------------------------------------------------------------------------------
  const handleMainStatusChange = async (event) => {
    const newStatusFilter = Number(event.target.value);
    setMainCurrentPage(1);
    setMainStatusFilter(newStatusFilter);
  }

  const handleSubStatusChange = async (event) => {
    const newStatusFilter = Number(event.target.value);
    setSubCurrentPage(1);
    setSubStatusFilter(newStatusFilter);
  }

  const sortedAndFilteredItems = useMemo(() => {
    let filteredItems = itemList || [];
    return filteredItems;
  }, [itemList]);

  // ------------------------------------------------------------------------------------
  // ソート
  // ------------------------------------------------------------------------------------
  const handleMainSortChange = async (event) => {
    const newSortConfig = !event ? { key: 'eSignatureId', direction: true } : JSON.parse(event);
    setMainSortConfig(newSortConfig);
  }
  
  const handleSubSortChange = async (event) => {
    const newSortConfig = !event ? { key: 'staffId', direction: true } : JSON.parse(event);
    setSubSortConfig(newSortConfig);
  }

  // ------------------------------------------------------------------------------------
  // 検索
  // ------------------------------------------------------------------------------------
  const onMainClear = () => {
    setMainSearchTerm('');
  }

  const onSubClear = () => {
    setSubSearchTerm('');
  }

  // ------------------------------------------------------------------------------------
  // 署名者一覧
  // ------------------------------------------------------------------------------------
  const handleStaffView = async (item) => {
    if (!item) { return; }
    
    ChangeScreenMode(MODE.STAFF);
    setSelectedItem(item);
  }

  // ------------------------------------------------------------------------------------
  // プレビュー
  // ------------------------------------------------------------------------------------
  const handleDocumentPreview = async (e, item) => {
    e.stopPropagation();
    if (!item) { return; }
    setSelectDocument({
      documentName: item.documentName,
      filePath: item.filePath,
    });
    setIsDocumentModalOpen(true);
  }

  // ------------------------------------------------------------------------------------
  // ダウンロード
  // ------------------------------------------------------------------------------------
  const handleDownloadFile = async (item, downloadType) => {
    if (!selectedItem || (downloadType === E_SIGNATURE_DOWNLOAD_TYPE.STAFF && !item)) { return; }

    if (downloadType === E_SIGNATURE_DOWNLOAD_TYPE.STAFF && Number(item.signStatus) === ESIGN_OPTION.UNSIGN) {
      return;
    }
 
    if (downloadType === E_SIGNATURE_DOWNLOAD_TYPE.ALL) {
      const allUnsigned = detailItem.every(item => Number(item.signStatus) === ESIGN_OPTION.UNSIGN);
      if (allUnsigned) {
        alert('署名済みのスタッフがいないため、ダウンロードできません。');
        return;
      }
    }  
  
    const params = {
      eSignatureId: selectedItem.eSignatureId,
      staffId: downloadType === E_SIGNATURE_DOWNLOAD_TYPE.STAFF ? item.id : null,
      downloadType: downloadType,
    }
    
    try {
      const res = await requestApiLoad('/eSignatureSetting/downloadFiles', params, setIsLoading);
      if (res.return !== RESPONSE.SUCCESS) {
        alert('ファイルのダウンロードに失敗しました');
        return;
      }
      
      const fileData = {
        data: res.zipData,
        name: res.zipName,
      }

      // Base64デコード
      const byteCharacters = atob(fileData.data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/zip' });

      // ダウンロード処理
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${fileData.name}.zip` || `${selectedItem.templateName}_${item.name}.zip`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      
    } catch (error) {
      console.error('ダウンロードエラー:', error);
      alert('ファイルのダウンロードに失敗しました。');
    }
  }

  // ------------------------------------------------------------------------------------
  // ValidationCheck
  // ------------------------------------------------------------------------------------
  // Viewのため、チェックなし

  // ------------------------------------------------------------------------------------
  // 一覧作成
  // ------------------------------------------------------------------------------------ 
  const createTable = (itemList) => {
    return (
      <div className="card-list">
        {itemList.length === 0 ? (
          <div className="no-data">データがありません</div>
        ) : (
          itemList.map((item, index) => (
            <div className="card" key={`${item.eSignatureId}-${index}`} onClick={() => handleStaffView(item)}>
              <div className="card-contents" id="e-signature-list-card-contents">
                <div className="card-contents-left">
                  <h3 className="card-title">
                    {item.templateName}
                    {item.publishCompanyList?.length > 0 && (
                      <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <svg
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          width="17px"
                          height="17px"
                          viewBox="0 0 24 24"
                          aria-labelledby="peopleIconTitle"
                          stroke="var(--blue-font-color)"
                          strokeWidth="1.7"
                          strokeLinecap="square"
                          strokeLinejoin="miter"
                          fill="none"
                          color="var(--blue-font-color)">
                          <title id="peopleIconTitle">公開顧客</title>
                          <path d="M1 18C1 15.75 4 15.75 5.5 14.25 6.25 13.5 4 13.5 4 9.75 4 7.25025 4.99975 6 7 6 9.00025 6 10 7.25025 10 9.75 10 13.5 7.75 13.5 8.5 14.25 10 15.75 13 15.75 13 18M12.7918114 15.7266684C13.2840551 15.548266 13.6874862 15.3832994 14.0021045 15.2317685 14.552776 14.9665463 15.0840574 14.6659426 15.5 14.25 16.25 13.5 14 13.5 14 9.75 14 7.25025 14.99975 6 17 6 19.00025 6 20 7.25025 20 9.75 20 13.5 17.75 13.5 18.5 14.25 20 15.75 23 15.75 23 18" />
                          <path strokeLinecap="round" d="M12,16 C12.3662741,15.8763472 12.6302112,15.7852366 12.7918114,15.7266684" />
                        </svg>
                      </span>
                    )}
                  </h3>
                  {item.documentList.map((doc, index) => (
                    <div key={`${item.eSignatureId}-${index}`} className="document-info">
                      <span className="document-name">{doc.documentName}</span>
                      <div className="document-buttons">
                        <button className="blue" title="プレビュー" onClick={(e) => handleDocumentPreview(e, doc)}>
                          <svg
                            width="20px"
                            height="20px"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-labelledby="eyeIconTitle"
                            stroke="var(--blue-font-color)"
                            strokeWidth="2.0"
                            strokeLinecap="square"
                            strokeLinejoin="miter"
                            fill="none"
                            color="var(--blue-font-color)">
                            <title id="eyeIconTitle">プレビュー</title>
                            <path d="M22 12C22 12 19 18 12 18C5 18 2 12 2 12C2 12 5 6 12 6C19 6 22 12 22 12Z" />
                            <circle cx="12" cy="12" r="3" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    );
  }

  // ------------------------------------------------------------------------------------
  // DetailTable
  // ------------------------------------------------------------------------------------
  const createDetailTable = (detailItem) => {
    return (
      <div className="card-list">
        {!detailItem || detailItem.length === 0 || !Array.isArray(detailItem) ? (
          <div className="no-data">データがありません</div>
        ) : (
          detailItem.map((item) => (
            <div className="card" key={item.id}>
              <div className="card-contents">
                <div className="card-contents-left">
                  <h3>{item.name}</h3>
                  <span className="sub-text">
                    署名日：{item.signDate}
                  </span>
                </div>
                <div className="card-footer">
                  {/* ダウンロード */}
                  <button
                    className="blue"
                    onClick={() => handleDownloadFile(item, E_SIGNATURE_DOWNLOAD_TYPE.STAFF)}
                    disabled={Number(item.signStatus) === ESIGN_OPTION.UNSIGN}>
                    <svg
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      aria-labelledby="downloadIconTitle"
                      stroke={Number(item.signStatus) === ESIGN_OPTION.UNSIGN ? 'var(--disable-font-color)' : 'var(--blue-font-color)'}
                      strokeWidth="1.9"
                      strokeLinecap="square"
                      strokeLinejoin="miter"
                      fill="none"
                      color={Number(item.signStatus) === ESIGN_OPTION.UNSIGN ? 'var(--disable-font-color)' : 'var(--blue-font-color)'}>
                      <title id="downloadIconTitle">ダウンロード</title>
                      <path d="M12,3 L12,16" />
                      <polyline points="7 12 12 17 17 12" />
                      <path d="M20,21 L4,21" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    )
  }

  // ------------------------------------------------------------------------------------
  // レンダリング
  // ------------------------------------------------------------------------------------
  return (
    <div className="view-contents" id="e-signature-list">
      <Loading isLoading={isLoading} />
      <BottomSheet ref={bottomSheetRef} />

      <div className="main-contents">
        {size.width > 1200 && (<h2 className="page-title">電子サイン一覧</h2>)}
        <div className="header-contents">
          <div className="search-bar">
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              <input
                type="text"
                placeholder="テンプレート名で検索"
                value={mainSearchTerm || ''}
                onChange={(e) => setMainSearchTerm(e.target.value)}
                maxLength={20}
                style={{
                  backgroundImage: 'url(/images/search.png)',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '10px center',
                  backgroundSize: '20px 20px',
                  paddingLeft: '40px',
                  paddingRight: mainSearchTerm ? '30px' : '10px'
                }}
              />
              {mainSearchTerm && (
                <button
                  onClick={onMainClear}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '16px',
                    color: '#878787'
                  }}>
                  ×
                </button>
              )}
            </div>
          </div>
          <div className="button-container">
           
          </div>
        </div>

        <div className="middle-contents">
          <div className="left-contents">
            <FilterButton
              statusFilter={mainStatusFilter}
              handleStatusChange={handleMainStatusChange}
              statusOptions={mainStatusOptions}
            />
            <SortingButton
              sortConfig={mainSortConfig}
              handleSortChange={handleMainSortChange}
              sortOptions={mainSortOptions}
            />
          </div>
          <Pagination
            totalPages={mainLastPage}
            currentPage={mainCurrentPage}
            onPageChange={handleMainPageChange}
            totalItems={mainTotalCount}
          />
        </div>

        {createTable(sortedAndFilteredItems)}
      </div>

      {size.width > 1200 ? (
        <>
          {showStaff && (
            <div className="sub-contents">
              <h2 className="page-title">{selectedItem.templateName}</h2>
              <div className="header-contents">
                <div className="search-bar">
                  <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                    <input
                      type="text"
                      placeholder="スタッフ名で検索"
                      value={subSearchTerm}
                      onChange={(e) => setSubSearchTerm(e.target.value)}
                      maxLength={20}
                      style={{
                        backgroundImage: 'url(/images/search.png)',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '10px center',
                        backgroundSize: '20px 20px',
                        paddingLeft: '40px',
                        paddingRight: subSearchTerm ? '30px' : '10px'
                      }}
                    />
                    {subSearchTerm && (
                      <button
                        onClick={onSubClear}
                        style={{
                          position: 'absolute',
                          right: '10px',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                          fontSize: '16px',
                          color: '#878787'
                        }}>
                        ×
                      </button>
                    )}
                  </div>
                </div>
                <div className="button-container">
                  <div
                    className="add-new-item-button"
                    onClick={() => handleDownloadFile(null, E_SIGNATURE_DOWNLOAD_TYPE.ALL)}>
                    <svg
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      aria-labelledby="downloadIconTitle"
                      stroke="var(--blue-font-color)"
                      strokeWidth="1.9"
                      strokeLinecap="square"
                      strokeLinejoin="miter"
                      fill="none" color="#007bff">
                      <title id="downloadIconTitle">ダウンロード</title>
                      <path d="M12,3 L12,16" />
                      <polyline points="7 12 12 17 17 12" />
                      <path d="M20,21 L4,21" />
                    </svg>
                    <p className="hide-option">ダウンロード</p>
                  </div>
                </div>
              </div>

              <div className="middle-contents">
                <div className="left-contents">
                  <FilterButton
                    statusFilter={subStatusFilter}
                    handleStatusChange={handleSubStatusChange}
                    statusOptions={subStatusOptions}
                  />
                  <SortingButton
                    sortConfig={subSortConfig}
                    handleSortChange={handleSubSortChange}
                    sortOptions={subSortOptions}
                  />
                </div>
                <Pagination
                  totalPages={subLastPage}
                  currentPage={subCurrentPage}
                  onPageChange={handleSubPageChange}
                  totalItems={subTotalCount}
                />
              </div>

              {createDetailTable(detailItem)}
            </div>
          )}
        </>
      ) : (
        <>
          <Modal
            isOpen={showStaff}
            title={selectedItem.documentName}
            closeModal={() => setShowStaff(false)}
            closeButtonText="閉じる"
          >
            <div className="sub-contents">
              <div className="header-contents">
                <div className="search-bar">
                  <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                    <input
                      type="text"
                      placeholder="スタッフ名で検索"
                      value={subSearchTerm}
                      onChange={(e) => setSubSearchTerm(e.target.value)}
                      maxLength={20}
                      style={{
                        backgroundImage: 'url(/images/search.png)',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '10px center',
                        backgroundSize: '20px 20px',
                        paddingLeft: '40px',
                        paddingRight: subSearchTerm ? '30px' : '10px'
                      }}
                    />
                    {subSearchTerm && (
                      <button
                        onClick={onSubClear}
                        style={{
                          position: 'absolute',
                          right: '10px',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                          fontSize: '16px',
                          color: '#878787'
                        }}>
                        ×
                      </button>
                    )}
                  </div>
                </div>
                <div className="button-container">
                  <div
                    className="add-new-item-button"
                    onClick={() => handleDownloadFile(null, E_SIGNATURE_DOWNLOAD_TYPE.ALL)}>
                    <svg
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      aria-labelledby="downloadIconTitle"
                      stroke="var(--blue-font-color)"
                      strokeWidth="1.9"
                      strokeLinecap="square"
                      strokeLinejoin="miter"
                      fill="none" color="#007bff">
                      <title id="downloadIconTitle">ダウンロード</title>
                      <path d="M12,3 L12,16" />
                      <polyline points="7 12 12 17 17 12" />
                      <path d="M20,21 L4,21" />
                    </svg>
                    <p className="hide-option">ダウンロード</p>
                  </div>
                </div>
              </div>

              <div className="middle-contents">
                <div className="left-contents">
                  <FilterButton
                    statusFilter={subStatusFilter}
                    handleStatusChange={handleSubStatusChange}
                    statusOptions={subStatusOptions}
                  />
                  <SortingButton
                    sortConfig={subSortConfig}
                    handleSortChange={handleSubSortChange}
                    sortOptions={subSortOptions}
                  />
                </div>
                <Pagination
                  totalPages={subCurrentPage}
                  currentPage={subCurrentPage}
                  onPageChange={handleSubPageChange}
                  totalItems={subTotalCount}
                />
              </div>
              {createDetailTable(detailItem)}
            </div>
          </Modal>
        </>
      )}

      <DocumentModal
        isOpen={isDocumentModalOpen}
        closeModal={() => setIsDocumentModalOpen(false)}
        title={selectDocument.documentName}
        filePath={selectDocument.filePath}
        isDownload={false}
        isDownloadDisp={false}
        isPreview={true}
      />
    </div>
  )
}

export default ESignatureList;