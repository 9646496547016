/* ==================== */
/* 書類設定画面 */
/* ==================== */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import BottomSheet from '../../components/BottomSheet/BottomSheet';
import FilterButton from '../../components/FilterButton/FilterButton';
import PaperViewerModal from '../../components/PaperViewerModal/PaperViewerModal';
import Loading from '../../components/Loading/Loading';
import MiniSelectedView from '../../components/MiniSelectedView/MiniSelectedView';
import Modal from '../../components/Modal/Modal';
import PaperDetailModal from '../../components/PaperDetailModal/PaperDetailModal';
import PDFThumbnail from '../../components/PDFThumbnail/PDFThumbnail';
import SelectMultGroupModal from '../../components/SelectMultGroupModal/SelectMultGroupModal';
import SelectMultStaffModal from '../../components/SelectMultStaffModal/SelectMultStaffModal';
import Pagination from '../../components/Pagination/Pagination';
import SortingButton from '../../components/SortingButton/SortingButton';
import useWindowSize from '../../hook/useWindowSize';
import { MaxLength } from '../../const/Constant';
import { READ_STATUS, RESPONSE } from '../../const/Enum';
import { requestApiLoad, requestApiLoadAndBottom } from '../../utils/apiLoadUtil';
import * as Validators from '../../utils/validation';
import { convertPDFToBase64 } from '../../utils/fileUtil';
import './PaperSetting.css';

const PaperSetting = () => {
  const [itemList, setItemList] = useState([]);
  const [addItem, setAddItem] = useState([]);
  const [detailItem, setDetailItem] = useState([]);
  const [dragActive, setDragActive] = useState(false);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
  const [isStaffModalOpen, setIsStaffModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectGroups, setSelectGroups] = useState([]);
  const [selectStaffs, setSelectStaffs] = useState([]);
  const bottomSheetRef = useRef(null);
  const size = useWindowSize();

  /* メイン一覧画面 */
  const [mainSearchTerm, setMainSearchTerm] = useState('');
  const [mainStatusFilter, setMainStatusFilter] = useState(0);
  const [mainSortConfig, setMainSortConfig] = useState({ key: 'messageId', direction: true });
  const [mainTotalCount, setMainTotalCount] = useState(0);
  const [mainLastPage, setMainLastPage] = useState(1);
  const [mainCurrentPage, setMainCurrentPage] = useState(1);
  const mainStatusOptions = [
    { value: 0, label: '全て' },
    { value: 1, label: '自分が作成' },
    { value: 2, label: '他人が作成' },
  ];
  const mainSortOptions = [
    { key: 'messageId', value: { key: 'messageId', direction: true }, label: '標準' },
    { key: 'date_asc', value: { key: 'date', direction: false }, label: '日付（昇順）' },
    { key: 'date_desc', value: { key: 'date', direction: true }, label: '日付（降順）' }
  ];
  
  /* サブ一覧画面 */
  const [subSearchTerm, setSubSearchTerm] = useState('');
  const [subStatusFilter, setSubStatusFilter] = useState(0);
  const [subSortConfig, setSubSortConfig] = useState({ key: 'staffId', direction: true });
  const [subTotalCount, setSubTotalCount] = useState(0);
  const [subLastPage, setSubLastPage] = useState(1);
  const [subCurrentPage, setSubCurrentPage] = useState(1);

  const subSearchTerm_MaxLength = 20;
  const subStatusOptions = [
    { value: 0, label: '全て' },
    { value: 1, label: '開封' },
    { value: 2, label: '未開封' },
    { value: 3, label: '確認済み' },
    { value: 4, label: '未確認' },
  ];
  const subSortOptions = [
    { key: 'staffId', value: { key: 'staffId', direction: true }, label: '標準' },
    { key: 'staffName_asc', value: { key: 'staffName', direction: false }, label: 'スタッフ名（昇順）' },
    { key: 'staffName_desc', value: { key: 'staffName', direction: true }, label: 'スタッフ名（降順）' },
  ];

  /* 画面状態 */
  const [showAdd, setShowAdd] = useState(false);
  const [showView, setShowView] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [isInit, setIsInit] = useState(true);

  // 詳細モーダル
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [detailModalItem, setDetailModalItem] = useState(null);

  // 書類プレビュー
  const [selectedPaper, setSelectedPaper] = useState(null);
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);

  const SEND_TO = {
    ALL: 0,
    GROUP: 1,
    PERSONAL: 2,
  }
  // ------------------------------------------------------------------------------------
  // 初期処理
  // ------------------------------------------------------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        page: 1,
        isDesc: mainSortConfig.direction,
        orderKey: mainSortConfig.key,
        creatorType: mainStatusFilter,
        name: mainSearchTerm,
      };
  
      const res = await requestApiLoad('/messageList/get', params, setIsLoading);
      if (res.return !== RESPONSE.SUCCESS) {
        return;
      }
  
      resetSubScreen();
      setSelectedItem((res.messageList && res.messageList.length > 0) ? res.messageList[0] : []);
      setItemList((res.messageList && res.messageList.length > 0) ? res.messageList : []);
      setMainCurrentPage(1);
      setMainLastPage(res.lastPage);
      setMainTotalCount(res.dataCount);

      // 初期表示時のみ詳細画面を表示
      if (isInit) {
        handleView(null,res.messageList[0]);
        setIsInit(false);
      }
    };
    
    fetchData();
  }, [mainStatusFilter, mainSortConfig.direction, mainSortConfig.key, mainSearchTerm]);

  useEffect(() => {
    const fetchSubData = async () => {
      if (!selectedItem || selectedItem.length === 0 || !showDetail) {
        return;
      }

      const params = {
        page: 1,
        isDesc: subSortConfig.direction,
        orderKey: subSortConfig.key,
        status: subStatusFilter,
        name: subSearchTerm,
      }
      
      const res = await requestApiLoad(`/message/getResult/${selectedItem.messageId}`, params, setIsLoading);
      if (res.return !== RESPONSE.SUCCESS) {
        return;
      }

      setDetailItem(res.userList);
      setSubCurrentPage(1);
      setSubLastPage(res.lastPage);
      setSubTotalCount(res.dataCount);
    }

    fetchSubData();
    // eslint-disable-next-line
  }, [selectedItem, subStatusFilter, subSortConfig.direction, subSortConfig.key, subSearchTerm]);
  
  useEffect(() => {
    if (Number(addItem.sendType) === SEND_TO.GROUP) {
      setAddItem(prev => ({
        ...prev,
        sendToIds: selectGroups
      }));
    } else if (Number(addItem.sendType) === SEND_TO.PERSONAL) {
      setAddItem(prev => ({
        ...prev,
        sendToIds: selectStaffs
      }));
    }
  }, [selectGroups, selectStaffs, addItem.sendType]);
  
  // ------------------------------------------------------------------------------------
  // 画面モード
  // ------------------------------------------------------------------------------------
  const MODE = {
    NONE: 'NONE',
    ADD: 'ADD',
    VIEW: 'VIEW',
    DETAIL: 'DETAIL',
  }

  const changeScreenMode = (mode) => {
    switch (mode) {
      case MODE.ADD:
        setShowAdd(true);
        setShowView(false);
        setShowDetail(false);
        break;
      
      case MODE.VIEW:
        setShowAdd(false);
        setShowView(true);
        setShowDetail(false);
        break;
      
      case MODE.DETAIL:
        setShowAdd(false);
        setShowView(false);
        setShowDetail(true);
        break;
      
      case MODE.NONE:
      default:
        setShowAdd(false);
        setShowView(false);
        setShowDetail(false);
        break;
    }
  }

  const resetSubScreen = () => {
    setSubCurrentPage(1);
    setSubSearchTerm('');
    setSubStatusFilter(0); //全て
    setSubSortConfig({ key: 'staffId', direction: true });
  }

  // ------------------------------------------------------------------------------------
  // イベント
  // ------------------------------------------------------------------------------------
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'sendType') {
      // 送信先タイプが変更された場合、選択済みのグループ/スタッフをリセット
      setSelectGroups([]);
      setSelectStaffs([]);
      setAddItem(prev => ({
        ...prev,
        [name]: value,
        sendToIds: []
      }));
    } else {
      setAddItem(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  // ------------------------------------------------------------------------------------
  // ファイルドロップハンドラー
  // ------------------------------------------------------------------------------------
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    
    const files = e.dataTransfer.files;
    if (files && files[0] && files[0].type === 'application/pdf') {
      handleFileChange(files[0]);
    } else {
      alert('PDFファイルを選択してください。');
    }
  };

  const handleFileChange = async (file) => {
    if (file && file.type === 'application/pdf') {
      // PDFファイルの最大数をチェック
      if (addItem.paperList && addItem.paperList.length >= 3) {
        alert('PDFファイルは最大3つまでアップロードできます。');
        return;
      }
      // ファイルサイズをチェック
      if (file.size > 50 * 1024 * 1024) { // 50MB制限
        alert('ファイルサイズは50MB以下にしてください。');
        return;
      }
      // PDFファイル名の拡張子を除いた部分が26文字を超えていないかチェック
      const fileNameWithoutExtension = file.name.slice(0, file.name.lastIndexOf('.'));
      if (fileNameWithoutExtension.length > MaxLength.MESSAGE_PAPER_NAME_MAX_LENGTH) {
        alert(`PDFファイル名は${MaxLength.MESSAGE_PAPER_NAME_MAX_LENGTH}文字以内にしてください。（拡張子を除く）`);
        return;
      }

      try {
        // PDFファイルをBASE64に変換
        const base64Data = await convertPDFToBase64(file);
        
        setAddItem(prevState => ({
          ...prevState,
          paperList: [...(prevState.paperList || []), {
            paperId: Date.now(), // 一時的なID
            paperTitle: file.name,
            paperData: base64Data,
            paperPath: URL.createObjectURL(file)
          }]
        }));
      } catch (error) {
        alert('PDFファイルの変換に失敗しました。');
        return;
      }
    } else {
      alert('PDFファイルを選択してください。');
    }
  };

  const handleRemovePDF = (index) => {
    setAddItem(prevState => ({
      ...prevState,
      paperList: prevState.paperList.filter((_, i) => i !== index)
    }));
  };

  // ------------------------------------------------------------------------------------
  // ページネーション
  // ------------------------------------------------------------------------------------
  const handleMainPageChange = async (pageNumber) => {
    setMainCurrentPage(pageNumber);

    const params = {
      page: pageNumber,
      isDesc: mainSortConfig.direction,
      orderKey: mainSortConfig.key,
      creatorType: mainStatusFilter,
      name: mainSearchTerm,
    }

    const res = await requestApiLoad('/messageList/get', params, setIsLoading);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }
    
    setItemList(res.messageList);
    setSelectedItem(res.messageList[0]);
    setMainLastPage(res.lastPage);
    setMainTotalCount(res.dataCount);
  }

  const handleSubPageChange = async (pageNumber) => {
    setSubCurrentPage(pageNumber);
    
    if(selectedItem.length === 0) {
      return;
    }
    
    const params = {
      page: pageNumber,
      isDesc: subSortConfig.direction,
      orderKey: subSortConfig.key,
      status: subStatusFilter,
      name: subSearchTerm,
    }

    const res = await requestApiLoad(`/message/getResult/${selectedItem.messageId}`, params, setIsLoading);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }

    setDetailItem(res.userList);
    setSubLastPage(res.lastPage);
    setSubTotalCount(res.dataCount);
  }

  // ------------------------------------------------------------------------------------
  // フィルター
  // ------------------------------------------------------------------------------------
  const handleMainStatusChange = async (event) => {
    const newStatusFilter = !event.target.value ? 1 : Number(event.target.value);
    setMainCurrentPage(1);
    setMainStatusFilter(newStatusFilter);
  };

  const handleSubStatusChange = async (event) => {
    const newStatusFilter = !event.target.value ? READ_STATUS.ALL : Number(event.target.value);
    setSubCurrentPage(1);
    setSubStatusFilter(newStatusFilter);
  };

  const sortedAndFilteredItems = useMemo(() => {
    let filteredItems = itemList || [];
    return filteredItems;
  }, [itemList]);

  // ------------------------------------------------------------------------------------
  // ソート
  // ------------------------------------------------------------------------------------
  const handleMainSortChange = async (event) => {
    const newSortConfig = !event ? { key: 'paperId', direction: true } : JSON.parse(event);
    setMainSortConfig(newSortConfig);
  };

  const handleSubSortChange = async (event) => {
    const newSortConfig = !event ? { key: 'staffId', direction: true } : JSON.parse(event);
    setSubSortConfig(newSortConfig);
  };

  // ------------------------------------------------------------------------------------
  // 検索
  // ------------------------------------------------------------------------------------
  const onMainClear = () => {
    setMainSearchTerm('');
  };

  const onSubClear = () => {
    setSubSearchTerm('');
  };

  // ------------------------------------------------------------------------------------
  // 新規追加
  // ------------------------------------------------------------------------------------
  const handleAdd = async () => {
    setAddItem({
      title: '',
      contents: '',
      sendType: SEND_TO.ALL,
      sendToIds: [],
      paperList: [],
    });
    
    setShowAdd(true);
    setShowView(false);
    setShowDetail(false);
  }

  const handleAddCancel = async (event) => {
    event.preventDefault();
    
    setItemList(itemList);
    setMainLastPage(mainLastPage);
    setMainTotalCount(mainTotalCount);
    setSelectedItem(itemList[0]);

    if (!itemList || itemList?.length === 0) {
      setSelectedItem({});
      changeScreenMode(MODE.NONE);
    } else if (size.width <= 1200) {
      changeScreenMode(MODE.NONE);
    } else {
      changeScreenMode(MODE.VIEW);
    }
  }

  const handleAddSubmit = async (event) => {
    event.preventDefault();
    
    let sendToIds = [];
    if (Number(addItem.sendType) === SEND_TO.GROUP) {
      sendToIds = addItem.sendToIds.map(item => parseInt(item.groupId));
    } else if (Number(addItem.sendType) === SEND_TO.PERSONAL) {
      sendToIds = addItem.sendToIds.map(item => parseInt(item.staffId));
    }
    
    const params = {
      title: addItem.title,
      contents: addItem.contents,
      sendType: Number(addItem.sendType),
      sendToIds: sendToIds,
      paperList: addItem.paperList,
    }

    const validationResult = ValidationCheck(params);
    if (validationResult.error) {
      return alert(validationResult.message);
    }

    const res = await requestApiLoadAndBottom('/message/add',
      params, setIsLoading, bottomSheetRef, 'メッセージを送信しました。');
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }
    resetSubScreen();
    setFirstItem(res);

    if (size.width <= 1200) {
      changeScreenMode(MODE.NONE);
    } else {
      changeScreenMode(MODE.VIEW);
    }
  }

  // ------------------------------------------------------------------------------------
  // ビュー
  // ------------------------------------------------------------------------------------
  const handleView = async (e, item) => {
    if(e) {
      e.stopPropagation();
    }
    const res = await requestApiLoad(`/message/get/${item.messageId}`, {}, setIsLoading);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }

    setSelectedItem({
      title: res.title,
      contents: res.contents,
      senderName: res.senderName,
      date: res.date,
      sendType: res.sendType,
      sendToIds: res.sendToList,
      paperList: res.paperList.map(item => ({
        paperId: item.paperId,
        paperTitle: item.paperTitle,
        paperPath: `${process.env.REACT_APP_S3_URL}/${item.paperPath}`,
      })),
    });
    
    changeScreenMode(MODE.VIEW);
  };

  // ------------------------------------------------------------------------------------
  // 詳細
  // ------------------------------------------------------------------------------------
  const handleDetail = async (e, item) => {
    e.stopPropagation();
    if (!item) {
      return;
    }
    resetSubScreen();
    setSelectedItem(item);
    changeScreenMode(MODE.DETAIL);
  };

  const handleDetailItem = async (item) => {
    if (!item) {
      return;
    }
    setDetailModalItem(item);
    setIsDetailModalOpen(true);
  }

   // ------------------------------------------------------------------------------------
  // 書類プレビュー
  // ------------------------------------------------------------------------------------
  const handlePaperPreview = (paper) => {
    setSelectedPaper(paper);
    setIsDocumentModalOpen(true);
  };

  // ------------------------------------------------------------------------------------
  // その他
  // ------------------------------------------------------------------------------------ 
  const setFirstItem = (res) => {
    setItemList(res.messageList);
    setMainLastPage(res.lastPage);
    setMainTotalCount(res.dataCount);
    setSelectedItem(res.messageList[0]);
    // setViewItem(res.messageList[0]);
    
    setMainCurrentPage(1);
    setMainStatusFilter(Number(SEND_TO.ALL));
    setMainSearchTerm('');
    setMainSortConfig({ key: 'messageId', direction: true });
    resetScroll();
    resetScrollMain();
  }

  const resetScroll = () => {
    const scrollForm = document.querySelector('.scroll-form');
    if (scrollForm) {
      scrollForm.scrollTop = 0;
    }
  }

  const resetScrollMain = () => {
    const scrollForm = document.querySelector('.card-list');
    if (scrollForm) {
      scrollForm.scrollTop = 0;
    }
  }

  // ------------------------------------------------------------------------------------
  // ValidationCheck
  // ------------------------------------------------------------------------------------
  const ValidationCheck = (params) => {
    // タイトル
    const titleResult = Validators.validateMessageTitle(params.title);
    if (titleResult.error) {
      return { error: true, message: titleResult.message };
    }
    // メッセージ内容
    const contentsResult = Validators.validateMessageContents(params.contents);
    if (contentsResult.error) {
      return { error: true, message: contentsResult.message };
    }
    // 送信先
    console.log(params.sendType === SEND_TO.GROUP);
    console.log(params);
    if(params.sendType === SEND_TO.GROUP || params.sendType === SEND_TO.PERSONAL) {
      const sendToResult = Validators.validateMessageSendTo(params.sendToIds, params.sendType);
      if (sendToResult.error) {
        return { error: true, message: sendToResult.message };
      }
    }
    // 書類リスト
    const paperListResult = Validators.validatePaperList(params.paperList);
    if(paperListResult.error) {
      return { error: true, message: paperListResult.message };
    }
    return { error: false, message: '' };
  }

  // ------------------------------------------------------------------------------------
  // 一覧作成
  // ------------------------------------------------------------------------------------
  const createTable = (itemList) => {
    return (
      <div className="card-list">
        {itemList.length === 0 ? (
          <div className="no-data">データがありません</div>
        ) : (
          itemList.map((item) => (
            <div className="card" key={item.messageId} onClick={(e) => handleView(e, item)}>
              <div className="card-contents" id="paper-setting-card-contents">
                <div className="card-contents-left">
                  <div className="card-titles">
                    <h3>{item.title}</h3>
                    <span className="sub-text">{item.date}</span>
                  </div>
                  
                  <div className="sender-name-container">
                    <span className="sender-name">{item.senderName}</span>
                    <div className="paper-icon-list">
                      {[...Array(Number(item.paperCount))].map((_, index) => (
                        <svg
                          key={index}
                          width="20px"
                          height="20px"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          stroke="var(--card-sub-font-color)"
                          strokeWidth="1.8"
                          strokeLinecap="square"
                          strokeLinejoin="miter"
                          color="var(--card-sub-font-color)">
                          <path d="M14 3v4a1 1 0 001 1h4" />
                          <path d="M17 21H7a2 2 0 01-2-2V5a2 2 0 012-2h7l5 5v11a2 2 0 01-2 2z" />
                        </svg>
                      ))}
                    </div>
                  </div>

                </div>

                <div className="card-footer">
                  {/* 詳細 */}
                  <button className="blue" onClick={(e) => handleDetail(e, item)} title="詳細">
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-labelledby="pollIconTitle"
                      stroke="var(--blue-font-color)"
                      strokeWidth="1.8"
                      strokeLinecap="square"
                      strokeLinejoin="miter"
                      color="var(--blue-font-color)">
                      <title id="pollIconTitle">詳細</title>
                      <path d="M4 4V20" />
                      <path d="M4 6H15V10H4" />
                      <path d="M4 10H19V14H4" />
                      <path d="M4 14H12V18H4" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    );
  };

  // ------------------------------------------------------------------------------------
  // AddTable
  // ------------------------------------------------------------------------------------
  const createAddTable = (addItem) => {
    return (
      <>
        <div className="scroll-form">
          <div className="modal-form" id="paper-setting-form">
            <div className='column-set'>
              <div className="row-set" id="if-name">
                <div className="input-group">
                  <label>タイトル</label>
                  <input
                    type="text"
                    name="title"
                    value={addItem.title}
                    onChange={(e) => handleInputChange(e)}
                    placeholder="タイトル"
                  />
                </div>
              </div>
            </div>
            <div className="column-set">
              <div className="row-set">
                <div className="input-group">
                  <label>内容</label>
                  <textarea
                    name="contents"
                    value={addItem.contents}
                    onChange={(e) => handleInputChange(e)}
                    placeholder="メッセージ内容"
                    style={{
                      height: '300px',
                      width: '100%',
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row-set" id="if-name">
              <div className="input-group">
                <label>送信先</label>
                <select
                  name="sendType"
                  value={addItem.sendType}
                  onChange={(e) => handleInputChange(e)}
                >
                  <option value={0}>全員</option>
                  <option value={1}>指定グループ</option>
                  <option value={2}>指定スタッフ</option>
                </select>
              </div>
            </div>

            {Number(addItem.sendType) === SEND_TO.GROUP && (
              <div>
                <button
                  className='blue group-select'
                  type="button"
                  onClick={() => setIsGroupModalOpen(true)}
                >
                  {selectGroups === null || selectGroups.length === 0 ? 'グループ選択' : 'グループ追加'}
                </button>
                {selectGroups && selectGroups.length > 0 && (
                  <MiniSelectedView items={selectGroups} keyProp="groupId" displayProp="groupName" />
                )}
              </div>
            )}

            {Number(addItem.sendType) === SEND_TO.PERSONAL && (
              <div>
                <button
                  className='blue staff-select'
                  type="button"
                  onClick={() => setIsStaffModalOpen(true)}
                >
                  {selectStaffs === null || selectStaffs.length === 0 ? 'スタッフ選択' : 'スタッフ追加'}
                </button>
                {selectStaffs && selectStaffs.length > 0 && (
                  <MiniSelectedView items={selectStaffs} keyProp="staffId" displayProp="staffName" />
                )}
              </div>
            )}
            
            <div
              className="detail-papers"
              style={{ gap: addItem.paperList && addItem.paperList.length === 0 ? '0px' : '20px' }}>
              {/* 新規作成時existing-papersの左側にスペースが開くため、上記のような制御を入れる。 */}
              <div className='existing-papers'>
                {addItem.paperList && addItem.paperList.map((doc, index) => (
                  <div className="detail-paper" key={index}>
                    <div className="paper-preview">
                      <span className="paper-title">{doc.paperTitle}</span>
                      <PDFThumbnail pdfUrl={doc.paperPath} />
                      <button
                        className="delete-button"
                        onClick={() => handleRemovePDF(index)}
                        title="削除"
                      >
                        <svg
                          width="30px"
                          height="30px"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          stroke="#ff6b6b"
                          strokeWidth="1.5"
                          strokeLinecap="square"
                          strokeLinejoin="miter"
                        >
                          <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" />
                          <path d="M9 9L15 15" />
                          <path d="M15 9L9 15" />
                        </svg>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              
              {(addItem.paperList && addItem.paperList.length < 3) && (
                <div
                  className={`file-upload-area ${dragActive ? 'drag-active' : ''}`}
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                >
                  <input
                    type="file"
                    accept=".pdf"
                    onChange={(e) => handleFileChange(e.target.files[0])}
                    style={{ display: 'none' }}
                    id="file-upload"
                  />
                  <label htmlFor="file-upload" className="upload-label">
                    <div className="upload-icon">+</div>
                    <div className="upload-text">
                      新規ファイル追加
                      <p className="upload-subtext">
                        クリックしてファイルを選択するか<br />
                        ファイルをドラッグ＆ドロップしてください。
                      </p>
                      <p className="file-limit">※PDFファイルは50MB以内</p>
                    </div>
                  </label>
                </div>
              )}
            </div>
            <SelectMultGroupModal
              isOpen={isGroupModalOpen}
              setIsOpen={setIsGroupModalOpen}
              setSelectGroups={setSelectGroups}
              selectGroups={selectGroups}
            />
            <SelectMultStaffModal
              isOpen={isStaffModalOpen}
              setIsOpen={setIsStaffModalOpen}
              setSelectStaffs={setSelectStaffs}
              selectStaffs={selectStaffs}
            />
          </div>
        </div>
        <div className="bottom-button">
          <button onClick={handleAddCancel}>
            キャンセル
          </button>
          <button className="blue" onClick={handleAddSubmit}>
            送信
          </button>
        </div>
      </>
    )
  }

  // ------------------------------------------------------------------------------------
  // ViewTable
  // ------------------------------------------------------------------------------------
  const createViewTable = () => {
    return (
      <>
        <div className="scroll-form">
          <div className="modal-form" id="paper-setting-form">
            <div className='column-set'>
              <div className="row-set">
                <div className="input-group">
                  <label>送信者</label>
                  <input
                    type="text"
                    name="senderName"
                    value={selectedItem.senderName}
                    disabled
                  />
                </div>
                <div className="input-group">
                  <label>日付</label>
                  <input
                    type="text"
                    name="date"
                    value={selectedItem.date}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="column-set">
              <div className="row-set">
                <div className="input-group">
                  <label>内容</label>
                  <textarea
                    name="contents"
                    value={selectedItem.contents}
                    placeholder="メッセージ内容"
                    disabled
                    style={{
                      height: '300px',
                      width: '100%',
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row-set" id="if-name">
              <div className="input-group">
                <label>送信先</label>
                <select
                  name="sendType"
                  value={selectedItem.sendType}
                  disabled
                >
                  <option value={0}>全員</option>
                  <option value={1}>指定グループ</option>
                  <option value={2}>指定スタッフ</option>
                </select>
              </div>
            </div>
            {(Number(selectedItem.sendType) === SEND_TO.GROUP || Number(selectedItem.sendType) === SEND_TO.PERSONAL) && (
              <div className="row-set" id="if-name">
                <MiniSelectedView items={selectedItem.sendToIds} keyProp="id" displayProp="name" />
              </div>
            )}
            <div className="detail-papers">
              {selectedItem.paperList && selectedItem.paperList.map((doc, index) => (
                <div className="detail-paper" key={index}>
                  <div className="paper-preview" onClick={() => {handlePaperPreview(doc) }}>
                    <span className="paper-title">{doc.paperTitle}</span>
                    <PDFThumbnail pdfUrl={doc.paperPath} />
                  </div>
                </div>
              ))}
            </div>

            <SelectMultGroupModal
              isOpen={isGroupModalOpen}
              setIsOpen={setIsGroupModalOpen}
              setSelectGroups={setSelectGroups}
              selectGroups={selectGroups}
            />
            <SelectMultStaffModal
              isOpen={isStaffModalOpen}
              setIsOpen={setIsStaffModalOpen}
              setSelectStaffs={setSelectStaffs}
              selectStaffs={selectStaffs}
            />
          </div>
        </div>
      </>
    )
  }

  // ------------------------------------------------------------------------------------
  // DetailTable
  // ------------------------------------------------------------------------------------
  const createDetailTable = () => {
    return (
      <div className="card-list">
        {!selectedItem || selectedItem.length === 0 ||
          !detailItem || detailItem.length === 0 || !Array.isArray(detailItem) ? (
          <div className="no-data">データがありません</div>
        ) : (
          detailItem.map((item) => (
            <div className="card" key={item.userId} onClick={() => handleDetailItem(item)}>
              <div className="card-contents">
                <div className="card-contents-left">
                  <h3>{item.userName}</h3>
                </div>
                <div className="detail-card-footer">
                  <span className="detail-card-footer-item">開封日時：{item.readDate ? item.readDate : '-'}</span>
                  <span className="detail-card-footer-item">確認日時：{item.paperList?.reduce((latest, paper) => {
                    if (!paper.confirmDate) return latest;
                    if (!latest) return paper.confirmDate;
                    return paper.confirmDate > latest ? paper.confirmDate : latest;
                  }, null) || '-'}</span>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    )
  }

  // ------------------------------------------------------------------------------------
  // レンダリング
  // ------------------------------------------------------------------------------------
  return (
    <div className="view-contents" id="message-setting">
      <Loading isLoading={isLoading} />
      <BottomSheet ref={bottomSheetRef} />

      <div className="main-contents">
        {size.width > 1200 && (<h2 className="page-title">書類設定</h2>)}
        <div className="header-contents">
          <div className="search-bar">
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              <input
                type="text"
                placeholder="タイトルで検索"
                value={mainSearchTerm}
                onChange={(e) => setMainSearchTerm(e.target.value)}
                maxLength={20}
                style={{
                  backgroundImage: 'url(/images/search.png)',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '10px center',
                  backgroundSize: '20px 20px',
                  paddingLeft: '40px',
                  paddingRight: mainSearchTerm ? '30px' : '10px'
                }}
              />
              {mainSearchTerm && (
                <button
                  onClick={onMainClear}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '16px',
                    color: '#878787'
                  }}>
                  ×
                </button>
              )}
            </div>
          </div>
          <div className="button-container">
            <div className="add-new-item-button" onClick={handleAdd}>
              <svg
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                width="18px"
                height="18px"
                viewBox="0 0 24 24"
                aria-labelledby="plusIconTitle"
                stroke="var(--blue-font-color)"
                strokeWidth="1.8"
                strokeLinecap="square"
                strokeLinejoin="miter"
                fill="none"
                color="var(--blue-font-color)"
              >
                <title id="plusIconTitle">新規追加</title>
                <path d="M20 12L4 12M12 4L12 20" />
              </svg>
              <p className="hide-option">新規追加</p>
            </div>
          </div>
        </div>

        <div className="middle-contents">
          <div className="left-contents">
            <FilterButton
              statusFilter={mainStatusFilter}
              handleStatusChange={handleMainStatusChange}
              statusOptions={mainStatusOptions}
            />
            <SortingButton
              sortConfig={mainSortConfig}
              handleSortChange={handleMainSortChange}
              sortOptions={mainSortOptions}
            />
          </div>
          
          <Pagination
            totalPages={mainLastPage}
            currentPage={mainCurrentPage}
            onPageChange={handleMainPageChange}
            totalItems={mainTotalCount}
          />
        </div>

        {createTable(sortedAndFilteredItems)}
      </div>
      
      {size.width > 1200 ? (
        <>
          {showAdd && (
            <div className="sub-contents">
              <h2 className="page-title">書類追加</h2>
              {createAddTable(addItem)}
            </div>
          )}
          {showView && (
            <div className="sub-contents">
              <h2 className="page-title">{selectedItem.title}</h2>
              {createViewTable()}
            </div>
          )}
          {showDetail && (
            <div className="sub-contents">
              <h2 className="page-title">{selectedItem.title}</h2>
              <div className="header-contents">
                <div className="search-bar">
                  <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                    <input
                      type="text"
                      placeholder="スタッフ名で検索"
                      value={subSearchTerm}
                      onChange={(e) => setSubSearchTerm(e.target.value)}
                      maxLength={subSearchTerm_MaxLength}
                      style={{
                        backgroundImage: 'url(/images/search.png)',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '10px center',
                        backgroundSize: '20px 20px',
                        paddingLeft: '40px',
                        paddingRight: subSearchTerm ? '30px' : '10px'
                      }}
                    />
                    {subSearchTerm && (
                      <button
                        onClick={onSubClear}
                        style={{
                          position: 'absolute',
                          right: '10px',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                          fontSize: '16px',
                          color: '#878787'
                        }}>
                        ×
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="middle-contents">
                <div className="left-contents">
                  <FilterButton
                    statusFilter={subStatusFilter}
                    handleStatusChange={handleSubStatusChange}
                    statusOptions={subStatusOptions}
                  />
                  <SortingButton
                    sortConfig={subSortConfig}
                    handleSortChange={handleSubSortChange}
                    sortOptions={subSortOptions}
                  />
                </div>
                <Pagination
                  totalPages={subLastPage}
                  currentPage={subCurrentPage}
                  onPageChange={handleSubPageChange}
                  totalItems={subTotalCount}
                />
              </div>

              {createDetailTable()}
            </div>
          )}
        </>
      ) : (
        <>
          <Modal
            isOpen={showAdd}
            title="書類追加"
            onAction={handleAddSubmit}
            actionButtonText="送信"
            closeModal={() => setShowAdd(false)}
            closeButtonText="閉じる"
          >
            <div className="sub-contents">
              <div>
                  
              </div>
              {createAddTable(addItem)}
            </div>
          </Modal>
            
          <Modal
            isOpen={showView}
            title={selectedItem.title}
            closeModal={() => setShowView(false)}
            closeButtonText="閉じる"
          >
            <div className="sub-contents">
              <div>
                  
              </div>
              {createViewTable(selectedItem)}
            </div>
          </Modal>
            
          <Modal
            isOpen={showDetail}
            title={selectedItem.title}
            closeModal={() => setShowDetail(false)}
            closeButtonText="閉じる"
          >
            <div className="sub-contents">
              <div className="header-contents">
                <div className="search-bar">
                  <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                    <input
                      type="text"
                      placeholder="スタッフ名で検索"
                      value={subSearchTerm}
                      onChange={(e) => setSubSearchTerm(e.target.value)}
                      maxLength={subSearchTerm_MaxLength}
                      style={{
                        backgroundImage: 'url(/images/search.png)',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '10px center',
                        backgroundSize: '20px 20px',
                        paddingLeft: '40px',
                        paddingRight: subSearchTerm ? '30px' : '10px'
                      }}
                    />
                    {subSearchTerm && (
                      <button
                        onClick={onSubClear}
                        style={{
                          position: 'absolute',
                          right: '10px',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                          fontSize: '16px',
                          color: '#878787'
                        }}>
                        ×
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="middle-contents">
                <div className="left-contents">
                  <FilterButton
                    statusFilter={subStatusFilter}
                    handleStatusChange={handleSubStatusChange}
                    statusOptions={subStatusOptions}
                  />
                  <SortingButton
                    sortConfig={subSortConfig}
                    handleSortChange={handleSubSortChange}
                    sortOptions={subSortOptions}
                  />
                </div>
                <Pagination
                  totalPages={subLastPage}
                  currentPage={subCurrentPage}
                  onPageChange={handleSubPageChange}
                  totalItems={subTotalCount}
                />
              </div>
              {createDetailTable()}
            </div>
          </Modal>
        </>
      )}
      <PaperDetailModal
        isOpen={isDetailModalOpen}
        closeModal={() => setIsDetailModalOpen(false)}
        item={detailModalItem}
      />
    <PaperViewerModal
        isOpen={isDocumentModalOpen}
        closeModal={() => setIsDocumentModalOpen(false)}
        title={selectedPaper?.paperTitle}
        pdfUrl={selectedPaper?.paperPath}
        onConfirm={() => { }}
        isAdmin={true}
      />
    </div>
  )
}

export default PaperSetting;