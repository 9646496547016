import React from 'react';
import './ToggleSwitch.css';

const ToggleSwitch = ({ name, checked, onChange, disabled, label }) => (
  <div>
    <label className={`toggle-switch-container ${disabled ? 'disabled' : ''}`}>
      <div className="toggle-switch">
        <input
          type="checkbox"
          name={name}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        <span className="slider"></span>
      </div>
      <p>{label}</p>
    </label>
  </div>
);

export default ToggleSwitch;