import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';


const PrivateRoute = ({ component: Component, allowedRoles }) => {
  const { userData } = useUser();
  
  if (!userData) {
    return <Navigate to="/" replace />;
  }

  if (!allowedRoles.includes(userData.role)) {
    return <Navigate to="/" replace />;
  }

  return <Component />;
};

export default PrivateRoute; 