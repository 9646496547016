/* ==================== */
/* 管理者選択モーダル */
/* ==================== */
import React, { useEffect, useState } from 'react';
import Loading from '../Loading/Loading';
import Modal from '../Modal/Modal';
import { MODAL_TYPE, RESPONSE } from '../../const/Enum';
import { requestApiLoad } from '../../utils/apiLoadUtil';
import './SelectAdminModal.css';

const SelectAdminModal = ({ isOpen, setIsOpen, onSelectAdmin }) => {
  const [adminList, setAdminList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const fetchData = async () => {
        const params = {
          orderKey: 'adminId',
          isDesc: false,
          status: 1,
        }

        const res = await requestApiLoad('/admin/get', params, setIsLoading);
        if (res.return !== RESPONSE.SUCCESS) {
          return;
        }
        setAdminList(res.adminList);
      }
      fetchData();
    }
  }, [isOpen]);

  const selectAdmin = (admin) => {
    onSelectAdmin(admin);
    setIsOpen(false);
  };
  
  return (
    <Modal
      title="リクエスト先選択"
      isOpen={isOpen}
      closeModal={() => setIsOpen(false)}
      playerKey={MODAL_TYPE.SELECT}
      id="select-admin-modal"
    >
      <div className="select-admin-modal">
        <Loading isLoading={isLoading} />
      
        <div className="card-list" id="modal-card-list">
          {adminList.length === 0 ? (
            <div className="no-data">データがありません</div>
          ) : (
            adminList.map((item) => (
              <div
                className="card"
                key={item.adminId || 'no-admin'}
                onClick={() => selectAdmin(item)}
              >
                <div className="card-contents">
                  <div className="card-contents-left">
                    <h3>{item.adminName}</h3>

                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </Modal>
  )
}

export default SelectAdminModal;