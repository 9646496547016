//不完全な日付の場合リセットする関数
export const handleDateBlur = (event, setFormData) => {
  const { name, value, type } = event.target;
  if (type === 'date') {
    if (!value) {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: ''
      }));
      event.target.value = '';
    }
  }
};