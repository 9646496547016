import React, { useState, useEffect } from 'react';
import './Toast.css';

const Toast = ({ message, isVisible, onClose }) => {
  const [shouldRender, setShouldRender] = useState(isVisible);

  useEffect(() => {
    if (isVisible) setShouldRender(true);
  }, [isVisible]);

  const onAnimationEnd = () => {
    if (!isVisible) setShouldRender(false);
  };

  if (!shouldRender) return null;

  return (
    <div
      className={`toast-overlay ${isVisible ? '' : 'fadeOut'}`}
      onClick={onClose}
      onAnimationEnd={onAnimationEnd}
    >
      <div className="toast-container">
        <svg className="paper-plane" viewBox="0 0 24 24" width="48" height="48">
          <path d="M21.71 3.29a1 1 0 0 0-1.42 0l-9.29 9.29-4.29-4.29a1 1 0 0 0-1.42 1.42l5 5a1 1 0 0 0 1.42 0l10-10a1 1 0 0 0 0-1.42z" fill="#ffffff"/>
        </svg>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default Toast;