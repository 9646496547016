/* ==================== */
/* 電子署名選択モーダル */
/* ==================== */
import React, { useEffect, useState } from 'react';
import Loading from '../Loading/Loading';
import Modal from '../Modal/Modal';
import { MODAL_TYPE, RESPONSE } from '../../const/Enum';
import { requestApiLoad } from '../../utils/apiLoadUtil';
import './SelectESignatureModal.css';

const SelectESignatureModal = ({ isOpen, setIsOpen, setSelectESignature }) => {
  const [eSignatireList, setSignatureList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const fetchData = async () => {
        const res = await requestApiLoad('/eSignature/get', {}, setIsLoading);
        if (res.return !== RESPONSE.SUCCESS) {
          return;
        }
        setSignatureList(res.eSignatureList);
      }
      fetchData();
    }
  }, [isOpen]);

  const onSelectJob = (item) => {
    const selectedESignature = eSignatireList.find((eSignature) => eSignature.eSignatureId === item.eSignatureId);
    setSelectESignature(selectedESignature);
    setIsOpen(false);
  };
  
  return (
    <Modal
      title="電子サイン選択"
      isOpen={isOpen}
      closeButtonText="キャンセル"
      closeModal={() => setIsOpen(false)}
      playerKey={MODAL_TYPE.SELECT}
      id="select-esignature-modal"
    >
      <div className="select-esignature-modal">
        <Loading isLoading={isLoading} />
      
        <div className="card-list" id="modal-card-list">
          {eSignatireList.length === 0 ? (
            <div className="no-data">データがありません</div>
          ) : (
            eSignatireList.map((item) => (
              <div className="card" key={item.eSignatureId} onClick={() => onSelectJob(item)}>
                <div className="card-contents">
                  <div className="card-contents-left">
                    <h3 className="card-title">
                      <span></span>
                      {item.templateName}
                    </h3>
                    <span className="sub-text"></span>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </Modal>
  )
}

export default SelectESignatureModal;