/* ==================== */
/* 会社選択モーダル */
/* ==================== */
import React, { useEffect, useState } from 'react';
import { CONTRACT_STATUS, MODAL_TYPE, RESPONSE } from '../../const/Enum';
import Loading from '../Loading/Loading';
import Modal from '../Modal/Modal';
import { requestApiLoad } from '../../utils/apiLoadUtil';
import './SelectCompanyModal.css';

const SelectCompanyModal = ({ isOpen, setIsOpen, setSelectCompany }) => {
  const [companyList, setCompanyList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const fetchData = async () => {
        const res = await requestApiLoad('/company/get', {}, setIsLoading);
        if (res.return !== RESPONSE.SUCCESS) {
          return;
        }
        setCompanyList(res.companyList.filter(company => company.contractStatus !== CONTRACT_STATUS.INACTIVE));
      }
      fetchData();
    }
  }, [isOpen]);

  const onSelectCompany = (item) => {
    if(item.activeAdminCount >= item.limitAdminCount){
      alert('アカウント数が契約プランの上限に達しています。');
      return;
    }
    setSelectCompany(item);
    setIsOpen(false);
  };
  
  return (   
    <Modal
      title="会社選択"
      isOpen={isOpen}
      closeModal={() => setIsOpen(false)}
      playerKey={MODAL_TYPE.SELECT}
      id="select-company-modal"
    >
      <div className="select-company-modal">
        <Loading isLoading={isLoading} />
      
        <div className="card-list" id="modal-card-list">
        {companyList.length === 0 ? (
          <div className="no-data">データがありません</div>
        ) : (
          companyList.map((item) => (
            <div className="card" key={item.companyId} onClick={() => onSelectCompany(item)}>
              <div className="card-contents">
                <div className="card-contents-left">
                  <h3>{item.companyName}</h3>
                  <span className="sub-text">{item.contractStartDate}~{item.contractEndDate}
                    ({item.contractStatus === CONTRACT_STATUS.ACTIVE ? '契約中' :
                      item.contractStatus === CONTRACT_STATUS.PREPARING ? '契約前' : '契約終了'})</span>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      </div>
    </Modal>
  )
}

export default SelectCompanyModal;