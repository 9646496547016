import { MaxLength } from '../const/Constant';
import { validateInput, validateDate, validateDateRange } from './validation';

/* ------------------------------------------------------------------------------------ */
/* 【共通】日付の整合性チェック（必須項目）
/* ------------------------------------------------------------------------------------ */
export const validateDateCheck = (value) => {
  const dateRangeResult = validateDate(value);
  return dateRangeResult.error ? dateRangeResult.message : '';
};

/* ------------------------------------------------------------------------------------ */
/* 【共通】日付の範囲チェック
/* ------------------------------------------------------------------------------------ */
export const validateDateRangeCheck = (value) => {
  const dateRangeResult = validateDateRange(value);
  return dateRangeResult.error ? dateRangeResult.message : '';
};

/* ------------------------------------------------------------------------------------ */
/* パスワード
/* ------------------------------------------------------------------------------------ */
export const validateNewPassword = (password) => {
  if (!password) {
    return { message: 'パスワードが入力されていません。', error: true };
  }
  if (password.length < 8 || password.length > 16) {
    return { message: 'パスワードは8文字以上16文字以下で入力してください。', error: true };
  }
  if (!/^[A-Za-z0-9!@#$%^&*(),.?":{}|<>]+$/.test(password)) {
    return { message: '使用できない文字が含まれています。英語、数字、記号のみ使用可能です。', error: true };
  }
  if (!/[0-9]/.test(password)) {
    return { message: 'パスワードには少なくとも1つの数字を含めてください。', error: true };
  }
  if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
    return { message: 'パスワードには少なくとも1つの記号を含めてください。', error: true };
  }
  if (!/[A-Z]/.test(password)) {
    return { message: 'パスワードには少なくとも1つの英語大文字を含めてください。', error: true };
  }
  if (!/[a-z]/.test(password)) {
    return { message: 'パスワードには少なくとも1つの英語小文字を含めてください。', error: true };
  }
  return { message: '', error: false };
};

/* ------------------------------------------------------------------------------------ */
/* PDFファイル
/* ------------------------------------------------------------------------------------ */
export const validatePDFFile = (file) => {
  const validFileExtensions = ['.pdf'];
  if (!file) {
    return 'PDFファイルを選択してください。';
  }
  
  const fileExtension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();
  const fileNameWithoutExtension = file.name.slice(0, file.name.lastIndexOf('.'));
  if (!file.name.endsWith('.pdf') || !validFileExtensions.includes(fileExtension)) {
    return 'PDFファイルを選択してください。';
  }
  if (fileNameWithoutExtension.length > MaxLength.FILE_NAME_MAX_LENGTH) {
    return `PDFファイル名は${MaxLength.FILE_NAME_MAX_LENGTH}文字以内にしてください。（拡張子を除く）`;
  }
  if (file.size > MaxLength.FILE_NAME_MAX_SIZE_MB * 1024 * 1024) {
    return `ファイルサイズは${MaxLength.FILE_NAME_MAX_SIZE_MB}MB以下にしてください。`;
  }
  return '';
};

/* ------------------------------------------------------------------------------------ */
/* 会社名
/* ------------------------------------------------------------------------------------ */
export const validateCompanyName = (value) => {
  if (!value.trim()) {
    return '必須項目です。';
  } else if (value.length > MaxLength.COMPANY_NAME_MAX_LENGTH) {
    return `最大文字数を超えています。(${value.length}/${MaxLength.COMPANY_NAME_MAX_LENGTH})`;
  } else if (/[\uff61-\uff9f]/.test(value)) {
    return '半角カタカナを使用しないでください。';
  } else if (value.match(/[^\u4e00-\u9faf\u3040-\u309f\u30a0-\u30ffA-Za-zＡ-Ｚａ-ｚ０-９ 0-9]/)) {
    return '漢字、ひらがな、カタカナ、英数字以外の文字は使用しないでください。';
  }
  return validateInput(value);
};

/* ------------------------------------------------------------------------------------ */
/* 契約開始日
/* ------------------------------------------------------------------------------------ */
export const validateContractStartDate = (value) => {
  if (!value.trim()) {
    return '契約開始日は必須項目です。';
  }
  return validateDateRange(value);
};

/* ------------------------------------------------------------------------------------ */
/* 契約終了日
/* ------------------------------------------------------------------------------------ */
export const validateContractEndDate = (value) => {
  if (!value.trim()) {
    return '契約終了日は必須項目です。';
  }
  return validateDateRange(value);
};

/* ------------------------------------------------------------------------------------ */
/* Enterpriseプランのアカウント数
/* ------------------------------------------------------------------------------------ */
export const validateEnterpriseAccountCount = (value) => {
  const numValue = Number(value);
  if (isNaN(numValue) || numValue < 1 || numValue > 999) {
    return '1~999の数値を入力してください。';
  }
  return '';
};

/* ------------------------------------------------------------------------------------ */
/* 研修名
/* ------------------------------------------------------------------------------------ */
export const validateJobName = (value) => {
  if (!value.trim()) {
    return '研修名は必須項目です。';
  } else if (value.length > MaxLength.JOB_NAME_MAX_LENGTH) {
    return `最大文字数を超えています。(${value.length}/${MaxLength.JOB_NAME_MAX_LENGTH})`;
  } else if (/[\uff61-\uff9f]/.test(value)) {
    return '半角カタカナを使用しないでください。';
  } else if (value.match(/[^\u4e00-\u9faf\u3040-\u309f\u30a0-\u30ffA-Za-zＡ-Ｚａ-ｚ０-９ 0-9（）＆？！]/)) {
    return '漢字、ひらがな、カタカナ、英数字、（）、＆、？、！以外の文字は使用しないでください。';
  }
  return validateInput(value);
};

/* ------------------------------------------------------------------------------------ */
/* Vimeo動画ID
/* ------------------------------------------------------------------------------------ */
export const validateVimeoId = (value) => {
  if (!value.trim()) {
    return 'Vimeo動画IDは必須項目です。';
  } else if (value.length > MaxLength.VIMEO_ID_MAX_LENGTH) {
    return `最大文字数を超えています。(${value.length}/${MaxLength.VIMEO_ID_MAX_LENGTH})`;
  } else if (value.match(/[^0-9]/)) {
    return '数字以外の文字は使用しないでください。';
  }
  return validateInput(value);
};

/* ------------------------------------------------------------------------------------ */
/* ユーザー名
/* ------------------------------------------------------------------------------------ */
export const validateUserName = (value) => {
  if (!value.trim()) {
    return '必須項目です。';
  } else if (value.length > MaxLength.USER_NAME_MAX_LENGTH) {
    return `最大文字数を超えています。(${value.length}/${MaxLength.USER_NAME_MAX_LENGTH})`;
  } else if (/[\uff61-\uff9f]/.test(value)) {
    return '半角カタカナを使用しないでください。';
  } else if (value.match(/[^\u4e00-\u9faf\u3040-\u309f\u30a0-\u30ffA-Za-zＡ-Ｚａ-ｚ０-９ 0-9]/)) {
    return '漢字、ひらがな、カタカナ、英数字以外の文字は使用しないでください。';
  }
  return validateInput(value);
};

/* ------------------------------------------------------------------------------------ */
/* ユーザー名（かな）
/* ------------------------------------------------------------------------------------ */
export const validateUserNameKana = (value) => {
  if (!value.trim()) {
    return '必須項目です。';
  } else if (value.length > MaxLength.USER_NAME_KANA_MAX_LENGTH) {
    return `最大文字数を超えています。(${value.length}/${MaxLength.USER_NAME_KANA_MAX_LENGTH})`;
  } else if (value.match(/[^\u4e00-\u9faf\u3040-\u309f\u30a0-\u30ffA-Za-zＡ-Ｚａ-ｚ０-９ 0-9]/)) {
    return 'ひらがな、アルファベット、数字以外の文字は使用しないでください。';
  }
  return validateInput(value);
};

/* ------------------------------------------------------------------------------------ */
/* メールアドレス
/* ------------------------------------------------------------------------------------ */
export const validateMail = (value) => {
  if (!value.trim()) {
    return '必須項目です。';
  } else if (value.length > MaxLength.MAIL_MAX_LENGTH) {
    return `最大文字数を超えています。(${value.length}/${MaxLength.MAIL_MAX_LENGTH})`;
  }
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!emailRegex.test(value)) {
    return '有効なメールアドレスを入力してください。';
  }
  const invalidCharsRegex = /[{}<>*?+!#$%^&|:;=~¥]/;
  if (invalidCharsRegex.test(value)) {
    return 'メールアドレスに使用できない文字が含まれています。';
  }
  return '';
};

/* ------------------------------------------------------------------------------------ */
/* 業務内容
/* ------------------------------------------------------------------------------------ */
export const validateWorkName = (value) => {
  if (value.length > MaxLength.WORK_NAME_MAX_LENGTH) {
    return `最大文字数を超えています。(${value.length}/${MaxLength.WORK_NAME_MAX_LENGTH})`;
  }
  return validateInput(value);
};

export const validateSex = (value) => {
  return value.trim() === '' ? '必須項目です。' : '';
};
/* ------------------------------------------------------------------------------------ */
/* 電話番号
/* ------------------------------------------------------------------------------------ */
export const validatePhoneNumber = (value) => {
  if (value.trim() === '') {
    return '';
  } else if (value.length > MaxLength.TELEPHONE_MAX_LENGTH) {
    return '最大文字数を超えています。';
  } else if (!/^\d{10,11}$/.test(value)) {
    return '有効な電話番号を入力してください。';
  }
  return validateInput(value);
};

/* ------------------------------------------------------------------------------------ */
/* 郵便番号
/* ------------------------------------------------------------------------------------ */
export const validatePostalCode = (value, isRequired = false) => {
  if (value.trim() === '') {
    return isRequired ? '必須項目です。' : '';
  } else if (!/^\d{7}$/.test(value.replace(/-/g, ''))) {
    return '有効な郵便番号を入力してください。';
  }
  return validateInput(value.replace(/-/g, ''));
};

/* ------------------------------------------------------------------------------------ */
/* 住所
/* ------------------------------------------------------------------------------------ */
export const validateAddress = (value, isRequired=false) => {
  if (value.trim() === '') {
    return isRequired ? '必須項目です。' : '';
  } else if (value.length > MaxLength.ADDRESS_MAX_LENGTH) {
    return `最大文字数を超えています。(${value.length}/${MaxLength.ADDRESS_MAX_LENGTH})`;
  }
  return validateInput(value);
};

/* ------------------------------------------------------------------------------------ */
/* 備考
/* ------------------------------------------------------------------------------------ */
export const validateNote = (value) => {
  if (value.length > MaxLength.NOTE_MAX_LENGTH) {
    return `最大文字数を超えています。(${value.length}/${MaxLength.NOTE_MAX_LENGTH})`;
  }
  return validateInput(value);
};

/* ------------------------------------------------------------------------------------ */
/* グループ名
/* ------------------------------------------------------------------------------------ */
export const validateGroupName = (value) => {
  if (!value.trim()) {
    return 'グループ名は必須項目です。';
  } else if (value.length > MaxLength.GROUP_NAME_MAX_LENGTH) {
    return `最大文字数を超えています。(${value.length}/${MaxLength.GROUP_NAME_MAX_LENGTH})`;
  } else {
    return validateInput(value);
  }
};

/* ------------------------------------------------------------------------------------ */
/* グループ名（かな）
/* ------------------------------------------------------------------------------------ */
export const validateGroupNameKana = (value) => {
  if (!value.trim()) {
    return 'グループ名（かな）は必須項目です。';
  } else if (value.length > MaxLength.GROUP_NAME_KANA_MAX_LENGTH) {
    return `最大文字数を超えています。(${value.length}/${MaxLength.GROUP_NAME_KANA_MAX_LENGTH})`;
  } else {
    return validateInput(value);
  }
};

/* ------------------------------------------------------------------------------------ */
/* 適格請求書発行事業者登録番号
/* ------------------------------------------------------------------------------------ */
export const validateBusinessNumber = (value) => {
  const checkValue = value.replace(/[^0-9]/g, '');
  if (checkValue.length > MaxLength.BUSINESS_NUMBER_MAX_LENGTH) {
    return `最大桁数を超えています。(${checkValue.length}/${MaxLength.BUSINESS_NUMBER_MAX_LENGTH})`;
  } else if (checkValue.length !== 0 && checkValue.length < MaxLength.BUSINESS_NUMBER_MAX_LENGTH) {
    return '13桁の数字を入力してください。';
  }
  return validateInput(checkValue);
};

/* ------------------------------------------------------------------------------------ */
/* 振込先
/* ------------------------------------------------------------------------------------ */
export const validatePayee = (value) => {
  if (!value?.trim()) {
    return '必須項目です。';
  } else if (value.length > MaxLength.PAYEE_MAX_LENGTH) {
    return `最大文字数を超えています。(${value.length}/${MaxLength.PAYEE_MAX_LENGTH})`;
  }
  return validateInput(value);
};

/* ------------------------------------------------------------------------------------ */
/* テンプレートID
/* ------------------------------------------------------------------------------------ */
export const validateTemplateId = (value) => {
  if (!value?.trim()) {
    return '必須項目です。';
  } else if (value.length > MaxLength.ESIGN_TEMPLATE_ID_MAX_LENGTH) {
    return `最大文字数を超えています。(${value.length}/${MaxLength.ESIGN_TEMPLATE_ID_MAX_LENGTH})`;
  }
  return validateInput(value);
};

/* ------------------------------------------------------------------------------------ */
/* テンプレート名
/* ------------------------------------------------------------------------------------ */
export const validateTemplateName = (value) => {
  if (!value?.trim()) {
    return '必須項目です。';
  } else if (value.length > MaxLength.ESIGN_TEMPLATE_NAME_MAX_LENGTH) {
    return `最大文字数を超えています。(${value.length}/${MaxLength.ESIGN_TEMPLATE_NAME_MAX_LENGTH})`;
  } else if (/[\uff61-\uff9f]/.test(value)) {
    return '半角カタカナを使用しないでください。';
  } else if (value.match(/[^\u4e00-\u9faf\u3040-\u309f\u30a0-\u30ffA-Za-zＡ-Ｚａ-ｚ０-９ 0-9（）＆？！]/)) {
    return '漢字、ひらがな、カタカナ、英数字、（）、＆、？、！以外の文字は使用しないでください。';
  }
  return validateInput(value);
};

/* ------------------------------------------------------------------------------------ */
/* テンプレート名（かな）
/* ------------------------------------------------------------------------------------ */
export const validateTemplateNameKana = (value) => {
  if (!value?.trim()) {
    return '必須項目です。';
  } else if (value.length > MaxLength.ESIGN_TEMPLATE_NAME_KANA_MAX_LENGTH) {
    return `最大文字数を超えています。(${value.length}/${MaxLength.ESIGN_TEMPLATE_NAME_KANA_MAX_LENGTH})`;
  } else if (/[\uff61-\uff9f]/.test(value)) {
    return '半角カタカナを使用しないでください。';
  } else if (value.match(/[^\u3040-\u309f\u30a0-\u30ffA-Za-zＡ-Ｚａ-ｚ０-９ 0-9（）＆？！]/)) {
    return 'ひらがな、カタカナ、英数字、（）、＆、？、！以外の文字は使用しないでください。';
  }
  return validateInput(value);
};

/* ------------------------------------------------------------------------------------ */
/* 通知タイトル
/* ------------------------------------------------------------------------------------ */
export const validateNotificationTitle = (value) => {
  if (!value?.trim()) {
    return '必須項目です。';
  } else if (value.length > MaxLength.NOTIFICATION_TITLE_MAX_LENGTH) {
    return `最大文字数を超えています。(${value.length}/${MaxLength.NOTIFICATION_TITLE_MAX_LENGTH})`;
  } else if (/[\uff61-\uff9f]/.test(value)) {
    return '半角カタカナを使用しないでください。';
  }
  return validateInput(value);
};

/* ------------------------------------------------------------------------------------ */
/* 通知内容
/* ------------------------------------------------------------------------------------ */
export const validateNotificationContents = (value) => {
  if (!value?.trim()) {
    return '必須項目です。';
  } else if (value.length > MaxLength.NOTIFICATION_CONTENTS_MAX_LENGTH) {
    return `最大文字数を超えています。(${value.length}/${MaxLength.NOTIFICATION_CONTENTS_MAX_LENGTH})`;
  } else if (/[\uff61-\uff9f]/.test(value)) {
    return '半角カタカナを使用しないでください。';
  }
  return validateInput(value);
};

/* ------------------------------------------------------------------------------------ */
/* テスト問題
/* ------------------------------------------------------------------------------------ */
export const validateTestQuestion = (value) => {
  if (!value?.trim()) {
    return '必須項目です。';
  } else if (value.length > MaxLength.QUESTION_TEXT_MAX_LENGTH) {
    return `最大文字数を超えています。(${value.length}/${MaxLength.QUESTION_TEXT_MAX_LENGTH})`;
  }
  return validateInput(value);
};

/* ------------------------------------------------------------------------------------ */
/* テスト選択肢
/* ------------------------------------------------------------------------------------ */
export const validateTestChoice = (value) => {
  if (!value?.trim()) {
    return '選択肢を入力してください。';
  } else if (value.length > MaxLength.CHOICE_TEXT_MAX_LENGTH) {
    return `最大文字数を超えています。(${value.length}/${MaxLength.CHOICE_TEXT_MAX_LENGTH})`;
  }
  return validateInput(value);
};