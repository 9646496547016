import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header/Header';
import Menu from './components/Menu/Menu';
import { ErrorMessage } from './const/Constant';
import AccountSetting from './pages/AccountSetting/AccountSetting';
import AdminMaster from './pages/AdminMaster/AdminMaster';
import CreateAccount from './pages/CreateAccount/CreateAccount';
import CustomerMaster from './pages/CustomerMaster/CustomerMaster';
import ESignatureList from './pages/ESignatureList/ESignatureList';
import ESignatureMaster from './pages/ESignatureMaster/ESignatureMaster';
import JobList from './pages/JobList/JobList';
import JobMaster from './pages/JobMaster/JobMaster';
import JobSetting from './pages/JobSetting/JobSetting';
import Login from './pages/Login/Login';
import Maintenance from './pages/Maintenance/Maintenance';
import MyPage from './pages/MyPage/MyPage';
import Notification from './pages/Notification/Notification';
import NotificationSetting from './pages/NotificationSetting/NotificationSetting';
import PaperList from './pages/PaperList/PaperList';
import PaperSetting from './pages/PaperSetting/PaperSetting';
import StaffGroupSetting from './pages/StaffGroupSetting/StaffGroupSetting';
import StaffSetting from './pages/StaffSetting/StaffSetting';
import TermsOfService from './pages/TermsOfService/TermsOfService';
import { UnreadCountProvider } from './context/UnreadCountContext';
import { UserProvider } from './context/UserContext';
import PrivateRoute from './components/PrivateRoute';
import { USER_TYPE } from './const/Enum';

import './global.css';
import './App.css';

const App = () => {
  const [isMaintenanceMode, setMaintenanceMode] = useState(false);

  const checkMaintenanceMode = async () => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/checkMaintenance`;
      const response = await axios.post(url);
      if (response.data.isMaintenance) {
        setMaintenanceMode(true);
      }
    } catch (error) {
      //メンテナンスモード確認でエラーが発生した場合
      alert(ErrorMessage.OUT_OF_SERVICE);
    }
  };
  
  useEffect(() => {
    checkMaintenanceMode();
  }, []);

  if (isMaintenanceMode) {
    return <Maintenance />;
  }
  
  return (
    <UserProvider>
      <UnreadCountProvider>
        <Router>
          <Header />
          <AppContent />
        </Router>
      </UnreadCountProvider>
    </UserProvider>
  );
};

const AppContent = () => {
  return (
    <div className="app-container">
      <Routes>
        {/* ログイン */}
        <Route path="/" element={
          <div className="content-container">
            <Login />
          </div>
        } />
        {/* アカウント作成 */}
        <Route path="/createAccount" element={
          <div className="content-container">
            <CreateAccount />
          </div>
        } />
        {/* 顧客マスタ（CLASSIX用） */}
        <Route path="/customer-master" element={
          <PrivateRoute
            component={() => (
              <div className="full-container">
                <div className="menu-container">
                  <Menu />
                </div>
                <div className="content-container">
                  <CustomerMaster />
                </div>
              </div>
            )}
            allowedRoles={[USER_TYPE.OWNER]}
          />
        } />
        {/* 管理者マスタ（CLASSIX者用） */}
        <Route path="/admin-master" element={
          <PrivateRoute
            component={() => (
              <div className="full-container">
                <div className="menu-container">
                  <Menu />
                </div>
                <div className="content-container">
                  <AdminMaster />
                </div>
              </div>
            )}
            allowedRoles={[USER_TYPE.OWNER]}
          />
        } />
        {/* 研修マスタ（CLASSIX者用） */}
        <Route path="/job-master" element={
          <PrivateRoute
            component={() => (
              <div className="full-container">
                <div className="menu-container">
                  <Menu />
                </div>
                <div className="content-container">
                  <JobMaster />
                </div>
              </div>
            )}
            allowedRoles={[USER_TYPE.OWNER]}
          />
        } />
        {/* 研修設定（管理者用） */}
        <Route path="/job-setting" element={
          <PrivateRoute
            component={() => (
              <div className="full-container">
                <div className="menu-container">
                  <Menu />
                </div>
                <div className="content-container">
                  <JobSetting />
                </div>
              </div>
            )}
            allowedRoles={[USER_TYPE.ADMIN]}
          />
        } />
        {/* 電子サインマスタ */}
        <Route path="/e-signature-master" element={
          <PrivateRoute
            component={() => (
              <div className="full-container">
                <div className="menu-container">
                  <Menu />
                </div>
                <div className="content-container">
                  <ESignatureMaster />
                </div>
              </div>
            )}
            allowedRoles={[USER_TYPE.OWNER]}
          />
        } />
        {/* 電子サイン一覧（管理者用） */}
        <Route path="/e-signature-list" element={
          <PrivateRoute
            component={() => (
              <div className="full-container">
                <div className="menu-container">
                  <Menu />
                </div>
                <div className="content-container">
                  <ESignatureList />
                </div>
              </div>
            )}
            allowedRoles={[USER_TYPE.ADMIN]}
          />
        } />
        {/* グループ設定（管理者用） */}
        <Route path="/staff-group-setting" element={
          <PrivateRoute
            component={() => (
              <div className="full-container">
                <div className="menu-container">
                  <Menu />
                </div>
                <div className="content-container">
                  <StaffGroupSetting />
                </div>
              </div>
            )}
            allowedRoles={[USER_TYPE.ADMIN]}
          />
        } />
        {/* スタッフ設定（管理者用） */}
        <Route path="/staff-setting" element={
          <PrivateRoute
            component={() => (
              <div className="full-container">
                <div className="menu-container">
                  <Menu />
                </div>
                <div className="content-container">
                  <StaffSetting />
                </div>
              </div>
            )}
            allowedRoles={[USER_TYPE.ADMIN]}
          />
        } />
        {/* 研修一覧（スタッフ用） */}
        <Route path="/job-list" element={
          <PrivateRoute
            component={() => (
              <div className="full-container">
                <div className="menu-container">
                  <Menu />
                </div>
                <div className="content-container">
                  <JobList />
                </div>
              </div>
            )}
            allowedRoles={[USER_TYPE.STAFF]}
          />
        } />
        {/* 通知設定（管理者） */}
        <Route path="/notification-setting" element={
          <PrivateRoute
            component={() => (
              <div className="full-container">
                <div className="menu-container">
                  <Menu />
                </div>
                <div className="content-container">
                  <NotificationSetting />
                </div>
              </div>
            )}
            allowedRoles={[USER_TYPE.OWNER, USER_TYPE.ADMIN]}
          />
        } />
        {/* アカウント設定 */}
        <Route path="/account-setting" element={
          <PrivateRoute
            component={() => (
              <div className="full-container">
                <div className="menu-container">
                  <Menu />
                </div>
                <div className="content-container">
                  <AccountSetting />
                </div>
              </div>
            )}
            allowedRoles={[USER_TYPE.OWNER, USER_TYPE.ADMIN, USER_TYPE.STAFF]}
          />
        } />
        {/* マイページ（スタッフ用） */}
        <Route path="/mypage" element={
          <PrivateRoute
            component={() => (
              <div className="full-container">
                <div className="menu-container">
                  <Menu />
                </div>
                <div className="content-container">
                  <MyPage />
                </div>
              </div>
            )}
            allowedRoles={[USER_TYPE.STAFF]}
          />
        } />
        {/* 通知画面 */}
        <Route path="/notification" element={
          <PrivateRoute
            component={() => (
              <div className="full-container">
                <div className="menu-container">
                  <Menu />
                </div>
                <div className="content-container">
                  <Notification />
                </div>
              </div>
            )}
            allowedRoles={[USER_TYPE.ADMIN, USER_TYPE.STAFF]}
          />
        } />
        {/* 書類設定（管理者用） */}
        <Route path="/paper-setting" element={
          <PrivateRoute
            component={() => (
              <div className="full-container">
                <div className="menu-container">
                  <Menu />
                </div>
                <div className="content-container">
                  <PaperSetting />
                </div>
              </div>
            )}
            allowedRoles={[USER_TYPE.ADMIN]}
          />
        } />
        {/* 書類一覧（スタッフ用） */}
        <Route path="/paper-list" element={
          <PrivateRoute
            component={() => (
              <div className="full-container">
                <div className="menu-container">
                  <Menu />
                </div>
                <div className="content-container">
                  <PaperList />
                </div>
              </div>
            )}
            allowedRoles={[USER_TYPE.STAFF]}
          />
        } />
        {/* 利用規約 */}
        <Route path="/terms-of-service" element={
          <PrivateRoute
            component={() => (
              <div className="full-container">
                <div className="menu-container">
                  <Menu />
                </div>
                <div className="content-container">
                  <TermsOfService />
                </div>
              </div>
            )}
            allowedRoles={[USER_TYPE.OWNER, USER_TYPE.ADMIN, USER_TYPE.STAFF]}
          />
        } />
        {/* 404ページ用のcatch-allルート - 最後に配置 */}
        <Route path="*" element={
          <Navigate to="/" replace />
        } />
      </Routes>
    </div>
  )
}

export default App;