/* ==================== */
/* スタッフ追加モーダル */
/* ==================== */
import React, { useEffect, useState } from 'react';
import Loading from '../../Loading/Loading';
import SelectGroupModal from '../../SelectGroupModal/SelectGroupModal';
import StatusMessage from '../../StatusMessage/StatusMessage';
import Toast from '../../Toast/Toast';
import { RESPONSE, SEX_TYPE, STAFF_STATUS, USER_TYPE } from '../../../const/Enum';
import { MaxLength, Message } from '../../../const/Constant';
import { getAddress } from '../../../utils/addressUtil';
import { requestApiLoad } from '../../../utils/apiLoadUtil';
import { formatPostalCode } from '../../../utils/formatUtil';
import * as validate from '../../../utils/realtimeValidation';
import './StaffSettingForm.css';

const StaffSettingForm = ({ formData, setFormData, isEdit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectGroupModalOpen, setIsSelectGroupModalOpen] = useState(false);
  const [selectGroup, setSelectGroup] = useState([]);
  const [showAddressButton, setShowAddressButton] = useState(false);
  const [showToast, setShowToast] = useState(false);

  /* エラーメッセージ */
  const [staffId, setStaffId] = useState('');
  const [staffNameError, setStaffNameError] = useState('');
  const [staffNameKanaError, setStaffNameKanaError] = useState('');
  const [mailError, setMailError] = useState('');
  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');
  const [employmentStartDateError, setEmploymentStartDateError] = useState('');
  const [employmentEndDateError, setEmploymentEndDateError] = useState('');
  const [workNameError, setWorkNameError] = useState('');
  const [telephoneError, setTelephoneError] = useState('');
  const [postalCodeError, setPostalCodeError] = useState('');
  const [address1Error, setAddress1Error] = useState('');
  const [address2Error, setAddress2Error] = useState('');
  const [noteError, setNoteError] = useState('');

  // ------------------------------------------------------------------------------------
  // 初期処理
  // ------------------------------------------------------------------------------------
  useEffect(() => {
    if (formData.staffId !== staffId) { 
      setStaffId(formData.staffId);
      setStaffNameError('');
      setStaffNameKanaError('');
      setMailError('');
      setStartDateError('');
      setEndDateError('');
      setEmploymentStartDateError('');
      setEmploymentEndDateError('');
      setWorkNameError('');
      setTelephoneError('');
      setPostalCodeError('');
      setAddress1Error('');
      setAddress2Error('');
      setNoteError('');
    }
  }, [formData]);
  
  useEffect(() => {
    if (selectGroup.groupId) {
      setFormData(prevFormData => ({
        ...prevFormData,
        groupId: selectGroup.groupId,
        groupName: selectGroup.groupName,
      }));
      setIsSelectGroupModalOpen(false);
    
      handleInputChange({
        target: {
          name: 'groupId',
          value: selectGroup.groupId
        }
      });
    }
    // eslint-disable-next-line
  }, [selectGroup]);

  // ------------------------------------------------------------------------------------
  // イベント
  // ------------------------------------------------------------------------------------
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let newStaffNameError = '';
    let newStaffNameKanaError = '';
    let newMailError = '';
    let newStartDateError = '';
    let newEndDateError = '';
    let newEmploymentStartDateError = '';
    let newEmploymentEndDateError = '';
    let newWorkNameError = '';
    let newTelephoneError = '';
    let newPostalCodeError = '';
    let newAddress1Error = '';
    let newAddress2Error = '';
    let newNoteError = '';

    if (name === 'staffName') {
      newStaffNameError = validate.validateUserName(value);
    } else if (name === 'staffNameKana') {
      newStaffNameKanaError = validate.validateUserNameKana(value);
    } else if (name === 'mail') {
      newMailError = validate.validateMail(value);
    } else if (name === 'startDate') {
      newStartDateError = validate.validateDateCheck(value);
    } else if (name === 'endDate') {
      newEndDateError = validate.validateDateRangeCheck(value);
    } else if (name === 'employmentStartDate') {
      newEmploymentStartDateError = validate.validateDateRangeCheck(value);
    } else if (name === 'employmentEndDate') {
      newEmploymentEndDateError = validate.validateDateRangeCheck(value);
    } else if (name === 'workName') {
      newWorkNameError = validate.validateWorkName(value);
    } else if (name === 'telephone') {
      newTelephoneError = validate.validatePhoneNumber(value);
    } else if (name === 'postalCode') {
      const formattedValue = formatPostalCode(value);
      newPostalCodeError = validate.validatePostalCode(formattedValue);
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: formattedValue
      }));
      setShowAddressButton(formattedValue.length === MaxLength.POSTAL_CODE_MAX_LENGTH + 1);
      return;
    } else if (name === 'address1') {
      newAddress1Error = validate.validateAddress(value);
    } else if (name === 'address2') {
      newAddress2Error = validate.validateAddress(value);
    } else if (name === 'note') {
      newNoteError = validate.validateNote(value);
    }

    // エラーステートを更新
    setStaffNameError(prevStaffNameError => name === 'staffName' ? newStaffNameError : prevStaffNameError);
    setStaffNameKanaError(prevStaffNameKanaError => name === 'staffNameKana' ? newStaffNameKanaError : prevStaffNameKanaError);
    setMailError(prevMailError => name === 'mail' ? newMailError : prevMailError);
    setStartDateError(prevStartDateError => name === 'startDate' ? newStartDateError : prevStartDateError);
    setEndDateError(prevEndDateError => name === 'endDate' ? newEndDateError : prevEndDateError);
    setEmploymentStartDateError(prevEmploymentStartDateError => name === 'employmentStartDate' ? newEmploymentStartDateError : prevEmploymentStartDateError);
    setEmploymentEndDateError(prevEmploymentEndDateError => name === 'employmentEndDate' ? newEmploymentEndDateError : prevEmploymentEndDateError);
    setWorkNameError(prevWorkNameError => name === 'workName' ? newWorkNameError : prevWorkNameError);
    setTelephoneError(prevTelephoneError => name === 'telephone' ? newTelephoneError : prevTelephoneError);
    setPostalCodeError(prevPostalCodeError => name === 'postalCode' ? newPostalCodeError : prevPostalCodeError);
    setAddress1Error(prevAddress1Error => name === 'address1' ? newAddress1Error : prevAddress1Error);
    setAddress2Error(prevAddress2Error => name === 'address2' ? newAddress2Error : prevAddress2Error);
    setNoteError(prevNoteError => name === 'note' ? newNoteError : prevNoteError);

    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  }

  // ------------------------------------------------------------------------------------
  // 住所取得
  // ------------------------------------------------------------------------------------
  const handleFetchAddress = async () => {
    if (!formData.postalCode) {
      return;
    }
    const address = await getAddress(formData.postalCode);
    if (address) {
      setFormData(prevFormData => ({
        ...prevFormData,
        address1: address.address1,
        address2: address.address2
      }));
    }
  };

  // ------------------------------------------------------------------------------------
  // メール送信
  // ------------------------------------------------------------------------------------
  const resendMail = async (staffId) => {
    if (window.confirm(Message.CONFIRM_RESEND_INIT_MAIL)) {
      if (!staffId) { return; }
      const res = await requestApiLoad(`/auth/repostMail/${USER_TYPE.STAFF}/${staffId}`, {}, setIsLoading);
      if (res.return !== RESPONSE.SUCCESS) {
        return;
      }
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);

    } else {
      return;
    }
  }

  // ------------------------------------------------------------------------------------
  // レンダリング
  // ------------------------------------------------------------------------------------
  return (
    <>
      <Loading isLoading={isLoading} />
      <Toast
        message={Message.BS_RESEND_INIT_MAIL}
        isVisible={showToast}
        onClose={() => setShowToast(false)}
      />
      <div className="modal-form" id="staff-setting-form">
        <StatusMessage
          status={formData.status}
          startDate={formData.startDate}
          endDate={formData.endDate}
          isOwner={false}
          isAvailable={formData.isAvailable}
          screen='staffSetting'
        />
        <p className="separate-title">基本情報</p>
        <div className="column-set" id="if-name">
        
          <div className="row-set">
            <div className="input-group">
              <label>スタッフ名</label>
              <div className={`error-message-container ${staffNameError ? 'has-error' : ''}`}>
                <input
                  type="text"
                  name="staffName"
                  value={formData.staffName || ''}
                  onChange={handleInputChange}
                  placeholder="スタッフ名"
                  disabled={formData.status === STAFF_STATUS.ACTIVE || isEdit}
                  style={{
                    borderColor: staffNameError ? 'red' : '',
                  }}
                />
                {staffNameError && (
                  <div className="error-message">
                    {staffNameError}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row-set">
            <div className="input-group">
              <label>スタッフ名（かな）</label>
              <div className={`error-message-container ${staffNameKanaError ? 'has-error' : ''}`}>
                <input
                  type="text"
                  name="staffNameKana"
                  value={formData.staffNameKana || ''}
                  onChange={handleInputChange}
                  placeholder="スタッフ名（かな）"
                  disabled={formData.status === STAFF_STATUS.ACTIVE || isEdit}
                  style={{
                    borderColor: staffNameKanaError ? 'red' : '',
                  }}
                />
                {staffNameKanaError && (
                  <div className="error-message">
                    {staffNameKanaError}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row-set">
            <div className="input-group">
              <label>メールアドレス</label>
              <div className={`error-message-container ${mailError ? 'has-error' : ''}`}>
                <input
                  type="email"
                  name="mail"
                  value={formData.mail || ''}
                  onChange={handleInputChange}
                  placeholder="メールアドレス"
                  disabled={formData.status === STAFF_STATUS.ACTIVE || isEdit}
                  style={{
                    borderColor: mailError ? 'red' : '',
                  }}
                />
                {mailError && (
                  <div className="error-message">
                    {mailError}
                  </div>
                )}
              </div>
              {isEdit && Number(formData.status) !== STAFF_STATUS.INACTIVE && (
                <div id='item-resend-mail'>
                  <button
                    type="button"
                    className='border-blue'
                    onClick={() => resendMail(formData.staffId)}>
                    <svg
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18px"
                      height="18px"
                      viewBox="0 0 24 24"
                      aria-labelledby="sendIconTitle"
                      stroke="#007bff"
                      strokeWidth="2.0"
                      strokeLinecap="square"
                      strokeLinejoin="miter"
                      fill="none"
                      color="#007bff"
                    >
                      <title id="sendIconTitle">再送信</title>
                      <polygon points="21.368 12.001 3 21.609 3 14 11 12 3 9.794 3 2.394" />
                    </svg>
                    メール再送信
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="column-set" id="if-name">
          <div className="spacer30"></div>
          <div className="row-set">
            <div className="input-group">
              <label>利用開始日</label>
              <div className={`error-message-container ${startDateError ? 'has-error' : ''}`}>
                <input
                  type="date"
                  name="startDate"
                  value={formData.startDate || ''}
                  onChange={handleInputChange}
                  pattern="\d{4}-\d{2}-\d{2}"
                  min="1900-01-01"
                  max="2999-12-31"
                  disabled={formData.status === STAFF_STATUS.ACTIVE || isEdit}
                  style={{
                    borderColor: startDateError ? 'red' : '',
                  }}
                />
                {startDateError && (
                  <div className="error-message">
                    {startDateError}
                  </div>
                )}
              </div>
            </div>
            <div className="input-group">
              <label>利用終了日</label>
              <div className={`error-message-container ${endDateError ? 'has-error' : ''}`}>
                <input
                  type="date"
                  name="endDate"
                  value={formData.endDate || ''}
                  onChange={handleInputChange}
                  pattern="\d{4}-\d{2}-\d{2}"
                  min="1900-01-01"
                  max="2999-12-31"
                  disabled={isEdit && Number(formData.status) === STAFF_STATUS.INACTIVE}
                  style={{
                    borderColor: endDateError ? 'red' : '',
                  }}
                />
                {endDateError && (
                  <div className="error-message">
                    {endDateError}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="separate-line"></div>
        <div className="column-set" id="if-name">
          <div className="row-set">
            <div className="input-group">
              <label>雇用開始日</label>
              <div className={`error-message-container ${employmentStartDateError ? 'has-error' : ''}`}>
                <input
                  type="date"
                  name="employmentStartDate"
                  value={formData.employmentStartDate || ''}
                  onChange={handleInputChange}
                  pattern="\d{4}-\d{2}-\d{2}"
                  min="1900-01-01"
                  max="2999-12-31"
                  disabled={isEdit && Number(formData.status) === STAFF_STATUS.INACTIVE}
                  style={{
                    borderColor: employmentStartDateError ? 'red' : '',
                  }}
                />
                {employmentStartDateError && (
                  <div className="error-message">
                    {employmentStartDateError}
                  </div>
                )}
              </div>
            </div>
            <div className="input-group">
              <label>雇用終了日</label>
              <div className={`error-message-container ${employmentEndDateError ? 'has-error' : ''}`}>
                <input
                  type="date"
                  name="employmentEndDate"
                  value={formData.employmentEndDate || ''}
                  onChange={handleInputChange}
                  pattern="\d{4}-\d{2}-\d{2}"
                  min="1900-01-01"
                  max="2999-12-31"
                  disabled={isEdit && Number(formData.status) === STAFF_STATUS.INACTIVE}
                  style={{
                    borderColor: employmentEndDateError ? 'red' : '',
                  }}
                />
                {employmentEndDateError && (
                  <div className="error-message">
                    {employmentEndDateError}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row-set">
            <div className="input-group">
              <label>業務内容</label>
              <div className={`error-message-container ${workNameError ? 'has-error' : ''}`}>
                <input
                  type="text"
                  name="workName"
                  value={formData.workName || ''}
                  onChange={handleInputChange}
                  placeholder="業務内容"
                  style={{
                    borderColor: workNameError ? 'red' : '',
                  }}
                  disabled={isEdit && Number(formData.status) === STAFF_STATUS.INACTIVE}
                />
                {workNameError && (
                  <div className="error-message">
                    {workNameError}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div>
            <label>
              所属グループ
            </label>
            <button
              className='blue staff-group-select'
              type="button"
              onClick={() => setIsSelectGroupModalOpen(true)}
              disabled={isEdit && Number(formData.status) === STAFF_STATUS.INACTIVE}
            >
              {formData.groupName || '未所属'}
            </button>
          </div>
        </div>

        <div className="separate-line"></div>
        <div className="column-set">
          <div className="row-set" id="if-dates">
            <div className="input-group">
              <label>生年月日</label>
              <input
                type="date"
                name="birthday"
                value={formData.birthday || ''}
                onChange={handleInputChange}
                pattern="\d{4}-\d{2}-\d{2}"
                min="1900-01-01"
                max="2999-12-31"
                disabled={isEdit && formData.status === STAFF_STATUS.INACTIVE}
              />
            </div>
            <div className="input-group">
              <label>性別</label>
              <select
                name="sex"
                value={formData.sex || ''}
                onChange={handleInputChange}
                disabled={isEdit && formData.status === STAFF_STATUS.INACTIVE}
              >
                <option value={SEX_TYPE.MALE}>男性</option>
                <option value={SEX_TYPE.FEMALE}>女性</option>
                <option value={SEX_TYPE.NONE}>無回答</option>
              </select>
            </div>
          </div>

          <div className="spacer30"></div>
          <div className="row-set" id="if-postalcode">
            <div className="input-group">
              <label>電話番号</label>
              <div className={`error-message-container ${telephoneError ? 'has-error' : ''}`}>
                <input
                  type="text"
                  name="telephone"
                  value={formData.telephone || ''}
                  onChange={handleInputChange}
                  placeholder="電話番号"
                  style={{
                    borderColor: telephoneError ? 'red' : '',
                  }}
                  disabled={isEdit && Number(formData.status) === STAFF_STATUS.INACTIVE}
                />
                {telephoneError && (
                  <div className="error-message">
                    {telephoneError}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row-set postalcode-row" id="if-postalcode">
            <div className="input-group">
              <label>郵便番号</label>
              <div className={`error-message-container ${postalCodeError ? 'has-error' : ''}`}>
                <input
                  type="text"
                  name="postalCode"
                  value={formData.postalCode || ''}
                  onChange={handleInputChange}
                  placeholder="郵便番号"
                  maxLength={MaxLength.POSTAL_CODE_MAX_LENGTH + 1}
                  disabled={isEdit && formData.status === STAFF_STATUS.INACTIVE}
                  style={{
                    borderColor: postalCodeError ? 'red' : '',
                  }}
                />
                {postalCodeError && (
                  <div className="error-message">
                    {postalCodeError}
                  </div>
                )}
              </div>
              {showAddressButton && (
                <button
                  className="blue"
                  onClick={handleFetchAddress}
                >
                  <svg
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    height="20px"
                    viewBox="0 0 24 24"
                    aria-labelledby="rotateIconTitle"
                    stroke="#007bff"
                    strokeWidth="2.4"
                    strokeLinecap="square"
                    strokeLinejoin="miter"
                    fill="none"
                    color="#007bff"
                  >
                    <title id="rotateIconTitle">住所取得</title>
                    <path d="M22 12l-3 3-3-3" />
                    <path d="M2 12l3-3 3 3" />
                    <path d="M19.016 14v-1.95A7.05 7.05 0 0 0 8 6.22" />
                    <path d="M16.016 17.845A7.05 7.05 0 0 1 5 12.015V10" />
                    <path strokeLinecap="round" d="M5 10V9" />
                    <path strokeLinecap="round" d="M19 15v-1" />
                  </svg>
                  住所取得
                </button>
              )}
            </div>
          </div>

          <div className="row-set" id="if-address">
            <div className="input-group">
              <label>住所</label>
              <div className={`error-message-container ${address1Error ? 'has-error' : ''}`}>
                <input
                  type="text"
                  name="address1"
                  value={formData.address1 || ''}
                  onChange={handleInputChange}
                  placeholder="住所1"
                  disabled={isEdit && formData.status === STAFF_STATUS.INACTIVE}
                  style={{
                    borderColor: address1Error ? 'red' : '',
                  }}
                />
                {address1Error && (
                  <div className="error-message">
                    {address1Error}
                  </div>
                )}
              </div>
              <div className={`error-message-container ${address2Error ? 'has-error' : ''}`}>
                <input
                  type="text"
                  name="address2"
                  value={formData.address2 || ''}
                  onChange={handleInputChange}
                  placeholder="住所2"
                  disabled={isEdit && formData.status === STAFF_STATUS.INACTIVE}
                  style={{
                    borderColor: address2Error ? 'red' : '',
                  }}
                />
                {address2Error && (
                  <div className="error-message">
                    {address2Error}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row-set" id="if-note">
            <div className="input-group">
              <label>備考</label>
              <div className={`error-message-container ${noteError ? 'has-error' : ''}`}>
                <textarea
                  name="note"
                  value={formData.note || ''}
                  onChange={handleInputChange}
                  placeholder="備考"
                  disabled={isEdit && formData.status === STAFF_STATUS.INACTIVE}
                  style={{
                    height: '100px',
                    width: '100%',
                    borderColor: noteError ? 'red' : '',
                  }}
                />
                {noteError && (
                  <div className="error-message">
                    {noteError}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <SelectGroupModal
          isOpen={isSelectGroupModalOpen}
          setIsOpen={setIsSelectGroupModalOpen}
          setSelectGroup={setSelectGroup}
        />
      </div>
    </>
  )
}

export default StaffSettingForm;