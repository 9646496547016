/* ==================== */
/* モーダル */
/* ==================== */
import React, { useState, useEffect, useRef } from 'react';
import useWindowSize from '../../hook/useWindowSize';
import DocumentViewer from '../DocumentViewer/DocumentViewer';
import './DocumentModal.css';

/**
 * PDFモーダルコンポーネント
 * @param {boolean} isOpen - モーダルが開いているかどうか
 * @param {function} closeModal - モーダルを閉じる関数
 * @param {string} title - モーダルのタイトル
 * @param {string} filePath - PDFファイルのパス
 * @param {boolean} isDownload - PDFファイルがダウンロード許可されているかどうか
 * @param {function} onEnd - ジョブが終了したときに呼ばれる関数
 */
const DocumentModal = ({
  isOpen,
  closeModal,
  title,
  filePath,
  isDownload = false,
  isDownloadDisp = true,
  onEnd = () => { },
  isPreview = false,
}) => {
  const size = useWindowSize();
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);
  const [initialScale, setInitialScale] = useState(1);
  const initialScaleRef = useRef(1);
  const [numPages, setNumPages] = useState(null);
  const scrollContainerRef = useRef(null);
  const [isEnd, setIsEnd] = useState(false);
  const [canZoomIn, setCanZoomIn] = useState(true);
  const [canZoomOut, setCanZoomOut] = useState(true);

  const endJob = () => {
    setIsEnd(true);
    onEnd();
  };

  useEffect(() => {
    if (isOpen) {
      setPageNumber(1);
      setScale(initialScale.current);
      setCanZoomIn(true);
      setCanZoomOut(true);
    }
    // eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    setScale(initialScale.current);
    setCanZoomIn(true);
    setCanZoomOut(true);
    // eslint-disable-next-line
  }, [filePath, pageNumber]);

  useEffect(() => {
    if (pageNumber === numPages) {
      endJob();
    }
    // eslint-disable-next-line
  }, [pageNumber, numPages, onEnd]);

  if (!isOpen) {
    return null;
  }

  const modalStyle = () => {
    if (size.width > 768) {
      /*1階層目のモーダルと重ならないように、あえてborder-boxを指定しない*/
      return { height: '85vh', width: '80vw' };
    } else {
      return { height: '95%', width: '90vw', boxSizing: 'border-box' };
    }
  }

  const goToPrevPage = () => {
    setPageNumber(prevPageNumber => Math.max(prevPageNumber - 1, 1));
  };

  const goToNextPage = () => {
    setPageNumber(prevPageNumber => prevPageNumber + 1);
  };

  const zoomIn = () => {
    setScale(prevScale => {
      const newScale = Math.min(prevScale + 0.1, initialScale * 2);
      setCanZoomIn(newScale < initialScale * 2);
      setCanZoomOut(true);
      return newScale;
    });
  };
  
  const zoomOut = () => {
    setScale(prevScale => {
      const newScale = Math.max(prevScale - 0.1, initialScale * 0.5);
      setCanZoomOut(newScale > initialScale * 0.5);
      setCanZoomIn(true);
      return newScale;
    });
  };

  const resetZoom = () => {
    setScale(initialScaleRef.current);
    setCanZoomIn(true);
    setCanZoomOut(true);
  };

  const downloadPDF = (filePath) => {
    if (!filePath) {
      return;
    }
    
    const pdfUrl = `${process.env.REACT_APP_S3_URL}/${filePath}`;
    if (!pdfUrl) {
      return;
    }
    fetch(pdfUrl)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = title || `${filePath}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch(error => console.error('Download error:', error));
  }
  
  return (
    <div className="document-modal-overlay" onClick={(e) => {
      e.stopPropagation();
      closeModal();
    }}>
      <div className="document-modal-container" style={modalStyle()} onClick={(e) => e.stopPropagation()}>
        <div className="document-modal-top-contents">
          <h2 className="document-modal-title">{title}</h2>
        </div>
        <div className="document-modal-center-contents" ref={scrollContainerRef}>
          <div className="document-modal-scrollable-contents" style={{ padding: '5px' }}>
            <DocumentViewer
              filePath={filePath}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              scale={scale}
              setScale={setScale}
              setInitialScale={(newScale) => {
                setInitialScale(newScale);
                initialScaleRef.current = newScale;
              }}
              setModalNumPages={setNumPages}
            />
          </div>
        </div>
        <div className="document-modal-bottom-contents">
          {numPages > 1 && size.width <= 440 && (
            <div className="page-button-group">
              <button className="blue" onClick={goToPrevPage} disabled={pageNumber <= 1}>＜</button>
              <p>{pageNumber}</p>
              <button className="blue" onClick={goToNextPage} disabled={pageNumber >= numPages}>＞</button>
            </div>
          )}

          <div className="action-button-group">
            <div className="zoom-buttons">
              <button onClick={zoomOut} disabled={!canZoomOut}>
                <img src={canZoomOut ? "/images/zoom_out.png" : "/images/zoom_out_disabled.png"} alt="zoom-out" />
              </button>
              <button onClick={zoomIn} disabled={!canZoomIn}>
                <img src={canZoomIn ? "/images/zoom_in.png" : "/images/zoom_in_disabled.png"} alt="zoom-in" />
              </button>
              <button onClick={resetZoom}><img src="/images/zoom_reset.png" alt="zoom-reset" /></button>
              {isDownloadDisp && (
                <button onClick={() => downloadPDF(filePath)} disabled={!isDownload}>
                  <img src={isDownload ? "/images/download.png" : "/images/download_disabled.png"} alt="download" />
                </button>
              )}
            </div>

            {numPages > 1 && size.width > 440 && (
              <div className="page-button-group">
                <button className="blue" onClick={goToPrevPage} disabled={pageNumber <= 1}>＜</button>
                <p>{pageNumber}</p>
                <button className="blue" onClick={goToNextPage} disabled={pageNumber >= numPages}>＞</button>
              </div>
            )}
            
            <div className="submit-buttons">
              {(isPreview || !isEnd) && (<button style={{ whiteSpace: 'nowrap' }} className="no-right-space" onClick={closeModal}>キャンセル</button>)}
              {(!isPreview && isEnd) && (<button style={{ whiteSpace: 'nowrap' }} className="no-right-space complete-button" onClick={closeModal}>
                {size.width > 768 ? '研修を完了する' : '研修完了'}</button>)}
            </div>
          </div>
        </div>
        <div className="scale-indicator">{scale !== undefined ? `${scale.toFixed(1)}x` : '1.0x'}</div>
      </div>
    </div>
  )
}

export default DocumentModal;
