import React from 'react';
import Modal from '../Modal/Modal';
import { MODAL_TYPE } from '../../const/Enum';
import './PaperDetailModal.css';

const PaperDetailModal = ({ isOpen, closeModal, item }) => {
  if (!item) return null;

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      title={item.userName}
      playerKey={MODAL_TYPE.PASSWORD}
    >
      <div className="detail-modal">
        <div className="paper-info">
          <h3>開封日時：{item.readDate || '-'}</h3>
          <table className="paper-list">
            <thead>
              <tr>
                <th>ファイル名</th>
                <th>チェック日時</th>
              </tr>
            </thead>
            <tbody>
              {item.paperList && item.paperList.map((paper, index) => (
                <tr key={index}>
                  <td className="paper-title">{paper.paperTitle}</td>
                  <td className="confirm-date">
                    {paper.confirmDate ? 
                      `${paper.confirmDate}` : 
                      '未チェック'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
};

export default PaperDetailModal;