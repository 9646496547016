/* ==================== */
/* ユーザーフォーム */
/* ==================== */
import React, { useEffect, useState } from 'react';
import Loading from '../../Loading/Loading';
import SelectCompanyModal from '../../SelectCompanyModal/SelectCompanyModal';
import { StatusMessage } from '../../StatusMessage/StatusMessage';
import Toast from '../../Toast/Toast';
import { SEX_TYPE, RESPONSE, STAFF_STATUS, USER_TYPE } from '../../../const/Enum';
import { MaxLength, Message } from '../../../const/Constant';
import { getAddress } from '../../../utils/addressUtil';
import { requestApiLoad } from '../../../utils/apiLoadUtil';
import { handleDateBlur as handleDateBlurUtil } from '../../../utils/dateInputUtil';
import { formatPostalCode } from '../../../utils/formatUtil';
import * as validate from '../../../utils/realtimeValidation';
import './AdminMasterForm.css';

const AdminMasterForm = ({ formData, setFormData, isEdit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectCompanyModalOpen, setIsSelectCompanyModalOpen] = useState(false);
  const [selectCompany, setSelectCompany] = useState({});
  const [showAddressButton, setShowAddressButton] = useState(false);
  const [showToast, setShowToast] = useState(false);

  /* エラーメッセージ */
  const [adminId, setAdminId] = useState('');
  const [adminNameError, setAdminNameError] = useState('');
  const [adminNameKanaError, setAdminNameKanaError] = useState('');
  const [mailError, setMailError] = useState('');
  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');
  const [workNameError, setWorkNameError] = useState('');
  const [birthdayError, setBirthdayError] = useState('');
  const [sexError, setSexError] = useState('');
  const [telephoneError, setTelephoneError] = useState('');
  const [postalCodeError, setPostalCodeError] = useState('');
  const [address1Error, setAddress1Error] = useState('');
  const [address2Error, setAddress2Error] = useState('');
  const [noteError, setNoteError] = useState('');

  // ------------------------------------------------------------------------------------
  // 初期処理
  // ------------------------------------------------------------------------------------ 
  useEffect(() => {
    if (formData.adminId !== adminId) { 
      setAdminId(formData.adminId);
      setAdminNameError('');
      setAdminNameKanaError('');
      setMailError('');
      setStartDateError('');
      setEndDateError('');
      setWorkNameError('');
      setBirthdayError('');
      setSexError('');
      setTelephoneError('');
      setPostalCodeError('');
      setAddress1Error('');
      setAddress2Error('');
      setNoteError('');
    }
  }, [formData]);

  // ------------------------------------------------------------------------------------
  // イベント
  // ------------------------------------------------------------------------------------
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let newAdminNameError = '';
    let newAdminNameKanaError = '';
    let newMailError = '';
    let newStartDateError = '';
    let newEndDateError = '';
    let newWorkNameError = '';
    let newBirthdayError = '';
    let newSexError = '';
    let newTelephoneError = '';
    let newPostalCodeError = '';
    let newAddress1Error = '';
    let newAddress2Error = '';
    let newNoteError = '';

    if (name === 'adminName') {
      newAdminNameError = validate.validateUserName(value);
    } else if (name === 'adminNameKana') {
      newAdminNameKanaError = validate.validateUserNameKana(value);
    } else if (name === 'mail') {
      newMailError = validate.validateMail(value);
    } else if (name === 'startDate') {
      newStartDateError = validate.validateDateCheck(value);
    } else if (name === 'endDate') {
      newEndDateError = validate.validateDateRangeCheck(value);
    } else if (name === 'workName') {
      newWorkNameError = validate.validateWorkName(value);
    } else if (name === 'birthday') {
      newBirthdayError = validate.validateDateRangeCheck(value);
    } else if (name === 'sex') {
      newSexError = validate.validateSex(value);
    } else if (name === 'telephone') {
      newTelephoneError = validate.validatePhoneNumber(value);
    } else if (name === 'postalCode') {
      const formattedValue = formatPostalCode(value);
      newPostalCodeError = validate.validatePostalCode(formattedValue);
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: formattedValue
      }));
      setShowAddressButton(formattedValue.length === MaxLength.POSTAL_CODE_MAX_LENGTH + 1);
      return;
    } else if (name === 'address1' || name === 'address2') {
      if (name === 'address1') {
        newAddress1Error = validate.validateAddress(value);
      } else {
        newAddress2Error = validate.validateAddress(value);
      }
    } else if (name === 'note') {
      newNoteError = validate.validateNote(value);
    }

    // エラーステートを更新
    setAdminNameError(prevAdminNameError => name === 'adminName' ? newAdminNameError : prevAdminNameError);
    setAdminNameKanaError(prevAdminNameKanaError => name === 'adminNameKana' ? newAdminNameKanaError : prevAdminNameKanaError);
    setMailError(prevMailError => name === 'mail' ? newMailError : prevMailError);
    setStartDateError(prevStartDateError => name === 'startDate' ? newStartDateError : prevStartDateError);
    setEndDateError(prevEndDateError => name === 'endDate' ? newEndDateError : prevEndDateError);
    setWorkNameError(prevWorkNameError => name === 'workName' ? newWorkNameError : prevWorkNameError);
    setBirthdayError(prevBirthdayError => name === 'birthday' ? newBirthdayError : prevBirthdayError);
    setSexError(prevSexError => name === 'sex' ? newSexError : prevSexError);
    setTelephoneError(prevTelephoneError => name === 'telephone' ? newTelephoneError : prevTelephoneError);
    setPostalCodeError(prevPostalCodeError => name === 'postalCode' ? newPostalCodeError : prevPostalCodeError);
    setAddress1Error(prevAddress1Error => name === 'address1' ? newAddress1Error : prevAddress1Error);
    setAddress2Error(prevAddress2Error => name === 'address2' ? newAddress2Error : prevAddress2Error);
    setNoteError(prevNoteError => name === 'note' ? newNoteError : prevNoteError);

    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleDateBlur = (event) => {
    handleDateBlurUtil(event, setFormData);
  };

  // ------------------------------------------------------------------------------------
  // 住所取得
  // ------------------------------------------------------------------------------------
  const handleFetchAddress = async () => {
    if (!formData.postalCode) {
      return;
    }
    const address = await getAddress(formData.postalCode);
    if (address) {
      setFormData(prevFormData => ({
        ...prevFormData,
        address1: address.address1,
        address2: address.address2
      }));
    }
  };

  // ------------------------------------------------------------------------------------
  // メール送信
  // ------------------------------------------------------------------------------------
  const resendMail = async (userId) => {
    if (window.confirm(Message.CONFIRM_RESEND_INIT_MAIL)) {
      
      if (!userId) { return; }
      const res = await requestApiLoad(`/auth/repostMail/${USER_TYPE.ADMIN}/${userId}`, {}, setIsLoading);
      if (res.return !== RESPONSE.SUCCESS) {
        return;
      }
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);

    } else {
      return;
    }
  }

  // ------------------------------------------------------------------------------------
  // レンダリング
  // ------------------------------------------------------------------------------------
  return (
    <>
      <Loading isLoading={isLoading} />
      <Toast
        message={Message.BS_RESEND_INIT_MAIL}
        isVisible={showToast}
        onClose={() => setShowToast(false)}
      />
      <div className="modal-form" id="admin-master-form">
        <StatusMessage
          status={formData.status}
          startDate={formData.startDate}
          endDate={formData.endDate}
          screen='adminMaster'
        />
        <p className="separate-title">基本情報</p>
        <div className="column-set" id="if-name">

          <div className="row-set">
            <div className="input-group">
              <label>管理者名</label>
              <div className={`error-message-container ${adminNameError ? 'has-error' : ''}`}>
                <input
                  type="text"
                  name="adminName"
                  value={formData.adminName || ''}
                  onChange={handleInputChange}
                  placeholder="管理者名"
                  disabled={formData.status === STAFF_STATUS.ACTIVE || isEdit}
                  style={{
                    borderColor: adminNameError ? 'red' : '',
                  }}
                />
                {adminNameError && (
                  <div className="error-message">
                    {adminNameError}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row-set">
            <div className="input-group">
              <label>管理者名（かな）</label>
              <div className={`error-message-container ${adminNameKanaError ? 'has-error' : ''}`}>
                <input
                  type="text"
                  name="adminNameKana"
                  value={formData.adminNameKana || ''}
                  onChange={handleInputChange}
                  placeholder="管理者名（かな）"
                  disabled={formData.status === STAFF_STATUS.ACTIVE || isEdit}
                  style={{
                    borderColor: adminNameKanaError ? 'red' : '',
                  }}
                />
                {adminNameKanaError && (
                  <div className="error-message">
                    {adminNameKanaError}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row-set">
            <div className="input-group">
              <label>メールアドレス</label>
              <div className={`error-message-container ${mailError ? 'has-error' : ''}`}>
                <input
                  type="email"
                  name="mail"
                  value={formData.mail || ''}
                  onChange={handleInputChange}
                  placeholder="メールアドレス"
                  disabled={formData.status === STAFF_STATUS.ACTIVE || isEdit}
                  style={{
                    borderColor: mailError ? 'red' : '',
                  }}
                />
                {mailError && (
                  <div className="error-message">
                    {mailError}
                  </div>
                )}
              </div>
              {isEdit && Number(formData.status) !== STAFF_STATUS.INACTIVE && (
                <div id='item-resend-mail'>
                  <button
                    type="button"
                    className='border-blue'
                    onClick={() => resendMail(formData.adminId)}>
                    <svg
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18px"
                      height="18px"
                      viewBox="0 0 24 24"
                      aria-labelledby="sendIconTitle"
                      stroke="#007bff"
                      strokeWidth="2.0"
                      strokeLinecap="square"
                      strokeLinejoin="miter"
                      fill="none"
                      color="#007bff"
                    >
                      <title id="sendIconTitle">再送信</title>
                      <polygon points="21.368 12.001 3 21.609 3 14 11 12 3 9.794 3 2.394" />
                    </svg>
                    メール再送信
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="separate-line"></div>
        <div className="column-set" id="if-name">
          <div className="row-set">
            <div className={isEdit ? 'input-group' : ''}>
              <label>所属会社</label>
              {!isEdit ? (
                <button
                  className='blue company-select'
                  type="button"
                  onClick={() => setIsSelectCompanyModalOpen(true)}
                >
                  {selectCompany.companyName ? selectCompany.companyName : '会社選択'}
                </button>
              ) : (
                <input
                  type="text"
                  name="companyName"
                  value={formData.companyName || ''}
                  onChange={handleInputChange}
                  disabled={isEdit}
                />
              )}
            </div>
          </div>
        </div>

        <div className="column-set" id="if-name">
          <div className="spacer30"></div>
          <div className="row-set">
            <div className="input-group">
              <label>利用開始日</label>
              <div className={`error-message-container ${startDateError ? 'has-error' : ''}`}>
                <input
                  type="date"
                  name="startDate"
                  value={formData.startDate || ''}
                  onChange={handleInputChange}
                  onBlur={handleDateBlur}
                  pattern="\d{4}-\d{2}-\d{2}"
                  min="1900-01-01"
                  max="2999-12-31"
                  disabled={formData.status === STAFF_STATUS.ACTIVE || isEdit}
                  style={{
                    borderColor: startDateError ? 'red' : '',
                  }}
                />
                {startDateError && (
                  <div className="error-message">
                    {startDateError}
                  </div>
                )}
              </div>
            </div>
            <div className="input-group">
              <label>利用終了日</label>
              <div className={`error-message-container ${endDateError ? 'has-error' : ''}`}>
                <input
                  type="date"
                  name="endDate"
                  value={formData.endDate || ''}
                  onChange={handleInputChange}
                  onBlur={handleDateBlur}
                  pattern="\d{4}-\d{2}-\d{2}"
                  min="1900-01-01"
                  max="2999-12-31"
                  disabled={isEdit && Number(formData.status) === STAFF_STATUS.INACTIVE}
                  style={{
                    borderColor: endDateError ? 'red' : '',
                  }}
                />
                {endDateError && (
                  <div className="error-message">
                    {endDateError}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row-set">
            <div className="input-group">
              <label>業務内容</label>
              <div className={`error-message-container ${workNameError ? 'has-error' : ''}`}>
                <input
                  type="text"
                  name="workName"
                  value={formData.workName || ''}
                  onChange={handleInputChange}
                  placeholder="業務内容"
                  style={{
                    borderColor: workNameError ? 'red' : '',
                  }}
                  disabled={isEdit && Number(formData.status) === STAFF_STATUS.INACTIVE}
                />
                {workNameError && (
                  <div className="error-message">
                    {workNameError}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="separate-line"></div>
        <div className="column-set">
          <div className="row-set" id="if-dates">
            <div className="input-group">
              <label>生年月日</label>
              <div className={`error-message-container ${birthdayError ? 'has-error' : ''}`}>
                <input
                  type="date"
                  name="birthday"
                  value={formData.birthday || ''}
                  onChange={handleInputChange}
                  onBlur={handleDateBlur}
                  pattern="\d{4}-\d{2}-\d{2}"
                  min="1900-01-01"
                  max="2999-12-31"
                  disabled={isEdit && formData.status === STAFF_STATUS.INACTIVE}
                  style={{
                    borderColor: birthdayError ? 'red' : '',
                  }}
                />
                {birthdayError && (
                  <div className="error-message">
                    {birthdayError}
                  </div>
                )}
              </div>
            </div>
            <div className="input-group">
              <label>性別</label>
              <div className={`error-message-container ${sexError ? 'has-error' : ''}`}>
                <select
                  name="sex"
                  value={formData.sex || ''}
                  onChange={handleInputChange}
                  disabled={isEdit && formData.status === STAFF_STATUS.INACTIVE}
                  style={{
                    borderColor: sexError ? 'red' : '',
                  }}
                >
                  <option value={SEX_TYPE.MALE}>男性</option>
                  <option value={SEX_TYPE.FEMALE}>女性</option>
                  <option value={SEX_TYPE.NONE}>無回答</option>
                </select>
                {sexError && (
                  <div className="error-message">
                    {sexError}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="spacer30"></div>
          <div className="row-set" id="if-postalcode">
            <div className="input-group">
              <label>電話番号</label>
              <div className={`error-message-container ${telephoneError ? 'has-error' : ''}`}>
                <input
                  type="text"
                  name="telephone"
                  value={formData.telephone || ''}
                  onChange={handleInputChange}
                  placeholder="電話番号"
                  disabled={isEdit && formData.status === STAFF_STATUS.INACTIVE}
                  style={{
                    borderColor: telephoneError ? 'red' : '',
                  }}
                />
                {telephoneError && (
                  <div className="error-message">
                    {telephoneError}
                  </div>
                )}
              </div>
            </div>
          </div>
      
          <div className="row-set postalcode-row" id="if-postalcode">
            <div className="input-group">
              <label>郵便番号</label>
              <div className={`error-message-container ${postalCodeError ? 'has-error' : ''}`}>
                <input
                  type="text"
                  name="postalCode"
                  value={formData.postalCode || ''}
                  onChange={handleInputChange}
                  placeholder="郵便番号"
                  maxLength={MaxLength.POSTAL_CODE_MAX_LENGTH + 1}
                  disabled={isEdit && formData.status === STAFF_STATUS.INACTIVE}
                  style={{
                    borderColor: postalCodeError ? 'red' : '',
                  }}
                />
                {postalCodeError && (
                  <div className="error-message">
                    {postalCodeError}
                  </div>
                )}
              </div>
              {showAddressButton && (
                <button
                  className="blue"
                  onClick={handleFetchAddress}
                >
                  <svg
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    height="20px"
                    viewBox="0 0 24 24"
                    aria-labelledby="rotateIconTitle"
                    stroke="#007bff"
                    strokeWidth="2.4"
                    strokeLinecap="square"
                    strokeLinejoin="miter"
                    fill="none"
                    color="#007bff"
                  >
                    <title id="rotateIconTitle">住所取得</title>
                    <path d="M22 12l-3 3-3-3" />
                    <path d="M2 12l3-3 3 3" />
                    <path d="M19.016 14v-1.95A7.05 7.05 0 0 0 8 6.22" />
                    <path d="M16.016 17.845A7.05 7.05 0 0 1 5 12.015V10" />
                    <path strokeLinecap="round" d="M5 10V9" />
                    <path strokeLinecap="round" d="M19 15v-1" />
                  </svg>
                  住所取得
                </button>
              )}
            </div>
          </div>

          <div className="row-set" id="if-address">
            <div className="input-group">
              <label>住所</label>
              <div className={`error-message-container ${address1Error ? 'has-error' : ''}`}>
                <input
                  type="text"
                  name="address1"
                  value={formData.address1 || ''}
                  onChange={handleInputChange}
                  placeholder="住所1"
                  disabled={isEdit && formData.status === STAFF_STATUS.INACTIVE}
                  style={{
                    borderColor: address1Error ? 'red' : '',
                  }}
                />
                {address1Error && (
                  <div className="error-message">
                    {address1Error}
                  </div>
                )}
              </div>
              <div className={`error-message-container ${address2Error ? 'has-error' : ''}`}>
                <input
                  type="text"
                  name="address2"
                  value={formData.address2 || ''}
                  onChange={handleInputChange}
                  placeholder="住所2"
                  disabled={isEdit && formData.status === STAFF_STATUS.INACTIVE}
                  style={{
                    borderColor: address2Error ? 'red' : '',
                  }}
                />
                {address2Error && (
                  <div className="error-message">
                    {address2Error}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row-set" id="if-note">
            <div className="input-group">
              <label>備考</label>
              <div className={`error-message-container ${noteError ? 'has-error' : ''}`}>
                <textarea
                  name="note"
                  value={formData.note || ''}
                  onChange={handleInputChange}
                  placeholder="備考"
                  disabled={isEdit && formData.status === STAFF_STATUS.INACTIVE}
                  style={{
                    height: '100px',
                    width: '100%',
                    borderColor: noteError ? 'red' : '',
                  }}
                />
                {noteError && (
                  <div className="error-message">
                    {noteError}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <SelectCompanyModal
          isOpen={isSelectCompanyModalOpen}
          setIsOpen={setIsSelectCompanyModalOpen}
          setSelectCompany={(company) => {
            setSelectCompany(company);
            setFormData(prevFormData => ({
              ...prevFormData,
              companyId: company.companyId,
              companyName: company.companyName
            }));
          }}
          selectCompany={selectCompany}
        />
      </div>
    </>
  )
}

export default AdminMasterForm;