/* ==================== */
/* 電子サインマスタ画面 */
/* ==================== */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import BottomSheet from '../../components/BottomSheet/BottomSheet';
import ESignatureForm from '../../components/Form/ESignatureForm/ESignatureForm';
import FilterButton from '../../components/FilterButton/FilterButton';
import Loading from '../../components/Loading/Loading';
import Modal from '../../components/Modal/Modal';
import Pagination from '../../components/Pagination/Pagination';
import SortingButton from '../../components/SortingButton/SortingButton';
import { convertPDFToBase64 } from '../../utils/fileUtil';
import { Message } from '../../const/Constant';
import { E_SIGNATURE_STATUS, PUBLISH_SCOPE, RESPONSE } from '../../const/Enum';
import useWindowSize from '../../hook/useWindowSize';
import { requestApiLoad, requestApiLoadAndBottom } from '../../utils/apiLoadUtil';
import * as Validators from '../../utils/validation';
import './ESignatureMaster.css';

const ESignatureMaster = () => {
  const [itemList, setItemList] = useState([]);
  const [addItem, setAddItem] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [isInit, setIsInit] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const bottomSheetRef = useRef(null);
  const size = useWindowSize();

  /* メイン一覧画面 */
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState(0);
  const [sortConfig, setSortConfig] = useState({ key: 'eSignatureId', direction: true });
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const statusOptions = [
    { value: E_SIGNATURE_STATUS.ALL, label: 'すべて' },
    { value: E_SIGNATURE_STATUS.USE, label: '使用中' },
    { value: E_SIGNATURE_STATUS.NOT_USE, label: '未使用' },
  ];
  const sortOptions = [
    { key: 'jobId', value: { key: 'eSignatureId', direction: true }, label: '標準' },
    { key: 'templateName_asc', value: { key: 'templateName', direction: false }, label: 'テンプレート名（昇順）' },
    { key: 'templateName_desc', value: { key: 'templateName', direction: true }, label: 'テンプレート名（降順）' },
  ];
  
  /* 画面状態 */
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  // ------------------------------------------------------------------------------------
  // 初期処理
  // ------------------------------------------------------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        page: 1,
        isDesc: sortConfig.direction,
        orderKey: sortConfig.key,
        status: statusFilter,
        name: searchTerm,
      }

      const res = await requestApiLoad('/eSignature/get', params, setIsLoading);
      if (res.return !== RESPONSE.SUCCESS) {
        return;
      }

      setSelectedItem((res.eSignatureList && res.eSignatureList.length > 0) ? res.eSignatureList[0] : {});
      setItemList((res.eSignatureList && res.eSignatureList.length > 0) ? res.eSignatureList : []);
      setCurrentPage(1);
      setLastPage(res.lastPage);
      setTotalCount(res.dataCount);
    };

    fetchData();
  }, [statusFilter, sortConfig.direction, sortConfig.key, searchTerm]);

  useEffect(() => {
    createEditTable();
    // eslint-disable-next-line
  }, [selectedItem]);

  useEffect(() => {
    if(!isInit) {
      return;
    }
    if (itemList.length > 0) {
      if (size.width > 1200) {
        handleEdit(null, itemList[0]);
      }
      setIsInit(false);
    }
    // eslint-disable-next-line
  }, [itemList]);

  // ------------------------------------------------------------------------------------
  // 画面モード
  // ------------------------------------------------------------------------------------
  const MODE = {
    NONE: 'NONE',
    ADD: 'ADD',
    EDIT: 'EDIT',
  }

  const ChangeScreenMode = (mode) => {
    switch (mode) {
      case MODE.ADD:
        setShowAdd(true);
        setShowEdit(false);
        break;
      
      case MODE.EDIT:
        setShowAdd(false);
        setShowEdit(true);
        break;
      
      case MODE.NONE:
      default:
        setShowAdd(false);
        setShowEdit(false);
        break;
    }
  }

  // ------------------------------------------------------------------------------------
  // ページネーション
  // ------------------------------------------------------------------------------------
  const handlePageChange = async (pageNumber) => {
    setCurrentPage(pageNumber);
    
    const params = {
      page: pageNumber,
      isDesc: sortConfig.direction,
      orderKey: sortConfig.key,
      status: statusFilter,
      name: searchTerm,
    }
    
    const res = await requestApiLoad('/eSignature/get', params, setIsLoading);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }

    setSelectedItem(res.eSignatureList[0]);
    setItemList(res.eSignatureList);
    setLastPage(res.lastPage);
    setTotalCount(res.dataCount);
    handleEdit(null, res.eSignatureList[0]);
  };

  // ------------------------------------------------------------------------------------
  // フィルター
  // ------------------------------------------------------------------------------------
  const handleStatusChange = async (event) => {
    const newStatusFilter = Number(event.target.value);
    setCurrentPage(1);
    setStatusFilter(newStatusFilter);
  };

  const sortedAndFilteredItems = useMemo(() => {
    let filteredItems = itemList || [];
    return filteredItems;
  }, [itemList]);

  // ------------------------------------------------------------------------------------
  // ソート
  // ------------------------------------------------------------------------------------
  const handleSortChange = async (event) => {
    const newSortConfig = !event ? { key: 'eSignatureId', direction: true } : JSON.parse(event);
    setSortConfig(newSortConfig);
  };

  // ------------------------------------------------------------------------------------
  // 検索
  // ------------------------------------------------------------------------------------
  const onClear = () => {
    setSearchTerm('');
  };

  // ------------------------------------------------------------------------------------
  // 新規追加
  // ------------------------------------------------------------------------------------
  const handleAdd = async () => {
    setAddItem({
      templateId: '',
      templateName: '',
      templateNameKana: '',
      documentList: [
        {
          eSignatureDocId: '',
          documentName: '',
          filePath: '',
        }
      ],
      publishScope: PUBLISH_SCOPE.ALL,
      publishCompanyList: null,
    });
    ChangeScreenMode(MODE.ADD);
  };

  const handleAddSubmit = async (event) => {
    event.preventDefault();
    
    const validationResult = validateCheck(addItem);
    if (validationResult.error) {
      alert(validationResult.message);
      return;
    }

    const publishCompanyIds =
      addItem.publishScope === PUBLISH_SCOPE.LIMITED ? addItem.publishCompanyList?.map(company => company.companyId) || null : null;

    // PDFファイルをBase64に変換
    const convertedDocumentList = await Promise.all(addItem.documentList.map(async (doc) => {
      if (doc.pdfFile) {
        const base64 = await convertPDFToBase64(doc.pdfFile);
        return {
          ...doc,
          pdfFile: base64
        };
      }
      return doc;
    }));

    const params = {
      templateId: addItem.templateId,
      templateName: addItem.templateName,
      templateNameKana: addItem.templateNameKana,
      documentList: convertedDocumentList,
      publishCompanyIds: publishCompanyIds,
    }

    const res = await requestApiLoadAndBottom('/eSignature/add',
      params, setIsLoading, bottomSheetRef, Message.BS_ESIGNATURE_ADD_SUCCESS);
    if (res.return !== RESPONSE.SUCCESS) {
      if (res.errorCode === 'W-ES2-001') {
        alert('既に使用されているテンプレートIDです。');
      }
      return;
    }
    setFirstItem(res);
    ChangeScreenMode(MODE.EDIT); 
  }

  // ------------------------------------------------------------------------------------
  // 編集
  // ------------------------------------------------------------------------------------
  const handleEdit = async (e, item) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    if (!item) { return; }

    const publishScope = item.publishCompanyList?.length > 0 ? PUBLISH_SCOPE.LIMITED : PUBLISH_SCOPE.ALL;
    setSelectedItem({
      eSignatureId: item.eSignatureId,
      templateId: item.templateId,
      templateName: item.templateName,
      templateNameKana: item.templateNameKana,
      documentList: item.documentList,
      publishScope: publishScope,
      publishCompanyList: item.publishCompanyList,
      status: item.status,
    }); 
    ChangeScreenMode(MODE.EDIT);
  }

  // ------------------------------------------------------------------------------------
  // 削除
  // ------------------------------------------------------------------------------------
  const handleDeleteSubmit = async (event) => {
    event.preventDefault();
    if (selectedItem.status === E_SIGNATURE_STATUS.USE) {
      alert(`「${selectedItem.templateName}」は使用されているため、削除できません。`);
      return;
    }

    if (!window.confirm(`「${selectedItem.templateName}」を削除してもよろしいですか？`)) {
      return;
    }
    const res = await requestApiLoadAndBottom(`/eSignature/delete/${selectedItem.eSignatureId}`,
      {}, setIsLoading, bottomSheetRef, Message.BS_ESIGNATURE_DELETE_SUCCESS);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }
    setFirstItem(res);
    
    if (size.width <= 1200) {
      ChangeScreenMode(MODE.NONE);
    } else {
      ChangeScreenMode(MODE.EDIT);
    }
  };

  // ------------------------------------------------------------------------------------
  // その他
  // ------------------------------------------------------------------------------------ 
  const setFirstItem = (res) => {
    setItemList(res.eSignatureList);
    setLastPage(res.lastPage);
    setTotalCount(res.dataCount);
    setSelectedItem(res.eSignatureList[0]);
    // setEditItem(res.eSignatureList[0]);
    
    setCurrentPage(1);
    setStatusFilter(Number(E_SIGNATURE_STATUS.ALL));
    setSearchTerm('');
    setSortConfig({ key: 'eSignatureId', direction: true });
    resetScroll();
    resetScrollMain();
  }

  const resetScroll = () => {
    const scrollForm = document.querySelector('.scroll-form');
    if (scrollForm) {
      scrollForm.scrollTop = 0;
    }
  }

  const resetScrollMain = () => {
    const scrollForm = document.querySelector('.card-list');
    if (scrollForm) {
      scrollForm.scrollTop = 0;
    }
  }

  // ------------------------------------------------------------------------------------
  // ValidationCheck
  // ------------------------------------------------------------------------------------
  const validateCheck = (params) => {
    // テンプレートID
    const templateIdResult = Validators.validateTemplateId(params.templateId);
    if (templateIdResult.error) {
      return { message: templateIdResult.message, error: true };
    }
    // テンプレート名
    const templateNameResult = Validators.validateTemplateName(params.templateName);
    if (templateNameResult.error) {
      return { message: templateNameResult.message, error: true };
    }
    // テンプレート名（かな）
    const templateNameKanaResult = Validators.validateTemplateNameKana(params.templateNameKana);
    if (templateNameKanaResult.error) {
      return { message: templateNameKanaResult.message, error: true };
    }
    // テンプレート文書
    const documentListResult = Validators.validateTemplateDocument(params.documentList);
    if (documentListResult.error) {
      return { message: documentListResult.message, error: true };
    }
    
    if(params.publishScope === PUBLISH_SCOPE.LIMITED) {
      // 公開先
      const publishCompanyListResult = Validators.validateTemplatePublishCompanyList(params.publishCompanyList);
      if (publishCompanyListResult.error) {
        return { message: publishCompanyListResult.message, error: true };
      }
    }
    return { message: '', error: false };
  }


  // ------------------------------------------------------------------------------------
  // 一覧作成
  // ------------------------------------------------------------------------------------ 
  const createTable = (itemList) => {
    return (
      <div className="card-list">
        {itemList.length === 0 ? (
          <div className="no-data">データがありません</div>
        ) : (
          itemList.map((item, index) => (
            <div className="card" key={item.templateId || index} onClick={(e) => handleEdit(e, item)}>
              <div className="card-contents" id="e-signature-master-card-contents">
                <div className="card-contents-left">
                  <h3 className="card-title">{item.templateName}</h3>
                  <span className="sub-text">{item.templateId}</span>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    );
  }

  // ------------------------------------------------------------------------------------
  // AddTable
  // ------------------------------------------------------------------------------------
  const createAddTable = () => {
    return (
      <>
        <div className={`${size.width > 1200 ? 'scroll-form ' : ''}`}>
          <ESignatureForm
            formData={addItem}
            setFormData={setAddItem}
            isEdit={false}
          />
        </div>
        <div className="bottom-button">
          <button className="blue" onClick={handleAddSubmit}>
            登録
          </button>
        </div>
      </>
    )
  }

  // ------------------------------------------------------------------------------------
  // EditTable
  // ------------------------------------------------------------------------------------
  const createEditTable = () => {
    if (!selectedItem || Object.keys(selectedItem).length === 0) {
      return null;
    }

    return (
      <>
        <div className={`${size.width > 1200 ? 'scroll-form ' : ''}`}>
          <ESignatureForm
            formData={selectedItem}
            setFormData={setSelectedItem}
            isEdit={true}
          />
        </div>
        <div className="bottom-button">
          <button className="red" onClick={handleDeleteSubmit}>
            削除
          </button>
        </div>
      </>
    )
  }

  // ------------------------------------------------------------------------------------
  // レンダリング
  // ------------------------------------------------------------------------------------
  return (
    <div className="view-contents" id="e-signature-list">
      <Loading isLoading={isLoading} />
      <BottomSheet ref={bottomSheetRef} />

      <div className="main-contents">
        {size.width > 1200 && (<h2 className="page-title">電子サインマスタ</h2>)}
        <div className="header-contents">
          <div className="search-bar">
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              <input
                type="text"
                placeholder="テンプレート名で検索"
                value={searchTerm || ''}
                onChange={(e) => setSearchTerm(e.target.value)}
                maxLength={20}
                style={{
                  backgroundImage: 'url(/images/search.png)',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '10px center',
                  backgroundSize: '20px 20px',
                  paddingLeft: '40px',
                  paddingRight: searchTerm ? '30px' : '10px'
                }}
              />
              {searchTerm && (
                <button
                  onClick={onClear}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '16px',
                    color: '#878787'
                  }}>
                  ×
                </button>
              )}
            </div>
          </div>
          <div className="button-container">
            <div className="add-new-item-button" onClick={handleAdd}>
              <svg
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                width="18px"
                height="18px"
                viewBox="0 0 24 24"
                aria-labelledby="plusIconTitle"
                stroke="var(--blue-font-color)"
                strokeWidth="1.8"
                strokeLinecap="square"
                strokeLinejoin="miter"
                fill="none"
                color="var(--blue-font-color)"
              >
                <title id="plusIconTitle">新規追加</title>
                <path d="M20 12L4 12M12 4L12 20" />
              </svg>
              <p className="hide-option">新規追加</p>
            </div>
          </div>
        </div>

        <div className="middle-contents">
          <div className="left-contents">
            <FilterButton
              statusFilter={statusFilter}
              handleStatusChange={handleStatusChange}
              statusOptions={statusOptions}
            />
            <SortingButton
              sortConfig={sortConfig}
              handleSortChange={handleSortChange}
              sortOptions={sortOptions}
            />
          </div>
          <Pagination
            totalPages={lastPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            totalItems={totalCount}
          />
        </div>

        {createTable(sortedAndFilteredItems)}
      </div>

      {size.width > 1200 ? (
        <>
          {showAdd && (
            <div className="sub-contents">
              <h2 className="page-title">新規追加</h2>
              {createAddTable()}
            </div>
          )}

          {showEdit && selectedItem && Object.keys(selectedItem).length > 0 && (
            <div className="sub-contents">
              <h2 className="page-title">{selectedItem.templateName}</h2>
              {createEditTable()}
            </div>
          )}
        </>
      ) : (
        <>
          <Modal
            isOpen={showAdd}
            title="新規追加"
            actionButtonText="登録"
            onAction={handleAddSubmit}
            closeButtonText="閉じる"
            closeModal={() => setShowAdd(false)}
          >
            <div className="sub-contents">
              {createAddTable()}
            </div>
          </Modal>
            
          <Modal
            isOpen={showEdit && selectedItem && Object.keys(selectedItem).length > 0}
            title={selectedItem?.templateName || ''}
            closeButtonText="閉じる"
            closeModal={() => setShowEdit(false)}
          >
            <div className="sub-contents">
              {createEditTable()}
            </div>
          </Modal>
        </>
      )}
    </div>
  )
}

export default ESignatureMaster;