/* ==================== */
/* 通知画面 */
/* ==================== */
import React, { useEffect, useMemo, useState, useContext } from 'react';
import FilterButton from '../../components/FilterButton/FilterButton';
import Pagination from '../../components/Pagination/Pagination';
import Loading from '../../components/Loading/Loading';
import Modal from '../../components/Modal/Modal';
import SortingButton from '../../components/SortingButton/SortingButton';
import { READ_STATUS, RESPONSE } from '../../const/Enum';
import useWindowSize from '../../hook/useWindowSize';
import { requestApiLoad } from '../../utils/apiLoadUtil';
import { UnreadCountContext } from '../../context/UnreadCountContext';
import './Notification.css';

const Notification = () => {
  const [itemList, setItemList] = useState([]);
  const [isInit, setIsInit] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState(READ_STATUS.ALL);
  const [sortConfig, setSortConfig] = useState({ key: 'notificationId', direction: true });
  const [totalCount, setTotalCount] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [showDetail, setShowDetail] = useState(false);
  const { decrementUnreadCount, setUnreadCount } = useContext(UnreadCountContext);
  const size = useWindowSize();
  const statusOptions = [
    { value: READ_STATUS.ALL, label: 'すべて' },
    { value: READ_STATUS.UNREAD, label: '未読' },
    { value: READ_STATUS.READ, label: '既読' }
  ];
  const sortOptions = [
    { key: 'notificationId', value: { key: 'notificationId', direction: true }, label: '標準' },
    { key: 'date_asc', value: { key: 'date', direction: false }, label: '日付（昇順）' },
    { key: 'date_desc', value: { key: 'date', direction: true }, label: '日付（降順）' }
  ];
  
  // ------------------------------------------------------------------------------------
  // 初期処理
  // ------------------------------------------------------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        page: 1,
        isDesc: sortConfig.direction,
        orderKey: sortConfig.key,
        status: statusFilter,
        name: searchTerm,
      };
    
      const res = await requestApiLoad('/notification/get', params, setIsLoading);
      if (res.return !== RESPONSE.SUCCESS) {
        return;
      }

      setSelectedItem((res.notificationList && res.notificationList.length > 0) ? res.notificationList[0] : []);
      setItemList((res.notificationList && res.notificationList.length > 0) ? res.notificationList : []);
      setCurrentPage(1);
      setLastPage(res.lastPage);
      setTotalCount(res.dataCount);
    }

    fetchData();
  }, [statusFilter, sortConfig.direction, sortConfig.key, searchTerm]);

  useEffect(() => {
    const subInit = async () => {
      if (!isInit) {
        return;
      }

      if (itemList.length > 0) {
        if (size.width > 1200) {
          changeScreenMode(MODE.DETAIL);
        } else {
          changeScreenMode(MODE.NONE);
        }
        setIsInit(false);
      }
    }
    subInit();
    // eslint-disable-next-line
  }, [itemList]);
  
  // ------------------------------------------------------------------------------------
  // 未読通知の取得
  // ------------------------------------------------------------------------------------
  const fetchUnreadCount = async () => {
    const res = await requestApiLoad('/notification/getUnreadCount', {}, setIsLoading);
    if (res.return === RESPONSE.SUCCESS) {
      setUnreadCount(res.unreadCount);
    }
  };

  useEffect(() => {
    fetchUnreadCount();
    // eslint-disable-next-line
  }, []);

  // ------------------------------------------------------------------------------------
  // 画面モード
  // ------------------------------------------------------------------------------------
  const MODE = {
    NONE: 'NONE',
    DETAIL: 'DETAIL',
  }

  const changeScreenMode = (mode) => {
    switch (mode) {   
      case MODE.DETAIL:
        setShowDetail(true);
        break;
   
      case MODE.NONE:
      default:
        setShowDetail(false);
        break;
    }
  }

  // ------------------------------------------------------------------------------------
  // ページネーション
  // ------------------------------------------------------------------------------------
  const handlePageChange = async (pageNumber) => {
    setCurrentPage(pageNumber);

    const params = {
      page: pageNumber,
      isDesc: sortConfig.direction,
      orderKey: sortConfig.key,
      status: statusFilter,
      name: searchTerm,
    }

    const res = await requestApiLoad('/notification/get', params, setIsLoading);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }
    
    setSelectedItem(res.notificationList[0]);
    setItemList(res.notificationList);
    setLastPage(res.lastPage);
    setTotalCount(res.dataCount);
  }

  // ------------------------------------------------------------------------------------
  // フィルター
  // ------------------------------------------------------------------------------------
  const handleStatusChange = async (event) => {
    const newStatusFilter = !event.target.value ? READ_STATUS.ALL : Number(event.target.value);
    setCurrentPage(1);
    setStatusFilter(newStatusFilter);
  };

  const sortedAndFilteredItems = useMemo(() => {
    let filteredItems = itemList || [];
    return filteredItems;
  }, [itemList]);

  // ------------------------------------------------------------------------------------
  // ソート
  // ------------------------------------------------------------------------------------
  const handleSortChange = async (event) => {
    const newSortConfig = !event ? { key: 'notificationId', direction: true } : JSON.parse(event);
    setSortConfig(newSortConfig);
  };

  // ------------------------------------------------------------------------------------
  // 検索
  // ------------------------------------------------------------------------------------
  const onClear = () => {
    setSearchTerm('');
  };

  // ------------------------------------------------------------------------------------
  // 詳細
  // ------------------------------------------------------------------------------------
  const handleDetail = async (item) => {
    if (!item) { return; }
    setSelectedItem(item);

    if (!item.isRead) {
      const res = await requestApiLoad(`/notification/read/${item.notificationId}`, {}, setIsLoading);
      if (res.return === RESPONSE.SUCCESS) {
        item.isRead = true;
        setSelectedItem({ ...item });
        decrementUnreadCount();
        
        // 一覧の該当項目も更新
        setItemList(prevList => prevList.map(listItem =>
          listItem.notificationId === item.notificationId ? { ...listItem, isRead: true } : listItem
        ));
      }
    }

    changeScreenMode(MODE.DETAIL);
  }

  // ------------------------------------------------------------------------------------
  // 一覧作成
  // ------------------------------------------------------------------------------------
  const createTable = (itemList) => {
    return (
      <div className="card-list">
        {itemList.length === 0 ? (
          <div className="no-data">データがありません</div>
        ) : (
          itemList.map((item) => (
            <div className="card" key={item.notificationId} onClick={() => handleDetail(item)}>
              <div className="card-contents" >
                <div className="card-contents-left">
                  <h3>{item.title}</h3>
                  <span className="sub-text">{item.date}</span>
                </div>
                {!item.isRead && <div className="unread-badge"></div>}
              </div>
            </div>
          ))
        )}
      </div>
    )
  }

  // ------------------------------------------------------------------------------------
  // 詳細
  // ------------------------------------------------------------------------------------
  const createDetailTable = (detailItem) => {
    return (
      <div className="card-list">
        {itemList.length === 0 ? (
          <div className="no-data">データがありません</div>
        ) : (
          <div className='column-set'>
            <div className="row-set">
              <div className="input-group" id="notification-date">
                <label>受信日時</label>
                <input
                  id="notification-date"
                  type="text"
                  name="date"
                  value={detailItem.date}
                  disabled
                />
              </div>
            </div>

            <div className="row-set" id="if-dates">
              <div className="input-group">
                <label>内容</label>
                <textarea
                  name="contents"
                  value={detailItem.contents}
                  disabled
                  style={{ height: 300 }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  // ------------------------------------------------------------------------------------
  // レンダリング
  // ------------------------------------------------------------------------------------
  return (
    <div className="view-contents" id="notification">
      <Loading isLoading={isLoading} />

      <div className="main-contents">
        {size.width > 1200 && (<h2 className="page-title">通知一覧</h2>)}
        <div className="header-contents">
          <div className="search-bar">
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              <input
                type="text"
                placeholder="タイトルで検索"
                value={searchTerm || ''}
                onChange={(e) => setSearchTerm(e.target.value)}
                maxLength={20}
                style={{
                  backgroundImage: 'url(/images/search.png)',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '10px center',
                  backgroundSize: '20px 20px',
                  paddingLeft: '40px',
                  paddingRight: searchTerm ? '30px' : '10px'
                }}
              />
              {searchTerm && (
                <button
                  onClick={onClear}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '16px',
                    color: '#878787'
                  }}>
                  ×
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="middle-contents">
          <div className="left-contents">
            <FilterButton
              statusFilter={statusFilter}
              handleStatusChange={handleStatusChange}
              statusOptions={statusOptions}
            />
            <SortingButton
              sortConfig={sortConfig}
              handleSortChange={handleSortChange}
              sortOptions={sortOptions}
            />
          </div>
          
          <Pagination
            totalPages={lastPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            totalItems={totalCount}
          />
        </div>

        {createTable(sortedAndFilteredItems)}
      </div>
      
      {size.width > 1200 ? (
        <>
          {showDetail && (
            <div className="sub-contents">
              <h2 className="page-title">{selectedItem.title}</h2>
              {createDetailTable(selectedItem)}
            </div>
          )}
        </>
      ) : (
        <>
          <Modal
            isOpen={showDetail}
            title={selectedItem.title}
            closeModal={() => setShowDetail(false)}
            closeButtonText="閉じる"
          >
            <div className="sub-contents">
              {createDetailTable(selectedItem)}
            </div>
          </Modal>
        </>
      )}
    </div>
  )
}

export default Notification;