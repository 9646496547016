import React, { useState, useRef, useEffect } from 'react';
import './FilterButton.css';

const FilterButton = ({ statusFilter, handleStatusChange, statusOptions, disabled = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState('');
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const selectedOption = statusOptions.find(option => option.value === statusFilter);
    setSelectedLabel(selectedOption ? selectedOption.label : '');
  }, [statusFilter, statusOptions]);

  return (
    <div className="filter-button">
      <div className="button-container">
        <svg
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          width="20px"
          height="20px"
          viewBox="0 0 24 24"
          aria-labelledby="filterIconTitle"
          stroke="var(--blue-font-color)"
          strokeWidth="1.5"
          strokeLinecap="square"
          strokeLinejoin="miter"
          fill="none"
          color="var(--blue-font-color)"
        >
          <title id="filterIconTitle">Filter</title>
          <path d="M10 12.261L4.028 3.972h16L14 12.329V17l-4 3z" />
        </svg>
        <button className="blue filter-button-label-wrapper" onClick={() => setIsOpen(!isOpen)}>
          <span className="filter-button-label">絞り込み：</span>{selectedLabel && `${selectedLabel}`}
        </button>
      </div>

      {isOpen && (
        <div className="filter-button-popup" ref={popupRef}>
          {statusOptions.map((option, index) => (
            <label
              key={`${option.value}-${index}`}
              className={`filter-button ${disabled ? 'disabled' : ''}`}
            >
              <input
                type="radio"
                name="status"
                value={option.value}
                checked={statusFilter === option.value}
                onChange={(e) => {
                  handleStatusChange(e);
                  setSelectedLabel(option.label);
                  setIsOpen(false);
                }}
                disabled={disabled}
              />
              <p>{option.label}</p>
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterButton;